import Maps from "@components/Maps/Maps"
import styled from "styled-components"

function MapsComponent() {
  return (
    <Wrapper>
      <Maps />
    </Wrapper>
  )
}

export default MapsComponent

const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  z-index: 1;
`
