import ArrowButton from "@components/Shared/ArrowButton"
import { globalStyles } from "@data/globalStyles"
import { useStore } from "@state/store"

export default function MapsButtons() {
  const mapFilter = useStore((state) => state.mapFilter)
  const syncState = useStore((state) => state.syncState)
  return (
    <div
      style={{
        zIndex: 10,
        transition: "opacity 0.3s, margin 0.3s",
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        display: "grid",
        placeSelf: "end start",
        height: "4rem",
        margin: "1rem 4rem",
        columnGap: "2rem",
        placeItems: "center",
        gridAutoFlow: "column",
      }}
    >
      {[
        "Les Bordes Estate",
        "Loire Valley",
        "Destination"
        // , "Destinations"
      ].map((item, index) => {
        const active = item === mapFilter
        return (
          <button
            onClick={() => {
              item === "Les Bordes Estate" && syncState("mapSubFilter", null)
              item === "Loire Valley" && syncState("mapSubFilter", "SIGHTS")
              item === "Destination" && syncState("mapSubFilter", null)
              syncState("mapFilter", item)
              syncState("activeMapItem", null)
            }}
            key={index}
            style={{
              cursor: "pointer",
              padding: "0.7rem 1.2rem",
              boxShadow: "none",
              backgroundColor: active ? globalStyles.col.blue : "transparent",
              border: active
                ? "1px solid #ffffff0"
                : `1px solid ${globalStyles.col.blue}`,
              color: active ? globalStyles.col.white : globalStyles.col.blue,
              fontFamily: "Canto Roman",
              fontSize: "1.3rem",
              lineHeight: "1.2rem",
              letterSpacing: "0.1rem",
              pointerEvents: "auto",
              transition: "background-color 0.8s, color 0.8s",
            }}
          >
            {item}
          </button>
        )
      })}
    </div>
  )
}
