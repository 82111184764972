export default function Video() {
  return (
    <>
      <div
        style={{
          position: "fixed",
          inset: 0,
          height: "100%",
          width: "100%",
          display: "grid",
          placeItems: "center",
        }}
      >
        <video
          src="/videos/video-1.mp4"
          autoPlay
          controls
          loop
          playsInline
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            objectPosition: "center",
            zIndex: 1,
            pointerEvents: "auto",
            touchAction: "auto",
          }}
        />
      </div>
    </>
  )
}
