import { globalStyles } from "@data/globalStyles"
import { mapsData } from "@data/mapsData"
import { useStore } from "@state/store"
import { createRef, useEffect, useRef } from "react"

const adjustIndex = (index, mapSubFilter) => {
  switch (mapSubFilter) {
    case "SIGHTS":
      return index + 1
    case "FOOD":
      return index + 20
    case "ACTIVITIES":
      return index + 30
    case "TRANSPORT":
      return index + 38
    default:
      return index + 1
  }
}

export default function SidebarInfo() {
  const mapFilter = useStore((state) => state.mapFilter)
  const mapSubFilter = useStore((state) => state.mapSubFilter)
  const activeMapItem = useStore((state) => state.activeMapItem)
  const syncState = useStore((state) => state.syncState)
  const scrollContainerRef = useRef(null)

  //
  const dataPath =
    mapSubFilter !== null
      ? mapsData[mapFilter][mapSubFilter]
      : mapsData[mapFilter]

  const itemRefs = useRef(
    dataPath?.length > 0 ? dataPath?.map(() => createRef()) : []
  )

  useEffect(() => {
    if (activeMapItem && dataPath) {
      const index =
        dataPath?.findIndex((item) => item.name === activeMapItem) ?? null
      if (index === null) return
      const activeElementRef = itemRefs.current[index]

      if (activeElementRef && activeElementRef.current) {
        // const contentHeight = activeElementRef.current.scrollHeight
        // const adjustedHeight = `${contentHeight}px`
        // activeElementRef.current.style.minHeight = adjustedHeight
        setTimeout(() => {
          activeElementRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          })
        }, 500)
      }
    }
  }, [activeMapItem, dataPath])

  return (
    <>
      <div
        style={{
          // gridColumn: "1 / -1",
          // gridRow: 3,
          height: "100%",
          width: "100%",
          display: "grid",
          placeItems: "center",
          overflow: "hidden",
          backgroundColor: "#f6f5efad",
        }}
      >
        <div
          ref={scrollContainerRef}
          style={{
            height: "100%",
            width: "100%",
            gridColumn: "1 / -1",
            gridRow: "1 / -1",
            display: "grid",
            placeItems: "center",
            gridAutoFlow: "row",
            overflowY: "auto",
            pointerEvents: "auto",
            touchAction: "auto",
            zIndex: 3,
          }}
        >
          {dataPath?.length > 0 &&
            dataPath.map((item, index) => {
              const active = item.name === activeMapItem
              const adjustedIndex = adjustIndex(index, mapSubFilter)

              return (
                <div
                  ref={itemRefs.current[index]}
                  key={index}
                  style={{
                    height: "100%",
                    minHeight: active
                      ? `${
                          mapFilter === "Les Bordes Estate"
                            ? 34
                            : 30 + 0.06 * item?.description?.length
                        }rem`
                      : "7rem",
                    width: "100%",
                    WebkitTapHighlightColor: "transparent",
                    pointerEvents: "auto",
                    touchAction: "pan-y",
                    display: "grid",
                    placeItems: "center",
                    gridAutoFlow: "row",
                    border: `0.5px solid ${globalStyles.col.blue}`,
                    overflow: "hidden",
                    padding: "2rem",
                    transition:
                      "grid-template-rows 0.3s ease-in-out, height 0.3s ease-in-out, min-height 0.3s ease-in-out",
                  }}
                  onClick={() => {
                    if (activeMapItem === item.name) {
                      syncState("activeMapIndex", null)
                      syncState("activeMapItem", null)
                    } else {
                      syncState("activeMapIndex", adjustedIndex)
                      syncState("activeMapItem", item.name)
                    }
                  }}
                  id={item.name}
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                      gridAutoFlow: "column",
                      columnGap: "1rem",
                      gridTemplateColumns:
                        "minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr)",
                      color: globalStyles.col.blue,
                      pointerEvents: "none",
                      touchAction: "none",
                    }}
                  >
                    <span
                      style={{
                        placeSelf: "center end",
                        width: "3rem",
                        aspectRatio: "1 / 1",
                        //   padding: "0.5rem",
                        border: `1px solid ${globalStyles.col.blue}`,
                        borderRadius: "100rem",
                        display: "grid",
                        placeItems: "center",
                        color: globalStyles.col.blue,
                        pointerEvents: "none",
                        touchAction: "none",
                      }}
                    >
                      {adjustedIndex}
                    </span>
                    <span
                      style={{
                        textTransform: "uppercase",
                        fontSize: "1.6vh",
                        letterSpacing: "0.1rem",
                        textAlign: "center",
                        width: "88%",
                        pointerEvents: "none",
                        touchAction: "none",
                      }}
                    >
                      {item.name}
                    </span>
                    <span />
                  </div>
                  <div
                    style={{
                      display: active ? "grid" : "none",
                      overflow: "hidden",
                      transformOrigin: "top",
                      transition:
                        "transform 0.3s ease-in-out ease, opacity 0.3s ease-in-out ease",
                      opacity: active ? 1 : 0,
                      transform: active ? "scaleY(1)" : "scaleY(0)",
                      rowGap: "2rem",
                      placeItems: "center",
                      color: globalStyles.col.blue,
                      pointerEvents: "none",
                      touchAction: "none",
                    }}
                  >
                    {item?.url && (
                      <div
                        style={{
                          marginTop: "3rem",
                          opacity: active ? 1 : 0,
                          transition: "opacity 0.3s 0.1s ease-in-out",
                          overflow: "hidden",
                          display: "grid",
                          placeItems: "center",
                          pointerEvents: "none",
                          touchAction: "none",
                        }}
                      >
                        <img
                          alt="SIX SENSES"
                          src={item.url}
                          style={{
                            height: "15rem",
                            // width: "70%",
                            overflow: "hidden",
                          }}
                        />
                      </div>
                    )}
                    {item?.description && (
                      <p
                        style={{
                          overflow: "hidden",
                          display: "grid",

                          placeItems: "center",
                          textAlign: "center",
                          width: "80%",
                          fontSize: "1.6vh",
                          lineHeight: "1.6vh",
                        }}
                      >
                        {item?.description}
                      </p>
                    )}
                    {(mapFilter === "Loire Valley" || mapFilter === "Destination")  && (
                      <p style={{ overflow: "hidden" }}>
                        {item?.area} | {item?.distance} km | {item?.time} Minute
                        Drive
                      </p>
                    )}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
      {/* <div
        style={{
          zIndex: 4,
          height: "100%",
          width: "100%",
          gridColumn: "1 / -1",
          gridRow: 3,
          display: "grid",
          placeItems: "center",
          gridAutoFlow: "row",
          background:
            "linear-gradient(180deg, rgba(78, 118, 155, 0.00) 84.78%, rgba(27, 40, 53, 0.30) 98.57%)",
        }}
      /> */}
    </>
  )
}
