export const LifeStyleData = [
  {
    type: "title",
    state: "none",
    text: "Lifestyle & Community with Six Senses",
    subtext:
      "In a hectic modern world that often pulls you from what matters most - family, friends, and nature - let Six Senses Loire Valley be your sanctuary, calling you back to Earth amidst the chaos.",
    arrow: "USE ARROWS TO EXPLORE",
  },
  {
    state: "eat",
    type: "title",
    text: "Eat with Six Senses",
    arrow: "EAT WITH SIX SENSES",
  },
  {
    state: "eat",
    type: "card",
    left: "Chefs are guided by the principles of natural ingredients, local and sustainable and less is more – curating hearty, healthy and homegrown menus while removing the bad stuff.",
    right:
      "Indulge in locally-sourced French cuisine at the Estate’s beautifully-preserved château, home to a gastronomic restaurant with indoor and outdoor seating, a private dining area, a lounge bar and a wine cellar.",
    arrow: "WELLNESS WITH SIX SENSES",
  },
  {
    state: "wellness",
    type: "title",
    text: "Wellness with Six Senses",
    arrow: "WELLNESS WITH SIX SENSES",
  },
  {
    state: "wellness",
    type: "card",
    left: "An on-site organic garden will grow plants and herbs for locally inspired treatments and Alchemy Bar workshops.",
    right:
      "The 18,000-square-foot Six Senses Spa will provide signature services such as massages, facials, and body treatments, as well as holistic programming including sleep regimens, fitness classes, meditation, and more.",
    arrow: "GROW WITH SIX SENSES",
  },
  {
    state: "grow",
    type: "title",
    text: "Grow with Six Senses",
    arrow: "GROW WITH SIX SENSES",
  },
  {
    state: "grow",
    type: "card",
    text: "Grow with Six Senses",
    arrow: "SLEEP WITH SIX SENSES",
    left: "Six Senses children’s activities promote positive play and development by incorporating the six dimensions of wellness: social, environmental, physical, spiritual, emotional and intellectual.",
    right:
      "The Estate is brimming with activities for children, including archery, cycling and electric quad paths, electric go-kart track, children’s playground, zipline, and petting farm, as well as forthcoming amenities like a kids’ club and ice cream bar.",
  },
  {
    state: "sleep",
    type: "title",
    text: "Sleep with Six Senses",
    arrow: "SLEEP WITH SIX SENSES",
  },

  {
    state: "sleep",
    type: "card",
    arrow: "SIX SENSES EARTH LAB",
    left: "From handmade mattresses and temperature regulating bedding to organic cotton sheets, sleep apps and more, Six Senses provides ideal conditions for a restful slumber.",
    right:
      "With fully-serviced living, a Six Senses Branded Residence is the perfect option for owners who wish to treat their home like a hotel room.",
  },
  {
    state: "earth",
    type: "title",
    text: "Six Senses Earth Lab",
    arrow: "SIX SENSES EARTH LAB",
  },
  {
    state: "earth",
    type: "card",
    arrow: null,
    left: "All guests and hosts are welcome to join us and learn some handy life hacks, such as making do-it-yourself compost and chemical-free detergent or extracting the essential oils from herbs grown in our organic gardens.",
    right:
      "Six Senses passion for eco-conscious and sustainable living is group wide. Each property communicates their initiatives on- and off-site at the Earth Lab.",
  },
]
