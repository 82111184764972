import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom"
import DefaultLayout from "./DefaultLayout"
import { motion, AnimatePresence } from "framer-motion"

const pageVariantsTablet = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

function AnimatedRoutes() {
  const location = useLocation()
  return (
    <>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            path="*"
            element={
              <>
                <motion.div
                  variants={pageVariantsTablet}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.8 }}
                  style={{
                    position: "absolute",
                    inset: 0,
                    width: "100%",
                    height: "100%",
                    display: "grid",
                  }}
                >
                  <DefaultLayout />
                </motion.div>
              </>
            }
          />
        </Routes>
      </AnimatePresence>
    </>
  )
}

function App() {
  return (
    <BrowserRouter>
      <AnimatedRoutes />
    </BrowserRouter>
  )
}

export default App
