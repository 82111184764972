import { useLocation, useSearchParams } from "react-router-dom"
import Logo from "./Logo"
import NavDisplayIcon from "./NavDisplayIcon"
import NavPopup from "./NavPopup"
import NavPopup2 from "./NavPopup2"

export default function Nav() {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const favParam = searchParams.get("favs")

  return (
    <nav
      style={{
        position: "fixed",
        inset: 0,
        height: "100%",
        width: "100%",
        zIndex: 9,
        pointerEvents: "none",
        overflow: "hidden",
      }}
    >
      {!(favParam && location.pathname === "/favourites") && <NavPopup2 />}
      {!(favParam && location.pathname === "/favourites") && <NavDisplayIcon />}
      <Logo />
    </nav>
  )
}
