
import Map from "./Map"
import Sidebar from "./Sidebar"
import { useStore } from "@state/store"


export default function Maps() {
  const mapFilter = useStore((state) => state.mapFilter)
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "minmax(0, 7fr) minmax(0, 3fr)",
        gridAutoFlow: "column",
        overflow: "hidden",
        backgroundColor: "#EFEFEF",
      }}
    >
      <Map />
      <Sidebar/>
    </div>
  )
}
