import { useStore } from "@state/store"

export default function ShareSVG({ ...props }) {
  const share = useStore((state) => state.share)
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 336 336"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        style={{
          opacity: share ? 1 : 0,
          transition: "opacity 0.8s ease-in-out",
        }}
        d="M168 0C75.3658 0 0 75.3658 0 168C0 260.634 75.3658 336 168 336C260.634 336 336 260.634 336 168C336 75.3658 260.634 0 168 0ZM228.827 210.557C233.997 215.468 234.207 223.65 229.288 228.827C224.369 233.997 216.187 234.207 211.018 229.288C210.856 229.134 210.695 228.973 210.549 228.819L167.992 186.27L125.435 228.819C120.257 233.73 112.075 233.52 107.165 228.351C102.423 223.359 102.423 215.533 107.165 210.541L149.714 167.984L107.165 125.427C102.246 120.249 102.456 112.067 107.625 107.157C112.608 102.415 120.435 102.415 125.427 107.157L167.984 149.706L210.541 107.157C215.71 102.238 223.892 102.448 228.811 107.617C233.544 112.6 233.544 120.427 228.811 125.418L186.262 167.976L228.827 210.557Z"
        fill="white"
      />

      <path
        style={{
          opacity: share ? 0 : 1,
          transition: "opacity 0.8s ease-in-out",
        }}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168 336C260.784 336 336 260.784 336 168C336 75.2162 260.784 0 168 0C75.2162 0 0 75.2162 0 168C0 260.784 75.2162 336 168 336ZM188.124 204.606C193.489 199.352 200.7 196.411 208.212 196.415L208.192 196.394C209.71 196.394 211.223 196.514 212.721 196.752C228.427 199.253 239.136 214.014 236.639 229.721C234.137 245.427 219.377 256.136 203.675 253.639C187.968 251.137 177.26 236.377 179.761 220.674L126.228 190.544C115.549 199.439 100.04 199.439 89.3642 190.544C77.1462 180.367 75.4924 162.208 85.6741 149.99C95.8517 137.772 114.01 136.118 126.232 146.3L179.765 116.187C178.35 107.251 181.23 98.1721 187.54 91.6886C198.59 80.3262 216.761 80.0752 228.123 91.1291C239.485 102.179 239.736 120.35 228.687 131.712C217.637 143.075 199.466 143.326 188.108 132.276L135.809 161.69C136.883 166.125 136.883 170.753 135.809 175.192L188.124 204.606Z"
        fill="white"
      />
    </svg>
  )
}
