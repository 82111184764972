import styled from "styled-components"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import { Fragment, useEffect, useRef } from "react"
import { useStore } from "@state/store"

export default function Transform({ children, ...props }) {
  const ref = useRef(null)
  const mapFilter = useStore((state) => state.mapFilter)
  useEffect(() => {
    if (ref.current) {
      ref.current.resetTransform()
    }
  }, [mapFilter])
  return (
    <Wrapper
      style={{
        mask: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 10%, #000 20.39%, #000 80.28%, rgba(0, 0, 0, 0.00) 90%)",
      }}
      {...props}
    >
      <ZoomWrapper>
        <TransformWrapper
          ref={ref}
          centerOnInit={true}
          initialScale={1}
          initialPositionX={0}
          initialPositionY={0}
          minScale={1}
          maxScale={10}
          alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
          limitToBounds={true}
          smooth={true}
          centerZoomedOut={true}
          doubleClick={{
            mode: "reset",
          }}
        >
          <div
            style={{
              mask: "linear-gradient(270deg, rgba(0, 0, 0, 0.00) 5%, #000 10.39%, #000 95.28%, rgba(0, 0, 0, 0.00) 100%)",
              transform: "scale(1.03)",
              border: "5px pink solid",
              height: "100%",
              width: "100%",
              display: "grid",
              placeItems: "center",
              // placeSelf: "center",
              // padding: "3rem",
            }}
          >
            <TransformComponent
              wrapperStyle={{
                height: "100%",
                width: "100%",
                display: "grid",
                placeItems: "center",
              }}
              contentStyle={{
                height: "100%",
                width: "100%",
                display: "grid",
                placeItems: "center",
              }}
            >
              {children}
            </TransformComponent>
          </div>
        </TransformWrapper>
      </ZoomWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  display: grid;
  place-items: center;
  pointer-events: auto;
  z-index: 2;
`

const ZoomWrapper = styled.div`
  overflow: hidden;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  z-index: 2;
  overscroll-behavior: none;
  display: grid;
  place-items: center;

  & svg {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    height: 100%;
    width: 100%;
    /* max-width: 100%;
    height: auto; */
    display: block;
    margin: 0 auto;
    border: 0px red solid;
    padding: 0;
  }
`
