/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import { useCallback, useRef, useEffect, memo } from "react"
import TWEEN from "@tweenjs/tween.js"
import { useThree, extend, useFrame } from "@react-three/fiber"
import { Vector3 } from "three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

import animateCamera from "./anim"

extend({ OrbitControls })
function Camera({ rotate, enablePan, enableZoom, camTarget, canClick }) {
  const thisArea = useRef()
  const [targetA, setTargetA] = React.useState({
    target: [0, 0, 0],
    position: [5.1, 3.7, 0]
  })

  const controls = useRef()
  const {
    camera,
    gl: { domElement }
  } = useThree()
  const animateToLookAt = useCallback(
    (destination, factor = 1.0) => {
      const { camera: position, target, fov, cb } = destination
      if (position && target && controls && fov) {
        const [cx, cy, cz] = position
        const [tx, ty, tz] = target

        animateCamera(
          camera,
          controls.current,
          {
            position: {
              x: cx,
              y: cy,
              z: cz
            },
            target: {
              x: tx,
              y: ty,
              z: tz
            },
            duration: 2000
          },
          () => cb()
        )
      }
    },
    [camera]
  )

  function getResetPos(currentRegion, newRegion) {
    const regionGroups = {
      europe: ["France", "UK", "Switzerland", "Germany", "Spain", "Italy"],
      usa: ["USA"],
      asia: ["Hong Kong", "Singapore", "South Korea"]
    }

    if (regionGroups.europe.indexOf(currentRegion) > -1) {
      if (regionGroups.europe.indexOf(newRegion) > -1) {
        return null
      }
      if (regionGroups.usa.indexOf(newRegion) > -1) {
        return [5.1, 3.7, 0]
      }
      if (regionGroups.asia.indexOf(newRegion) > -1) {
        return [5.1, 3.7, 0]
      }
    }

    if (regionGroups.usa.indexOf(currentRegion) > -1) {
      if (regionGroups.europe.indexOf(newRegion) > -1) {
        return [5.66, 5.75, 1.21]
      }
      if (regionGroups.usa.indexOf(newRegion) > -1) {
        return null
      }
      if (regionGroups.asia.indexOf(newRegion) > -1) {
        return [5.66, 5.75, 1.21]
      }
    }

    if (regionGroups.asia.indexOf(currentRegion) > -1) {
      if (regionGroups.europe.indexOf(newRegion) > -1) {
        return [1.22, 5.11, -3.11]
      }
      if (regionGroups.usa.indexOf(newRegion) > -1) {
        return [1.22, 5.11, -3.11]
      }
      if (regionGroups.asia.indexOf(newRegion) > -1) {
        return null
      }
    }
  }

  useEffect(() => {
    setTargetA({ target: camTarget.target, position: camTarget.position })
  }, [camTarget])

  useEffect(() => {
    !getResetPos(thisArea.current, camTarget.area)
      ? animateToLookAt({
          target: targetA.target,
          camera: targetA.position,
          fov: 30,
          cb: () => {
            canClick.update(true)
            thisArea.current = camTarget.area
          }
        })
      : animateToLookAt({
          target: targetA.target,
          camera: getResetPos(thisArea.current, camTarget.area),
          fov: 30,
          cb: () =>
            animateToLookAt({
              target: targetA.target,
              camera: targetA.position,
              fov: 30,
              cb: () => {
                canClick.update(true)
                thisArea.current = camTarget.area
              }
            })
        })
  }, [targetA])

  useFrame(({ clock, camera }) => {
    TWEEN.update()
    controls.current.update()
  })

  return (
    <>
      <orbitControls
        minPan={new Vector3(0, 0, 0)}
        maxPan={new Vector3(0, 0, 0)}
        enableZoom={enableZoom}
        enablePan={enablePan}
        enableDamping={true}
        autoRotate={rotate}
        autoRotateSpeed={rotate}
        ref={controls}
        args={[camera, domElement]}
      />
    </>
  )
}

export default memo(Camera)
