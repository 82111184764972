import { globalStyles } from "@data/globalStyles"
import { useStore } from "@state/store"

export default function Title() {
  const mapFilter = useStore((state) => state.mapFilter)
  return (
    <>
      <div
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          height: "12rem",
          width: "100%",
          margin: "0rem 0rem 4rem 8rem",
          placeSelf: "start center",
          display: "grid",
          placeItems: "center",
          gridAutoFlow: "row",
          textAlign: "center",
          pointerEvents: "none",
          zIndex: 3,
        }}
      >
        <p
          style={{
            display: "grid",
            placeItems: "center",
            fontSize: "2rem",
            lineHeight: "1rem",
            letterSpacing: "0.8rem",
            color: globalStyles.col.blue,
          }}
        >
          {mapFilter !== "GLOBAL DESTINATIONS" && (
            <span
              style={{
                fontSize: "1rem",
                lineHeight: "1rem",
                letterSpacing: "0.6rem",
              }}
            >
              WELCOME TO
            </span>
          )}
          <br />
          <span>{mapFilter.toUpperCase()}</span>
        </p>
      </div>
    </>
  )
}
