import { residencesData } from "@data/residencesData"
import { useStore } from "@state/store"
import { useEffect } from "react"

type ResidenceTypes = {
  plot: number
  unitType: string
  phase: string
  poolSize: string
  bathrooms: number
  livingSqm: number
  plotSqm: number
  plotSqft: number
  aspect?: string[]
}

export default function ResidencesFilter() {
  const typeFilter = useStore((state) => state.typeFilter)
  const orientationFilter = useStore((state) => state.orientationFilter)
  const syncState = useStore((state) => state.syncState)

  useEffect(() => {
    const filteredResidences = residencesData.filter(
      (residence: ResidenceTypes) => {
        // Check if the residence type matches any in the typeFilter array
        const typeMatch =
          typeFilter?.length === 0 || typeFilter.includes(residence?.unitType)
        // Check if the residence beds match any in the bedFilter array
        const orientationMatch =
          orientationFilter?.length === 0 ||
          (residence.aspect &&
            residence.aspect.some((aspect) =>
              orientationFilter.includes(aspect)
            ))

        return typeMatch && orientationMatch
      }
    )

    syncState("filterResults", filteredResidences)
  }, [typeFilter, orientationFilter])

  return null
}
