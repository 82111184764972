import Residences from "@components/Residences/Residences"
import styled from "styled-components"

function ResidencesComponent() {
  return (
    <Wrapper>
      {/* // */}
      <Residences />
    </Wrapper>
  )
}

export default ResidencesComponent

const Wrapper = styled.div`
  overflow: hidden;
  display: grid;
`
