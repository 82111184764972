import TCanvas from "@canvas_components/TCanvas"
import Content from "./Content"
import Nav from "@components/Nav/Nav"
import { useLocation, useSearchParams } from "react-router-dom"
import SVGAnalyzer from "@utils/svgAnalyser"
import { useEffect, useState } from "react"
import { useStore } from "@state/store"
import { globalStyles } from "@data/globalStyles"
import Login from "@utils/login"

const darkLogoPages = ["/maps"]

function DefaultLayout() {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const favParam = searchParams.get("favs")
  const darkLogo = useStore((state) => state.darkLogo)
  const typeFilter = useStore((state) => state.typeFilter)
  const filter = useStore((state) => state.filter)
  const residencePopupCategory = useStore(
    (state) => state.residencePopupCategory
  )
  const syncState = useStore((s) => s.syncState)
  const residencePopup = useStore((state) => state.residencePopup)
  const [isPortrait, setIsPortrait] = useState(
    window.innerHeight > window.innerWidth
  )
  const auth = useStore((state) => state.auth)

  useEffect(() => {
    fetch(`https://api-gateway.vmiservers.com/sixsenses/houses`)
      .then((res) => res.json())
      .then((res) => {
        const propertiesById = {}
        Object.keys(res.payload.properties).forEach((key) => {
          const property = res.payload.properties[key]
          propertiesById[property.id] = {
            publicPrice: property.publicPrice,
            status: property.status,
          }
        })
        syncState("unitData", propertiesById)
      })
      .catch((error) => {
        console.error("Cannot fetch data")
      })
  }, [])

  useEffect(() => {
    const dark =
      darkLogoPages.includes(location.pathname) ||
      (residencePopupCategory !== "Render" && residencePopup)
    syncState("hideLogo", location.pathname === "/")
    syncState(
      "darkLogo",

      dark
    )
    residencePopup && filter && syncState("filter", false)
  }, [location, residencePopup, residencePopupCategory])

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth)
    }

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <>
      <div
        style={{
          opacity: isPortrait ? 1 : 0,

          pointerEvents: isPortrait ? "auto" : "none",
          position: "fixed",
          inset: 0,
          zIndex: 1000,
          height: "100%",
          width: "100%",
          display: "grid",
          placeItems: "center",
          overflow: "hidden",
          backgroundColor: "black",
        }}
      >
        <p
          style={{
            gridColumn: "1 / -1",
            gridRow: "1 / -1",
            color: "white",
            display: "grid",
            placeItems: "center",
            fontSize: "6rem",
            lineHeight: "5rem",
            zIndex: 1000,
            width: "50%",
            textAlign: "center",
            // overflow: "hidden",
          }}
        >
          Please turn your device to landscape to use this application
        </p>
        <img
          style={{
            gridColumn: "1 / -1",
            gridRow: "1 / -1",
            objectFit: "cover",
            objectPosition: "center",
            overflow: "hidden",
            height: "100%",
            width: "100%",
            opacity: 0.4,
          }}
          src="/renders/6 Bed XL.webp"
          alt="Six Senses"
        />
      </div>

      <div
        style={{
          position: "fixed",
          inset: 0,
          backgroundColor: "black",
          // pointerEvents: "auto",
          // gridColumn: "1 / -1",
          // gridRow: "1 / -1",
          height: "100%",
          width: "100%",
          // display: "grid",
          // overflow: "hidden",
        }}
      >
        {/* <div
        onClick={() => alert("EMMAAAAA")}
        style={{
          pointerEvents: "auto",
          height: "100%",
          width: "100%",
          zIndex: 10,
          position: "fixed",
          inset: 0,
          backgroundColor: "red",
        }}
      ></div> */}
        {/* <SVGAnalyzer /> */}
        <Content />
        <Nav />
        {!(favParam && location.pathname === "/favourites") && <Login />}

        {/* <TCanvas /> */}
      </div>
    </>
  )
}

export default DefaultLayout
