export default function HeartSVG({ $isFavourite, ...props }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        style={{
          opacity: $isFavourite ? 1 : 0,
          transition: "opacity 0.9s",
        }}
        d="M28 0C12.561 0 0 12.561 0 28C0 43.439 12.561 56 28 56C43.439 56 56 43.439 56 28C56 12.561 43.439 0 28 0ZM38.1379 35.0929C38.9994 35.9113 39.0344 37.275 38.2146 38.1379C37.3948 38.9994 36.0312 39.0344 35.1696 38.2146C35.1427 38.189 35.1158 38.1621 35.0915 38.1365L27.9987 31.045L20.9058 38.1365C20.0429 38.955 18.6792 38.92 17.8608 38.0585C17.0706 37.2265 17.0706 35.9221 17.8608 35.0902L24.9523 27.9973L17.8608 20.9044C17.041 20.0415 17.076 18.6779 17.9375 17.8594C18.7681 17.0692 20.0725 17.0692 20.9044 17.8594L27.9973 24.951L35.0902 17.8594C35.9517 17.0396 37.3154 17.0746 38.1352 17.9362C38.924 18.7667 38.924 20.0712 38.1352 20.9031L31.0437 27.996L38.1379 35.0929Z"
        fill="#4E769B"
      />

      <path
        style={{
          opacity: $isFavourite ? 0 : 1,
          transition: "opacity 0.9s",
        }}
        d="M28 0C12.561 0 0 12.561 0 28C0 43.439 12.561 56 28 56C43.439 56 56 43.439 56 28C56 12.561 43.439 0 28 0ZM38.0544 34.0335C36.7917 35.5735 34.5006 38.0356 29.2102 41.6271C28.4792 42.1265 27.5166 42.1265 26.787 41.6271C21.4966 38.0356 19.2054 35.5694 17.9428 34.0335C15.2504 30.7529 13.9608 27.3835 13.9985 23.7354C14.0403 19.5542 17.3935 16.1525 21.4737 16.1525C24.2199 16.1525 26.1853 17.5835 27.4103 18.9C27.7158 19.2231 28.2274 19.2379 28.5518 18.931C28.5626 18.9202 28.5733 18.9094 28.5828 18.8987C29.8091 17.5821 31.7731 16.1512 34.5193 16.1512C38.5995 16.1512 41.9528 19.5529 41.9945 23.734C42.0349 27.3821 40.7439 30.7515 38.0529 34.0321L38.0544 34.0335Z"
        fill="#4E769B"
      />
    </svg>
  )
}
