export const residencesData = [
  {
    plot: 6,
    unitType: "3 Bed",
    phase: "1",
    poolSize: "9 x 4",
    bathrooms: 3,
    livingSqm: 219,
    plotSqm: 1524,
    plotSqft: 16404,
    aspect: ["N", "W"],
  },
  {
    plot: 7,
    unitType: "3 Bed",
    phase: "1",
    poolSize: "9 x 4",
    bathrooms: 3,
    livingSqm: 219,
    plotSqm: 2046,
    plotSqft: 22023,
    aspect: ["N", "W"],
  },
  {
    plot: 8,
    unitType: "3 Bed",
    phase: "1",
    poolSize: "9 x 4",
    bathrooms: 3,
    livingSqm: 219,
    plotSqm: 1569,
    plotSqft: 16889,
    aspect: ["N", "W"],
  },
  {
    plot: 9,
    unitType: "3 Bed",
    phase: "1",
    poolSize: "9 x 4",
    bathrooms: 3,
    livingSqm: 219,
    plotSqm: 2141,
    plotSqft: 23046,
    aspect: ["N", "W"],
  },
  {
    plot: 10,
    unitType: "3 Bed",
    phase: "1",
    poolSize: "9 x 4",
    bathrooms: 3,
    livingSqm: 219,
    plotSqm: 1474,
    plotSqft: 15866,
    aspect: ["N", "W"],
  },
  {
    plot: 1,
    unitType: "4 Bed",
    phase: "1",
    poolSize: "9.5 x 4",
    bathrooms: 4,
    livingSqm: 277,
    plotSqm: 2612,
    plotSqft: 28116,
    aspect: ["N", "E"],
  },
  {
    plot: 2,
    unitType: "4 Bed",
    phase: "1",
    poolSize: "9.5 x 4",
    bathrooms: 4,
    livingSqm: 277,
    plotSqm: 2265,
    plotSqft: 24380,
    aspect: ["E"],
  },
  {
    plot: 3,
    unitType: "4 Bed",
    phase: "1",
    poolSize: "9.5 x 4",
    bathrooms: 4,
    livingSqm: 277,
    plotSqm: 1738,
    plotSqft: 18708,
    aspect: ["E"],
  },
  {
    plot: 4,
    unitType: "4 Bed",
    phase: "1",
    poolSize: "9.5 x 4",
    bathrooms: 4,
    livingSqm: 277,
    plotSqm: 1994,
    plotSqft: 21463,
    aspect: ["E"],
  },
  {
    plot: 52,
    unitType: "4 Bed",
    phase: "1",
    poolSize: "9.5 x 4",
    bathrooms: 4,
    livingSqm: 277,
    plotSqm: 2065,
    plotSqft: 22228,
    aspect: ["W"],
  },
  {
    plot: 5,
    unitType: "6 Bed",
    phase: "1",
    poolSize: "14 x 4.5",
    bathrooms: 6,
    livingSqm: 489,
    plotSqm: 3919,
    plotSqft: 42184,
    aspect: ["N", "E"],
  },
  {
    plot: 51,
    unitType: "7 Bed",
    phase: "1",
    poolSize: "14 x 4.5",
    bathrooms: 6.5,
    livingSqm: 662,
    plotSqm: 3300,
    plotSqft: 35521,
    aspect: ["N", "E"],
  },
  {
    plot: 15,
    unitType: "3 Bed",
    phase: "2a",
    poolSize: "9 x 4",
    bathrooms: 3,
    livingSqm: 219,
    plotSqm: 2381,
    plotSqft: 25629,
    aspect: ["N", "W"],
  },
  {
    plot: 16,
    unitType: "3 Bed",
    phase: "2a",
    poolSize: "9 x 4",
    bathrooms: 3,
    livingSqm: 219,
    plotSqm: 2375,
    plotSqft: 25565,
    aspect: ["N", "W"],
  },
  {
    plot: 11,
    unitType: "4 Bed",
    phase: "2a",
    poolSize: "9.5 x 4",
    bathrooms: 4,
    livingSqm: 277,
    plotSqm: 2417,
    plotSqft: 26017,
    aspect: ["N", "W"],
  },
  {
    plot: 12,
    unitType: "4 Bed",
    phase: "2a",
    poolSize: "9.5 x 4",
    bathrooms: 4,
    livingSqm: 277,
    plotSqm: 2664,
    plotSqft: 28675,
    aspect: ["N", "W"],
  },
  {
    plot: 13,
    unitType: "4 Bed",
    phase: "2a",
    poolSize: "9.5 x 4",
    bathrooms: 4,
    livingSqm: 277,
    plotSqm: 2341,
    plotSqft: 25199,
    aspect: ["N", "W"],
  },
  {
    plot: 14,
    unitType: "4 Bed",
    phase: "2a",
    poolSize: "9.5 x 4",
    bathrooms: 4,
    livingSqm: 277,
    plotSqm: 2136,
    plotSqft: 22992,
    aspect: ["N", "W"],
  },
  {
    plot: 19,
    unitType: "4 Bed",
    phase: "2a",
    poolSize: "9.5 x 4",
    bathrooms: 4,
    livingSqm: 277,
    plotSqm: 2548,
    plotSqft: 27427,
    aspect: ["N", "W"],
  },
  {
    plot: 24,
    unitType: "4 Bed",
    phase: "2a",
    poolSize: "9.5 x 4",
    bathrooms: 4,
    livingSqm: 277,
    plotSqm: 2905,
    plotSqft: 31269,
    aspect: ["N", "W"],
  },
  {
    plot: 25,
    unitType: "4 Bed",
    phase: "2a",
    poolSize: "9.5 x 4",
    bathrooms: 4,
    livingSqm: 277,
    plotSqm: 2652,
    plotSqft: 28546,
    aspect: ["N", "W"],
  },
  {
    plot: 26,
    unitType: "4 Bed",
    phase: "2a",
    poolSize: "9.5 x 4",
    bathrooms: 4,
    livingSqm: 277,
    plotSqm: 2578,
    plotSqft: 27750,
    aspect: ["N", "W"],
  },
  {
    plot: 17,
    unitType: "5 Bed",
    phase: "2a",
    poolSize: "9.5 x 4.5",
    bathrooms: 5,
    livingSqm: 359,
    plotSqm: 2627,
    plotSqft: 28277,
    aspect: ["N", "W"],
  },
  {
    plot: 18,
    unitType: "5 Bed",
    phase: "2a",
    poolSize: "9.5 x 4.5",
    bathrooms: 5,
    livingSqm: 359,
    plotSqm: 2332,
    plotSqft: 25102,
    aspect: ["N", "W"],
  },
  {
    plot: 21,
    unitType: "5 Bed",
    phase: "2a",
    poolSize: "9.5 x 4.5",
    bathrooms: 5,
    livingSqm: 359,
    plotSqm: 4208,
    plotSqft: 45295,
    aspect: ["N", "W"],
  },
  {
    plot: 22,
    unitType: "5 Bed",
    phase: "2a",
    poolSize: "9.5 x 4.5",
    bathrooms: 5,
    livingSqm: 359,
    plotSqm: 3710,
    plotSqft: 39934,
    aspect: ["N", "W"],
  },
  {
    plot: 23,
    unitType: "5 Bed",
    phase: "2a",
    poolSize: "9.5 x 4.5",
    bathrooms: 5,
    livingSqm: 359,
    plotSqm: 3260,
    plotSqft: 35091,
    aspect: ["N", "W"],
  },
  {
    plot: 27,
    unitType: "5 Bed",
    phase: "2a",
    poolSize: "9.5 x 4.5",
    bathrooms: 5,
    livingSqm: 359,
    plotSqm: 3224,
    plotSqft: 34703,
    aspect: ["N", "W"],
  },
  {
    plot: 28,
    unitType: "5 Bed",
    phase: "2a",
    poolSize: "9.5 x 4.5",
    bathrooms: 5,
    livingSqm: 359,
    plotSqm: 2803,
    plotSqft: 30171,
    aspect: ["N", "W"],
  },
  {
    plot: 29,
    unitType: "5 Bed",
    phase: "2a",
    poolSize: "9.5 x 4.5",
    bathrooms: 5,
    livingSqm: 359,
    plotSqm: 2844,
    plotSqft: 30613,
    aspect: ["N", "W"],
  },
  {
    plot: 30,
    unitType: "5 Bed",
    phase: "2a",
    poolSize: "9.5 x 4.5",
    bathrooms: 5,
    livingSqm: 359,
    plotSqm: 3024,
    plotSqft: 32550,
    aspect: ["N", "W"],
  },
  {
    plot: 31,
    unitType: "5 Bed XL",
    phase: "2a",
    poolSize: "11 x 4.5",
    bathrooms: 5,
    livingSqm: 467,
    plotSqm: 4076,
    plotSqft: 43874,
    aspect: ["N", "W"],
  },
  {
    plot: 50,
    unitType: "5 Bed XL",
    phase: "2a",
    poolSize: "11 x 4.5",
    bathrooms: 5,
    livingSqm: 467,
    plotSqm: 3008,
    plotSqft: 32378,
    aspect: ["N", "W"],
  },
  {
    plot: 20,
    unitType: "6 Bed",
    phase: "2a",
    poolSize: "14 x 4.5",
    bathrooms: 6,
    livingSqm: 489,
    plotSqm: 4251,
    plotSqft: 45758,
    aspect: ["N", "W"],
  },
  {
    plot: 32,
    unitType: "4 Bed XL",
    phase: "2b",
    poolSize: "9.5 x 4.5",
    bathrooms: 4,
    livingSqm: 364,
    plotSqm: 4152,
    plotSqft: 44692,
    aspect: ["N", "W"],
  },
  {
    plot: 33,
    unitType: "4 Bed XL",
    phase: "2b",
    poolSize: "9.5 x 4.5",
    bathrooms: 4,
    livingSqm: 364,
    plotSqm: 4368,
    plotSqft: 47017,
    aspect: ["N", "W"],
  },
  {
    plot: 34,
    unitType: "6 Bed XL",
    phase: "2b",
    poolSize: "11 x 4.5",
    bathrooms: 5,
    livingSqm: 467,
    plotSqm: 6980,
    plotSqft: 75133,
    aspect: ["N"],
  },
  {
    plot: 43,
    unitType: "7 Bed XL",
    phase: "2b",
    poolSize: "11 x 4.5",
    bathrooms: 5,
    livingSqm: 467,
    plotSqm: 2844,
    plotSqft: 30613,
    aspect: ["N", "E"],
  },
  {
    plot: 44,
    unitType: "5 Bed XL",
    phase: "2b",
    poolSize: "11 x 4.5",
    bathrooms: 5,
    livingSqm: 467,
    plotSqm: 3444,
    plotSqft: 37071,
    aspect: ["N", "E"],
  },
  {
    plot: 46,
    unitType: "5 Bed XL",
    phase: "2b",
    poolSize: "11 x 4.5",
    bathrooms: 5,
    livingSqm: 467,
    plotSqm: 4091,
    plotSqft: 44036,
    aspect: ["N", "E"],
  },
  {
    plot: 47,
    unitType: "5 Bed XL",
    phase: "2b",
    poolSize: "11 x 4.5",
    bathrooms: 5,
    livingSqm: 467,
    plotSqm: 3817,
    plotSqft: 41086,
    aspect: ["N", "E"],
  },
  {
    plot: 35,
    unitType: "6 Bed XL",
    phase: "2b",
    poolSize: "14 x 4.5",
    bathrooms: 6,
    livingSqm: 619,
    plotSqm: 5715,
    plotSqft: 61516,
    aspect: ["N", "E"],
  },
  {
    plot: 36,
    unitType: "6 Bed XL",
    phase: "2b",
    poolSize: "14 x 4.5",
    bathrooms: 6,
    livingSqm: 619,
    plotSqm: 5288,
    plotSqft: 56920,
    aspect: ["N", "E"],
  },
  {
    plot: 38,
    unitType: "6 Bed XL",
    phase: "2b",
    poolSize: "14 x 4.5",
    bathrooms: 6,
    livingSqm: 619,
    plotSqm: 3845,
    plotSqft: 41388,
    aspect: ["N", "E"],
  },
  {
    plot: 39,
    unitType: "6 Bed XL",
    phase: "2b",
    poolSize: "14 x 4.5",
    bathrooms: 6,
    livingSqm: 619,
    plotSqm: 3493,
    plotSqft: 37599,
    aspect: ["N", "E"],
  },
  {
    plot: 41,
    unitType: "6 Bed XL",
    phase: "2b",
    poolSize: "14 x 4.5",
    bathrooms: 6,
    livingSqm: 619,
    plotSqm: 3947,
    plotSqft: 42486,
    aspect: ["N", "E"],
  },
  {
    plot: 45,
    unitType: "6 Bed XL",
    phase: "2b",
    poolSize: "14 x 4.5",
    bathrooms: 6,
    livingSqm: 619,
    plotSqm: 4243,
    plotSqft: 45672,
    aspect: ["N", "E"],
  },
  {
    plot: 48,
    unitType: "6 Bed XL",
    phase: "2b",
    poolSize: "14 x 4.5",
    bathrooms: 6,
    livingSqm: 619,
    plotSqm: 4831,
    plotSqft: 52001,
    aspect: ["N", "E"],
  },
  {
    plot: 49,
    unitType: "6 Bed XL",
    phase: "2b",
    poolSize: "14 x 4.5",
    bathrooms: 6,
    livingSqm: 619,
    plotSqm: 5492,
    plotSqft: 59116,
    aspect: ["N", "E"],
  },
  {
    plot: 37,
    unitType: "7 Bed",
    phase: "2b",
    poolSize: "14 x 4.5",
    bathrooms: 6.5,
    livingSqm: 662,
    plotSqm: 4735,
    plotSqft: 50968,
    aspect: ["N", "E"],
  },
  {
    plot: 40,
    unitType: "7 Bed",
    phase: "2b",
    poolSize: "14 x 4.5",
    bathrooms: 6.5,
    livingSqm: 662,
    plotSqm: 3410,
    plotSqft: 36705,
    aspect: ["N", "E"],
  },
  {
    plot: 42,
    unitType: "7 Bed",
    phase: "2b",
    poolSize: "14 x 4.5",
    bathrooms: 6.5,
    livingSqm: 662,
    plotSqm: 4193,
    plotSqft: 45133,
    aspect: ["N", "E"],
  },
]
