import styled from "styled-components"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import { Fragment, useEffect, useRef } from "react"
import { globalStyles } from "@data/globalStyles"
import { useStore } from "@state/store"
import { residencesData } from "@data/residencesData"

export default function FloorPlanImage({ ...props }) {
  const residencePopupCategory = useStore(
    (state) => state.residencePopupCategory
  )
  const zoomRef = useRef(null)
  const activeID = useStore((state) => state.activeID)

  useEffect(() => {
    if (zoomRef.current) {
      zoomRef.current.resetTransform()
    }
  }, [residencePopupCategory])

  if (!activeID) return null
  // console.log("activeID", activeID)
  const unitType = residencesData.find(
    (residence) => residence.plot === activeID
  ).unitType

  return (
    <Wrapper
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        height: "100%",
        width: "100%",
        overflow: "hidden",
        display: "grid",
        placeItems: "center",
      }}
      {...props}
    >
      <ZoomWrapper>
        <TransformWrapper
          ref={zoomRef}
          centerOnInit={true}
          minScale={1}
          maxScale={5}
          alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
          limitToBounds={true}
          smooth={true}
          centerZoomedOut={true}
        >
          <TransformComponent
            wrapperStyle={{
              gridColumn: "1 / -1",
              gridRow: "1 / -1",
              height: "100%",
              width: "100%",
              display: "grid",
              placeItems: "center",
              zIndex: 1,
            }}
            contentStyle={{
              gridColumn: "1 / -1",
              gridRow: "1 / -1",
              height: "100%",
              width: "100%",
              overflow: "hidden",
              display: "grid",
              placeItems: "center",
              zIndex: 1,
            }}
          >
            {unitType &&
              (residencePopupCategory === "Ground Floor Plan" ||
                residencePopupCategory === "Upper Floor Plan") && (
                <img
                  alt="Floor plan"
                  src={`/floor-plans/${unitType}-${residencePopupCategory
                    .split(" ")[0]
                    .toUpperCase()}.webp`}
                  style={{
                    gridColumn: "1 / -1",
                    gridRow: "1 / -1",
                    objectFit: "contain",
                    overflow: "hidden",
                    maxHeight: "80%",
                    maxWidth: "70%",
                    transform: "translate(-10%,-2%)",

                    // marginRight: "4rem",
                    // marginBottom: "4rem",
                  }}
                />
              )}
          </TransformComponent>
        </TransformWrapper>
      </ZoomWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  place-self: start;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  display: grid;
  place-items: center;
  pointer-events: auto;
  z-index: 1;
`

const ZoomWrapper = styled.div`
  overflow: hidden;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  z-index: 1;
  overscroll-behavior: none;
  display: flex;
  place-items: center;

  & svg {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    border: 0px red solid;
  }
`
