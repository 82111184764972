/* eslint-disable no-unused-vars */
import { pathData } from "../../../../../data/pathData"
import React, { useEffect, useState, memo } from "react"
import * as THREE from "three"
import Dot from "./Dot"
import PlaceCircle from "./PlaceCircle"

function distanceVector(v1, v2) {
  var dx = v1.x - v2.x
  var dy = v1.y - v2.y
  var dz = v1.z - v2.z

  return Math.sqrt(dx * dx + dy * dy + dz * dz)
}

function FlightLine({ flightLines, camTarget }) {
  let points = pathData[camTarget.name.replaceAll(" ", "").toLowerCase()]
  const colorA = "rgba(69, 107, 144)"
  const distance = distanceVector(flightLines[0], flightLines[2])
  const nodes = []
  points?.forEach(e => nodes.push(new THREE.Vector3(e[0], e[1], e[2])))
  const curveNodes = nodes
  // const curvePoints = [flightLines[0], flightLines[1], flightLines[2]]
  // const nbrNodes = distance / 0.005
  // const curveNodes = new THREE.CatmullRomCurve3(curvePoints).getPoints(nbrNodes)

  if (!curveNodes) return null
  return (
    <group>
      <PlaceCircle
        camTarget={camTarget}
        position={camTarget.origin}
        size={0.01}
        fontSize={1}
      />
      {curveNodes.map((item, index) => {
        return (
          <Dot
            key={`${index}-${camTarget.name}`}
            position={item}
            delay={(10 / distance) * index}
            visible={true}
            size={0.002}
            color={camTarget.drive === "Fly" ? "rgba(69, 107, 144, 0.1)" : "rgba(69, 107, 144)"}
          />
        )
      })}
      <PlaceCircle
        camTarget={camTarget}
        position={camTarget.flightLines[2]}
        size={0.02}
        h1={camTarget.area}
        h2={camTarget.name}
        h3={camTarget.distance}
        fontSize={0.5}
        isActive={true}
      />
    </group>
  )
}

function FlightLines({ camTarget }) {
  const flightLines = camTarget.flightLines
  if (!flightLines) return null
  return flightLines.map((item, index) => (
    <FlightLine
      flightLines={flightLines}
      visible={true}
      key={index}
      camTarget={camTarget}
    />
  ))
}

export default memo(FlightLines)
