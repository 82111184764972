import { useGSAP } from "@gsap/react"
import { useStore } from "@state/store"
import { gsap } from "gsap"

export default function HomeScreen4() {
  const nav = useStore((state) => state.nav)

  useGSAP(
    () => {
      const text = gsap.utils.toArray(".home-4-text")
      if (!text) return
      gsap.to(text, {
        opacity: nav ? 0 : 1,
        duration: 0.3,
      })
    },
    { dependencies: [nav] }
  )
  return (
    <div
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        height: "100%",
        width: "100%",
        display: "grid",
        placeItems: "center",
        overflow: "hidden",
        backgroundColor: "black",
      }}
    >
      <div
        id="home-4-bg"
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          visibility: "hidden",
          opacity: 0,
          display: "grid",
          placeItems: "center",
          overflow: "hidden",
          height: "100%",
          width: "100%",
        }}
      >
        <img
          alt="Six Sense"
          src="/renders/SSLB_4B_cam05.webp"
          style={{
            objectFit: "cover",
            overflow: "hidden",
            height: "100%",
            width: "100%",
          }}
        />
      </div>
      <p
        id="home-4-text1"
        className="home-4-text"
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          visibility: "hidden",
          opacity: 0,
          height: "3vh",
          placeSelf: "end start",
          padding: "0rem 4rem 1rem 4rem",
          fontSize: "1.4rem",
          color: "white",
          textAlign: "left",
          zIndex: 3,
        }}
      >
        Exclusive Branded Residences in the Loire Valley
      </p>
      <p
        id="home-4-text2"
        className="home-4-text"
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          visibility: "hidden",
          opacity: 0,
          height: "3vh",
          placeSelf: "end end",
          padding: "0rem 4rem 1rem 4rem",
          fontSize: "1.4rem",
          color: "white",
          textAlign: "right",
          zIndex: 3,
        }}
      >
        Escape Among the Trees at Les Bordes Estate
      </p>
    </div>
  )
}
