export const residencesMapData = [
  {
    id: 1,
    centerX: 2001,
    centerY: 1717,
  },
  {
    id: 2,
    centerX: 1985,
    centerY: 1563,
  },
  {
    id: 3,
    centerX: 1969,
    centerY: 1457,
  },
  {
    id: 4,
    centerX: 1953,
    centerY: 1358,
  },
  {
    id: 5,
    centerX: 1975,
    centerY: 1192,
  },
  {
    id: 6,
    centerX: 2106,
    centerY: 1281,
  },
  {
    id: 7,
    centerX: 2178,
    centerY: 1380,
  },
  {
    id: 8,
    centerX: 2171,
    centerY: 1510,
  },
  {
    id: 9,
    centerX: 2295,
    centerY: 1467,
  },
  {
    id: 10,
    centerX: 2194,
    centerY: 1658,
  },
  {
    id: 11,
    centerX: 2155,
    centerY: 2602,
  },
  {
    id: 12,
    centerX: 2295,
    centerY: 2570,
  },
  {
    id: 13,
    centerX: 2393,
    centerY: 2499,
  },
  {
    id: 14,
    centerX: 2537,
    centerY: 2453,
  },
  {
    id: 15,
    centerX: 2667,
    centerY: 2407,
  },
  {
    id: 16,
    centerX: 2738,
    centerY: 2325,
  },
  {
    id: 17,
    centerX: 2852,
    centerY: 2275,
  },
  {
    id: 18,
    centerX: 2973,
    centerY: 2213,
  },
  {
    id: 19,
    centerX: 3101,
    centerY: 2129,
  },
  {
    id: 20,
    centerX: 3318,
    centerY: 2032,
  },
  {
    id: 21,
    centerX: 3402,
    centerY: 2197,
  },
  {
    id: 22,
    centerX: 3350,
    centerY: 2325,
  },
  {
    id: 23,
    centerX: 3238,
    centerY: 2375,
  },
  {
    id: 24,
    centerX: 3101,
    centerY: 2423,
  },
  {
    id: 25,
    centerX: 2973,
    centerY: 2485,
  },
  {
    id: 26,
    centerX: 2868,
    centerY: 2554,
  },
  {
    id: 27,
    centerX: 2738,
    centerY: 2618,
  },
  {
    id: 28,
    centerX: 2635,
    centerY: 2711,
  },
  {
    id: 29,
    centerX: 2471,
    centerY: 2743,
  },
  {
    id: 30,
    centerX: 2377,
    centerY: 2827,
  },
  {
    id: 31,
    centerX: 2240,
    centerY: 2884,
  },
  {
    id: 32,
    centerX: 1973,
    centerY: 2980,
  },
  {
    id: 33,
    centerX: 1859,
    centerY: 3073,
  },
  {
    id: 34,
    centerX: 1645,
    centerY: 3105,
  },
  {
    id: 35,
    centerX: 1501,
    centerY: 2996,
  },
  {
    id: 36,
    centerX: 1284,
    centerY: 2948,
  },
  {
    id: 37,
    centerX: 1143,
    centerY: 2843,
  },
  {
    id: 38,
    centerX: 1054,
    centerY: 2743,
  },
  {
    id: 39,
    centerX: 897,
    centerY: 2650,
  },
  {
    id: 40,
    centerX: 813,
    centerY: 2554,
  },
  {
    id: 41,
    centerX: 694,
    centerY: 2469,
  },
  {
    id: 42,
    centerX: 616,
    centerY: 2341,
  },
  {
    id: 43,
    centerX: 985,
    centerY: 2341,
  },
  {
    id: 44,
    centerX: 1070,
    centerY: 2439,
  },
  {
    id: 45,
    centerX: 1175,
    centerY: 2538,
  },
  {
    id: 46,
    centerX: 1351,
    centerY: 2602,
  },
  {
    id: 47,
    centerX: 1438,
    centerY: 2695,
  },
  {
    id: 48,
    centerX: 1629,
    centerY: 2727,
  },
  {
    id: 49,
    centerX: 1795,
    centerY: 2811,
  },
  {
    id: 50,
    centerX: 2048,
    centerY: 2682,
  },
  {
    id: 51,
    centerX: 2409,
    centerY: 1613,
  },
  {
    id: 52,
    centerX: 2240,
    centerY: 1779,
  },
]
