import Lifestyle from "@components/Lifestyle/Lifestyle"
import styled from "styled-components"

function LifestyleComponent() {
  return (
    <Wrapper>
      <Lifestyle />
    </Wrapper>
  )
}

export default LifestyleComponent

const Wrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  z-index: 1;
  background-color: black;
`
