import { useEffect, useState } from "react"
import { useStore } from "@state/store"
import Logo from "./logo"

export default function Login() {
  const syncState = useStore((state) => state.syncState)
  const [inputValue, setInputValue] = useState("")

  const handleChange = (event) => {
    const { value } = event.target
    setInputValue(value)

    if (value === "sslv2024") {
      syncState("auth", true)
    }
  }

  const auth = useStore((state) => state.auth)

  useEffect(() => {
    //Check if in local storage
    const auth = localStorage.getItem("auth")
    if (auth) {
      syncState("auth", true)
    }
  }, [])

  useEffect(() => {
    if (auth) {
      //Add to local storage
      localStorage.setItem("auth", "true")
    }
  }, [auth])

  return (
    <div
      style={{
        opacity: !auth ? 1 : 0,
        pointerEvents: !auth ? "auto" : "none",
        position: "fixed",
        inset: 0,
        zIndex: 999,
        height: "100%",
        width: "100%",
        display: "grid",
        placeItems: "center",
        gridAutoFlow: "row",
        overflow: "hidden",
        backgroundColor: "black",
      }}
    >
      <div
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / 3",
          height: "40%",
          width: "100%",
          display: "grid",
          placeItems: "center",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            gridColumn: "1 / -1",
            gridRow: 1,
            // height: "50rem",
            width: "14rem",
            display: "grid",
            placeItems: "end center",
            zIndex: 999,
          }}
        >
          <Logo />
        </div>
        <div
          style={{
            gridColumn: "1 / -1",
            gridRow: 2,
            // gridRow: "1 / -1",
            display: "grid",
            placeItems: "start center",
            // height: "10rem",
            // width: "30%",
            backgroundColor: "#f0f2f4d4",
            backdropFilter: "blur(10px)",
            zIndex: 999,
            borderRadius: "10rem",
          }}
        >
          <input
            className="mainLoginInput"
            placeholder="ENTER PASSWORD..."
            type="password"
            value={inputValue}
            onChange={handleChange}
            style={{
              // overflow: "hidden",
              backgroundColor: "transparent",
              border: "none",
              width: "100%",
              color: "black",
              display: "grid",
              placeItems: "center",
              fontSize: "3rem",
              height: "auto",
              padding: 0,
              margin: 0,
              zIndex: 1000,
              textAlign: "center",
            }}
          />
        </div>
      </div>
      <div
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / 3",
          height: "100%",
          width: "100%",
          display: "grid",
          placeItems: "center",
          overflow: "hidden",
        }}
      >
        <img
          style={{
            gridColumn: "1 / -1",
            gridRow: "1 / -1",
            objectFit: "cover",
            objectPosition: "center",
            overflow: "hidden",
            height: "100%",
            width: "100%",
            opacity: 0.6,
          }}
          src="/renders/6 Bed XL.webp"
          alt="Six Senses"
        />
      </div>
    </div>
  )
}
