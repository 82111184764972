import { useGSAP } from "@gsap/react"
import { gsap } from "gsap"
import { useStore } from "@state/store"
import ResetSVG from "./ResetSVG"
import { useRef } from "react"

const types = [
  { key: "3", label: "3 Bed" },
  { key: "4", label: "4 Bed" },
  { key: "4 XL", label: "4 Bed XL" },
  { key: "5", label: "5 Bed" },
  { key: "5 XL", label: "5 Bed XL" },
  { key: "6", label: "6 Bed" },
  { key: "6 XL", label: "6 Bed XL" },
  { key: "7", label: "7 Bed" },
  { key: "7 XL", label: "7 Bed XL" },
]
const orientation = ["N", "E", "S", "W"]
export default function FilterBar() {
  const typeFilter = useStore((state) => state.typeFilter)
  const syncState = useStore((state) => state.syncState)
  const orientationFilter = useStore((state) => state.orientationFilter)
  const filter = useStore((state) => state.filter)
  const resetRef = useRef(null)
  useGSAP(
    () => {
      gsap.to("#filterBar", {
        autoAlpha: filter ? 1 : 0,
        x: filter ? "0rem" : "12rem",
        duration: 0.5,
        ease: "back.inOut",
      })
    },
    { dependencies: [filter] }
  )
  return (
    <div
      id="filterBar"
      style={{
        opacity: 0,
        visibility: "hidden",
        transform: "translate3D(30rem, 0, 0)",
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        placeSelf: "end",
        margin: "9rem 2rem",
        borderRadius: "1.5rem",
        color: "#fff",
        letterSpacing: "0.1rem",
        display: "grid",
        placeItems: "center",
        rowGap: "1rem",
        pointerEvents: "auto",
        gridAutoFlow: "row",
        backgroundColor: "#4946462c",
        backdropFilter: "blur(2px)",
        zIndex: 5,
        padding: "1.4rem",
      }}
    >
      {/* <p
        style={{
          fontSize: "1.6rem",
          lineHeight: "1.6rem",
          letterSpacing: "0",
        }}
      >
        Explore Residences
      </p> */}
      <p>TYPE</p>
      <div
        style={{
          display: "grid",
          gridAutoFlow: "column",
          placeItems: "center",
        }}
      >
        {types.map((type, index) => {
          const active = typeFilter?.includes(type.label)
          const text = type.key.split(" ")
          return (
            <button
              key={index}
              onClick={() => {
                const update = active
                  ? typeFilter.filter((t) => t !== type.label)
                  : [...typeFilter, type.label]
                syncState("typeFilter", update)
              }}
              style={{
                backgroundColor: active ? "#fff" : "transparent",
                border: `1px solid ${active ? "#fff" : "#ffffff"}`,
                transition: "color 0.3s, background-color 0.3s, border 0.3s",
                color: active ? "#577598" : "#ffffff",
                aspectRatio: 1,
                height: "4rem",
                fontSize: "2rem",
                fontFamily: "inherit",
                cursor: "pointer",
              }}
            >
              {text[0]}
              {text[1] && (
                <span style={{ marginLeft: "0.2rem", fontSize: "1rem" }}>
                  {text[1]}
                </span>
              )}
            </button>
          )
        })}
      </div>
      <div
        style={{
          display: "grid",
          placeItems: "center",
          gridAutoFlow: "column",
          columnGap: "4rem",
        }}
      >
        <div
          style={{ display: "grid", placeItems: "center", gridAutoFlow: "row" }}
        >
          <p>ORIENTATION</p>
          <div
            style={{
              display: "grid",
              gridAutoFlow: "column",
              placeItems: "center",
            }}
          >
            {orientation.map((item, index) => {
              const active = orientationFilter?.includes(item)

              return (
                <button
                  onClick={() => {
                    active
                      ? syncState(
                          "orientationFilter",
                          orientationFilter.filter((type) => type !== item)
                        )
                      : syncState("orientationFilter", [
                          ...orientationFilter,
                          item,
                        ])
                  }}
                  key={index}
                  style={{
                    transition:
                      "color 0.3s, background-color 0.3s, border 0.3s",
                    backgroundColor: active ? "#fff" : "transparent",
                    border: `1px solid ${active ? "#fff" : "#ffffff"}`,
                    color: active ? "#577598" : "#ffffff",
                    aspectRatio: 1,
                    height: "4rem",
                    fontSize: "2rem",
                    cursor: "pointer",
                    fontFamily: "inherit",
                  }}
                >
                  {item}
                </button>
              )
            })}
          </div>
        </div>
        <div>
          <p style={{ textAlign: "center" }}>RESET</p>
          <button
            ref={resetRef}
            onClick={() => {
              resetRef.current.style.backgroundColor = "white"
              resetRef.current.style.filter = "invert(0.3)"
              syncState("typeFilter", [])
              syncState("orientationFilter", [])
              setTimeout(() => {
                resetRef.current.style.backgroundColor = "transparent"
                resetRef.current.style.filter = "invert(0)"
              }, 200)
            }}
            style={{
              backgroundColor: "#ffffff0",
              border: `1px solid #ffffff`,
              aspectRatio: 2,
              height: "4rem",
              fontSize: "2rem",
              fontFamily: "inherit",
              transition: "color 0.2s, background-color 0.2s, border 0.2s",
              cursor: "pointer",
            }}
          >
            <ResetSVG style={{ height: "50%" }} />
          </button>
        </div>
      </div>
    </div>
  )
}
