import { residencesMapData } from "@data/residencesMapData"
import { useStore } from "@state/store"

export default function ResidencesSVGLabels() {
  const syncState = useStore((state) => state.syncState)
  const activeID = useStore((state) => state.activeID)
  const favourites = useStore((state) => state.favourites)
  const filterResults = useStore((state) => state.filterResults)
  return (
    <g id="fade-svg" style={{ opacity: 0 }}>
      {residencesMapData.map((residence, index) => {
        const { id, centerX, centerY } = residence
        const active = filterResults.find((item) => item.plot === Number(id))
        const isFavourite = favourites?.includes(id)
        return (
          <g
            style={{
              pointerEvents: active ? "auto" : "none",
              cursor: "pointer",
            }}
            key={index}
            opacity={active ? 1 : 0.3}
            transform={`translate(${centerX}, ${centerY}) scale(${0.6})`}
            onClick={(e) => {
              e.stopPropagation()
              syncState("activeID", activeID === id ? null : Number(id))
              syncState("residencePopup", true)
            }}
          >
            <circle r="50" fill={isFavourite ? "#ffffffc5" : "#4e769b86"} />
            {isFavourite ? (
              <path
                transform={`translate(-40,-40) scale(0.8)`}
                d="M50.0006 73C49.2278 72.9988 48.4742 72.7664 47.8372 72.3328C38.3916 65.9421 34.3015 61.5602 32.0456 58.8206C27.238 52.9809 24.9364 46.9855 25.0013 40.493C25.0771 33.0529 31.0661 27.0012 38.3519 27.0012C43.6498 27.0012 47.3192 29.9755 49.4561 32.4528C49.717 32.7522 50.1725 32.7834 50.4729 32.5234C50.4982 32.5007 50.5222 32.4767 50.5439 32.4516C52.6808 29.972 56.3502 27 61.6481 27C68.9339 27 74.9229 33.0517 74.9987 40.493C75.0636 46.9867 72.7596 52.9821 67.9544 58.8206C65.6985 61.5602 61.6084 65.9421 52.1628 72.3328C51.5246 72.7652 50.771 72.9976 49.9994 72.9988L50.0006 73Z"
                fill="#4e769b"
              />
            ) : (
              <text textAnchor="middle" dy="0.3em" fontSize="50" fill="#fff">
                {id}
              </text>
            )}
          </g>
        )
      })}
    </g>
  )
}
