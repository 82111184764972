import { useGSAP } from "@gsap/react"
import Buttons from "./Buttons"
import FloorPlan from "./FloorPlan"
import Render from "./Render"
import ResidencesMap from "./ResidencesMap"
import { gsap } from "gsap"
import { useStore } from "@state/store"
import PopupButtons from "./PopupButtons"
import { residencesData } from "@data/residencesData"
import ResidencePopup from "./ResidencePopup"

export default function LeftContent() {
  const residencePopup = useStore((state) => state.residencePopup)
  const activeID = useStore((state) => state.activeID)
  const aspect = residencesData?.find(
    (residence) => residence?.plot === activeID
  )?.aspect
  const bedrooms = residencesData
    ?.find((residence) => residence?.plot === activeID)
    ?.unitType.split(" ")[0]
  const bathrooms = residencesData?.find(
    (residence) => residence?.plot === activeID
  )?.bathrooms

  useGSAP(
    () => {
      gsap.to("#residencePopup", {
        autoAlpha: residencePopup ? 1 : 0,
        duration: 0.5,
        ease: "power2.inOut",
      })
    },
    { dependencies: [residencePopup] }
  )
  return (
    <div
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        height: "100%",
        width: "100%",
        display: "grid",
        placeItems: "center",
        gridAutoFlow: "row",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          height: "100%",
          width: "100%",
          display: "grid",
          placeItems: "center",
          overflow: "hidden",
        }}
      >
        <ResidencesMap />
      </div>
      <ResidencePopup />
    </div>
  )
}
