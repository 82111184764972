import { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import ReactDOMServer from "react-dom/server"

import { useStore } from "@state/store"
import LoadingIcon from "./LoadingIcon"
import { validation } from "@utils/validation"
import EmailTemplate from "./EmailTemplate"
import { globalStyles } from "@data/globalStyles"

type TForm = {
  name: string
  email: string
  link?: string
  bcc?: string
}

const initialForm = {
  name: null,
  email: null,
  bcc: null,
}

function ShareCard() {
  const favourites = useStore((state) => state.favourites)
  const promptRef = useRef<HTMLDivElement>()
  const [formData, setFormData] = useState<TForm | null>(initialForm)
  const [formMessage, setFormMessage] = useState("")
  const [readyToSend, setReadyToSend] = useState(false)
  const [sending, setSending] = useState(false)
  const encodedFav = `${favourites?.join(",")}`
  const url = `ss.lesbordesestate.app/favourites?favs=${encodedFav}`

  function resetPrompt() {
    promptRef.current.style.opacity = "0"
    setTimeout(() => {
      setFormMessage("")
    }, 800)
  }

  function handleSubmit(e) {
    e.preventDefault()
    const form = document.getElementById("form") as HTMLFormElement
    const errors = validation(formData)
    if (errors !== undefined) {
      setFormMessage(errors)
      promptRef.current.style.opacity = "1"
    } else {
      setFormData({ ...formData, link: url })
      setSending(true)
      setReadyToSend(true)
      form.reset()
    }
  }

  useEffect(() => {
    if (formData && readyToSend) {
      const htmlTemplate = ReactDOMServer.renderToStaticMarkup(
        <EmailTemplate link={url} />
      )
      fetch(`https://api-gateway.vmiservers.com/mailer/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          from: `Your Houses at Six Senses <homes@lesbordes.com>`,
          html: htmlTemplate,
          subject: `Hi ${formData.name}, Here are your favorites.`,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setReadyToSend(false)
            setFormData(initialForm)
            setSending(false)
            setFormMessage("Email sent")
            promptRef.current.style.opacity = "1"
          }
        })
        .catch((err) => {
          setReadyToSend(false)
          setFormData(initialForm)
          setSending(false)
          setFormMessage("Email failed to send. Pleas try again later.")
          promptRef.current.style.opacity = "1"
          console.error(
            "There has been a problem with your fetch operation:",
            err
          )
        })
    }
    return () => {
      setReadyToSend(false)
      setFormMessage("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToSend])

  return (
    <div
      id="sharePopup"
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        visibility: "hidden",
        opacity: 0,
        zIndex: 10,
        padding: "3rem 4rem",
        backgroundColor: "white",
        borderRadius: "2rem",
        display: "grid",
        gridAutoFlow: "row",
        fontFamily: "Canto Roman",
        color: globalStyles.col.blue,
        fontSize: "1.6rem",
        lineHeight: "1.6rem",
      }}
    >
      <h1 style={{ fontSize: "2rem", lineHeight: "1.8rem", opacity: 0.6 }}>
        Share favourites
        <br />
        residences via email
      </h1>
      <form
        id="form"
        style={{
          display: "grid",
          gridAutoFlow: "row",
          rowGap: "1rem",
        }}
      >
        <input
          className="input"
          placeholder="Name"
          name="fName"
          type="text"
          max="20"
          onInput={(e) => {
            setFormData({
              ...formData,
              name: (e.target as HTMLInputElement).value,
            })
            resetPrompt()
          }}
          style={{
            border: "none",
            borderBottom: `1px solid ${globalStyles.col.blue}`,
            width: "100%",
            minHeight: "2rem",
            minWidth: "20rem",
            marginBottom: "1rem",
            color: globalStyles.col.blue,
            fontFamily: "Canto Roman",
            fontSize: "1.6rem",
            lineHeight: "2.2rem",
          }}
        />

        <input
          className="input"
          placeholder="Email"
          name="Email"
          type="Email"
          max="30"
          onInput={(e) => {
            setFormData({
              ...formData,
              email: (e.target as HTMLInputElement).value,
            })
            resetPrompt()
          }}
          style={{
            border: "none",
            borderBottom: `1px solid ${globalStyles.col.blue}`,
            width: "100%",
            minHeight: "2rem",
            minWidth: "20rem",
            marginBottom: "1rem",
            color: globalStyles.col.blue,
            fontFamily: "Canto Roman",
            fontSize: "1.6rem",
            lineHeight: "2.2rem",
          }}
        />
        <input
          className="input"
          placeholder="CC Email (optional)"
          name="CC"
          type="Email"
          max="30"
          onInput={(e) => {
            setFormData({
              ...formData,
              bcc: (e.target as HTMLInputElement).value,
            })
            resetPrompt()
          }}
          style={{
            border: "none",
            borderBottom: `1px solid ${globalStyles.col.blue}`,
            width: "100%",
            minHeight: "2rem",
            minWidth: "20rem",
            marginBottom: "1rem",
            color: globalStyles.col.blue,
            fontFamily: "Canto Roman",
            fontSize: "1.6rem",
            lineHeight: "2.2rem",
          }}
        />
        <div
          style={{
            display: "grid",
            gridAutoFlow: "column",
            columnGap: "1rem",
            width: "80%",
            placeItems: "end start",
          }}
        >
          <button
            className="Email"
            onClick={(e) => {
              handleSubmit(e)
            }}
            style={{
              cursor: "pointer",
              height: "2rem",
              aspectRatio: 1,
              display: "grid",
              placeItems: "center",
              border: `1px solid white`,
              zIndex: 10,
              backgroundColor: globalStyles.col.blue,
              padding: "0.7rem 1rem",
              minHeight: "3rem",
              fontSize: "1.7rem",
              lineHeight: "1.7rem",
              fontFamily: "Canto Roman",
              color: "white",
            }}
          >
            {sending ? <LoadingIcon color={globalStyles.col.blue} /> : "Email"}
          </button>
          <button
            className="Email"
            onClick={(e) => {
              e.preventDefault()
              navigator.clipboard
                .writeText(url)
                .then(() => {
                  promptRef.current.style.opacity = "1"

                  setFormMessage("Link copied")
                })
                .catch((err) => {
                  console.error("Failed to copy text: ", err)
                })
            }}
            style={{
              cursor: "pointer",
              height: "2rem",
              aspectRatio: 1,
              display: "grid",
              placeItems: "center",
              border: `1px solid ${globalStyles.col.blue}`,
              zIndex: 10,
              backgroundColor: "transparent",
              padding: "0.7rem 1rem",
              minHeight: "3rem",
              minWidth: "7rem",
              fontSize: "1.2rem",
              lineHeight: "1.2rem",
              fontFamily: "Canto Roman",
              color: globalStyles.col.blue,
              // transform: "scale(0.8)",
            }}
          >
            Copy Link
          </button>
        </div>
      </form>
      <h6
        style={{
          height: "2rem",
          padding: 0,
          // margin: 0,
          margin: "2rem 0 0 0",
          fontSize: "1.3rem",
          lineHeight: "1.3rem",
        }}
        ref={promptRef}
      >
        {formMessage}
      </h6>
    </div>
  )
}

export default ShareCard
