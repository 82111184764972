import Favourites from "@components/Favourites/Favourites"
import ResidencePopup from "@components/Residences/ResidencePopup"
import styled from "styled-components"

function FavouritesComponent() {
  return (
    <Wrapper>
      <Favourites />
    </Wrapper>
  )
}

export default FavouritesComponent

const Wrapper = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: grid;
  z-index: 1;
  background-color: black;
`
