import { globalStyles } from "@data/globalStyles"
import LeftWrapperComponent from "./LeftWrapperComponent"
import RightWrapperComponent from "./RightWrapperComponent"
import { useStore } from "@state/store"
import { useGSAP } from "@gsap/react"
import { gsap } from "gsap"

export default function GalleryColumns() {
  const syncState = useStore((state) => state.syncState)

  useGSAP(() => {
    const columns = gsap.utils.toArray("#gallery-column")
    gsap.to(columns, {
      opacity: 1,
      stagger: 0.2,
      delay: 0.5,
      duration: 2,
    })
  })
  return (
    <div
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        // height: "100%",
        // width: "100%",
        display: "grid",
        placeItems: "end center",
        gridAutoFlow: "column",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        transform: "translateY(1.2rem)",
        columnGap: "2rem",
        color: globalStyles.col.white,
        fontSize: "2.4rem",
        lineHeight: "2.4rem",
        fontFamily: "Canto Roman",
        letterSpacing: "0.04rem",
        pointerEvents: "auto",
      }}
    >
      {["left", "right"].map((item, index) => {
        return (
          <img
            id="gallery-column"
            key={index}
            onClick={() => {
              syncState("galleryCategory", index + 1)
              setTimeout(() => {
                syncState("galleryPopup", true)
              }, 100)
            }}
            style={{
              cursor: "pointer",
              opacity: 0,
              height: "auto",
              width: "36rem",
              transform: `translateY(${index === 1 ? "-2.46rem" : "0rem"})`,
              objectFit: "contain",
              objectPosition: "center",
              overflow: "hidden",
              pointerEvents: "auto",
            }}
            alt="Six Senses"
            src={`/images/gallery-${item}.webp`}
          />
        )
      })}
    </div>
  )
}
