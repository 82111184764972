export default function ResetSVG({ ...props }) {
  return (
    <svg
      width="50%"
      height="50%"
      viewBox="0 0 23 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9044 8.59389C15.9879 8.55211 16.0813 8.49619 16.1827 8.41385C16.4095 8.22951 16.5803 8.00092 16.6301 7.65374C16.702 7.15049 16.4445 6.74125 16.0475 6.50099C15.4484 6.13783 14.1119 5.98975 11.5355 5.98975C5.56215 5.98975 0.720703 10.8312 0.720703 16.8045C0.720703 22.7772 5.56215 27.6193 11.5355 27.6193C17.5081 27.6193 22.3502 22.7772 22.3502 16.8045V15.8213H20.3839V16.8045C20.3839 21.6908 16.4218 25.6529 11.5355 25.6529C6.64854 25.6529 2.68702 21.6908 2.68702 16.8045C2.68702 11.9176 6.64854 7.95606 11.5355 7.95606C12.7742 7.95606 13.6978 7.99478 14.3319 8.06483C14.5912 8.09309 14.7916 8.12627 14.9329 8.16068C14.9839 8.17297 15.0226 8.18465 15.0484 8.19387C15.0539 8.19571 15.0564 8.19694 15.0564 8.19632C15.0496 8.19264 15.0398 8.18772 15.0281 8.18096C14.9532 8.13549 14.8745 8.06421 14.8038 7.95176C14.6975 7.7834 14.6527 7.57632 14.6816 7.37231C14.7031 7.21808 14.7602 7.09519 14.834 6.99687C14.8714 6.94587 14.9071 6.91023 14.9421 6.88196C14.9728 6.85677 14.9956 6.84325 15.0318 6.82543L14.1494 7.25986L15.0183 9.02341L15.9001 8.58836L15.9044 8.59389Z"
        fill="white"
      />
      <path
        d="M10.841 3.00009L15.7568 7.91589V6.52533L10.841 11.4411L10.1454 12.1361L11.5353 13.526L12.2303 12.8305L17.1461 7.91466L17.8411 7.21908L17.1455 6.52349L12.2297 1.60769L11.5341 0.912109L10.1436 2.30205L10.8385 2.99702L10.841 3.00009Z"
        fill="white"
      />
    </svg>
  )
}
