export const mapsData = {
  "Les Bordes Estate": [
    {
      name: "Six Senses Resort & Spa",
      description:
        "With a name internationally synonymous with nature-immersed comfort and wellbeing, Six Senses brings not only a new hotel to the estate, but also a full-service spa, stunning castle suites, and a fine-dining restaurant. **Expected Summer 2025.**",
      url: "/images/maps/les-bordes/six-senses-hotel.webp",
      d: "M789.592 1006.57L743.696 1085.72L789.592 1183.13L834.082 1241.2L902.457 1251.5L1022.82 1183.13L1062.16 1101.64L1030.78 1013.13L963.807 968.638H868.738L789.592 1006.57Z",
    },
    {
      name: "Le Village",
      description:
        "Rendezvous with friends and family at the social heart of the estate, Le Village; home to the gracious Resident Clubhouse**, a natural swimming lake and beach, restaurants and more. **Expected Summer 2025**",
      url: "/images/maps/les-bordes/levillage.webp",
      d: "M332.509 921.337L394.327 831.888L428.515 795.359L543.722 800.042L605.541 850.152L641.602 936.324L612.097 1015.94L516.56 1050.13L410.25 1035.61L332.509 980.814V921.337Z",
    },
    {
      name: "La Ferme",
      description:
        "Enjoy the fresh air to the fullest with a range of active pursuits, including tennis courts and equestrian stables, as well as a playground, electric go karts, and a petting zoo.",
      url: "/images/maps/les-bordes/la-ferme.webp",
      d: "M593.833 483.924L572.758 592.107L580.251 705.909L636.45 721.832L776.01 674.531L807.388 578.525L787.25 496.101L684.219 452.547L593.833 483.924Z",
    },
    {
      name: "Golf Clubhouse",
      description:
        "Refresh with a well earned libation by the fire in this rustic-chic haven created by Soho House-designer Michaelis Boyd, complete with stunning views of the Old Course.",
      url: "/images/maps/les-bordes/club-house.webp",
      d: "M1163.78 552.768L1065.43 612.245L1103.37 787.397L1216.23 832.356L1301 747.59L1346.9 624.421L1271.03 552.768H1163.78Z",
    },

    {
      name: "Old Course",
      description:
        "The estate’s original Par-72 course, designed by Robert Von Hagge in 1987, has been consistently ranked among Europe’s top three courses over the last 30 years.",
      url: "/images/maps/les-bordes/les-bordes-old-course.webp",
      d: "M1473.34 504.999L1392.79 522.795L1374.99 572.906L1367.03 696.074L1456.48 728.389L1577.31 720.427L1613.84 647.837L1582.93 546.211L1473.34 504.999Z",
    },
    {
      name: "New Course",
      description:
        "With its broom and heather-brimmed terrain playing firm and fast, the private New Course by Gil Hanse and Jim Wagner was included in GOLF Magazine’s Top 100 Courses in the World 2021-2022.",
      url: "/images/maps/les-bordes/les-bordes-new-course.webp",
      d: "M459.424 651.115L385.898 819.711L309.093 844.532L218.238 819.711L209.34 669.38L319.396 614.118L459.424 651.115Z",
    },
    {
      name: "Wild Piglet Course",
      description:
        "Ten par-3 holes pay whimsical homage to some of the most iconic holes around the globe in this fresh Gil Hanse creation.",
      url: "/images/maps/les-bordes/wild-piglet-par-3-course.webp",
      d: "M447.716 1076.82L385.898 1130.68L409.314 1213.1L447.716 1282.41L525.926 1296L621.932 1233.24L686.561 1148.47L621.932 1076.82L525.926 1059.02L447.716 1076.82Z",
    },
    {
      name: "Cour du Baron",
      description:
        "Live amidst a pastoral dream in this French farmhouse-inspired collection of three- to seven-bedroom homes, designed by Michaelis Boyd and immersed seamlessly into the landscape.",
      url: "/images/maps/les-bordes/cdb.webp",
      d: "M1073.39 780.841L1087.91 892.77L1015.32 965.36L846.259 918.527L824.247 869.354V780.841L920.253 729.325H1002.68L1073.39 780.841Z",
    },
  ],
  "Loire Valley": {
    SIGHTS: [
      {
        name: "Château de Beaugency",
        area: "Beaugency",
        description:
          "An exceptional place where the arts, sciences and heritage come together. Tansformed into a digital art centre, the châteaux is adorned with thousands of pixels. Dressed in lights, resonating with electronic music, (re)animated to the rhythm of the projectors, the digital art centre will amaze young and old alike.",
        distance: 8,
        url: "/images/maps/le-loire/pois/1.png",
        time: 12,
      },
      {
        name: "Château de Royal de Cheverny",
        area: "Cheverny",
        description:
          "A majestic property that has been in the same family for more than six centuries, the Château de Cheverny is famous for its richly furnished interior. The Château, its gardens, its labyrinth and the 3D Tintin exhibition will give family and friends an unforgettable experience.",
        distance: 38,
        url: "/images/maps/le-loire/pois/2.png",
        time: 40,
      },
      {
        name: "Château de Chaumont Sur Loire",
        area: "Chaumont Sur Loire",
        description:
          "Watching over the Loire from a high bluff, the Château de Chaumont-sur-Loire shines above its little village. Nestled on the banks of the River Loire In Loire et Cher, it is unique for its presentation of art and the international garden festival that’s held here annually.",
        distance: 51,
        url: "/images/maps/le-loire/pois/3.png",
        time: 55,
      },
      {
        name: "Château de Royal de Blois",
        area: "Blois",
        description:
          'The Royal Château de Blois is a "Museum of France" with more than 35,000 works and exhibits. It houses the precious stones museum and the Museum of the Fine Arts of the city of Blois. It is also the setting for regular annual events and the place where special occasions in the life of the city are focussed.',
        distance: 32,
        url: "/images/maps/le-loire/pois/4.png",
        time: 40,
      },
      {
        name: "Château de Chambord",
        area: "Chambord",
        description:
          "Commissioned by King Francis I and imagined by the great Leonardo da Vinci, the Château de Chambord is the largest and most majestic castle of the Loire. Much more than a castle, Chambord has captured the imagination of visitors and francophiles alike for centuries.",
        distance: 20,
        url: "/images/maps/le-loire/pois/5.png",
        time: 25,
      },
      {
        name: "Château de Chenonceau",
        area: "Chenonceaux",
        description:
          "Proudly bridging the banks of the Cher River, Château de Chenonceau offers one of the Loire’s most precious settings. Nestled within beautifully curated formal gardens, the Château provides a perfect day out - get lost in the maze, immerse yourself in 16th Century art at the museum and finish the day wine tasting Touraine-Chenonceaux AOC wines.",
        distance: 76,
        url: "/images/maps/le-loire/pois/6.png",
        time: 75,
      },
      {
        name: "Château du Clos Lucé",
        area: "Amboise",
        description:
          "King Louis XI passed on the land to his former kitchen boy in 1471, named Etienne Le Loup, who built the Château du Clos Lucé with bricks and freestone, as seen today.\nThe Château became summer residence to King Charles VIII and Queen Anne the Bretagne until 1516 when Francis I and Louise de Savoie passed it on to Leonardo da Vinci, where he spent the last three years of his life.",
        distance: 86,
        url: "/images/maps/le-loire/pois/7.png",
        time: 65,
      },
      {
        name: "Château d'Azay-le-Rideau",
        area: "Azay-le-Rideau",
        description:
          "Built on an island in the Indre River, Château d'Azay-le-Rideau provides a subtle blend of French tradition and innovative Italian decor and is an icon of the new art of building in the Loire Valley in the 16th century.",
        distance: 125,
        url: "/images/maps/le-loire/pois/8.png",
        time: 85,
      },
      {
        name: "Château de Villandry",
        area: "Villandry",
        description:
          "Bought as a fortress in 1532 by Jean Breton, finance minister of François I, the monumental building underwent a remarkable transformations through the following decades. Like the sovereign, Breton followed the new architectural techniques of the Renaissance and embarked on major works to bring the Château de Villandry into a more modern fitting. The Villandry gardens are one of few places in France open to the public all year around.",
        distance: 125,
        url: "/images/maps/le-loire/pois/9.png",
        time: 85,
      },
      {
        name: "ZooParc de Beauval",
        area: "Saint-Aignan",
        description:
          "Ranked amongst the 5 best zoos in the world, the ZooParc de Beauval is home to the highest diversity of animals in France. Embark on an unforgettable journey and meet more than 10,000 animals from around the world, living within the ZooParc’s 20 territories.",
        distance: 68,
        url: "/images/maps/le-loire/pois/10.png",
        time: 70,
      },
      {
        name: "Cathédrale Sainte-Croix d'Orléans",
        area: "Orléans ",
        description:
          "Built between 1278 and 1329, the Orléans Cathedral is a Roman Catholic Church that was partially destroyed by the Huguenots during the French Wars of Religion.\nIts gothic style and stained glass windows carry the story of the city's siege and Joan of Arc's role in helping France crown Charles VII after the 100 Years War against England.",
        distance: 30,
        url: "/images/maps/le-loire/pois/11.png",
        time: 40,
      },
      {
        name: "Orléans : Rue de Bourgogne",
        area: "Orléans ",
        description:
          "Orléans is one of the oldest cities in France and was famously saved the city by Joan of Arc from English siege in 1429. Today, as you stroll along the city’s leafy boulevards, you will see various tributes to the Maid of Orléans; from paintings and stained glass windows to striking statues.",
        distance: 29,
        url: "/images/maps/le-loire/pois/12.png",
        time: 40,
      },
      {
        name: "Parc Floral de la Source",
        area: "Orléans ",
        description:
          "Parc Floral de La Source offers plenty space for relaxation and some incredible botanical discoveries. Stroll through this vast 35-hectare garden and discover the different spaces within it devoted to irises, roses, fuchsias or dahlias.",
        distance: 33,
        url: "/images/maps/le-loire/pois/13.png",
        time: 35,
      },
      {
        name: "Musée des Beaux-Arts",
        area: "Orléans ",
        description:
          "Whatever your artistic preference, you are sure to be captivated at Musée des Beaux-Arts. The museum owns more than 1,200 pieces of decorative arts, 10,000 drawings, 50,000 prints and the second largest collection of pastels in France after that in the Louvre.",
        distance: 30,
        url: "/images/maps/le-loire/pois/14.png",
        time: 40,
      },
      {
        name: "Collégiale Saint-Aignan",
        area: "Orléans ",
        description:
          "Originally built to worship the remains of St Aignan, the Collégiale has now become a monument of historical interest due to the discovery of ancient ruins within the site. The Church of Saint-Aignan serves as remembrance of the defeat against the Huns in 451.",
        distance: 30,
        url: "/images/maps/le-loire/pois/15.png",
        time: 40,
      },
      {
        name: "Cathédrale Saint-Louis",
        area: "Blois",
        description:
          "Built in late gothic style and with signs of early classicism, Saint-Louis de Blois is a Roman Catholic church listed as historical monument in 1906. Of special interest are the stained glass windows that had to be replaced and restored after the original ones were destroyed during the Second World War.",
        distance: 32,
        url: "/images/maps/le-loire/pois/16.png",
        time: 40,
      },
      {
        name: "Escalier Denis Papin",
        area: "Blois",
        description:
          "An iconic attraction in Blois, the monumental Denis-Papin staircase overlooks the town, providing an unforgettable panoramic view, and regularly enlivens urban space with its original, grandiose decorations.",
        distance: 34,
        url: "/images/maps/le-loire/pois/17.png",
        time: 40,
      },
      {
        name: "Les Jardins de l’Ancien Eveche",
        area: "Blois",
        description:
          "Take time for the walk up to Les Jardins de l’Ancien Eveche, as along the way are some lovely twisting, climbing streets, with beautifully preserved 15th century architecture. The Acrobats House on Place Saint Louis and Hôtel de Villebresme on Rue Pierre de Blois, both 15th century houses are worth a stop on the way.",
        distance: 33,
        url: "/images/maps/le-loire/pois/18.png",
        time: 35,
      },
      {
        name: "Maison de la Magie Robert-Houdin",
        area: "Blois",
        description:
          "The Robert-Houdin house of magic is the only public museum in Europe bringing together magic collections and permanent live performances. On five floors with a total area of 2,000 m², discover the history of magic, the life of the celebrated magician Robert-Houdin and optical illusions of all varieties. ",
        distance: 33,
        url: "/images/maps/le-loire/pois/19.png",
        time: 35,
      },
    ],
    FOOD: [
      {
        name: "Fleur de Loire",
        area: "Blois",
        description:
          "A true place of life in the heart of Blois, the new project of the two-starred Chef Christophe Hay presents itself as the promise of a timeless stay, in harmony with nature and the Loire region, where disconnection and tranquility are the key words.",
        distance: 35,
        url: "/images/maps/le-loire/pois/20.png",
        time: 35,
      },
      {
        name: "L'Orangerie du Château",
        area: "Blois",
        description:
          "Gourmet restaurant selected in the Michelin guide 2021. Enjoy the terrace in summer with a breathtaking view of the François I lodges of the Royal Castle of Blois, the St Vincent Church and the Anne de Bretagne pavilion.",
        distance: 33,
        url: "/images/maps/le-loire/pois/21.png",
        time: 40,
      },
      {
        name: "Assa",
        area: "Blois",
        description:
          "Assa’s founders, Fumiko and Anthony, invite you to experience dishes inspired by the River Loire with fine details of Japan. Fumiko and Anthony explore nature’s treasures, select the best products and cook following the daily provision of their suppliers. ",
        distance: 35,
        url: "/images/maps/le-loire/pois/22.png",
        time: 40,
      },
      {
        name: "Le Médicis",
        area: "Blois",
        description:
          "Le Médicis chef, Damien Garanger, has multiplied his experiences with starred chefs and has traveled the world, bringing back flavours from across continents that he mixes with a culinary tradition deeply rooted in the land.",
        distance: 37,
        url: "/images/maps/le-loire/pois/23.png",
        time: 45,
      },
      {
        name: "Le Favori",
        area: "Cheverny",
        description:
          "Like the dining room, which opens out onto the natural landscape, Frédéric Calmels finds his inspiration in the wild land. Vegetables are central to his dishes and are accompanied by fish and game meticulously chosen from the western France.",
        distance: 43,
        url: "/images/maps/le-loire/pois/24.png",
        time: 47,
      },
      {
        name: "Côté Jardin",
        area: "Gien",
        description:
          "On the left bank of the Loire, the freshness comes as much from the breeze as from the carefully selected ingredients that go into the fish-oriented menu. At the helm, Arnaud Billard devises subtle combinations of ingredients to create tasty market-based dishes. ",
        distance: 90,
        url: "/images/maps/le-loire/pois/25.png",
        time: 80,
      },
      {
        name: "L'Evidence",
        area: "Montbazon",
        description:
          "The meat and vegetables are regional and the fish comes direct from Brittany: the ingredients take the limelight here, enhanced by a chef, as bold as he is talented. To wash down your meal, take your pick from a list of fine Loire Valley wines.",
        distance: 110,
        url: "/images/maps/le-loire/pois/26.png",
        time: 85,
      },
      {
        name: "Le Grand Saint Michel",
        area: "Chambord",
        description:
          "Revamped at the same time as the hotel in which it is housed, the restaurant is chic and elegant - an ideal environment for sampling the dishes meticulously prepared by a truly enthusiastic chef. The view from the terrace of the iconic château built by King François makes a visit here unforgettable.",
        distance: 20,
        url: "/images/maps/le-loire/pois/27.png",
        time: 25,
      },
      {
        name: "Le Rendez-vous Des Gourmets",
        area: "Chambord",
        description:
          "This family-run guesthouse is the fief of Didier Doreau. The chef uses fine ingredients in cooking that sticks with tradition (lamb confit with garden herbs, citrus gratin etc) and has carved out a solid reputation in the region for his dishes with boar, venison and hare.",
        distance: 28,
        url: "/images/maps/le-loire/pois/28.png",
        time: 30,
      },
      {
        name: "Volupia",
        area: "Chargé",
        description:
          "This extraordinary location is the perfect spot to discover the region's wines in a troglodyte cellar dating back to the 17th century, or – weather permitting –, on a sun deck that resembles a small guinguette (riverside café with a dance floor) on the banks of the Loire River.",
        distance: 85,
        url: "/images/maps/le-loire/pois/29.png",
        time: 65,
      },
    ],
    ACTIVITIES: [
      {
        name: "Ballons de Loire",
        area: "Jouy-Le-Potier",
        description:
          "Board the Ballons de Loire for an unforgettable flight over the Sologne, the Loire and the Loiret. Enjoy a panoramic discovery of landscapes, fauna, flora, castles and hidden manors. Departures from Orléans, Olivet, Meung-sur-Loire, Beaugency, Jouy-le-Potier or private land. ",
        distance: 18,
        url: "/images/maps/le-loire/pois/30.jpg",
        time: 20,
      },
      {
        name: "Boat Trip / Cruise on the Loire",
        area: "Loire River",
        description:
          "Experience the charm of the Loire by boat and sail along Europe’s last great wild river. Immerse yourself in the magic of Loire Valley - the UNESCO World Heritage-listed landscapes and architecture, the biodiversity and the culture. Whether its for an hour or a full week, hop on one of the boats setting sail from Tours, Nantes or Chambord for a journey you won't forget. ",
        distance: 18,
        url: "/images/maps/le-loire/pois/31.jpeg",
        time: 25,
      },
      {
        name: "Léo Parc Aventure",
        area: "Orléans ",
        description:
          "Léo Parc offers a world of adventure in the trees, with more than 200 games and sports workshops including ziplining, trampoline, giant net climbing, paintball and laser games. Enjoy a moment of relaxation at the Parc’s beach before finishing the day at Loiret’s first aquatic park. ",
        distance: 31,
        url: "/images/maps/le-loire/pois/32.png",
        time: 50,
      },
      {
        name: "Les Halles de Tours",
        area: "Tours",
        description:
          "Stroll through the aisles and smell the scents of Les Halles de Tours, a central market of 38 merchants offering a tantalising selection of fresh produce, breads and pastries, delicatessens and wines - all under one roof.",
        distance: 99,
        url: "/images/maps/le-loire/pois/33.png",
        time: 70,
      },
      {
        name: "Max Vauche (Chocolatier)",
        area: "Bracieux",
        description:
          "Max Vauché, master chocolatier, invites you to universe of chocolate. Drop in on a workshop, take a guided tour, or visit one of their delicacies in Blois and Orléans to learn the origins and celebrate the pure taste of chocolate. ",
        distance: 28,
        url: "/images/maps/le-loire/pois/34.png",
        time: 30,
      },
      {
        name: "Domaine du Croc du Merle Vinyard",
        area: "Muides-sur-Loire",
        description:
          "Independent father and son winemakers since 1794, Domaine du Croc du Merle boasts 10 hectares of vineyards and farmland between the Loire and Chambord Château. Visit the charming farm shop to buy wines, cheeses, yoghurts and soft drinks produced on the farm.",
        distance: 15,
        url: "/images/maps/le-loire/pois/35.png",
        time: 20,
      },
      {
        name: "Domaine Le Portail Vinyard",
        area: "Chevrney",
        description:
          "Previously an old monastery, the estate now specialises in wines entitled to use the designation Appellation d'Origine Contrôlée of Cheverny. Book in for a tasting to experience the unrivalled blends of Damien Cadoux.",
        distance: 40,
        url: "/images/maps/le-loire/pois/36.jpg",
        time: 45,
      },
      {
        name: "There are 900 km of bike paths along the Loire river: ‘The Loire by Bike’",
        area: "Le Loire River",
        description:
          "From the foothills of the Massif Central to the beaches of the Atlantic, the Loire by bike follows the banks of Europe’s last great wild river. Over almost 900 km, an exceptional diversity of landscapes is on offer to lovers of nature and heritage.",
        distance: 5,
        url: "/images/maps/le-loire/pois/37.png",
        time: 12,
      },
    ],
    TRANSPORT: [
      {
        name: "Beaugency Train Station",
        area: "Beaugency",
        description: "",
        distance: 9,
        url: "",
        time: 15,
      },
      {
        name: "Blois Train Station",
        area: "Blois",
        description: "",
        distance: 35,
        url: "",
        time: 40,
      },
      {
        name: "Orléans  Train Station",
        area: "Orléans ",
        description: "",
        distance: 30,
        url: "",
        time: 40,
      },
      {
        name: "Amboise Train Station",
        area: "Amboise",
        description: "",
        distance: 85,
        url: "",
        time: 67,
      },
      {
        name: "Gien Train Station",
        area: "Gien",
        description: "",
        distance: 88,
        url: "",
        time: 85,
      },
      {
        name: "Tours Train Station",
        area: "Tours",
        description: "",
        distance: 99,
        url: "",
        time: 75,
      },
      {
        name: "Tours Airport",
        area: "Tours",
        description: "",
        distance: 97,
        url: "",
        time: 70,
      },
      {
        name: "Private Air Terminal",
        area: "Orléans ",
        description: "",
        distance: 50,
        url: "",
        time: 55,
      },
      {
        name: "Vendôme Train Station",
        area: "Vendôme",
        description: "",
        distance: 57,
        url: "",
        time: 60,
      },
    ],
  },
}
