import { useEffect, useRef, useState } from "react"
import HomeScreen1 from "./HomeScreen1"
import { useGSAP } from "@gsap/react"
import { gsap } from "gsap"
import HomeScreen2 from "./HomeScreen2"
import SplitType from "split-type"
import HomeScreen3 from "./HomeScreen3"
import HomeScreen4 from "./HomeScreen4"
import { useStore } from "@state/store"
import ArrowButton from "@components/Shared/ArrowButton"

export default function Home() {
  const [timelinePlaying, setTimelinePlaying] = useState(false)
  const [shiftArrows, setShiftArrows] = useState(false)
  const syncState = useStore((s) => s.syncState)
  const timelineRef = useRef(null)

  const reverseToPreviousLabel = () => {
    const labels = timelineRef.current.labels
    const currentTime = timelineRef.current.time()
    let previousLabelTime = 0

    // Find the last label before the current time
    for (let label in labels) {
      if (labels[label] < currentTime) {
        previousLabelTime = labels[label]
      } else {
        break
      }
    }

    // Reverse the timeline to the last found label
    timelineRef.current.tweenTo(previousLabelTime)
  }

  useGSAP(() => {
    const home2Text1 = new SplitType("#home-2-text1", { types: "chars" })
    const home2Text2 = new SplitType("#home-2-text2", { types: "words" })
    const home3Text1 = new SplitType("#home-3-text1", { types: "chars" })
    const home3Text2 = new SplitType("#home-3-text2", { types: "words" })
    const home4Text1 = new SplitType("#home-4-text1", { types: "chars" })
    const home4Text2 = new SplitType("#home-4-text2", { types: "chars" })
    gsap.set(
      [
        home2Text1.chars,
        home2Text2.words,
        home3Text1.chars,
        home3Text2.words,
        home4Text1.chars,
        home4Text2.chars,
      ],
      { autoAlpha: 0, y: 15 }
    )
    gsap.set("#home-3-img1", { y: 60 })
    gsap.set("#home-3-img2", { y: 30 })
    gsap.set(
      [
        "#home-2-text1",
        "#home-2-text2",
        "#home-3-text1",
        "#home-3-text2",
        "#home-4-text1",
        "#home-4-text2",
      ],
      { autoAlpha: 1 }
    )
    timelineRef.current = gsap
      .timeline({
        paused: true,
        defaults: {
          ease: "power2.inOut",
          duration: 2,
        },
      })
      .timeScale(1.4)
      //Parallax (#1)
      .to(
        ["#home-1-castle", "#home-1-trees"],
        {
          opacity: 1,
          onStart: () => setTimelinePlaying(true),
          onReverseComplete: () => setTimelinePlaying(false),
        },
        "<"
      )
      .to(
        "#home-1-logo",
        {
          delay: 0.4,
          scale: 1.3,
        },
        "<"
      )
      .to(
        "#home-1-castle",
        {
          scale: 2.1,
        },
        "<"
      )
      .to(
        "#home-1-trees",
        {
          scale: 3.4,
        },
        "<"
      )
      .to(
        "#home-1-logo",
        {
          delay: 0.3,
          opacity: 0,
          y: "10rem",
        },
        "<"
      )
      // .to(
      //   "#home-1-text-1",
      //   {
      //     duration: 0.5,
      //     opacity: 0,
      //   },
      //   "<"
      // )
      .to(
        ["#home-1-castle", "#home-1-trees"],
        {
          opacity: 0,
        },
        "<"
      )
      .to(
        ["#home-2-video", "#home-2-overlay"],
        {
          display: "grid",
          autoAlpha: (index) => (index === 0 ? 0.6 : 1),
          scale: 1.1,
        },
        "<"
      )
      .to(
        home2Text1.chars,
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.4,
          duration: 1.3,
          onComplete: () => setTimelinePlaying(false),
          onReverseStart: () => setTimelinePlaying(true),
          stagger: {
            each: 0.03,
            from: "random",
          },
        },
        "<"
      )

      .addPause()
      .addLabel("pause")
      //Video in (#2)
      .to(
        home2Text1.chars,
        {
          autoAlpha: 0,
          y: 20,
          stagger: {
            each: 0.01,
          },
          onStart: () => setTimelinePlaying(true),
          onReverseComplete: () => setTimelinePlaying(false),
        },
        "<"
      )
      .to(
        home2Text2.words,
        {
          delay: 0.4,
          autoAlpha: 1,
          y: 0,
          stagger: {
            each: 0.05,
          },
          onComplete: () => {
            setTimelinePlaying(false)
          },
          onReverseComplete: () => setTimelinePlaying(true),
        },

        "<"
      )

      .addPause()
      .addLabel("pause")
      .to(
        home2Text2.words,
        {
          autoAlpha: 0,
          // y: 10,
          stagger: {
            each: 0.03,
          },
          onStart: () => setTimelinePlaying(true),
          onReverseComplete: () => setTimelinePlaying(false),
        },

        "<"
      )
      .to(
        ["#home-2-video"],
        {
          autoAlpha: 0,
          scale: 1,
        },
        "<"
      )
      .to(
        ["#home-3-video"],
        {
          display: "grid",
          autoAlpha: 0.6,
          scale: 1.1,
        },
        "<"
      )
      .to(
        home3Text1.chars,
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.6,
          duration: 1.3,
          stagger: {
            each: 0.03,
            from: "random",
          },
          onComplete: () => {
            setTimelinePlaying(false)
          },
          onReverseComplete: () => setTimelinePlaying(true),
        },
        "<"
      )
      //New text (#3)

      .addPause()
      .addLabel("pause")
      .to(
        home3Text1.chars,
        {
          autoAlpha: 0,
          // y: 30,
          stagger: {
            each: 0.01,
          },
          onStart: () => setTimelinePlaying(true),
          onReverseComplete: () => setTimelinePlaying(false),
        },
        "<"
      )
      .to(
        home3Text2.words,
        {
          autoAlpha: 1,
          // y: 0,
          delay: 0.4,
          stagger: {
            each: 0.05,
          },
        },

        "<"
      )
      .to(
        ["#home-3-img1", "#home-3-img2"],
        {
          autoAlpha: 0.5,
          y: 0,
          delay: 1.2,
          duration: 2,
          stagger: {
            each: 1,
          },
          onComplete: () => {
            setTimelinePlaying(false)
          },
          onReverseComplete: () => setTimelinePlaying(true),
        },
        "<"
      )
      //New video in (#4)

      .addPause()
      .addLabel("pause")
      .to(
        home3Text2.words,
        {
          autoAlpha: 0,
          // y: 10,
          stagger: {
            each: 0.01,
          },
          onStart: () => setTimelinePlaying(true),
          onReverseComplete: () => setTimelinePlaying(false),
        },

        "<"
      )
      .to(
        ["#home-3-img1", "#home-3-img2"],
        {
          autoAlpha: 1,
          scale: 1.3,
          stagger: {
            each: 0.2,
          },
          onComplete: () => {
            setTimelinePlaying(false)
          },
          onReverseComplete: () => {
            setShiftArrows(false)
            setTimelinePlaying(true)
          },
        },
        "<"
      )
      //Images fully in (#5)

      .addPause()
      .addLabel("pause")

      .to(
        ["#home-3-img1", "#home-3-img2", "#home-3-video"],
        {
          autoAlpha: 0,
          duration: 1.2,
          onStart: () => {
            setTimelinePlaying(true)
            setShiftArrows(false)
          },
          onReverseStart: () => setShiftArrows(false),
          onReverseComplete: () => {
            setShiftArrows(false)
            setTimelinePlaying(false)
          },
          onComplete: () => {
            syncState("hideLogo", false)
          },
        },
        "<"
      )
      .to(
        ["#home-4-bg", home4Text1.chars, home4Text2.chars],
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.3,
          stagger: { each: 0.02 },
          onStart: () => {
            setTimelinePlaying(false)
            setShiftArrows(true)
          },
          onReverseComplete: () => setTimelinePlaying(true),
        },
        "<"
      )
      //Final image (#6)

      .addPause()
      .addLabel("pause")
  })

  return (
    <div
      // onClick={() => {
      //   timelineRef.current.play()
      // }}
      style={{
        pointerEvents: "auto",
        position: "fixed",
        inset: 0,
        height: "100%",
        width: "100%",
        display: "grid",
        placeItems: "center",
      }}
    >
      <HomeScreen1 />
      <HomeScreen2 />
      <HomeScreen3 />
      <HomeScreen4 />
      {/* <div
        id="home-dot"
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          placeSelf: "end",
          margin: "3.5rem",
          borderRadius: "100%",
          height: "1rem",
          aspectRatio: 1,
          backgroundColor: "#f4f1f1e5",
          zIndex: 5,
        }}
      /> */}
      <div
        style={{
          zIndex: 10,
          opacity: timelinePlaying ? 0.3 : 1,
          transition: "opacity 0.3s, margin 0.3s",
          pointerEvents: timelinePlaying ? "none" : "auto",
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          display: "grid",
          placeSelf: "end start",
          height: "4rem",
          margin: shiftArrows ? "6rem 4rem" : "1rem 4rem",
          columnGap: "2rem",
          placeItems: "center",
          gridAutoFlow: "column",
        }}
      >
        <ArrowButton
          id="timeline-button"
          height="3rem"
          style={{
            opacity: timelineRef.current?.progress() <= 0 ? 0.3 : 1,
            cursor: "pointer",
            transition: "opacity 0.3s",
            ...(timelineRef.current?.progress() <= 0 && {
              pointerEvents: "none",
            }),
          }}
          onClick={(e) => {
            setTimelinePlaying(true)
            timelineRef.current.pause()
            timelineRef.current.reverse()
            // reverseToPreviousLabel()
          }}
        />
        <ArrowButton
          id="timeline-button"
          height="3rem"
          rotation={-90}
          style={{
            opacity: timelineRef.current?.progress() >= 0.8 ? 0.3 : 1,
            cursor: "pointer",
            transition: "opacity 0.3s",
            ...(timelineRef.current?.progress() >= 0.8 && {
              pointerEvents: "none",
            }),
          }}
          onClick={(e) => {
            setTimelinePlaying(true)
            timelineRef.current.pause()
            timelineRef.current.play()
          }}
        />
      </div>
    </div>
  )
}
