import PlaceCircle from "../FlightLine/PlaceCircle"

export default function Destinations({ destinations, camTargetName }) {
  return destinations
    .map((item,index) => {
      return item.camera ? (
        <PlaceCircle
          key={index}
          position={item.camera.flightLine[2]}
          size={0.005}
          fontSize={0.8}
          color="rgba(129, 177, 224)"
          isActive={false}
        />
      ) : null
    })
    .filter(item => item !== null)
}
