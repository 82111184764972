import * as THREE from "three"

export default function flightLinesFunction(canClick, element, setCamTarget) {
    setCamTarget({
      ...element,
      target: element.camera.flightLine[0],
      position: element.camera.target.position,
      origin: element.camera.flightLine[0],
      flightLines: [
        new THREE.Vector3(...element.camera.flightLine[0]),
        new THREE.Vector3(...element.camera.flightLine[1]),
        new THREE.Vector3(...element.camera.flightLine[2])
      ]
    })
  canClick.update(false)
}
