import { globalStyles } from "@data/globalStyles"
import { useStore } from "@state/store"

// const filters = ["LES BORDES ESTATE", "LOIRE VALLEY", "GLOBAL DESTINATIONS"]
const filters = ["SIGHTS", "FOOD", "ACTIVITIES", "TRANSPORT"]

const loireSubFilters = {
  "LOIRE VALLEY": ["SIGHTS", "FOOD", "ACTIVITIES", "TRANSPORT"],
}
export default function SidebarFilterButtons() {
  const mapSubFilter = useStore((state) => state.mapSubFilter)
  const syncState = useStore((state) => state.syncState)
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "grid",
        gridAutoFlow: "column",
        gridTemplateColumns: "repeat(4, minmax(0, 1f))",
        placeItems: "center",
      }}
    >
      {filters.map((filter, index) => {
        const active = mapSubFilter === filter
        return (
          <button
            key={index}
            onClick={() => {
              syncState("mapSubFilter", filter)
            }}
            style={{
              height: " 100%",
              width: "100%",
              background: "none",
              border: "none",
              borderLeft: "none",
              borderRight: "none",
              borderTop: "1px solid white",
              borderBottom: "1px solid white",
              fontFamily: "Canto Roman",
              fontSize: "1rem",
              letterSpacing: "0.15rem",
              color: active ? globalStyles.col.blue : "white",
              backgroundColor: active ? "white" : "#4e769bb3",
              transition: "opacity 0.3s, background-color 0.3s, color 0.3s",
              pointerEvents: "auto",
            }}
          >
            {filter}
          </button>
        )
      })}
    </div>
  )
}
