import { useStore } from "@state/store"
import FavouritesCard from "./FavouritesCard"
import { AnimatePresence } from "framer-motion"
import ResidencePopup from "@components/Residences/ResidencePopup"

export default function FavouritesCards() {
  const favourites = useStore((state) => state.favourites)
  return (
    <>
      <div
        id="sharePopupHide"
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          paddingLeft: "4rem",
          display: "grid",
          placeSelf: "center start",
          placeItems: "center start",
          gridAutoFlow: "column",
          // columnGap: "1.4rem",
          height: "65%",
          ...(favourites.length > 3 && { width: "100%" }),
          touchAction: "auto",
          overflowX: "scroll",
          transform: "translateY(2.4rem)",
          zIndex: 2,
          // mask: "linear-gradient(to right, transparent, black 2rem, black 85%, #00000029 100%)",
        }}
      >
        <AnimatePresence>
          {favourites.map((favourite, index) => {
            return <FavouritesCard key={index} favourite={favourite} />
          })}
        </AnimatePresence>
      </div>
    </>
  )
}
