import { useStore } from "@state/store"
import LogoSVG from "./LogoSVG"
import { useGSAP } from "@gsap/react"
import { gsap } from "gsap"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

export default function Logo({ ...props }) {
  const nav = useStore((state) => state.nav)
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const favParam = searchParams?.get("favs")
  const navigate = useNavigate()
  const hideLogo = useStore((s) => s.hideLogo)
  const syncState = useStore((state) => state.syncState)

  useGSAP(
    () => {
      gsap.to("#logo-button", {
        autoAlpha: hideLogo ? 0 : 1,
        duration: 0.2,
      })
    },
    { dependencies: [hideLogo] }
  )
  return (
    <button
      onClick={() => {
        if (!favParam) {
          location.pathname === "/" ? window.location.reload() : navigate("/")
        }
      }}
      id="logo-button"
      style={{
        position: "fixed",
        inset: 0,
        width: "8rem",
        margin: "2.4rem",
        aspectRatio: "213/209",
        backgroundColor: "transparent",
        // background:
        //   "linear-gradient(134deg, rgba(255, 255, 255, 0.95) 17.8%, rgba(255, 255, 255, 0.9) 87.88%)",
        // backdropFilter: "blur(1rem)",
        padding: "1rem",
        display: "grid",
        placeItems: "center",
        border: "none",
        zIndex: 10,
        pointerEvents: "auto",
      }}
    >
      <LogoSVG />
    </button>
  )
}
