import ErrorMessage from "@pages/ErrorMessage"
import EstateComponent from "@pages/Estate"
import FavouritesComponent from "@pages/Favourites"
import GalleryComponent from "@pages/Gallery"
import HomeComponent from "@pages/Home"
import LifestyleComponent from "@pages/Lifestyle"
import MapsComponent from "@pages/Maps"
import ResidencesComponent from "@pages/Residences"
import VideoComponent from "@pages/Video"

const routes = [
  {
    name: "Home",
    path: "/",
    exact: true,
    element: HomeComponent,
  },
  {
    name: "Maps",
    path: "/maps",
    exact: true,
    element: MapsComponent,
  },
  {
    name: "Video",
    path: "/video",
    exact: true,
    element: VideoComponent,
  },
  {
    name: "Gallery",
    path: "/gallery",
    exact: true,
    element: GalleryComponent,
  },
  {
    name: "Residences",
    path: "/residences",
    exact: true,
    element: ResidencesComponent,
  },
  {
    name: "Lifestyle",
    path: "/lifestyle",
    exact: true,
    element: LifestyleComponent,
  },
  {
    name: "Estate",
    path: "/estate",
    exact: true,
    element: EstateComponent,
  },
  {
    name: "Favourites",
    path: "/favourites",
    exact: true,
    element: FavouritesComponent,
  },
  { path: "/*", name: "Not Found", element: ErrorMessage },
]

export default routes
