export default function ResetSVG({ ...props }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 336 336"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168 336C260.784 336 336 260.784 336 168C336 75.2162 260.784 0 168 0C75.2162 0 0 75.2162 0 168C0 260.784 75.2162 336 168 336ZM119.904 167.904C119.904 141.312 141.408 119.904 168 120V139.143C168 142.707 172.309 144.491 174.828 141.972L203.572 113.228C205.134 111.666 205.134 109.134 203.572 107.572L174.828 78.8284C172.309 76.3086 168 78.0932 168 81.6568V100.8L167.808 100.608C149.952 100.608 132.864 107.712 120.288 120.384C95.0263 145.646 94.1142 186.07 117.638 212.513C119.106 214.163 121.65 214.158 123.212 212.596L131.092 204.716C132.654 203.154 132.639 200.632 131.211 198.946C123.885 190.299 119.818 179.332 119.904 167.904ZM218.734 123.953C216.93 121.863 213.745 121.871 211.792 123.824L204.62 130.996C203.058 132.558 203.073 135.08 204.501 136.766C211.828 145.414 215.904 156.39 215.904 167.904C215.904 194.464 194.452 215.852 168 215.904V196.857C168 193.293 163.691 191.509 161.172 194.028L132.428 222.772C130.866 224.334 130.866 226.866 132.428 228.428L161.172 257.172C163.691 259.691 168 257.907 168 254.343V235.104C185.821 235.079 202.871 227.977 215.52 215.424C240.539 190.405 241.499 150.338 218.734 123.953Z"
        fill="white"
      />
    </svg>
  )
}
