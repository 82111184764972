export const residencesDimensionsData = {
  "3 Bed": {
    floorplans: {
      ground: {
        title: "Ground Floor",
        sectionIds: ["interior", "exterior", "pool"],
        sections: {
          interior: {
            title: "Interior",
            entries: [
              { title: "Foyer", value: "15.3", suffix: "m²" },
              { title: "Living Room", value: "36.8", suffix: "m²" },
              { title: "Dining Room", value: "19.4", suffix: "m²" },
              { title: "Kitchen", value: "21.2", suffix: "m²" },
              { title: "Store", value: "6.4", suffix: "m²" },
              { title: "Tech. Area", value: "3.8", suffix: "m²" },
              { title: "Bedroom 1", value: "21.3", suffix: "m²" },
              { title: "Dressing 1", value: "3.2", suffix: "m²" },
              { title: "Bathroom 1", value: "7.4", suffix: "m²" },
              { title: "MEP Cupboard", value: "1.8", suffix: "m²" },
              { title: "Bedroom 3", value: "10.5", suffix: "m²" },
              { title: "Dressing 3", value: "3.7", suffix: "m²" },
              { title: "Bathroom 3", value: "6.0", suffix: "m²" },
            ],
          },
          exterior: {
            title: "Exterior",
            entries: [
              { title: "Forecourt", value: "11.2", suffix: "m²" },
              { title: "Terrace 1", value: "26.6", suffix: "m²" },
              { title: "Terrace 2", value: "80.5", suffix: "m²" },
              { title: "Garden Storage", value: "6.8", suffix: "m²" },
              { title: "MEP Pool", value: "5.6", suffix: "m²" },
            ],
          },
          pool: {
            title: "Pool",
            entries: [{ title: "Swimming Pool", value: "9 x 4", suffix: "m" }],
          },
        },
      },
      first: {
        title: "First Floor",
        sectionIds: ["interior", "exterior"],
        sections: {
          interior: {
            title: "Interior",
            entries: [
              { title: "Bedroom 2", value: "33.9", suffix: "m²" },
              { title: "Dressing 2", value: "8.0", suffix: "m²" },
              { title: "Bathroom 2", value: "13.5", suffix: "m²" },
              { title: "Landing", value: "5.9", suffix: "m²" },
            ],
          },
          exterior: {
            title: "Exterior",
            entries: [{ title: "Loggia", value: "17.5", suffix: "m²" }],
          },
        },
      },
    },
  },
  "4 Bed": {
    floorplans: {
      ground: {
        title: "Ground Floor",
        sectionIds: ["interior", "exterior", "pool"],
        sections: {
          interior: {
            title: "Interior",
            entries: [
              { title: "Foyer", value: "8.7", suffix: "m²" },
              { title: "Living Room", value: "30.4", suffix: "m²" },
              { title: "Dining Room", value: "26.7", suffix: "m²" },
              { title: "Kitchen", value: "23.4", suffix: "m²" },
              { title: "Utility", value: "6.1", suffix: "m²" },
              { title: "Tech. Area", value: "4.0", suffix: "m²" },
              { title: "Store", value: "6.0", suffix: "m²" },
              { title: "MEP Cupboard", value: "1.8", suffix: "m²" },
              { title: "Bedroom 1", value: "22.4", suffix: "m²" },
              { title: "Dressing 1", value: "5.0", suffix: "m²" },
              { title: "Bathroom 1", value: "12.6", suffix: "m²" },
              { title: "Corridor", value: "13.3", suffix: "m²" },
              { title: "Bedroom 4", value: "19.4", suffix: "m²" },
              { title: "Dressing 4", value: "4.5", suffix: "m²" },
              { title: "Bathroom 4", value: "8.1", suffix: "m²" },
            ],
          },
          exterior: {
            title: "Exterior",
            entries: [
              { title: "Forecourt", value: "9.5", suffix: "m²" },
              { title: "Terrace 1", value: "28.5", suffix: "m²" },
              { title: "Terrace 2", value: "96.0", suffix: "m²" },
              { title: "Terrace 3", value: "17.1", suffix: "m²" },
              { title: "Garden Storage", value: "6.8", suffix: "m²" },
              { title: "MEP Pool", value: "5.6", suffix: "m²" },
            ],
          },
          pool: {
            title: "Pool",
            entries: [
              { title: "Swimming Pool", value: "9.5 x 4", suffix: "m" },
            ],
          },
        },
      },
      first: {
        title: "First Floor",
        sectionIds: ["interior", "exterior"],
        sections: {
          interior: {
            title: "Interior",
            entries: [
              { title: "Master Bedroom 2", value: "33.7", suffix: "m²" },
              { title: "Master Dressing 2", value: "5.3", suffix: "m²" },
              { title: "Master Bathroom 2", value: "10.1", suffix: "m²" },
              { title: "Landing", value: "3.3", suffix: "m²" },
              { title: "Bedroom 3", value: "25.1", suffix: "m²" },
              { title: "Bathroom 3", value: "7.3", suffix: "m²" },
            ],
          },
          exterior: {
            title: "Exterior",
            entries: [{ title: "Loggia", value: "16.0", suffix: "m²" }],
          },
        },
      },
    },
  },
  "4 Bed XL": {
    floorplans: {
      ground: {
        title: "Ground Floor",
        sectionIds: ["interior", "exterior", "pool"],
        sections: {
          interior: {
            title: "Interior",
            entries: [
              { title: "Foyer", value: "25.2", suffix: "m²" },
              { title: "Living Room", value: "42.4", suffix: "m²" },
              { title: "Dining Room", value: "25.4", suffix: "m²" },
              { title: "Kitchen", value: "23.6", suffix: "m²" },
              { title: "Utility", value: "4.2", suffix: "m²" },
              { title: "Store", value: "3.6", suffix: "m²" },
              { title: "Tech. Area", value: "4.9", suffix: "m²" },
              { title: "Powder Room", value: "4.5", suffix: "m²" },
              { title: "Wardrobe", value: "3.5", suffix: "m²" },
              { title: "Storage", value: "8.5", suffix: "m²" },
              { title: "Bedroom 1", value: "30.0", suffix: "m²" },
              { title: "Dressing 1", value: "7.1", suffix: "m²" },
              { title: "Bathroom 1", value: "15.6", suffix: "m²" },
              { title: "MEP Cupboard", value: "1.6", suffix: "m²" },
              { title: "Corridor", value: "16.7", suffix: "m²" },
              { title: "Bedroom 4", value: "25.1", suffix: "m²" },
              { title: "Dressing 4", value: "5.8", suffix: "m²" },
              { title: "Bathroom 4", value: "9.4", suffix: "m²" },
            ],
          },
          exterior: {
            title: "Exterior",
            entries: [
              { title: "Forecourt", value: "15.8", suffix: "m²" },
              { title: "Terrace 1", value: "35.8", suffix: "m²" },
              { title: "Terrace 2", value: "115.2", suffix: "m²" },
              { title: "Garden Storage", value: "6.8", suffix: "m²" },
              { title: "MEP Pool", value: "5.6", suffix: "m²" },
            ],
          },
          pool: {
            title: "Pool",
            entries: [
              { title: "Swimming Pool", value: "9.5 x 4.5", suffix: "m" },
            ],
          },
        },
      },
      first: {
        title: "First Floor",
        sectionIds: ["interior", "exterior"],
        sections: {
          interior: {
            title: "Interior",
            entries: [
              { title: "Bedroom 2", value: "45.3", suffix: "m²" },
              { title: "Dressing 2", value: "6.7", suffix: "m²" },
              { title: "Bathroom 2", value: "9.6", suffix: "m²" },
              { title: "Landing", value: "5.7", suffix: "m²" },
              { title: "Bedroom 3", value: "27.4", suffix: "m²" },
              { title: "Dressing 3", value: "4.7", suffix: "m²" },
              { title: "Bathroom 3", value: "8.6", suffix: "m²" },
            ],
          },
          exterior: {
            title: "Exterior",
            entries: [
              { title: "Loggia", value: "18.6", suffix: "m²" },
              { title: "Terrace", value: "55.5", suffix: "m²" },
            ],
          },
        },
      },
    },
  },
  "5 Bed": {
    floorplans: {
      ground: {
        title: "Ground Floor",
        sectionIds: ["interior", "exterior", "pool"],
        sections: {
          interior: {
            title: "Interior",
            entries: [
              { title: "Foyer", value: "31.7", suffix: "m²" },
              { title: "Living Room", value: "43.0", suffix: "m²" },
              { title: "Dining Room", value: "30.3", suffix: "m²" },
              { title: "Kitchen", value: "22.3", suffix: "m²" },
              { title: "Prep Kitchen", value: "6.9", suffix: "m²" },
              { title: "Utility", value: "5.5", suffix: "m²" },
              { title: "Store", value: "7.8", suffix: "m²" },
              { title: "Tech. Area", value: "3.8", suffix: "m²" },
              { title: "Bedroom 5", value: "17.9", suffix: "m²" },
              { title: "Dressing 5", value: "4.0", suffix: "m²" },
              { title: "Bathroom 5", value: "8.7", suffix: "m²" },
              { title: "MEP Cupboard", value: "2.0", suffix: "m²" },
              { title: "Bedroom 1", value: "24.3", suffix: "m²" },
              { title: "Dressing 1", value: "4.9", suffix: "m²" },
              { title: "Bathroom 1", value: "12.5", suffix: "m²" },
              { title: "Bedroom 2", value: "30.3", suffix: "m²" },
              { title: "Bathroom 2", value: "8.6", suffix: "m²" },
            ],
          },
          exterior: {
            title: "Exterior",
            entries: [
              { title: "Forecourt", value: "15.3", suffix: "m²" },
              { title: "Terrace 1", value: "86.5", suffix: "m²" },
              { title: "Terrace 2", value: "23.1", suffix: "m²" },
              { title: "Terrace 3", value: "30.8", suffix: "m²" },
              { title: "Garden Storage", value: "6.8", suffix: "m²" },
              { title: "MEP Pool", value: "5.6", suffix: "m²" },
            ],
          },
          pool: {
            title: "Pool",
            entries: [
              { title: "Swimming Pool", value: "9.5 x 4.5", suffix: "m" },
            ],
          },
        },
      },
      first: {
        title: "First Floor",
        sectionIds: ["interior", "exterior"],
        sections: {
          interior: {
            title: "Interior",
            entries: [
              { title: "Bedroom 3", value: "26.8", suffix: "m²" },
              { title: "Dressing 3", value: "7.0", suffix: "m²" },
              { title: "Bathroom 3", value: "12.5", suffix: "m²" },
              { title: "Landing", value: "7.4", suffix: "m²" },
              { title: "Bedroom 4", value: "31.9", suffix: "m²" },
              { title: "Bathroom 4", value: "8.6", suffix: "m²" },
            ],
          },
          exterior: {
            title: "Exterior",
            entries: [{ title: "Loggia", value: "16.4", suffix: "m²" }],
          },
        },
      },
    },
  },
  "5 Bed XL": {
    floorplans: {
      ground: {
        title: "Ground Floor",
        sectionIds: ["interior", "exterior", "pool"],
        sections: {
          interior: {
            title: "Interior",
            entries: [
              { title: "Foyer", value: "27.7", suffix: "m²" },
              { title: "Living Room", value: "52.4", suffix: "m²" },
              { title: "Dining Room", value: "39.5", suffix: "m²" },
              { title: "Kitchen", value: "28.1", suffix: "m²" },
              { title: "Prep Kitchen", value: "7.2", suffix: "m²" },
              { title: "Utility", value: "4.7", suffix: "m²" },
              { title: "Store", value: "6.0", suffix: "m²" },
              { title: "Tech. Area", value: "5.7", suffix: "m²" },
              { title: "Bedroom 5", value: "19.2", suffix: "m²" },
              { title: "Dressing 5", value: "3.8", suffix: "m²" },
              { title: "Bathroom 5", value: "8.1", suffix: "m²" },
              { title: "MEP Cupboard", value: "2.0", suffix: "m²" },
              { title: "Bedroom 1", value: "20.4", suffix: "m²" },
              { title: "Dressing 1", value: "4.9", suffix: "m²" },
              { title: "Bathroom 1", value: "12.9", suffix: "m²" },
              { title: "Bedroom 2", value: "31.7", suffix: "m²" },
              { title: "Bathroom 2", value: "7.3", suffix: "m²" },
            ],
          },
          exterior: {
            title: "Exterior",
            entries: [
              { title: "Forecourt", value: "16.3", suffix: "m²" },
              { title: "Terrace 1", value: "76.9", suffix: "m²" },
              { title: "Terrace 2", value: "31.4", suffix: "m²" },
              { title: "Terrace 3", value: "30.6", suffix: "m²" },
              { title: "Garden Storage", value: "6.8", suffix: "m²" },
              { title: "MEP Pool", value: "5.6", suffix: "m²" },
            ],
          },
          pool: {
            title: "Pool",
            entries: [
              { title: "Swimming Pool", value: "11 x 4.5", suffix: "m" },
            ],
          },
        },
      },
      first: {
        title: "First Floor",
        sectionIds: ["interior", "exterior"],
        sections: {
          interior: {
            title: "Interior",
            entries: [
              { title: "Bedroom 3", value: "23.0", suffix: "m²" },
              { title: "Dressing 3", value: "7.0", suffix: "m²" },
              { title: "Bathroom 3", value: "12.9", suffix: "m²" },
              { title: "Landing", value: "16.9", suffix: "m²" },
              { title: "Store", value: "6.9", suffix: "m²" },
              { title: "Spa & Fitness", value: "32.1", suffix: "m²" },
              { title: "Fitness Bathroom", value: "7.0", suffix: "m²" },
              { title: "Master Bedroom 4", value: "34.7", suffix: "m²" },
              { title: "Master Dressing 4", value: "7.4", suffix: "m²" },
              { title: "Master Bathroom 4", value: "12.3", suffix: "m²" },
            ],
          },
          exterior: {
            title: "Exterior",
            entries: [
              { title: "Loggia", value: "16.2", suffix: "m²" },
              { title: "Terrace", value: "91.0", suffix: "m²" },
            ],
          },
        },
      },
    },
  },
  "6 Bed": {
    floorplans: {
      ground: {
        title: "Ground Floor",
        sectionIds: ["interior", "exterior", "pool"],
        sections: {
          interior: {
            title: "Interior",
            entries: [
              { title: "Foyer", value: "5.5", suffix: "m²" },
              { title: "Living Room", value: "95.8", suffix: "m²" },
              { title: "Dining Room", value: "26.3", suffix: "m²" },
              { title: "Kitchen", value: "25.5", suffix: "m²" },
              { title: "Prep Kitchen", value: "8.8", suffix: "m²" },
              { title: "Utility", value: "5.3", suffix: "m²" },
              { title: "Store", value: "12.2", suffix: "m²" },
              { title: "Tech. Area", value: "5.9", suffix: "m²" },
              { title: "Bedroom 1", value: "33.5", suffix: "m²" },
              { title: "Dressing 1", value: "19.1", suffix: "m²" },
              { title: "Bathroom 1", value: "13.6", suffix: "m²" },
              { title: "MEP Cupboard", value: "2.4", suffix: "m²" },
              { title: "Bedroom 2", value: "21.7", suffix: "m²" },
              { title: "Bathroom 2", value: "7.5", suffix: "m²" },
              { title: "Corridor", value: "25.0", suffix: "m²" },
              { title: "Bedroom 6", value: "29.8", suffix: "m²" },
              { title: "Dressing 6", value: "5.2", suffix: "m²" },
              { title: "Bathroom 6", value: "8.2", suffix: "m²" },
            ],
          },
          exterior: {
            title: "Exterior",
            entries: [
              { title: "Forecourt", value: "14.3", suffix: "m²" },
              { title: "Terrace 1", value: "72.1", suffix: "m²" },
              { title: "Terrace 2", value: "89.4", suffix: "m²" },
              { title: "Terrace 3", value: "34.7", suffix: "m²" },
              { title: "Sauna", value: "4.3", suffix: "m²" },
              { title: "Restroom", value: "4.8", suffix: "m²" },
              { title: "Garden Storage", value: "7.4", suffix: "m²" },
              { title: "MEP Pool", value: "9.5", suffix: "m²" },
            ],
          },
          pool: {
            title: "Pool",
            entries: [
              { title: "Swimming Pool", value: "14 x 4.5", suffix: "m" },
            ],
          },
        },
      },
      first: {
        title: "First Floor",
        sectionIds: ["interior", "exterior"],
        sections: {
          interior: {
            title: "Interior",
            entries: [
              { title: "Bedroom 3", value: "26.9", suffix: "m²" },
              { title: "Bathroom 3", value: "20.2", suffix: "m²" },
              { title: "Dressing 3", value: "5.3", suffix: "m²" },
              { title: "Bedroom 4", value: "25.6", suffix: "m²" },
              { title: "Bathroom 4", value: "7.5", suffix: "m²" },
              { title: "Landing", value: "14.9", suffix: "m²" },
              { title: "Bedroom 5", value: "27.4", suffix: "m²" },
              { title: "Bathroom 5", value: "7.3", suffix: "m²" },
            ],
          },
          exterior: {
            title: "Exterior",
            entries: [
              { title: "Loggia", value: "27.6", suffix: "m²" },
              { title: "Roof Terrace", value: "46.6", suffix: "m²" },
            ],
          },
        },
      },
    },
  },
  "6 Bed XL": {
    floorplans: {
      ground: {
        title: "Ground Floor",
        sectionIds: ["interior", "exterior", "pool"],
        sections: {
          interior: {
            title: "Interior",
            entries: [
              { title: "Foyer", value: "5.6", suffix: "m²" },
              { title: "Living Room", value: "118.2", suffix: "m²" },
              { title: "Dining Room", value: "34.9", suffix: "m²" },
              { title: "Kitchen", value: "30.3", suffix: "m²" },
              { title: "Wine Cellar", value: "3.5", suffix: "m²" },
              { title: "Prep Kitchen / Utility", value: "19.9", suffix: "m²" },
              { title: "Store", value: "15.3", suffix: "m²" },
              { title: "Tech. Area", value: "5.0", suffix: "m²" },
              { title: "Bedroom 1", value: "36.9", suffix: "m²" },
              { title: "Dressing 1", value: "17.5", suffix: "m²" },
              { title: "Bathroom 1", value: "13.1", suffix: "m²" },
              { title: "MEP Cupboard", value: "2.7", suffix: "m²" },
              { title: "Bedroom 2", value: "24.6", suffix: "m²" },
              { title: "Bathroom 2", value: "7.2", suffix: "m²" },
              { title: "Corridor", value: "27.6", suffix: "m²" },
              { title: "Bedroom 6", value: "33.2", suffix: "m²" },
              { title: "Dressing 6", value: "4.8", suffix: "m²" },
              { title: "Bathroom 6", value: "8.1", suffix: "m²" },
            ],
          },
          exterior: {
            title: "Exterior",
            entries: [
              { title: "Forecourt", value: "15.9", suffix: "m²" },
              { title: "Terrace 1", value: "102.4", suffix: "m²" },
              { title: "Terrace 2", value: "32.7", suffix: "m²" },
              { title: "Terrace 3", value: "54.4", suffix: "m²" },
              { title: "Terrace 4", value: "43.7", suffix: "m²" },
              { title: "Garden Storage", value: "7.4", suffix: "m²" },
              { title: "MEP Pool", value: "9.5", suffix: "m²" },
            ],
          },
          pool: {
            title: "Pool",
            entries: [
              { title: "Swimming Pool", value: "14 x 4.5", suffix: "m" },
            ],
          },
        },
      },
      first: {
        title: "First Floor",
        sectionIds: ["interior", "exterior"],
        sections: {
          interior: {
            title: "Interior",
            entries: [
              { title: "Bedroom 3", value: "28.1", suffix: "m²" },
              { title: "Bathroom 3", value: "15.3", suffix: "m²" },
              { title: "Dressing 3", value: "5.3", suffix: "m²" },
              { title: "Bedroom 4", value: "22.0", suffix: "m²" },
              { title: "Bathroom 4", value: "7.4", suffix: "m²" },
              { title: "Landing", value: "18.6", suffix: "m²" },
              { title: "Spa & Fitness", value: "36.7", suffix: "m²" },
              { title: "Fitness Dressing", value: "4.5", suffix: "m²" },
              { title: "Fitness Bathroom", value: "7.3", suffix: "m²" },
              { title: "Master Bedroom 5", value: "38.0", suffix: "m²" },
              { title: "Master Dressing 5", value: "11.1", suffix: "m²" },
              { title: "Master Bathroom 5", value: "13.3", suffix: "m²" },
            ],
          },
          exterior: {
            title: "Exterior",
            entries: [
              { title: "Loggia", value: "30.6", suffix: "m²" },
              { title: "Terrace 1", value: "66.7", suffix: "m²" },
              { title: "Terrace 2", value: "34.6", suffix: "m²" },
            ],
          },
        },
      },
    },
  },
  "7 Bed": {
    floorplans: {
      ground: {
        title: "Ground Floor",
        sectionIds: ["interior", "exterior", "pool"],
        sections: {
          interior: {
            title: "Interior",
            entries: [
              { title: "Vestibule", value: "26.5", suffix: "m²" },
              { title: "Foyer", value: "56.8", suffix: "m²" },
              { title: "Cloakroom", value: "4.6", suffix: "m²" },
              { title: "Family Room", value: "16.1", suffix: "m²" },
              { title: "Powder Room", value: "4.9", suffix: "m²" },
              { title: "Living Room", value: "69.3", suffix: "m²" },
              { title: "Dining Room", value: "38.5", suffix: "m²" },
              { title: "Kitchen", value: "43.4", suffix: "m²" },
              { title: "Prep Kitchen", value: "15.5", suffix: "m²" },
              { title: "Corridor", value: "6.2", suffix: "m²" },
              { title: "Store", value: "11.8", suffix: "m²" },
              { title: "Utility", value: "9.0", suffix: "m²" },
              { title: "Tech. Area", value: "8.0", suffix: "m²" },
              { title: "Bedroom 1", value: "20.0", suffix: "m²" },
              { title: "Dressing 1", value: "10.2", suffix: "m²" },
              { title: "Bathroom 1", value: "15.9", suffix: "m²" },
              { title: "Corridor", value: "3.8", suffix: "m²" },
              { title: "MEP Cupboard", value: "2.2", suffix: "m²" },
              { title: "Bedroom 7", value: "17.0", suffix: "m²" },
              { title: "Bedroom 2", value: "30.9", suffix: "m²" },
              { title: "Dressing 2", value: "4.7", suffix: "m²" },
              { title: "Bathroom 2", value: "8.4", suffix: "m²" },
            ],
          },
          exterior: {
            title: "Exterior",
            entries: [
              { title: "Terrace 1", value: "28.5", suffix: "m²" },
              { title: "Terrace 2", value: "66.1", suffix: "m²" },
              { title: "Terrace 3", value: "74.1", suffix: "m²" },
              { title: "Garden Storage", value: "7.4", suffix: "m²" },
              { title: "MEP Pool", value: "9.5", suffix: "m²" },
            ],
          },
          pool: {
            title: "Pool",
            entries: [
              { title: "Swimming Pool", value: "14 x 4.5", suffix: "m" },
            ],
          },
        },
      },
      first: {
        title: "First Floor",
        sectionIds: ["interior", "exterior"],
        sections: {
          interior: {
            title: "Interior",
            entries: [
              { title: "TV Room", value: "28.6", suffix: "m²" },
              { title: "Landing", value: "41.6", suffix: "m²" },
              { title: "Master Bedroom 4", value: "45.0", suffix: "m²" },
              { title: "Master Bathroom 4", value: "12.5", suffix: "m²" },
              { title: "Master Dressing 4", value: "9.0", suffix: "m²" },
              { title: "Bedroom 3", value: "19.1", suffix: "m²" },
              { title: "Dressing 3", value: "7.3", suffix: "m²" },
              { title: "Bathroom 3", value: "6.4", suffix: "m²" },
              { title: "Bedroom 6", value: "22.8", suffix: "m²" },
              { title: "Dressing 6", value: "6.5", suffix: "m²" },
              { title: "Bathroom 6", value: "7.8", suffix: "m²" },
              { title: "Bedroom 5", value: "29.6", suffix: "m²" },
              { title: "Dressing 5", value: "7.7", suffix: "m²" },
              { title: "Bathroom 5", value: "16.2", suffix: "m²" },
              { title: "Store", value: "1.4", suffix: "m²" },
            ],
          },
          exterior: {
            title: "Exterior",
            entries: [
              { title: "Terrace 1", value: "47.7", suffix: "m²" },
              { title: "Terrace 2", value: "30.5", suffix: "m²" },
              { title: "Terrace 3", value: "32.1", suffix: "m²" },
            ],
          },
        },
      },
    },
  },
}
