import React, { useState, useRef } from "react"
import styled from "styled-components"
import { useLocation } from "react-router-dom"
import { useStore } from "../../state/store.js"
import Globe from "./globe/Globe.js"
import * as THREE from "three"
import AccordionFor3D from "./globe/components/AccordionFor3D.jsx"
import location from "../../data/location.js"
import MapsSideBar from "./globe/components/MapsSideBar.jsx"

function Maps() {
  //Set a destination and source for drawing flight lines
  const [camTarget, setCamTarget] = useState({
    index: 0,
    name: "Les Bordes",
    area: "France",
    description: "",
    distance: "",
    url: "",
    time: "",
    drive: {
      fly: "Drive"
    },
    target: [1.67, 1.95, 0.48],
    position: [3.85, 5.04, 1.35],
    origin: [1.67, 1.95, 0.48],
    flightLines: [
      new THREE.Vector3(...[1.67, 1.95, 0.48]),
      new THREE.Vector3(...[1.67, 1.95, 0.48]),
      new THREE.Vector3(...[1.67, 1.95, 0.49])
    ],
    camera: {
      flightLine: [
        [1.67, 1.95, 0.48],
        [1.67, 1.95, 0.48],
        [1.67, 1.95, 0.49]
      ],
      target: { target: [1.67, 1.95, 0.48], position: [5.1, 3.7, 0] }
    }
  })
  const canClick = useRef(true)
  const getLocation = useLocation()

  function updateCanClick(value) {
    canClick.current = value
  }

  return (
    <Wrapper>
      <Background />
      <MapWrapper>
        <Globe
          camTarget={camTarget}
          canClick={{ value: canClick, update: updateCanClick }}
        />
      </MapWrapper>
      <MapsSideBar 
       setCamTarget={setCamTarget}
       camTarget={camTarget}
       canClick={{ value: canClick, update: updateCanClick }}
      />
    </Wrapper>
  )
}

export default Maps

const Wrapper = styled.div`
position: absolute;
display: block;
width: clamp(100vw, 100vw, 100vw);
height: clamp(100vh, 100vh, 100vh);
background-color: transparent;
overflow: hidden;
pointer-events: auto;
`

const MapWrapper = styled.div`
  position: absolute;
  left: 0;
  width: clamp(75vw, 75vw, 75vw);
  height: clamp(100vh, 100vh, 100vh);

  .draw {
    opacity: 0.7;
  }

  .selectedPoi {
    transform-box: fill-box;
    transform-origin: center;
    transform: scale(4, 4);
    transition: transform 0.2s linear;
  }

  .selectedPoiLB {
    transform-box: fill-box;
    transform-origin: center;
    transform: scale(2, 2);
    transition: transform 0.2s linear;
  }
`

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  background-position: 100% 100%;
  background-size: contain;
  background-repeat: repeat-x;
  opacity: 0.5;
`
