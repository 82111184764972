import { useStore } from "@state/store"
import ResidencesSVGAmenityOverlays from "./ResidencesSVGAmenityOverlays"
import ResidencesSVGOutlines from "./ResidencesSVGOutlines"
import ResidencesSVGLabels from "./ResidencesSVGLabels"
import { globalStyles } from "@data/globalStyles"

export default function ResidencesSVG() {
  const syncState = useStore((state) => state.syncState)
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4096"
      height="4096"
      viewBox="0 0 4096 4096"
      preserveAspectRatio="xMidYMid meet"
      onClick={(e) => {
        syncState("activeID", null)
      }}
      style={{ backgroundColor: globalStyles.col.white }}
    >
      <image
        id="image-svg"
        style={{ opacity: 0 }}
        href="/images/residences-map-2.webp"
        width="4096"
        height="4096"
      />
      <ResidencesSVGAmenityOverlays />
      <ResidencesSVGLabels />
    </svg>
  )
}
