import { useStore } from "@state/store"
import Dimensions from "./Dimensions"
import FloorPlanImage from "./FloorPlanImage"
import { residencesData } from "@data/residencesData"
import HeartSVG from "./HeartSVG"
import { globalStyles } from "@data/globalStyles"
import FavouritesButton from "./FavouritesButton"

const asp = {
  N: "North",
  E: "East",
  S: "South",
  W: "West",
}

export default function FloorPlan() {
  const residencePopup = useStore((state) => state.residencePopup)
  const activeID = useStore((state) => state.activeID)
  const favourites = useStore((state) => state.favourites)
  const syncState = useStore((state) => state.syncState)
  const residencePopupCategory = useStore(
    (state) => state.residencePopupCategory
  )
  const isFavourite = favourites.includes(activeID)
  const aspect = residencesData?.find(
    (residence) => residence?.plot === activeID
  )?.aspect
  const bedrooms = residencesData
    ?.find((residence) => residence?.plot === activeID)
    ?.unitType.split(" ")[0]
  const bathrooms = residencesData?.find(
    (residence) => residence?.plot === activeID
  )?.bathrooms
  const unitData = useStore((state) => state.unitData)
  return (
    <div
      style={{
        display: "grid",
        placeItems: "center",
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        height: "100%",
        width: "100%",
        gridAutoFlow: "column",
        overflow: "hidden",
        // border: "5px solid red",
      }}
    >
      <div
        style={{
          opacity: residencePopupCategory === "Render" ? 0 : 1,
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          height: "100%",
          width: "20%",
          placeSelf: "center end",
          backgroundColor: "white",
          padding: "4rem 2.5rem",
          zIndex: 5,
          display: "grid",
          placeItems: "center",
          gridAutoFlow: "row",
          overflow: "hidden",
          rowGap: "3rem",
        }}
      >
        <FavouritesButton />
        {activeID && (
          <div
            style={{
              display: "grid",
              placeSelf: "end center",
              placeItems: "end center",
              gridAutoFlow: "row",
              width: "100%",
              borderRadius: "1rem",
              border: `1px solid ${globalStyles.col.blue}`,
              // zIndex: 5,
            }}
          >
            <p
              style={{
                fontSize: "1rem",
                lineHeight: "1.1rem",
                letterSpacing: "0.05rem",
                textAlign: "center",
                width: "100%",
                color: "#4E769B",
                zIndex: 1,
              }}
            >
              <span style={{ fontSize: "4vh", lineHeight: "4vh" }}>
                Plot {activeID}
              </span>
              <br />
              <br />
              Bedrooms: {bedrooms}
              <br />
              Bathrooms: {bathrooms}
              <br />
              Orientation: {aspect?.map((a) => asp[a]).join(" / ")}
              {unitData && (
                <>
                  <br />
                  Price: {unitData[activeID]?.publicPrice}
                </>
              )}
            </p>
          </div>
        )}

        <Dimensions />
      </div>
      <FloorPlanImage />
    </div>
  )
}
