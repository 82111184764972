/* eslint-disable no-unused-vars */
import React, { useEffect } from "react"
import styled from "styled-components"

import AccordionFor3D from "./AccordionFor3D"

import { useStore } from "@state/store"
import location from "../../../../data/location.js"

function MapsSideBar({ canClick, setCamTarget }) {

  return (
    <Wrapper>
      <SideMain id="main">
          <AccordionFor3D
            elements={location["destination"]["see"]}
            width="100%"
            height="94%"
            canClick={canClick}
            setCamTarget={setCamTarget}
          />
      </SideMain>
    </Wrapper>
  )
}

export default MapsSideBar

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items:center;
  width: 30vw;
  height: clamp(100vh, 100vh, 100vh);
  background-color: rgba(242, 241, 237);
  z-index: 1000000;
`


const SideMain = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: transparent;
  overflow: hidden scroll;
  position: relative;

  ::-webkit-scrollbar {
    width: 1px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb {
    background-color:blue;
    border-radius: 50px;
  }
`
