/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useFrame } from "@react-three/fiber"
import React, { useEffect, useRef, useState } from "react"
import * as THREE from "three"

export default function Dot({ position, delay, size = 1, color, visible }) {
  const ref = useRef()
  const [isDisplayed, setIsDisplayed] = useState(false)

  // useFrame(({ camera }) => {
  //   if (ref.current) ref.current.scale.x = camera.position.length()
  // })

  useEffect(() => {
    const interval = setInterval(
      () => {
        setIsDisplayed(visible)
        clearInterval(interval)
      },
      visible ? delay : delay / 4
    )
  }, [visible])

  if (!isDisplayed) return null

  const geometry = new THREE.SphereGeometry(size, 16)
  const material = new THREE.MeshBasicMaterial({ color })

  return (
    <group ref={ref} position={position}>
      <mesh geometry={geometry} material={material}></mesh>
    </group>
  )
}
