import { useEffect } from "react"
import LeftContent from "./LeftContent"
import ResidencesFilter from "./ResidencesFilter"
import Sidebar from "./Sidebar"
import { useStore } from "@state/store"
import FilterBar from "./FilterBar"

export default function Residences() {
  const syncState = useStore((state) => state.syncState)

  useEffect(() => {
    return () => {
      syncState("activeID", null)
      syncState("typeFilter", [])
      syncState("bedsFilter", [])
      syncState("searchUnit", null)
      syncState("residencePopupCategory", "Ground Floor Plan")
      syncState("residencePopup", false)
    }
  }, [])
  return (
    <div
      style={{
        position: "fixed",
        inset: 0,
        height: "100%",
        width: "100%",
        display: "grid",
        placeItems: "center",
        overflow: "hidden",
        gridAutoFlow: "column",
        // gridTemplateColumns: "minmax(0, 2228fr) minmax(0, 732fr)",
      }}
    >
      <LeftContent />
      <FilterBar />
      {/* <Sidebar /> */}
      <ResidencesFilter />
    </div>
  )
}
