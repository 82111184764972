import React from "react"

function ToolMesh({ display, scale, label }) {
  //This mesh is used to get a location on the globe for marking cities
  //It is temporary and can be switched off by setting "display" to "false"
  if (!display) return null
  return (
    <>
      {display && (
        <mesh
          rotation={[0, 340 * (Math.PI / 180), 0]}
          position-x={0}
          position-y={0}
          position-z={0}
          scale={scale}>
          <sphereGeometry args={[2, 100, 200]} />
          <meshBasicMaterial
            wireframe={true}
            transparent={true}
            opacity={0.2}
          />
        </mesh>
      )}
    </>
  )
}

export default ToolMesh
