import styled from "styled-components"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import { Fragment, useEffect, useRef } from "react"
import { globalStyles } from "@data/globalStyles"
import { useStore } from "@state/store"
import { residencesData } from "@data/residencesData"

export default function FloorPlanImage({ ...props }) {
  const activeID = useStore((state) => state.activeID)
  const residencePopupCategory = useStore(
    (state) => state.residencePopupCategory
  )
  if (!activeID) return null

  const unitType = residencesData.find(
    (residence) => residence.plot === activeID
  ).unitType
  if (!unitType) return null

  return (
    <Wrapper
      style={{
        opacity: residencePopupCategory.includes("Render") ? 1 : 0,
        pointerEvents: residencePopupCategory.includes("Render")
          ? "auto"
          : "none",
        transition: "opacity 0.3s ease",
        display: "grid",
        placeItems: "center",
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        height: "100%",
        width: "100%",
        gridAutoFlow: "column",
        overflow: "hidden",
      }}
      {...props}
    >
      <ZoomWrapper>
        <TransformWrapper
          centerOnInit={true}
          minScale={1}
          maxScale={5}
          alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
          limitToBounds={true}
          smooth={true}
          centerZoomedOut={true}
        >
          <TransformComponent
            wrapperStyle={{
              gridColumn: "1 / -1",
              gridRow: "1 / -1",
              height: "100%",
              width: "100%",
              display: "grid",
              placeItems: "center",
              zIndex: 1,
            }}
            contentStyle={{
              gridColumn: "1 / -1",
              gridRow: "1 / -1",
              height: "100%",
              width: "100%",
              display: "grid",
              placeItems: "center",
              zIndex: 1,
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
                overflow: "hidden",
              }}
              alt="Render"
              src={`/renders/${unitType}.webp`}
            />
          </TransformComponent>
        </TransformWrapper>
      </ZoomWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  place-self: start;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  display: grid;
  place-items: center;
  pointer-events: auto;
  z-index: 1;
`

const ZoomWrapper = styled.div`
  overflow: hidden;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  z-index: 1;
  overscroll-behavior: none;
  display: flex;
  place-items: center;

  & svg {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    border: 0px red solid;
  }
`
