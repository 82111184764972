/* eslint-disable no-unused-vars */
import { useSpring, animated } from "react-spring"
import styled from "styled-components"

export default function SideBar({ details }) {

  return (
    <Wrapper>
      <H1>{details.name}</H1>
      <DividerLine />
      {details.time != "" && <H4>{details.time}</H4>}
      <H2>{details.area}</H2>
      <Image />
    </Wrapper>
  )
}

const Image = styled.img``

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  width: 10vw;
  // height: 100vh;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.6s;
  color: rgba(69, 107, 144);
  font-weight: 100;
  z-index: 1000;
  top: 5%;
  right: 10%;
  border-radius: 5px;
  border: 1px solid rgba(69, 107, 144, 0.5);
  background-color: rgba(242, 241, 237);
`

const H1 = styled.h1`
  width: 100%;
  font-size: 1.2vw;
  text-align: center;
  font-weight: 100;
`
const H2 = styled.h2`
  width: 100%;
  font-size: 1vw;
  text-align: center;
  font-weight: 100;
`
const H4 = styled.h4`
  width: 100%;
  font-size: 1vw;
  text-align: center;
  font-weight: 100;
`

const DividerLine = styled.div`
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(69, 107, 144);
`
