import { useStore } from "@state/store"

export default function SeeSVG() {
  const activeMapIndex = useStore((state) => state.activeMapIndex)
  return (
    <>
      <svg
        id="svg-loire-see"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1515 1080"
        height="100%"
        width="100%"
      >
        <g id="b">
          <g
            id="1"
            className={Number(1) === Number(activeMapIndex) ? "bouncing" : ""}
            data-group="target"
          >
            <g id="d">
              <circle id="e" cx="859.82" cy="423.08" r="9.63" fill="#c99f42" />
              <path
                d="M860.61,418.79v7.31l.71,.61v.07h-3.46v-.07l.72-.61v-6.49l-.93-.13v-.07l2.88-1h.07v.39Z"
                fill="#0d294a"
              />
            </g>
          </g>

          <g
            id="2"
            className={Number(2) === Number(activeMapIndex) ? "bouncing" : ""}
            data-group="target"
          >
            <g id="g">
              <circle id="h" cx="768.65" cy="667.71" r="9.63" fill="#c99f42" />
              <path
                scale={3}
                d="M771.5,668.51v2.82l-.07,.07h-5.75v-.07l1.93-2.44c.6-.78,1.05-1.45,1.36-2,.3-.55,.45-1.11,.45-1.67,0-.46-.09-.83-.27-1.11-.18-.28-.44-.42-.77-.42-.49,0-.73,.4-.73,1.19,0,.32,.04,.69,.12,1.12l-2.14,.61-.04-.05c.63-1.29,1.26-2.2,1.91-2.75,.65-.54,1.25-.82,1.81-.82,.63,0,1.14,.16,1.54,.49,.4,.33,.6,.79,.6,1.38,0,.71-.26,1.38-.79,2.03s-1.27,1.25-2.22,1.81l-1.18,1v.06h2.81c.36,0,.64-.09,.85-.29,.21-.19,.38-.51,.51-.96h.07Z"
                fill="#0d294a"
              />
            </g>
          </g>

          <g
            id="3"
            className={Number(3) === Number(activeMapIndex) ? "bouncing" : ""}
            data-group="target"
          >
            <g id="j">
              <circle id="k" cx="594.96" cy="689.57" r="9.63" fill="#c99f42" />
              <path
                d="M598.29,685.75l-2.72,2.93v.06c.18-.02,.31-.02,.39-.02,.7,0,1.26,.17,1.67,.51,.42,.34,.62,.81,.62,1.41,0,.46-.15,.94-.46,1.45s-.81,.94-1.49,1.3-1.55,.54-2.6,.54c-.37,0-.76-.02-1.17-.07v-2.24h.07c.59,.47,1.17,.83,1.73,1.06s1.05,.35,1.47,.35,.77-.08,1.08-.25c.3-.17,.45-.46,.45-.87,0-.46-.23-.84-.69-1.14-.46-.3-1.23-.45-2.3-.45h-.38l-.05-.08,2.57-2.9h-2.48c-.33,0-.59,.09-.8,.28-.21,.19-.36,.5-.47,.93h-.07v-2.79l.06-.07h5.58v.06Z"
                fill="#0d294a"
              />
            </g>
          </g>

          <g
            id="4"
            className={Number(4) === Number(activeMapIndex) ? "bouncing" : ""}
            data-group="target"
          >
            <g id="m">
              <circle id="n" cx="691.89" cy="588.75" r="9.63" fill="#c99f42" />
              <path
                d="M693.25,590.37h1.58v.22h-1.58v1.17l.72,.61v.07h-3.46v-.07l.72-.61v-1.17h-2.88v-.16l2.85-6.23h2.48v.07l-4.97,6.1h2.53v-2.02l1.91-1.8h.1v3.81Z"
                fill="#0d294a"
              />
            </g>
          </g>

          <g
            id="5"
            className={Number(5) === Number(activeMapIndex) ? "bouncing" : ""}
            data-group="target"
          >
            <g id="p">
              <circle id="q" cx="797.14" cy="574.51" r="9.63" fill="#c99f42" />
              <path
                d="M797.18,573.6c.72,.16,1.29,.32,1.7,.51,.41,.18,.71,.41,.9,.69,.19,.28,.28,.63,.28,1.06,0,.41-.12,.83-.37,1.28-.24,.44-.73,.84-1.45,1.18s-1.74,.51-3.06,.51c-.15,0-.43,0-.82-.02v-2.25h.06c.2,.18,.5,.39,.91,.62,.41,.22,.85,.41,1.31,.56,.46,.15,.86,.23,1.2,.23,.44,0,.78-.09,1.02-.27s.36-.43,.36-.76-.15-.61-.45-.84c-.3-.22-.83-.44-1.59-.64l-2.68-.65,.98-4.26h2.72c.46,0,.81-.05,1.05-.15,.24-.1,.42-.27,.54-.53h.1v2.3l-.07,.06h-4.46l-.22,.96,2.03,.4Z"
                fill="#0d294a"
              />
            </g>
          </g>

          <g
            id="6"
            className={Number(6) === Number(activeMapIndex) ? "bouncing" : ""}
            data-group="target"
          >
            <g id="s">
              <circle id="t" cx="535.81" cy="829.71" r="9.63" fill="#c99f42" />
              <path
                d="M534.32,833.25c-.47-.27-.85-.66-1.15-1.17-.29-.51-.44-1.12-.44-1.82,0-.88,.22-1.68,.66-2.4,.44-.72,1.03-1.32,1.78-1.8s1.56-.82,2.45-1.01l.04,.08c-.77,.27-1.4,.74-1.9,1.4-.5,.66-.84,1.37-1.01,2.11l.05,.04c.24-.28,.55-.5,.91-.66,.36-.16,.65-.24,.85-.24,.4,0,.78,.11,1.13,.33,.35,.22,.64,.54,.86,.95,.22,.42,.33,.9,.33,1.47,0,.61-.14,1.15-.42,1.63s-.65,.85-1.12,1.11-.98,.4-1.53,.4c-.52,0-1.02-.13-1.49-.4Zm2.85-1.12c0-.29-.04-.67-.13-1.16-.15-.77-.38-1.36-.67-1.79-.29-.43-.61-.62-.96-.58-.43,.06-.65,.42-.65,1.1,0,.34,.06,.77,.17,1.29,.15,.76,.36,1.33,.62,1.72,.26,.39,.55,.59,.88,.59,.5,0,.75-.39,.75-1.17Z"
                fill="#0d294a"
              />
            </g>
          </g>

          <g
            id="7"
            className={Number(7) === Number(activeMapIndex) ? "bouncing" : ""}
            data-group="target"
          >
            <g id="v">
              <circle id="w" cx="472.72" cy="738.25" r="9.63" fill="#c99f42" />
              <path
                d="M470.2,734.12h5.8v.06l-.82,1.64-1.78,6.55h-2.46v-.07l4.01-6.52h-3.47c-.33,0-.59,.09-.8,.28-.21,.19-.36,.5-.47,.93h-.07v-2.79l.06-.07Z"
                fill="#0d294a"
              />
            </g>
          </g>

          <g
            id="8"
            className={Number(8) === Number(activeMapIndex) ? "bouncing" : ""}
            data-group="target"
          >
            <g id="y">
              <circle id="a`" cx="93.53" cy="909.51" r="9.63" fill="#c99f42" />
              <path
                d="M96.16,912.44c-.38,.38-.82,.66-1.33,.84-.51,.18-.95,.28-1.31,.28-.39,0-.83-.06-1.31-.19s-.9-.34-1.25-.63c-.35-.29-.53-.67-.53-1.14,0-.35,.1-.67,.29-.95s.46-.5,.78-.67,.68-.27,1.05-.3c-.54-.34-.96-.69-1.25-1.04s-.45-.79-.45-1.31c0-.58,.19-1.04,.56-1.39,.37-.35,.8-.59,1.28-.73s.9-.21,1.27-.21c.68,0,1.25,.15,1.73,.46,.48,.31,.72,.74,.72,1.31,0,.38-.14,.74-.42,1.07-.28,.33-.71,.61-1.29,.85l.32,.2c.37,.23,.66,.43,.89,.6,.23,.18,.42,.39,.58,.65,.16,.26,.24,.56,.24,.9,0,.55-.19,1.01-.57,1.39Zm-1.51,.46c.21-.17,.31-.42,.31-.75,0-.33-.13-.64-.38-.93s-.6-.58-1.08-.88l-.73-.51c-.15,.12-.26,.31-.35,.55-.09,.25-.13,.52-.13,.83,0,.68,.16,1.18,.48,1.48,.32,.31,.69,.46,1.12,.46,.3,0,.56-.08,.76-.25Zm-1.91-7.25c-.18,.18-.26,.41-.26,.7,0,.35,.12,.65,.35,.91,.23,.26,.58,.54,1.06,.85l.5,.35c.28-.49,.42-.94,.42-1.34,0-.53-.13-.95-.38-1.26-.26-.31-.58-.47-.97-.47-.29,0-.53,.09-.7,.26Z"
                fill="#0d294a"
              />
            </g>
          </g>

          <g
            id="9"
            className={Number(9) === Number(activeMapIndex) ? "bouncing" : ""}
            data-group="target"
          >
            <g id="ab">
              <circle id="ac" cx="134.19" cy="818.01" r="9.63" fill="#c99f42" />
              <path
                d="M135.96,814.01c.47,.27,.86,.66,1.15,1.17,.29,.51,.44,1.12,.44,1.82,0,.88-.22,1.68-.66,2.4-.44,.72-1.03,1.32-1.78,1.8-.75,.48-1.56,.82-2.45,1.01l-.05-.09c.77-.27,1.4-.73,1.91-1.4s.84-1.37,1.01-2.11l-.06-.04c-.24,.28-.55,.5-.91,.66-.36,.16-.64,.24-.84,.24-.4,0-.78-.11-1.13-.33-.35-.22-.64-.54-.86-.95-.22-.42-.33-.9-.33-1.47,0-.61,.14-1.16,.42-1.63,.28-.48,.65-.85,1.12-1.11s.98-.4,1.53-.4c.52,0,1.02,.13,1.49,.4Zm-2.85,1.11c0,.29,.04,.68,.13,1.17,.15,.77,.38,1.36,.67,1.79,.29,.43,.61,.62,.97,.58,.43-.06,.65-.42,.65-1.1,0-.34-.06-.77-.17-1.29-.15-.76-.36-1.33-.62-1.72-.26-.39-.55-.59-.88-.59-.5,0-.75,.39-.75,1.16Z"
                fill="#0d294a"
              />
            </g>
          </g>

          <g
            id="10"
            className={Number(10) === Number(activeMapIndex) ? "bouncing" : ""}
            data-group="target"
          >
            <g id="ae">
              <circle id="af" cx="699.26" cy="899.88" r="9.63" fill="#c99f42" />
              <g>
                <path
                  d="M697.28,895.76v7.31l.71,.61v.07h-3.46v-.07l.72-.61v-6.49l-.93-.13v-.07l2.88-1h.07v.39Z"
                  fill="#0d294a"
                />
                <path
                  d="M700.08,903.36c-.51-.38-.9-.89-1.17-1.53-.27-.65-.41-1.36-.41-2.13,0-1.09,.21-1.96,.64-2.61s.9-1.1,1.41-1.36c.51-.26,.91-.38,1.2-.38,.68,0,1.27,.19,1.77,.57,.5,.38,.89,.89,1.17,1.54,.27,.65,.41,1.37,.41,2.16,0,1.08-.21,1.93-.64,2.58-.43,.64-.9,1.09-1.41,1.35-.51,.26-.91,.38-1.2,.38-.67,0-1.26-.19-1.77-.56Zm3.26-.62c.11-.26,.17-.61,.17-1.04,0-.69-.14-1.51-.42-2.46-.31-1-.66-1.79-1.06-2.35-.4-.57-.8-.85-1.21-.85-.07,0-.13,0-.16,.01-.19,.06-.34,.21-.45,.46-.11,.25-.17,.6-.17,1.04,0,.62,.11,1.35,.34,2.2,.3,1.05,.67,1.89,1.09,2.53,.43,.64,.85,.95,1.26,.95,.07,0,.11,0,.15-.01,.19-.06,.34-.22,.45-.48Z"
                  fill="#0d294a"
                />
              </g>
            </g>
          </g>

          <g id="ag">
            <circle cx="1035.87" cy="331.16" r="4.93" fill="#c99f42" />
            <line
              x1="988.9"
              y1="331.34"
              x2="1082.97"
              y2="331.34"
              fill="#c99f42"
              stroke="#c99f42"
              strokeMiterlimit="10"
            />
            <g
              id="11"
              className={
                Number(11) === Number(activeMapIndex) ? "bouncing" : ""
              }
              data-group="target"
            >
              <circle
                id="ai"
                cx="1012.55"
                cy="331.16"
                r="9.63"
                fill="#c99f42"
              />
              <g>
                <path
                  d="M1011.63,326.87v7.31l.71,.61v.07h-3.46v-.07l.72-.61v-6.49l-.93-.13v-.07l2.88-1h.07v.39Z"
                  fill="#0d294a"
                />
                <path
                  d="M1015.72,326.87v7.31l.71,.61v.07h-3.46v-.07l.72-.61v-6.49l-.93-.13v-.07l2.88-1h.07v.39Z"
                  fill="#0d294a"
                />
              </g>
            </g>
          </g>

          <g
            id="12"
            className={Number(12) === Number(activeMapIndex) ? "bouncing" : ""}
            data-group="target"
          >
            <g id="ak">
              <circle id="al" cx="981.73" cy="331.16" r="9.63" fill="#c99f42" />
              <g>
                <path
                  d="M979.41,326.87v7.31l.71,.61v.07h-3.46v-.07l.72-.61v-6.49l-.93-.13v-.07l2.88-1h.07v.39Z"
                  fill="#0d294a"
                />
                <path
                  d="M986.45,331.96v2.82l-.07,.07h-5.75v-.07l1.93-2.44c.6-.78,1.05-1.45,1.36-2,.3-.55,.45-1.11,.45-1.67,0-.46-.09-.83-.27-1.11-.18-.28-.44-.42-.77-.42-.49,0-.73,.4-.73,1.19,0,.32,.04,.69,.12,1.12l-2.14,.61-.04-.05c.63-1.29,1.26-2.2,1.91-2.75,.65-.55,1.25-.82,1.81-.82,.63,0,1.14,.16,1.54,.49s.6,.79,.6,1.38c0,.71-.26,1.38-.79,2.03s-1.27,1.25-2.22,1.81l-1.18,1v.06h2.81c.36,0,.64-.1,.85-.29,.21-.19,.38-.51,.51-.96h.07Z"
                  fill="#0d294a"
                />
              </g>
            </g>
          </g>

          <g
            id="13"
            className={Number(13) === Number(activeMapIndex) ? "bouncing" : ""}
            data-group="target"
          >
            <g id="an">
              <circle
                id="ao"
                cx="1043.33"
                cy="374.36"
                r="9.63"
                fill="#c99f42"
              />
              <g>
                <path
                  d="M1041.47,370.47v7.31l.71,.61v.07h-3.46v-.07l.72-.61v-6.49l-.93-.13v-.07l2.88-1h.07v.39Z"
                  fill="#0d294a"
                />
                <path
                  d="M1048.73,370.28l-2.72,2.93v.06c.18-.02,.31-.02,.39-.02,.7,0,1.26,.17,1.67,.51,.42,.34,.62,.81,.62,1.4,0,.46-.15,.94-.46,1.45-.31,.51-.81,.94-1.49,1.3-.68,.36-1.55,.54-2.6,.54-.37,0-.76-.02-1.17-.07v-2.24h.07c.59,.47,1.17,.83,1.74,1.06,.56,.24,1.05,.35,1.47,.35s.77-.08,1.08-.25c.3-.17,.45-.46,.45-.87,0-.46-.23-.84-.69-1.14s-1.23-.45-2.3-.45h-.38l-.05-.09,2.57-2.9h-2.48c-.33,0-.59,.09-.8,.28-.21,.19-.36,.5-.47,.93h-.07v-2.79l.06-.07h5.58v.06Z"
                  fill="#0d294a"
                />
              </g>
            </g>
          </g>

          <g
            id="14"
            className={Number(14) === Number(activeMapIndex) ? "bouncing" : ""}
            data-group="target"
          >
            <g id="aq">
              <circle id="ar" cx="1060" cy="331.16" r="9.63" fill="#c99f42" />
              <g>
                <path
                  d="M1058.22,326.87v7.31l.71,.61v.07h-3.46v-.07l.72-.61v-6.49l-.93-.13v-.07l2.88-1h.07v.39Z"
                  fill="#0d294a"
                />
                <path
                  d="M1064.01,332.78h1.58v.22h-1.58v1.17l.72,.61v.07h-3.46v-.07l.72-.61v-1.17h-2.88v-.16l2.85-6.23h2.48v.07l-4.97,6.1h2.53v-2.02l1.91-1.8h.1v3.81Z"
                  fill="#0d294a"
                />
              </g>
            </g>
          </g>

          <g
            id="15"
            className={Number(15) === Number(activeMapIndex) ? "bouncing" : ""}
            data-group="target"
          >
            <g id="at">
              <circle
                id="au"
                cx="1090.64"
                cy="332.13"
                r="9.63"
                fill="#c99f42"
              />
              <g>
                <path
                  d="M1088.45,328.54v7.31l.71,.61v.07h-3.46v-.07l.72-.61v-6.49l-.93-.13v-.07l2.88-1h.07v.39Z"
                  fill="#0d294a"
                />
                <path
                  d="M1092.6,331.34c.73,.15,1.29,.32,1.71,.51,.41,.18,.71,.41,.9,.69s.28,.63,.28,1.06c0,.41-.12,.83-.37,1.28-.24,.44-.73,.84-1.45,1.18-.72,.34-1.74,.51-3.06,.51-.15,0-.43,0-.82-.02v-2.25h.06c.2,.18,.5,.39,.91,.62s.85,.41,1.31,.56,.86,.23,1.2,.23c.44,0,.78-.09,1.02-.27,.24-.18,.36-.43,.36-.76s-.15-.61-.45-.84c-.3-.22-.83-.44-1.59-.64l-2.68-.65,.98-4.26h2.72c.46,0,.81-.05,1.05-.15,.24-.1,.42-.27,.54-.53h.1v2.3l-.07,.06h-4.46l-.22,.96,2.03,.4Z"
                  fill="#0d294a"
                />
              </g>
            </g>
          </g>

          <g id="av">
            <circle cx="691.89" cy="565.82" r="4.93" fill="#c99f42" />
            <line
              x1="643.77"
              y1="566"
              x2="742.66"
              y2="566"
              fill="#c99f42"
              stroke="#c99f42"
              strokeMiterlimit="10"
            />
            <g
              id="16"
              className={
                Number(16) === Number(activeMapIndex) ? "bouncing" : ""
              }
              data-group="target"
            >
              <circle id="ax" cx="634.14" cy="566.35" r="9.63" fill="#c99f42" />
              <g>
                <path
                  d="M632.19,562.18v7.3l.71,.61v.07h-3.46v-.07l.72-.61v-6.49l-.93-.13v-.07l2.88-1h.07v.39Z"
                  fill="#0d294a"
                />
                <path
                  d="M634.96,569.89c-.47-.27-.86-.66-1.15-1.17-.29-.51-.44-1.12-.44-1.82,0-.88,.22-1.68,.66-2.4,.44-.72,1.03-1.32,1.78-1.8s1.56-.82,2.45-1.01l.04,.08c-.77,.27-1.4,.74-1.9,1.4s-.84,1.37-1.01,2.11l.05,.04c.24-.28,.55-.5,.91-.66s.65-.25,.85-.25c.4,0,.78,.11,1.13,.33,.35,.22,.64,.54,.86,.95s.33,.9,.33,1.47c0,.61-.14,1.15-.42,1.63-.28,.48-.65,.85-1.12,1.11s-.98,.4-1.53,.4c-.52,0-1.02-.13-1.49-.4Zm2.85-1.12c0-.28-.04-.67-.13-1.16-.15-.77-.38-1.36-.67-1.79s-.62-.62-.96-.58c-.43,.06-.65,.42-.65,1.1,0,.34,.06,.77,.17,1.29,.15,.76,.36,1.33,.62,1.72s.55,.59,.88,.59c.5,0,.75-.39,.75-1.17Z"
                  fill="#0d294a"
                />
              </g>
            </g>
            <line
              x1="691.89"
              y1="570.75"
              x2="691.89"
              y2="581.89"
              fill="#c99f42"
              stroke="#c99f42"
              strokeMiterlimit="10"
            />
          </g>

          <g
            id="17"
            className={Number(17) === Number(activeMapIndex) ? "bouncing" : ""}
            data-group="target"
          >
            <g id="b`">
              <circle id="ba" cx="721.04" cy="566" r="9.63" fill="#c99f42" />
              <g>
                <path
                  d="M719.26,561.71v7.3l.71,.61v.07h-3.46v-.07l.72-.61v-6.49l-.93-.13v-.07l2.88-1h.07v.39Z"
                  fill="#0d294a"
                />
                <path
                  d="M720.46,561.45h5.8v.06l-.82,1.64-1.78,6.55h-2.46v-.07l4.01-6.52h-3.47c-.33,0-.59,.09-.8,.28-.21,.19-.36,.5-.47,.93h-.07v-2.79l.06-.07Z"
                  fill="#0d294a"
                />
              </g>
            </g>
          </g>

          <g
            id="18"
            className={Number(18) === Number(activeMapIndex) ? "bouncing" : ""}
            data-group="target"
          >
            <g id="bc">
              <circle id="bd" cx="751.24" cy="565.82" r="9.63" fill="#c99f42" />
              <g>
                <path
                  d="M749.18,561.7v7.3l.71,.61v.07h-3.46v-.07l.72-.61v-6.49l-.93-.13v-.07l2.88-1h.07v.39Z"
                  fill="#0d294a"
                />
                <path
                  d="M756.13,568.74c-.38,.38-.82,.66-1.33,.84-.51,.18-.95,.27-1.31,.27-.39,0-.83-.06-1.31-.19-.48-.13-.9-.34-1.25-.63-.35-.29-.53-.67-.53-1.14,0-.35,.1-.67,.29-.95,.2-.28,.46-.5,.78-.67,.33-.17,.68-.27,1.05-.3-.54-.34-.96-.69-1.25-1.04-.3-.35-.45-.79-.45-1.31,0-.58,.19-1.04,.56-1.39,.37-.35,.8-.59,1.28-.73s.9-.21,1.27-.21c.68,0,1.25,.15,1.74,.46,.48,.3,.72,.74,.72,1.31,0,.38-.14,.74-.42,1.07-.28,.33-.71,.61-1.29,.85l.32,.2c.37,.23,.66,.43,.89,.61,.23,.17,.42,.39,.58,.65,.16,.26,.24,.56,.24,.9,0,.54-.19,1.01-.57,1.39Zm-1.51,.46c.21-.17,.31-.42,.31-.75,0-.33-.13-.64-.38-.93-.24-.29-.6-.58-1.07-.88l-.73-.51c-.15,.12-.26,.3-.35,.55-.09,.24-.13,.52-.13,.83,0,.68,.16,1.18,.48,1.48,.32,.31,.69,.46,1.12,.46,.3,0,.56-.08,.76-.25Zm-1.91-7.24c-.18,.17-.26,.41-.26,.7,0,.35,.12,.65,.35,.91,.23,.26,.58,.54,1.06,.85l.5,.35c.28-.49,.42-.94,.42-1.34,0-.53-.13-.95-.38-1.26-.26-.31-.58-.47-.97-.47-.29,0-.53,.09-.7,.26Z"
                  fill="#0d294a"
                />
              </g>
            </g>
          </g>

          <g
            id="19"
            className={Number(19) === Number(activeMapIndex) ? "bouncing" : ""}
            data-group="target"
          >
            <g id="bf">
              <circle id="bg" cx="663.96" cy="565.82" r="9.63" fill="#c99f42" />
              <g>
                <path
                  d="M662.21,561.79v7.3l.71,.61v.07h-3.46v-.07l.72-.61v-6.49l-.93-.13v-.07l2.88-1h.07v.39Z"
                  fill="#0d294a"
                />
                <path
                  d="M667.84,561.82c.47,.27,.85,.66,1.15,1.17,.29,.51,.44,1.12,.44,1.82,0,.88-.22,1.68-.66,2.4-.44,.72-1.03,1.32-1.78,1.8-.75,.48-1.56,.82-2.45,1.01l-.05-.09c.77-.27,1.4-.73,1.91-1.4,.5-.66,.84-1.37,1.01-2.11l-.06-.04c-.24,.28-.55,.5-.91,.66s-.64,.24-.84,.24c-.4,0-.78-.11-1.13-.33-.35-.22-.64-.54-.86-.95-.22-.42-.33-.9-.33-1.47,0-.61,.14-1.15,.42-1.63,.28-.48,.65-.85,1.12-1.11,.47-.26,.98-.4,1.53-.4,.52,0,1.02,.13,1.49,.4Zm-2.85,1.11c0,.29,.04,.68,.13,1.17,.15,.77,.38,1.36,.67,1.79s.61,.62,.97,.58c.43-.06,.65-.42,.65-1.1,0-.34-.06-.77-.17-1.29-.16-.76-.36-1.33-.62-1.72-.26-.39-.55-.59-.88-.59-.5,0-.75,.39-.75,1.16Z"
                  fill="#0d294a"
                />
              </g>
            </g>
          </g>
        </g>

        <image
          width="430"
          x="450"
          y="270"
          href={`/images/maps/le-loire/illustrations/steps.webp`}
        />

        <image
          width="400"
          x="950"
          y="50"
          href={`/images/maps/le-loire/illustrations/orleans.webp`}
        />

        <image
          width="200"
          x="400"
          y="830"
          href={`/images/maps/le-loire/illustrations/chenonceau.webp`}
        />

        <image
          width="350"
          x="670"
          y="170"
          href={`/images/maps/le-loire/illustrations/beaugency.webp`}
        />

        <image
          width="500"
          x="210"
          y="450"
          href={`/images/maps/le-loire/illustrations/clos.webp`}
        />

        <image
          width="400"
          x="-50"
          y="530"
          href={`/images/maps/le-loire/illustrations/tours.webp`}
        />
      </svg>
    </>
  )
}
