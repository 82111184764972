import DownSVG from "@components/SVGs/Down"

export default function ArrowButton({
  style,
  height = "2rem",
  rotation = -270,
  ...props
}) {
  return (
    <div
      style={{
        height: height,
        aspectRatio: 1,
        display: "grid",
        placeItems: "center",
        backgroundColor: "#ffffff25",
        border: `1px solid white`,
        zIndex: 10,
        cursor: "pointer",
        ...style,
      }}
      {...props}
    >
      <DownSVG
        fill="white"
        style={{
          transform: `rotate(${rotation}deg)`,
          height: "40%",
          width: "40%",
        }}
      />
    </div>
  )
}
