import { globalStyles } from "@data/globalStyles"
import { residencesData } from "@data/residencesData"
import { residencesDimensionsData } from "@data/residencesDimensionsData"
import { useStore } from "@state/store"
import { useEffect, useState } from "react"

export default function Dimensions() {
  const activeID = useStore((state) => state.activeID)
  const residencePopupCategory = useStore(
    (state) => state.residencePopupCategory
  )
  const [dimensionsData, setDimensionsData] = useState(null)
  const syncState = useStore((state) => state.syncState)

  useEffect(() => {
    const matchingResidence = residencesData.find(
      (residence) => residence.plot === activeID
    )

    if (!matchingResidence) return

    const unitType = matchingResidence.unitType

    const floorKey = residencePopupCategory.includes("Ground")
      ? "ground"
      : residencePopupCategory.includes("Upper")
      ? "first"
      : null
    // console.log("888: ", residencePopupCategory)
    const data = residencesDimensionsData[unitType]?.floorplans[floorKey]
    setDimensionsData(data)

    // console.log(dimensionsData)
  }, [activeID, residencePopupCategory])

  if (!dimensionsData) return null
  return (
    <div
      style={{
        // height: "100%",
        width: "100%",
        // borderRadius: "1rem",
        // border: `1px solid ${globalStyles.col.blue}`,
        // padding: "1rem",
        // gridColumn: "1 / -1",
        // gridRow: "1 / -1",
        // margin: "5rem 3rem",
        // placeSelf: "end",
        display: "grid",
        gridAutoFlow: "row",
        // rowGap: "2rem",
        color: globalStyles.col.blue,
        zIndex: 6,
        fontSize: "0.8rem",
        lineHeight: "0.7rem",
        letterSpacing: "0.05rem",
        // overflow: "hidden",
      }}
    >
      {/* <div
        style={{
          display: "grid",
          gridAutoFlow: "row",
          rowGap: "1rem",
          placeSelf: "center",
        }}
      > */}
      {dimensionsData?.sectionIds.map((sectionId, index) => {
        const section = dimensionsData.sections[sectionId]
        return (
          <table key={index} style={{ width: "100%", marginBottom: "10px" }}>
            <thead>
              <tr>
                <th
                  style={{
                    paddingBottom: "1rem",
                    borderBottom: "1px solid #577598",
                  }}
                  colSpan={2}
                >
                  {section.title} (m²)
                </th>
              </tr>
            </thead>
            <tbody>
              {section.entries.map((entry, entryIndex) => (
                <tr key={entryIndex}>
                  <td>{entry.title}</td>
                  <td>{`${entry.value} ${entry.suffix}`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )
      })}
      {/* </div> */}
    </div>
  )
}
