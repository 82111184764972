const location = {
  destination: {
    see: [
      {
        index: 1,
        name: "Sancerre ",
        area: "France",
        description: "",
        distance: "118km",
        url: "",
        time: "1hr 40 min Drive",
        drive: {
          fly: "Drive"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [1.69, 1.94, 0.47],
            [1.71, 1.93, 0.45]
          ],
          target: {
            target: [1.71, 1.93, 0.45],
            position: [2.03, 2.08, 0.86]
          }
        }
      },
      {
        index: 2,
        name: "Champagne",
        area: "France",
        description: "",
        distance: "160km",
        url: "",
        time: "3hr 20 min Drive",
        drive: {
          fly: "Drive"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [1.65, 1.98, 0.42],
            [1.62, 2.01, 0.36]
          ],
          target: {
            target: [1.62, 2.01, 0.36],
            position: [2.16, 1.96, 0.64]
          }
        }
      },
      {
        index: 3,
        name: "Paris",
        area: "France",
        description: "",
        distance: "163km",
        url: "",
        time: "90 min Drive",
        drive: {
          fly: "Drive"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [1.68, 2.05, 0.47],
            [1.62, 2, 0.44]
          ],
          target: {
            target: [1.62, 2, 0.44],
            position: [1.91, 2.29, 0.29]
          }
        }
      },
      {
        index: 4,
        name: "Normandy",
        area: "France",
        description: "",
        distance: "290km",
        url: "",
        time: "3hr 10 min Drive",
        drive: {
          fly: "Drive"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [1.64, 1.98, 0.52],
            [1.58, 2.01, 0.56]
          ],
          target: {
            target: [1.58, 2.01, 0.56],
            position: [2.22, 1.8, 1.07]
          }
        }
      },
      {
        index: 5,
        name: "Bordeaux",
        area: "France",
        description: "",
        distance: "444km",
        url: "",
        time: "4hr 30 min Drive",
        drive: {
          fly: "Drive"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [1.7, 1.91, 0.53],
            [1.73, 1.86, 0.61]
          ],
          target: {
            target: [1.73, 1.86, 0.61],
            position: [2.29, 2.16, 0.75]
          }
        }
      },
      {
        index: 6,
        name: "Geneva",
        area: "Switzerland",
        description: "",
        distance: "530km",
        url: "",
        time: "1hr Flight",
        drive: {
          fly: "Fly"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [1.76, 1.34, -2.01],
            [1.75, 1.9, 0.4]
          ],
          target: {
            target: [1.75, 1.9, 0.4],
            position: [2.19, 2.13, 0.95]
          }
        }
      },
      {
        index: 7,
        name: "London",
        area: "UK",
        description: "",
        distance: "550km",
        url: "",
        time: "1hr 20 min Flight",
        drive: {
          fly: "Fly"
        },
        camera: {
          flightLine: [
            [1.62, 1.97, 0.46],
            [1.52, 2.1, 0.5],
            [1.47, 2.07, 0.53]
          ],
          target: {
            target: [1.47, 2.07, 0.53],
            position: [2.2, 2.01, 1.35]
          }
        }
      },
      {
        index: 8,
        name: "Zürich",
        area: "Switzerland",
        description: "",
        distance: "600km",
        url: "",
        time: "1hr 15 min Flight",
        drive: {
          fly: "Fly"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [1.76, 1.34, -2.01],
            [1.69, 1.98, 0.28]
          ],
          target: {
            target: [1.69, 1.98, 0.28],
            position: [2.19, 2.13, 0.95]
          }
        }
      },
      {
        index: 9,
        name: "Milan",
        area: "Italy",
        description: "",
        distance: "780km",
        url: "",
        time: "1hr 30 min Flight",
        drive: {
          fly: "Fly"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [1.76, 1.34, -2.01],
            [1.78, 1.89, 0.31]
          ],
          target: {
            target: [1.78, 1.89, 0.31],
            position: [2.48, 1.75, 0.6]
          }
        }
      },
      {
        index: 10,
        name: "Madrid",
        area: "Spain",
        description: "",
        distance: "950km",
        url: "",
        time: "2hr 10 min Flight",
        drive: {
          fly: "Fly"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [1.76, 1.34, -2.01],
            [1.82, 1.7, 0.79]
          ],
          target: {
            target: [1.82, 1.7, 0.79],
            position: [2.2, 2.01, 1.35]
          }
        }
      },
      {
        index: 11,
        name: "Edinburgh",
        area: "UK",
        description: "",
        distance: "1080km",
        url: "",
        time: "2 hr Flight",
        drive: {
          fly: "Fly"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [1.76, 1.34, -2.01],
            [1.31, 2.19, 0.56]
          ],
          target: {
            target: [1.31, 2.19, 0.56],
            position: [2.84, 2.31, 1.01]
          }
        }
      },
      {
        index: 12,
        name: "Berlin",
        area: "Germany",
        description: "",
        distance: "1200km",
        url: "",
        time: "1hr 55 min Flight",
        drive: {
          fly: "Fly"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [1.76, 1.34, -2.01],
            [1.58, 2.08, 0.08]
          ],
          target: {
            target: [1.58, 2.08, 0.08],
            position: [2.23, 2.22, 0.84]
          }
        }
      },
      {
        index: 13,
        name: "Rome ",
        area: "Italy",
        description: "",
        distance: "1210km",
        url: "",
        time: "2 hr Flight",
        drive: {
          fly: "Fly"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [1.76, 1.34, -2.01],
            [1.91, 1.76, 0.27]
          ],
          target: {
            target: [1.91, 1.76, 0.27],
            position: [2.66, 1.68, 0.34]
          }
        }
      },
      {
        index: 14,
        name: "Boston",
        area: "USA",
        description: "",
        distance: "5600km",
        url: "",
        time: "6hr 45 min Flight",
        drive: {
          fly: "Fly"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [1.76, 1.34, -2.01],
            [0, 1.77, 1.92]
          ],
          target: {
            target: [0, 1.77, 1.92],
            position: [-2.12, 3.58, 3.84]
          }
        }
      },
      {
        index: 15,
        name: "New York",
        area: "USA",
        description: "",
        distance: "5880km",
        url: "",
        time: "8hr 15 min Flight",
        drive: {
          fly: "Fly"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [1, 2.42, 1.32],
            [-0.12, 1.7, 1.98]
          ],
          target: {
            target: [-0.12, 1.7, 1.98],
            position: [-0.69, 1.73, 2.82]
          }
        }
      },
      {
        index: 16,
        name: "Miami",
        area: "USA",
        description: "",
        distance: "7630km",
        url: "",
        time: "8hr 50 min Flight",
        drive: {
          fly: "Fly"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [1.76, 1.34, -2.01],
            [-0.38, 1.12, 2.33]
          ],
          target: {
            target: [-0.38, 1.12, 2.33],
            position: [-1.03, 1.31, 2.95]
          }
        }
      },
      {
        index: 17,
        name: "San Francisco ",
        area: "USA",
        description: "",
        distance: "8900km",
        url: "",
        time: "10hr 50 min Flight",
        drive: {
          fly: "Fly"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [1.76, 1.34, -2.01],
            [-1.61, 1.54, 1.35]
          ],
          target: {
            target: [-1.61, 1.54, 1.35],
            position: [-3.97, 1.91, 2.2]
          }
        }
      },
      {
        index: 18,
        name: "Seoul",
        area: "South Korea",
        description: "",
        distance: "9250km",
        url: "",
        time: "11hr 50 min Flight",
        drive: {
          fly: "Fly"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [1.76, 1.34, -2.01],
            [-0.59, 1.57, -2]
          ],
          target: {
            target: [-0.59, 1.57, -2],
            position: [-1.5, 2.04, -3.13]
          }
        }
      },
      {
        index: 19,
        name: "Hong Kong",
        area: "Hong Kong",
        description: "",
        distance: "9910km",
        url: "",
        time: "14hr Flight",
        drive: {
          fly: "Fly"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [0.81, 1.98, -1.96],
            [-0.2, 1.02, -2.4]
          ],
          target: {
            target: [-0.2, 1.02, -2.4],
            position: [-1.1, 1.12, -3.99]
          }
        }
      },
      {
        index: 20,
        name: "Singapore",
        area: "Singapore",
        description: "",
        distance: "10900km",
        url: "",
        time: "13hr 20 min Flight",
        drive: {
          fly: "Fly"
        },
        camera: {
          flightLine: [
            [1.67, 1.95, 0.48],
            [1.76, 1.34, -2.01],
            [0.29, -0.01, -2.6]
          ],
          target: {
            target: [0.29, -0.01, -2.6],
            position: [0.31, -0.67, -4.63]
          }
        }
      }
    ]
  }
}

export default location
