import { Html } from "@react-three/drei"

export default function PlaceCircle({
  size,
  position,
  h1,
  h2,
  h3,
  fontSize,
  color,
  isActive
}) {
  return (
    <group position={position}>
      <mesh scale={size}>
        <sphereGeometry />
        <meshBasicMaterial
          attach="material"
          wireframe={false}
          color={`${color || "rgba(69, 107, 144)"}`}
        />
      </mesh>
      {isActive && (
        <Html center={true} style={{ pointerEvents: "none",}}>
          <div
            style={{
              color: "rgba(0,0,0,0.5)",
              textAlign: "center",
              padding: 5,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              fontSize: `16px`,
              width: 300,
              marginBottom: 200,
              textShadow: "0px 0px 2px rgba(256,256,256,1)",
            }}>
            <h2 style={{ fontWeight: "100", fontSize: "1em", marginTop: 5,  color: "rgba(69, 107, 144)" }}>
              {h1}
            </h2>
            <h1 style={{  fontWeight: "100", fontSize: "1.4em", color: "rgba(69, 107, 144)" }}> {h2}</h1>
            <p style={{  fontWeight: "100", fontSize: "0.8em", color: "rgba(69, 107, 144)" }}> {h3}</p>
          </div>
        </Html>
      )}
    </group>
  )
}
