import { mapsData } from "@data/mapsData"
import { useStore } from "@state/store"

const imagePaths = {
  "Les Bordes Estate": "/images/les-bordes-map.webp",
  "Loire Valley": {
    SIGHTS: "/images/les-bordes-map.webp",
    FOOD: "/images/les-bordes-map.webp",
    ACTIVITIES: "/images/les-bordes-map.webp",
    TRANSPORT: "/images/les-bordes-map.webp",
  },
}

export default function SVG() {
  const mapFilter = useStore((state) => state.mapFilter)
  const mapSubFilter = useStore((state) => state.mapSubFilter)
  const activeMapItem = useStore((state) => state.activeMapItem)
  const syncState = useStore((state) => state.syncState)

  //
  const dataPath = mapSubFilter
    ? mapsData[mapFilter][mapSubFilter]
    : mapsData[mapFilter]

  //
  const imagePath = mapSubFilter
    ? imagePaths[mapFilter][mapSubFilter]
    : imagePaths[mapFilter]

  return (
    <svg
      width="1848"
      height="1848"
      viewBox="0 0 1848 1848"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      style={{
        transform: "translateY(-2rem)",
        WebkitTapHighlightColor: "transparent",
      }}
    >
      {imagePath && (
        <image
          width="1848"
          height="1311"
          y="293"
          href={imagePath}
          style={{ pointerEvents: "none", zIndex: 1 }}
        />
      )}
      {dataPath &&
        dataPath.map((path, index) => {
          return (
            <path
              key={index}
              style={{
                WebkitTapHighlightColor: "transparent",
                pointerEvents: "auto",
                touchAction: "auto",
                cursor: "pointer",
              }}
              onClick={() => {
                activeMapItem === path.name
                  ? syncState("activeMapItem", null)
                  : syncState("activeMapItem", path.name)
              }}
              id={path.name}
              d={path.d}
              fill="transparent"
            />
          )
        })}
    </svg>
  )
}
