import Gallery from "@components/Gallery/Gallery"
import styled from "styled-components"

function GalleryComponent() {
  return (
    <Wrapper>
      <Gallery />
    </Wrapper>
  )
}

export default GalleryComponent

const Wrapper = styled.div`
  overflow: hidden;
  display: grid;
`
