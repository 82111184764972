import { Fragment, useRef, useState } from "react"
import DotSVG from "./DotSVG"
import { LifeStyleData } from "./LifestyleData"
import ArrowSVG from "./ArrowSVG"
import { useGSAP } from "@gsap/react"
import { gsap } from "gsap"
import ArrowButton from "@components/Shared/ArrowButton"

const nav = {
  eat: "EAT WITH SIX SENSES",
  wellness: "WELLNESS WITH SIX SENSES",
  grow: "GROW WITH SIX SENSES",
  sleep: "SLEEP WITH SIX SENSES",
  earth: "SIX SENSES EARTH LAB",
}

export default function Lifestyle() {
  const [hide, setHide] = useState(false)
  const [activeState, setActiveState] = useState("LIFESTYLE & COMMUNITY")
  const [arrowState, setArrowState] = useState("USE ARROWS TO EXPLORE")
  const timelineRef = useRef(null)

  useGSAP(() => {
    const timeline = (timelineRef.current = gsap.timeline({
      defaults: {
        ease: "power2.inOut",
        duration: 0.5,
      },
    }))
    LifeStyleData.forEach((item, index) => {
      const { state, arrow, type } = item
      const fadeInSelector =
        index + 1 < LifeStyleData.length + 1 ? `#lifestyle-${index + 1}` : null
      const label = type === "title" ? state : `lifestyle-${index}`
      timeline.addLabel(label)
      index === 0 ? timeline.pause() : timeline.addPause()
      if (fadeInSelector && index !== 0) {
        timeline.to(`#lifestyle-${index - 1}`, {
          autoAlpha: 0,
          onStart: () => {
            gsap.to("#home-dot", { opacity: 0, duration: 0.3 })
            setArrowState(arrow)
            setActiveState(state)
          },
        })

        timeline.to(`#lifestyle-${index}`, {
          autoAlpha: 1,
          stagger: {
            each: 0.1,
          },
          onComplete: () => {
            index !== LifeStyleData.length - 1 &&
              fadeInSelector &&
              gsap.to("#home-dot", { opacity: 1, duration: 0.3 })
          },
        })
      }
    })
  })

  return (
    <div
      // onClick={() => {
      //   setTimeout(() => {
      //     // console.log(hide)
      //     !hide && timelineRef.current.play()
      //   }, 10)
      // }}
      style={{
        zIndex: 4,
        pointerEvents: "auto",
        position: "fixed",
        inset: 0,
        height: "100%",
        width: "100%",
        display: "grid",
        placeItems: "center",
        backgroundColor: "black",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          zIndex: 0,
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          display: "grid",
          gridAutoFlow: "row",
          placeItems: "center",
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <video
          autoPlay
          playsInline
          muted
          loop
          style={{
            opacity: 0.3,
            gridColumn: "1 / -1",
            gridRow: "1 / -1",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            overflow: "hidden",
          }}
        >
          <source src="/images/lifestyle/bg.mp4" type="video/mp4" />
        </video>
      </div>
      <div
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          placeSelf: "start center",
          display: "grid",
          gridAutoFlow: "row",
          placeItems: "start center",
          marginTop: "4rem",
        }}
      >
        <div
          style={{
            placeSelf: "start center",
            display: "grid",
            gridAutoFlow: "column",
            placeItems: "start center",
            columnGap: "1rem",
            zIndex: 5,
          }}
        >
          {Object.keys(nav).map((item, index) => {
            const active = activeState === item

            return (
              <div
                onClick={(event) => {
                  event.stopPropagation()
                  setHide(true)
                  setTimeout(() => {
                    timelineRef.current.play(item)
                  }, 300)
                  setTimeout(() => {
                    setHide(false)
                  }, 600)
                }}
                key={index}
                style={{
                  opacity: active ? 1 : 0.3,
                  transition: "opacity 0.3s ease-in-out",
                  display: "grid",
                  placeItems: "center",
                  width: "1rem",
                  zIndex: 5,
                  pointerEvents: "auto",
                  cursor: "pointer",
                }}
              >
                <DotSVG />
              </div>
            )
          })}
        </div>
        <p
          style={{
            transform: "translateY(-1rem)",
            fontSize: "1rem",
            letterSpacing: "0.04rem",
            marginTop: "2rem",
            lineHeight: "1rem",
            color: "white",
            placeSelf: "start center",
            placeItems: "start center",
          }}
        >
          {activeState === "LIFESTYLE & COMMUNITY"
            ? "SIX SENSES LOIRE VALLEY"
            : "LIFESTYLE & COMMUNITY"}
        </p>
        <p
          style={{
            transform: "translateY(-3rem)",
            fontSize: "1.6rem",
            lineHeight: "1.4rem",
            color: "white",
            padding: 0,
            display: "grid",
            placeSelf: "start center",
            placeItems: "start center",
          }}
        >
          {nav[activeState]}
        </p>
      </div>
      <div
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          display: "grid",
          margin: "2rem 8rem",
          placeSelf: "end",
          placeItems: "center start",
          gridAutoFlow: "row",
          color: "white",
          fontSize: "1.3rem",
          letterSpacing: "0.03rem",
          rowGap: 0,
          lineHeight: "1rem",
          textAlign: "center",
          overflow: "hidden",
          zIndex: 1,
        }}
      >
        <div style={{ display: "grid", placeItems: "center start" }}>
          {LifeStyleData.map((item, index) => {
            const { arrow } = item
            const active = arrow === arrowState
            return (
              <p
                key={index}
                style={{
                  gridColumn: "1 / -1",
                  gridRow: "1 / -1",
                  transform: "translateY(0.4rem)",
                  opacity: active ? 1 : 0,
                  transition: "opacity 0.7s 0.7s ease-in-out",
                }}
              >
                {arrow}
              </p>
            )
          })}
        </div>
        <ArrowSVG
          style={{
            display: !arrowState ? "none" : "grid",
            width: `${arrowState?.length * 0.75}rem`,
            transition: "width 0.4s 0.4s ease-in-out",
          }}
        />
      </div>

      {LifeStyleData.map((item, index) => {
        const { type, text, subtext, arrow, left, right } = item

        if (type === "title") {
          return (
            <div
              style={{
                opacity: hide ? 0 : 1,
                transition: "opacity 0.3s ease-in-out",
                gridColumn: "1 / -1",
                gridRow: "1 / -1",
                display: "grid",
                placeItems: "center",
                color: "white",
                fontSize: "1.4rem",
                lineHeight: "1.4rem",
                textAlign: "center",
                overflow: "hidden",
                zIndex: 1,
              }}
              key={index}
            >
              <p
                id={`lifestyle-${index}`}
                style={{
                  visibility: index === 0 ? "visible" : "hidden",
                  opacity: index === 0 ? 1 : 0,
                  width: index === 0 ? "50%" : "100%",
                  fontSize: "5.6rem",
                  lineHeight: "4.8rem",
                }}
              >
                {text}
              </p>
              {subtext && (
                <p
                  id={`lifestyle-${index}`}
                  style={{
                    fontFamily: "Avenir Next",
                    visibility: index === 0 ? "visible" : "hidden",
                    opacity: index === 0 ? 1 : 0,
                    display: "grid",
                    width: "43%",
                    placeItems: "center",
                  }}
                >
                  {subtext}
                </p>
              )}
            </div>
          )
        } else {
          const { left, right, state } = item
          return (
            <div
              style={{
                height: "80%",
                opacity: hide ? 0 : 1,
                transition: "opacity 0.3s ease-in-out",
                zIndex: 1,
                gridColumn: "1 / -1",
                gridRow: "1 / -1",
                // height: "100%",
                maxHeight: "70%",
                width: "100%",
                display: "grid",
                placeItems: "center",
                color: "white",
                fontSize: "1.6rem",
                textAlign: "center",
                // overflow: "hidden",
                gridAutoFlow: "column",
                gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                columnGap: "1rem",
              }}
              key={index}
            >
              {["left", "right"].map((item, i) => {
                const text = item === "left" ? left : right
                return (
                  <div
                    key={i}
                    style={{
                      //   height: "100%",
                      width: "100%",
                      //   gridColumn: i,
                      gridRow: "1 / -1",
                      transform: `translate(${
                        item === "left" ? "4rem, 9rem" : "-4rem, -2rem"
                      })`,
                      display: "grid",
                      placeItems: "center",
                      gridAutoFlow: "row",
                      rowGap: "0.4rem",
                    }}
                  >
                    <img
                      id={`lifestyle-${index}`}
                      alt="Six Senses"
                      src={`/images/lifestyle/${state}-${item}.webp`}
                      style={{
                        visibility: "hidden",
                        opacity: 0,
                        gridColumn: "1 / -1",
                        gridRow: item === "left" ? 1 : 0,
                        ...(index === LifeStyleData.length - 1
                          ? { maxHeight: "55vh" }
                          : {}),
                        maxWidth: "60%",
                        height: "auto",
                        overflow: "hidden",
                      }}
                    />
                    <p
                      id={`lifestyle-${index}`}
                      style={{
                        visibility: "hidden",
                        opacity: 0,
                        fontFamily: "Avenir Next",
                        textAlign: "center",
                        height: "auto",
                        width: "90%",
                        gridColumn: "1 / -1",
                        fontSize: "1.2rem",
                        lineHeight: "1.2rem",
                        gridRow: item === "left" ? 0 : 1,
                      }}
                    >
                      {text}
                    </p>
                  </div>
                )
              })}
            </div>
          )
        }
      })}
      <div
        id="home-dot"
        style={{
          display: "none",
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          placeSelf: "end",
          margin: "3rem",
          borderRadius: "100%",
          height: "1rem",
          aspectRatio: 1,
          backgroundColor: "#f4f1f1e5",
          zIndex: 5,
        }}
      />
      <div
        style={{
          zIndex: 10,
          opacity: hide ? 0.3 : 1,
          transition: "opacity 0.3s, margin 0.3s",
          pointerEvents: hide ? "none" : "auto",
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          display: "grid",
          placeSelf: "end start",
          height: "4rem",
          margin: "1rem 4rem",
          columnGap: "2rem",
          placeItems: "center",
          gridAutoFlow: "column",
        }}
      >
        <ArrowButton
          id="timeline-button"
          height="3rem"
          style={{
            opacity: timelineRef.current?.progress() <= 0.03 ? 0.3 : 1,
            cursor: "pointer",
            transition: "opacity 0.3s",
            ...(timelineRef.current?.progress() <= 0.03 && {
              pointerEvents: "none",
            }),
          }}
          onClick={(e) => {
            setHide(true)
            timelineRef.current.pause()
            timelineRef.current.reverse()
            setTimeout(() => {
              setHide(false)
            }, 600)
          }}
        />
        <ArrowButton
          id="timeline-button"
          height="3rem"
          rotation={-90}
          style={{
            opacity: timelineRef.current?.progress() >= 0.9 ? 0.3 : 1,
            cursor: "pointer",
            transition: "opacity 0.3s",
            ...(timelineRef.current?.progress() >= 0.9 && {
              pointerEvents: "none",
            }),
          }}
          onClick={(e) => {
            setHide(true)
            timelineRef.current.pause()
            timelineRef.current.play()
            setTimeout(() => {
              setHide(false)
            }, 600)
          }}
        />
      </div>
    </div>
  )
}
