import { residencesData } from "@data/residencesData"
import { create } from "zustand"

interface InitialState {
  syncState: (stateToUpdate: any, data: any) => void
  nav: boolean
  mapFilter: string
  mapSubFilter: null | "SIGHTS" | "FOOD" | "ACTIVITIES" | "TRANSPORT"
  activeMapItem: string | null
  activeID: number | null
  searchUnit: number | null
  typeFilter: string[]
  orientationFilter: string[]
  bedFilter: number[]
  filterResults: any[]
  sidebarPopup: string | null
  residencePopup: boolean
  residencePopupCategory: string
  galleryPopup: boolean
  galleryCategory: number
  auth: boolean
  hideLogo: boolean
  darkLogo: boolean
  filter: boolean
  favourites: number[]
  share: boolean
  unitData: null | {}
  activeMapIndex: number
}

export const useStore = create<InitialState>()((set, get) => ({
  syncState: (stateToUpdate: string, data: any) => {
    set({ [stateToUpdate]: data })
  },
  nav: false,
  hideLogo: true,
  mapFilter: "Les Bordes Estate",
  // mapFilter: "LOIRE VALLEY",
  mapSubFilter: null,
  activeMapItem: null,
  activeID: null,
  searchUnit: null,
  typeFilter: [],
  orientationFilter: [],
  bedFilter: [],
  filterResults: residencesData,
  sidebarPopup: null,
  residencePopup: false,
  residencePopupCategory: "Ground Floor Plan",
  galleryPopup: false,
  galleryCategory: 1,
  auth: false,
  darkLogo: false,
  filter: false,
  favourites: [],
  share: false,
  unitData: null,
  activeMapIndex: 0,
}))
