import { useGSAP } from "@gsap/react"
import { gsap } from "gsap"

const data = {
  WELLNESS: {
    gridColumn: 1,
    gridRow: "1 / 8",
    image: "/images/wellness/9.webp",
  },
  "SPORTS CLUB": {
    gridColumn: 1,
    gridRow: "9 / -1",
    image: "/images/landing/tennis.webp",
  },
  NATURE: {
    gridColumn: 2,
    gridRow: "2 / -2",
    image: "/images/estate/nature.webp",
  },
  GOLF: {
    gridColumn: 3,
    gridRow: "1 / 10",
    image: "/images/estate/golf.webp",
  },
  WILDFLIFE: {
    gridColumn: 3,
    gridRow: "11 / -1",
    image: "/images/estate/wildlife.webp",
  },
  EQUESTRIAN: {
    gridColumn: 4,
    gridRow: "2 / -2",
    image: "/images/estate/equestrian.webp",
    color: "#cfd1d2",
  },
}
export default function Estate() {
  useGSAP(() => {
    const images = gsap.utils.toArray("#estate-img")
    gsap.to(images, {
      opacity: 0.9,
      y: 0,
      // delay: 1.5,
      stagger: {
        each: 0.15,
      },
      //   stagger: { each: 0.15, from: "random", grid: "auto" },
      duration: 0.4,
      ease: "back.in",
    })
  })
  return (
    <div
      style={{
        backgroundColor: "black",
        height: "100%",
        width: "100%",
        position: "fixed",
        inset: 0,
        display: "grid",
        placeItems: "center",
      }}
    >
      <img
        alt="Six Senses"
        src="/images/estate-bg.webp"
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          height: "100%",
          width: "100%",
          objectFit: "cover",
          overflow: "hidden",
          opacity: 0.7,
        }}
      />
      <div
        style={{
          zIndex: 2,
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          height: "70%",
          width: "82%",
          display: "grid",
          gridAutoFlow: "column",
          gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
          gridTemplateRows: "repeat(13, minmax(0, 1fr))",
          columnGap: "2rem",
          overflow: "hidden",
        }}
      >
        {Object.keys(data).map((item, i) => {
          const { gridColumn, gridRow, image, color } = data[item]
          return (
            <div
              id="estate-img"
              key={i}
              style={{
                transform: "translate3d(0,5rem,0)", // Promote layering to GPU
                opacity: 0,
                transition: "transform 0.4s, opacity 0.4s",
                gridColumn: gridColumn,
                gridRow: gridRow,
                height: "100%",
                width: "100%",
                borderRadius: "1rem",
                overflow: "hidden",
                display: "grid",
                placeItems: "center",
                backgroundColor: "white",
              }}
            >
              <img
                alt="Six Senses"
                src={image}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "25% 50%",
                  opacity: 0.6,
                  gridColumn: "1 / -1",
                  gridRow: "1 / -1",
                  overflow: "hidden",
                }}
              />
              <p
                style={{
                  color: color ? color : "white",
                  fontSize: "2rem",
                  gridColumn: "1 / -1",
                  gridRow: "1 / -1",
                  zIndex: 4,
                }}
              >
                {item}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}
