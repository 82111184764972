import { Routes, Route } from "react-router-dom"

import routes from "./routes/routes"
import { useStore } from "@state/store"

function Content() {
  const nav = useStore((state) => state.nav)
  const syncState = useStore((state) => state.syncState)
  return (
    <section
      onClick={() => nav && syncState("nav", false)}
      style={{
        position: "fixed",
        inset: 0,
        width: "100%",
        height: "100%",
        display: "grid",
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        overflow: "hidden",
        // opacity: nav ? 0.7 : 1,
        transform: nav ? "scale(1.05)" : "scale(1)",
        filter: nav ? "blur(6px)" : "blur(0px)",
        transition: "opacity 0.3s, filter 0.3s, transform 0.3s",
      }}
    >
      <Routes>
        {routes.map((route, idx) => {
          return (
            route.element && (
              <Route key={idx} path={route.path} element={<route.element />} />
            )
          )
        })}
      </Routes>
    </section>
  )
}

export default Content
