import { useStore } from "@state/store"
import SidebarFilterButtons from "./SidebarFilterButtons"
import SidebarInfo from "./SidebarInfo"
import SidebarTopImage from "./SidebarTopImage"
import AccordionFor3D from "./globe/components/AccordionFor3D.jsx"
import location from "../../data/location.js"
import MapsSideBar from "./globe/components/MapsSideBar.jsx"
import { useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import * as THREE from "three"

export default function Sidebar() {
  const mapFilter = useStore((state) => state.mapFilter)
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
        display: "grid",
        placeItems: "center",
        gridAutoFlow: "row",
        ...(mapFilter === "Loire Valley" && {
          gridTemplateRows: "minmax(0, 0.5fr) minmax(0, 10fr)",
          zIndex: "1000000"
        }),
        // gridTemplateRows: "minmax(0, 400fr) minmax(0, 200fr) minmax(0, 1000fr)",
        // gridTemplateRows: "minmax(0, 400fr) minmax(0, 1000fr)",
        maxHeight: "100%",
      }}
    >
      {/* <SidebarTopImage /> */}
      {mapFilter === "Loire Valley" && <SidebarFilterButtons />}
      {mapFilter != "Destination" && <SidebarInfo />} 
      
    </div>
  )
}
