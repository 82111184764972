import React, { useState } from "react"
import styled from "styled-components"
import DestinationCard from "./DestinationCard.jsx"
import flightLinesFunction from "./flightLinesFunction.js"
function Accordion({
  elements,
  setCamTarget,
  canClick
}) {
  const [activeElement, setActiveElement] = useState(null)
  return (

     <AccordionWrapper>
       {elements?.map((element, id) => {
        return (
          <DestinationCard
            key={`card${id}`}
            number={element.index ? element.index : id + 1}
            title={element.name}
            description={element.description}
            area={element.area}
            distance={element.distance}
            time={element.time}
            url={element.url}
            width="100%"
            reference={element.index ? `card${element.index}` : `card${id + 1}`}
            id={element.index ? element.index : id + 1}
            activeElement={activeElement}
            onClickEvent={() => {
              setActiveElement(() => setActiveElement(element.index))
              flightLinesFunction(canClick, element, setCamTarget)
            }}
          />
        )
      })}
    </AccordionWrapper>

  )
}

export default Accordion

const AccordionWrapper = styled.div`
width: 100%;
height: 100%;
overflow-y: scroll;
`
