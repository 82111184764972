import styled from "styled-components"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch"
import { Fragment, useEffect, useRef } from "react"
import ResidencesSVG from "./ResidencesSVG"
import { globalStyles } from "@data/globalStyles"
import { useStore } from "@state/store"
import { useGSAP } from "@gsap/react"
import { gsap } from "gsap"
import ArrowSVG from "@components/Lifestyle/ArrowSVG"
import DownSVG from "@components/SVGs/Down"

export default function ResidencesMap({ ...props }) {
  const zoomRef = useRef(null)
  const filter = useStore((state) => state.filter)
  const syncState = useStore((state) => state.syncState)
  const activeID = useStore((state) => state.activeID)
  const residencePopup = useStore((state) => state.residencePopup)

  useEffect(() => {
    if (zoomRef.current) {
      // console.log("resetting")
      zoomRef.current.resetTransform()
    }
  }, [residencePopup])

  useGSAP(
    () => {
      gsap.to("#image-svg", { opacity: 1, duration: 1, ease: "power2.inOut" })
      gsap.to("#fade-svg", {
        opacity: 1,
        delay: 0.3,
        duration: 1,
        ease: "power2.inOut",
      })
    },
    { dependencies: [] }
  )

  return (
    <Wrapper
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
      {...props}
    >
      <div
        style={{
          placeSelf: "start end",
          zIndex: 2,
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          display: "grid",
          height: "10.4vh",
          margin: "2rem",
        }}
      >
        <img
          alt="Compass"
          src="/images/compass.webp"
          style={{ objectFit: "contain", width: "5rem" }}
        />
      </div>
      <p
        style={{
          height: "8.5vh",
          margin: "0 0 0 2.5rem",
          placeSelf: "end start",
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          display: "grid",
          placeItems: "center",
          fontSize: "1.2rem",
          lineHeight: "2.2rem",
          letterSpacing: "0.06rem",
          color: globalStyles.col.white,
          pointerEvents: "auto",
          zIndex: 3,
        }}
      >
        Pinch (zoom), pan & tap residences for details
        {/* PINCH (ZOOM), PAN & TAP RESIDENCES FOR DETAILS */}
      </p>
      <div
        style={{
          cursor: "pointer",
          transform: "translate(3.4rem, 0.4rem)",
          height: "10vh",
          marginRight: "2rem",
          placeSelf: "end",
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          display: "grid",
          placeItems: "center",
          color: globalStyles.col.white,
          pointerEvents: "auto",
          zIndex: 3,
        }}
      >
        <div
          onClick={() => syncState("filter", !filter)}
          style={{
            height: "50%",
            aspectRatio: 1,
            margin: "0 4rem 0 4rem",

            gridColumn: "1 / -1",
            gridRow: "1 / -1",
            display: "grid",
            placeItems: "center",
            fontSize: "1.2rem",
            lineHeight: "2.2rem",
            letterSpacing: "0.2rem",
            color: globalStyles.col.white,
            pointerEvents: "auto",
            zIndex: 5,
            border: "0.1rem white solid",
            borderRadius: "1000rem",
          }}
        />
        <DownSVG
          style={{
            height: "50%",
            gridColumn: "1 / -1",
            gridRow: "1 / -1",
            transform: `rotate(${filter ? `90deg` : `-90deg`})`,
            transition: "transform 0.3s",
          }}
        />
      </div>
      <ZoomWrapper id="neighborhood-svg-wrapper">
        <TransformWrapper
          ref={zoomRef}
          centerOnInit={true}
          minScale={1}
          maxScale={1.8}
          initialPositionY={-window.innerHeight / 3}
          alignmentAnimation={{ sizeX: 0, sizeY: 0 }}
          limitToBounds={true}
          smooth={true}
          centerZoomedOut={true}
        >
          <TransformComponent
            wrapperStyle={{
              gridColumn: "1 / -1",
              gridRow: "1 / -1",
              height: "100%",
              width: "100%",
              display: "grid",
              placeItems: "center",
              zIndex: 1,
            }}
            contentStyle={{
              gridColumn: "1 / -1",
              gridRow: "1 / -1",
              height: "100%",
              width: "100%",
              display: "grid",
              placeItems: "center",
              zIndex: 1,
            }}
          >
            <ResidencesSVG />
          </TransformComponent>
        </TransformWrapper>
      </ZoomWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  place-self: start;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  display: grid;
  place-items: center;
  pointer-events: auto;
  z-index: 1;
`

const ZoomWrapper = styled.div`
  overflow: hidden;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  height: 100%;
  width: 100%;
  z-index: 1;
  overscroll-behavior: none;
  display: flex;
  place-items: center;

  & svg {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    border: 0px red solid;
  }
`
