import CloseSVG from "@components/SVGs/Close"
import { useStore } from "@state/store"

const buttons = [
  {
    label: "Ground Floor Plan",
    state: "Ground Floor Plan",
  },
  {
    label: "Upper Floor Plan",
    state: "Upper Floor Plan",
  },
  {
    label: "Render",
    state: "Render",
  },
]

export default function PopupButtons() {
  const residencePopupCategory = useStore(
    (state) => state.residencePopupCategory
  )
  const syncState = useStore((state) => state.syncState)
  return (
    <div
      style={{
        marginLeft: "3.5rem",
        height: "8.5vh",
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        placeSelf: "end start",
        display: "grid",
        gridAutoFlow: "column",
        columnGap: "1rem",
        placeItems: "center",
        zIndex: 10,
      }}
    >
      <div
        onClick={() => {
          syncState("residencePopup", false)
          syncState("activeID", null)
        }}
        style={{
          cursor: "pointer",
          height: "2rem",
          aspectRatio: 1,
          display: "grid",
          placeItems: "center",
          backgroundColor: "#ffffff25",
          border: `1px solid ${
            residencePopupCategory === "Render" ? "white" : "#4E769B"
          }`,
          zIndex: 10,
          pointerEvents: "auto",
        }}
      >
        <CloseSVG
          fill={residencePopupCategory === "Render" ? "white" : "#4E769B"}
          style={{
            transform: "rotate(-90deg)",
            height: "40%",
            width: "40%",
          }}
        />
      </div>
      {Object.keys(buttons).map((cat, i) => {
        const active = residencePopupCategory === buttons[cat].label
        const color = residencePopupCategory === "Render" ? "white" : "#577598"
        return (
          <button
            onClick={() =>
              syncState(
                "residencePopupCategory",
                buttons.find((b) => b.label === buttons[cat].label).state
              )
            }
            style={{
              cursor: "pointer",
              opacity: active ? 1 : 0.5,
              border: `1px solid ${color}`,
              padding: "0.4rem 0.8rem",
              boxShadow: "none",
              backgroundColor: "#ffffff25",
              color: color,
              fontFamily: "Canto Roman",
              fontSize: "1.2rem",
              lineHeight: "1.2rem",
              // letterSpacing: "0.03rem",
              pointerEvents: "auto",
              transition: "background-color 0.4s, color 0.4s",
            }}
            key={i}
          >
            {buttons[cat].label}
          </button>
        )
      })}
    </div>
  )
}
