import React from "react"
import styled from "styled-components"
import { globalStyles } from "@data/globalStyles"
import { useStore } from "@state/store"

function DestinationCard({
  number,
  title,
  width,
  distance,
  time,
  reference,
  id,
  onClickEvent,
  activeElement
}) {
  // eslint-disable-next-line no-unused-vars
  const setAccordeonCount = useStore(s => s.setAccordeonCount)
  // eslint-disable-next-line no-unused-vars
  const setCurrentPoi = useStore(s => s.setCurrentPoi)
  const currentPoi = useStore(s => s.currentPoi)
  const mapFilter = useStore(s => s.mapFilter)
  const activeMapsTab = useStore(s => s.activeMapsTab)

  function handleClick(e, onClickEvent) {
    onClickEvent && onClickEvent()
  }

  return (
    <LocationCardWrapper
      id={id}
      onClick={e => {
        handleClick(e, onClickEvent && onClickEvent())
      }}
      width={width}
      data-scrollid={reference}>
      <CardHeader>
        <div style={{display: "flex", width: "80%" }}>
        <CardHeaderNumber>
          <h3> {number} </h3>
        </CardHeaderNumber>

        <CardHeaderInfo>
          <CardTitle>{title ? title : "Title"}</CardTitle>
          <CardBody show={id === activeElement} style={{ padding: 0 }}>
            <CardData style={{ padding: "0 0 0px 0px" }}>
              {distance === "" ? null : ` ${distance}  | `}
              {time === "" ? null : ` ${time}`}
            </CardData>
          </CardBody>
        </CardHeaderInfo>
        </div>
      </CardHeader>
    </LocationCardWrapper>
  )
}

export default DestinationCard

const LocationCardWrapper = styled.div`
  display: block;
  box-sizing: border-box;
  position: relative;
  
  height: auto;
  // margin: 0% 2%;
  font-size: 1.5rem;
  color: "blue;
  cursor: pointer !important;
  `
  
  const CardHeader = styled.div`
  width: 100%;
  // height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid ${globalStyles.col.blue}
  `
  
  const CardHeaderNumber = styled.div`
  width: 5vw;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 100%;
  display: grid;
  place-content: center;
  font-size: 1.6vh;
  color: rgba(69, 107, 144);
  
  h3 {
    color: rgba(69, 107, 144)
    font-size: 0.5rem;
    width: 2vw;
    height: 2vw;
    padding: 1.25rem;
    display: grid;
    place-content: center;
    text-align: center;
    border-radius: 100%;
    font-weight: 10;
    border: 1px solid ${globalStyles.col.blue}
  }
  `
  
  const CardHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  `
  
  const CardTitle = styled.h4`
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 100;
  color: rgba(69, 107, 144);
`
const CardBody = styled.div`
  display: ${p => (p.show ? "block" : "none")};
  padding: 5% 0%;
`

const CardData = styled.div`
  
  color: rgba(69, 107, 144);
  font-size: 1rem;
  margin-top: 0.25rem;
  letter-spacing: 2px;

  & p {
    text-align: justify;
    box-sizing: border-box;
    font-family: main;
    font-weight: 100;
    letter-spacing: 0.1rem;
    color: rgba(69, 107, 144)
  }
`
