import LogoSVG from "@components/Nav/LogoSVG"

export default function HomeScreen1() {
  return (
    <div
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        height: "100%",
        width: "100%",
        display: "grid",
        placeItems: "center",
        overflow: "hidden",
        backgroundColor: "black",
      }}
    >
      <img
        id="home-1-castle"
        alt="Six Senses"
        src="/images/landing/castle.webp"
        style={{
          opacity: 0.6,
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          overflow: "hidden",
        }}
      />
      <img
        id="home-1-trees"
        alt="Six Senses"
        src="/images/landing/tree-frame.webp"
        style={{
          opacity: 0.6,
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          overflow: "hidden",
        }}
      />
      <div
        id="home-1-logo"
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          width: "14rem",
          display: "grid",
          placeItems: "center",
          zIndex: 2,
        }}
      >
        <LogoSVG />
      </div>
      {/* <p
        id="home-1-text-1"
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          // width: "10",
          display: "grid",
          placeItems: "center",
          zIndex: 2,
          transform: "translateY(14rem)",
          color: "white",
          fontSize: "1.3rem",
          textAlign: "left",
          letterSpacing: "0.03rem",
        }}
      >
        Tap to explore
      </p> */}
    </div>
  )
}
