import { createRoot } from "react-dom/client"

import "./style.css"

import App from "./App"

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/serviceworker.js").then((registration) => {
    registration.addEventListener("updatefound", () => {
      const newWorker = registration.installing
      newWorker.addEventListener("statechange", () => {
        if (
          newWorker.state === "installed" &&
          navigator.serviceWorker.controller
        ) {
          if (confirm("New content is available. Reload to update?")) {
            window.location.reload()
          }
        }
      })
    })
  })
}

// import { SocketIOProvider } from "./providers/sockets"

const container: HTMLElement = document.getElementById("root")
const root = createRoot(container!)

// root.render(<SocketIOProvider url={`http://127.0.0.1:8080`}><App/></SocketIOProvider>)
root.render(<App />)
