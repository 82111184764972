import LogoSVG from "@components/Nav/LogoSVG"

export default function HomeScreen2() {
  return (
    <div
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        height: "100%",
        width: "100%",
        display: "grid",
        placeItems: "center",
        overflow: "hidden",
        backgroundColor: "black",
      }}
    >
      <div
        id="home-2-overlay"
        style={{
          visibility: "hidden",
          opacity: 0,
          zIndex: 3,
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          height: "40vh",
          placeSelf: "start center",
          width: "100%",
          background:
            "linear-gradient(180deg, rgba(22, 21, 21, 0.37) 27.49%, rgba(102, 102, 102, 0.00) 100%)",
        }}
      />
      <div
        id="home-2-overlay"
        style={{
          visibility: "hidden",
          opacity: 0,
          zIndex: 3,
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          height: "40vh",
          placeSelf: "end center",
          width: "100%",
          background:
            "linear-gradient(0deg, rgba(22, 21, 21, 0.37) 27.49%, rgba(102, 102, 102, 0.00) 100%)",
        }}
      />
      <video
        id="home-2-video"
        autoPlay
        playsInline
        muted
        loop
        style={{
          display: "none",
          visibility: "hidden",
          opacity: 0,
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          overflow: "hidden",
        }}
      >
        <source src="/videos/activity.mp4" type="video/mp4" />
      </video>
      <p
        id="home-2-text1"
        style={{
          visibility: "hidden",
          opacity: 0,
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          fontSize: "4rem",
          color: "white",
          zIndex: 2,
          // overflow: "hidden",
        }}
      >
        An Enchanting Estate
      </p>
      <p
        id="home-2-text2"
        style={{
          visibility: "hidden",
          opacity: 0,
          width: "50%",
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          fontSize: "2.2rem",
          color: "white",
          zIndex: 2,
          // overflow: "hidden",
          textAlign: "center",
        }}
      >
        Secluded in the tranquillity of the Sologne forest awaits an enchanting
        1,400-acre private estate where timeless woodland beauty meets vibrant
        social-house bonhomie.
      </p>
    </div>
  )
}
