export default function GalleryBackground() {
  return (
    <div
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        height: "100%",
        width: "100%",
        display: "grid",
        placeItems: "center",
        backgroundColor: "black",
        zIndex: -1,
        overflow: "hidden",
      }}
    >
      <img
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
          objectPosition: "center",
          filter: "blur(10px)",
          opacity: 0.5,
          transform: "scale(1.03)",
          overflow: "hidden",
        }}
        alt="SIX SENSES"
        src={`/renders/6 Bed XL.webp`}
      />
    </div>
  )
}
