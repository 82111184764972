import { useStore } from "@state/store"

export default function TransportSVG() {
  const activeMapIndex = useStore((state) => state.activeMapIndex)
  const pos = [
    ["1315", "500"],
    ["940", "310"],
    ["615", "565"],
    ["450", "685"],
    ["100", "820"],
  ]

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1515 1080"
      height="100%"
      width="100%"
    >
      <g id="b">
        <g
          id="38"
          className={Number(38) === Number(activeMapIndex) ? "bouncing" : ""}
          data-group="target"
        >
          <g id="d">
            <ellipse
              id="e"
              cx="861.98"
              cy="418.85"
              rx="9.73"
              ry="9.88"
              fill="#c99f42"
            />
            <g>
              <path
                d="M861.7,414.86l-2.75,3.01v.06c.18-.02,.31-.03,.4-.03,.71,0,1.27,.18,1.69,.53s.63,.83,.63,1.44c0,.47-.16,.96-.47,1.49-.31,.52-.81,.97-1.5,1.33-.69,.37-1.57,.55-2.63,.55-.37,0-.76-.03-1.18-.08v-2.29h.07c.6,.48,1.18,.85,1.75,1.09s1.06,.36,1.48,.36,.78-.08,1.09-.26c.3-.17,.46-.47,.46-.9,0-.48-.23-.87-.7-1.17-.46-.31-1.24-.46-2.33-.46h-.38l-.05-.09,2.59-2.97h-2.5c-.33,0-.6,.1-.81,.29-.21,.19-.37,.51-.48,.95h-.07v-2.86l.06-.08h5.64v.06Z"
                fill="#0d294a"
              />
              <path
                d="M867.99,422.29c-.38,.39-.83,.68-1.34,.87-.51,.19-.96,.28-1.33,.28-.4,0-.83-.07-1.32-.19-.49-.13-.91-.34-1.26-.65-.36-.3-.54-.69-.54-1.17,0-.36,.1-.68,.3-.97,.2-.29,.46-.52,.79-.69,.33-.17,.68-.27,1.06-.31-.54-.35-.96-.71-1.26-1.07-.3-.36-.45-.81-.45-1.35,0-.59,.19-1.07,.56-1.42,.37-.36,.8-.6,1.29-.75,.48-.14,.91-.21,1.28-.21,.68,0,1.27,.16,1.75,.47s.73,.76,.73,1.35c0,.39-.14,.76-.42,1.1-.28,.34-.72,.63-1.31,.87l.32,.2c.37,.23,.67,.44,.9,.62,.23,.18,.43,.4,.59,.67s.24,.58,.24,.93c0,.56-.19,1.03-.57,1.42Zm-1.53,.48c.21-.17,.31-.43,.31-.77,0-.34-.14-.66-.38-.95-.25-.29-.61-.59-1.09-.9l-.74-.53c-.15,.13-.27,.31-.36,.56-.09,.25-.14,.54-.14,.85,0,.7,.16,1.21,.49,1.52,.32,.31,.7,.47,1.13,.47,.3,0,.56-.08,.77-.26Zm-1.92-7.43c-.18,.18-.27,.42-.27,.72,0,.36,.12,.67,.35,.93,.23,.26,.59,.55,1.07,.87l.51,.36c.28-.5,.42-.96,.42-1.38,0-.54-.13-.98-.39-1.3-.26-.32-.59-.48-.98-.48-.3,0-.53,.09-.71,.27Z"
                fill="#0d294a"
              />
            </g>
          </g>
        </g>

        <g
          id="39"
          className={Number(39) === Number(activeMapIndex) ? "bouncing" : ""}
          data-group="target"
        >
          <g id="g">
            <ellipse
              id="h"
              cx="683.98"
              cy="571.74"
              rx="9.73"
              ry="9.88"
              fill="#c99f42"
            />
            <g>
              <path
                d="M684.07,567.82l-2.75,3.01v.06c.18-.02,.31-.03,.4-.03,.71,0,1.27,.18,1.69,.53s.63,.83,.63,1.44c0,.47-.16,.96-.47,1.49-.31,.52-.81,.97-1.5,1.33-.69,.37-1.57,.55-2.63,.55-.37,0-.76-.03-1.18-.08v-2.29h.07c.6,.48,1.18,.85,1.75,1.09s1.06,.36,1.48,.36,.78-.08,1.09-.26c.3-.17,.46-.47,.46-.9,0-.48-.23-.87-.7-1.17-.46-.31-1.24-.46-2.33-.46h-.38l-.05-.09,2.59-2.97h-2.5c-.33,0-.6,.1-.81,.29-.21,.19-.37,.51-.48,.95h-.07v-2.86l.06-.08h5.64v.06Z"
                fill="#0d294a"
              />
              <path
                d="M689.13,568.06c.48,.28,.86,.68,1.16,1.2s.44,1.15,.44,1.87c0,.9-.22,1.72-.67,2.46-.44,.74-1.04,1.36-1.79,1.85-.75,.49-1.58,.84-2.47,1.04l-.05-.09c.77-.28,1.41-.75,1.92-1.44,.51-.68,.85-1.4,1.02-2.16l-.06-.04c-.25,.28-.55,.51-.92,.68-.37,.17-.65,.25-.85,.25-.4,0-.78-.11-1.14-.34-.36-.23-.65-.55-.87-.98-.22-.43-.33-.93-.33-1.5,0-.63,.14-1.18,.42-1.67,.28-.49,.66-.87,1.13-1.14,.47-.27,.99-.41,1.55-.41,.53,0,1.03,.14,1.5,.41Zm-2.87,1.14c0,.29,.05,.69,.14,1.2,.16,.79,.38,1.4,.67,1.84,.29,.44,.62,.64,.98,.6,.44-.06,.65-.43,.65-1.13,0-.35-.06-.79-.17-1.33-.16-.78-.37-1.37-.63-1.77s-.56-.6-.89-.6c-.5,0-.75,.4-.75,1.19Z"
                fill="#0d294a"
              />
            </g>
          </g>
        </g>

        <g
          id="40"
          className={Number(40) === Number(activeMapIndex) ? "bouncing" : ""}
          data-group="target"
        >
          <g id="j">
            <ellipse
              id="k"
              cx="1026.08"
              cy="335.07"
              rx="9.73"
              ry="9.88"
              fill="#c99f42"
            />
            <g>
              <path
                d="M1024.3,336.73h1.59v.23h-1.59v1.2l.73,.63v.08h-3.49v-.08l.73-.63v-1.2h-2.91v-.16l2.87-6.39h2.5v.08l-5.02,6.25h2.55v-2.07l1.92-1.84h.1v3.91Z"
                fill="#0d294a"
              />
              <path
                d="M1027.83,338.46c-.51-.38-.91-.91-1.18-1.57-.28-.67-.41-1.39-.41-2.19,0-1.12,.22-2.01,.65-2.68s.91-1.13,1.42-1.39c.52-.26,.92-.4,1.21-.4,.68,0,1.28,.19,1.79,.58s.9,.92,1.18,1.58,.41,1.4,.41,2.21c0,1.1-.22,1.98-.65,2.64s-.91,1.12-1.42,1.38c-.52,.26-.92,.4-1.21,.4-.67,0-1.27-.19-1.78-.58Zm3.29-.63c.11-.27,.17-.63,.17-1.07,0-.71-.14-1.55-.42-2.52-.31-1.03-.67-1.83-1.07-2.41s-.81-.87-1.22-.87c-.07,0-.13,0-.16,.01-.19,.06-.34,.22-.46,.48-.12,.26-.17,.61-.17,1.07,0,.64,.12,1.39,.35,2.26,.3,1.08,.67,1.94,1.1,2.59s.86,.98,1.28,.98c.07,0,.11,0,.15-.01,.19-.06,.34-.22,.46-.5Z"
                fill="#0d294a"
              />
            </g>
          </g>
        </g>

        <g
          id="41"
          className={Number(41) === Number(activeMapIndex) ? "bouncing" : ""}
          data-group="target"
        >
          <g id="m">
            <ellipse
              id="n"
              cx="441.02"
              cy="732.89"
              rx="9.73"
              ry="9.88"
              fill="#c99f42"
            />
            <g>
              <path
                d="M440.43,734.56h1.59v.23h-1.59v1.2l.73,.63v.08h-3.49v-.08l.73-.63v-1.2h-2.91v-.16l2.87-6.39h2.5v.08l-5.02,6.25h2.55v-2.07l1.92-1.84h.1v3.91Z"
                fill="#0d294a"
              />
              <path
                d="M445.16,728.49v7.49l.72,.63v.08h-3.49v-.08l.73-.63v-6.66l-.94-.14v-.08l2.91-1.03h.07v.4Z"
                fill="#0d294a"
              />
            </g>
          </g>
        </g>

        <g
          id="42"
          className={Number(42) === Number(activeMapIndex) ? "bouncing" : ""}
          data-group="target"
        >
          <g id="p">
            <ellipse
              id="q"
              cx="1480.4"
              cy="509.39"
              rx="9.73"
              ry="9.88"
              fill="#c99f42"
            />
            <g>
              <path
                d="M1478.53,511.05h1.59v.23h-1.59v1.2l.73,.63v.08h-3.49v-.08l.73-.63v-1.2h-2.91v-.16l2.87-6.39h2.5v.08l-5.02,6.25h2.55v-2.07l1.92-1.84h.1v3.91Z"
                fill="#0d294a"
              />
              <path
                d="M1486.24,510.21v2.9l-.07,.08h-5.81v-.08l1.95-2.51c.61-.8,1.06-1.49,1.37-2.06,.3-.57,.46-1.14,.46-1.72,0-.48-.09-.86-.27-1.14-.18-.28-.44-.43-.78-.43-.49,0-.74,.41-.74,1.22,0,.33,.04,.71,.12,1.15l-2.16,.63-.04-.05c.63-1.32,1.28-2.26,1.93-2.82,.65-.56,1.26-.84,1.83-.84,.63,0,1.15,.17,1.56,.5s.61,.81,.61,1.42c0,.73-.27,1.42-.8,2.08-.53,.66-1.28,1.28-2.25,1.86l-1.2,1.03v.06h2.84c.36,0,.65-.1,.86-.29s.38-.52,.51-.98h.07Z"
                fill="#0d294a"
              />
            </g>
          </g>
        </g>

        <g
          id="43"
          className={Number(43) === Number(activeMapIndex) ? "bouncing" : ""}
          data-group="target"
        >
          <g id="s">
            <ellipse
              id="t"
              cx="239.15"
              cy="769.5"
              rx="9.73"
              ry="9.88"
              fill="#c99f42"
            />
            <g>
              <path
                d="M237.49,771.54h1.59v.23h-1.59v1.2l.73,.63v.08h-3.49v-.08l.73-.63v-1.2h-2.91v-.16l2.87-6.39h2.5v.08l-5.02,6.25h2.55v-2.07l1.92-1.84h.1v3.91Z"
                fill="#0d294a"
              />
              <path
                d="M245.42,765.28l-2.75,3.01v.06c.18-.02,.31-.03,.4-.03,.71,0,1.27,.18,1.69,.53s.63,.83,.63,1.44c0,.47-.16,.96-.47,1.49-.31,.52-.81,.97-1.5,1.33-.69,.37-1.57,.55-2.63,.55-.37,0-.76-.03-1.18-.08v-2.29h.07c.6,.48,1.18,.85,1.75,1.09s1.06,.36,1.48,.36,.78-.08,1.09-.26c.3-.17,.46-.47,.46-.9,0-.48-.23-.87-.7-1.17-.46-.31-1.24-.46-2.33-.46h-.38l-.05-.09,2.59-2.97h-2.5c-.33,0-.6,.1-.81,.29-.21,.19-.37,.51-.48,.95h-.07v-2.86l.06-.08h5.64v.06Z"
                fill="#0d294a"
              />
            </g>
          </g>
        </g>

        <g
          id="44"
          className={Number(44) === Number(activeMapIndex) ? "bouncing" : ""}
          data-group="target"
        >
          <g id="v">
            <ellipse
              id="w"
              cx="300.4"
              cy="713.02"
              rx="9.73"
              ry="9.88"
              fill="#c99f42"
            />
            <g>
              <path
                d="M298.74,715.06h1.59v.23h-1.59v1.2l.73,.63v.08h-3.49v-.08l.73-.63v-1.2h-2.91v-.16l2.87-6.39h2.5v.08l-5.02,6.25h2.55v-2.07l1.92-1.84h.1v3.91Z"
                fill="#0d294a"
              />
              <path
                d="M305.32,715.06h1.59v.23h-1.59v1.2l.73,.63v.08h-3.49v-.08l.73-.63v-1.2h-2.91v-.16l2.87-6.39h2.5v.08l-5.02,6.25h2.55v-2.07l1.92-1.84h.1v3.91Z"
                fill="#0d294a"
              />
            </g>
          </g>
        </g>

        <g
          id="45"
          className={Number(45) === Number(activeMapIndex) ? "bouncing" : ""}
          data-group="target"
        >
          <g id="y">
            <ellipse
              id="a`"
              cx="1169.03"
              cy="349.94"
              rx="9.73"
              ry="9.88"
              fill="#c99f42"
            />
            <g>
              <path
                d="M1167.37,351.99h1.59v.23h-1.59v1.2l.73,.63v.08h-3.49v-.08l.73-.63v-1.2h-2.91v-.16l2.87-6.39h2.5v.08l-5.02,6.25h2.55v-2.07l1.92-1.84h.1v3.91Z"
                fill="#0d294a"
              />
              <path
                d="M1172.17,348.79c.73,.16,1.31,.33,1.72,.52,.42,.19,.72,.42,.91,.71,.19,.28,.28,.65,.28,1.09,0,.42-.12,.85-.37,1.31-.25,.46-.73,.86-1.46,1.21-.73,.35-1.76,.53-3.09,.53-.16,0-.43,0-.83-.03v-2.31h.06c.2,.19,.5,.4,.92,.63,.42,.23,.86,.42,1.32,.58s.87,.23,1.21,.23c.44,0,.79-.09,1.03-.28,.24-.18,.36-.44,.36-.78s-.15-.63-.46-.86c-.3-.23-.84-.45-1.6-.66l-2.7-.66,.99-4.37h2.75c.46,0,.81-.05,1.06-.15,.25-.1,.43-.28,.54-.54h.1v2.36l-.07,.06h-4.5l-.22,.99,2.05,.41Z"
                fill="#0d294a"
              />
            </g>
          </g>
        </g>

        <g
          id="46"
          className={Number(46) === Number(activeMapIndex) ? "bouncing" : ""}
          data-group="target"
        >
          <g id="ab">
            <ellipse
              id="ac"
              cx="504.82"
              cy="423.43"
              rx="9.73"
              ry="9.88"
              fill="#c99f42"
            />
            <g>
              <path
                d="M503.16,425.48h1.59v.23h-1.59v1.2l.73,.63v.08h-3.49v-.08l.73-.63v-1.2h-2.91v-.16l2.87-6.39h2.5v.08l-5.02,6.25h2.55v-2.07l1.92-1.84h.1v3.91Z"
                fill="#0d294a"
              />
              <path
                d="M506.61,427.32c-.48-.28-.86-.68-1.16-1.2s-.44-1.15-.44-1.87c0-.9,.22-1.72,.67-2.46,.44-.74,1.04-1.36,1.79-1.85s1.58-.84,2.47-1.04l.04,.09c-.77,.28-1.41,.75-1.92,1.44s-.85,1.4-1.02,2.16l.05,.04c.25-.28,.55-.51,.92-.68,.37-.17,.65-.25,.86-.25,.4,0,.78,.11,1.14,.34,.36,.23,.65,.55,.87,.98,.22,.43,.33,.93,.33,1.5,0,.63-.14,1.18-.42,1.67-.28,.49-.66,.87-1.13,1.14-.47,.27-.99,.41-1.55,.41-.53,0-1.03-.14-1.5-.41Zm2.87-1.15c0-.29-.05-.69-.14-1.19-.16-.79-.38-1.4-.68-1.84-.3-.44-.62-.64-.97-.6-.44,.06-.65,.43-.65,1.13,0,.35,.06,.79,.17,1.33,.16,.78,.37,1.37,.63,1.77s.56,.6,.89,.6c.5,0,.75-.4,.75-1.2Z"
                fill="#0d294a"
              />
            </g>
          </g>
        </g>
        <image
          className="draw"
          style={{ display: "inline", overflow: "visible" }}
          x="100"
          y="600"
          width="200"
          xlinkHref={`/images/maps/le-loire/illustrations/plane.webp`}
        />

        <image
          className="draw"
          style={{ display: "inline", overflow: "visible" }}
          width="300"
          x="530"
          y="320"
          xlinkHref={`/images/maps/le-loire/illustrations/train.webp`}
        />

        <image
          className="draw"
          style={{ display: "inline", overflow: "visible" }}
          x="1050"
          y="100"
          width="400"
          xlinkHref={`/images/maps/le-loire/illustrations/airport.webp`}
        />
      </g>
    </svg>
  )
}
