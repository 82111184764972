import { useStore } from "@state/store"
import HeartSVG from "./HeartSVG"
import { globalStyles } from "@data/globalStyles"

export default function FavouritesButton() {
  const activeID = useStore((state) => state.activeID)
  const favourites = useStore((state) => state.favourites)
  const syncState = useStore((state) => state.syncState)
  const isFavourite = favourites.includes(activeID)

  return (
    <button
      onClick={() => {
        syncState(
          "favourites",
          isFavourite
            ? favourites.filter((f) => f !== activeID)
            : [...favourites, activeID]
        )
      }}
      style={{
        pointerEvents: "auto",
        cursor: "pointer",
        display: "grid",
        gridAutoFlow: "column",
        placeSelf: "center center",
        placeItems: "center center",
        columnGap: "0.8rem",
        zIndex: 5,
        transition: "width 0.8s",
      }}
    >
      <HeartSVG $isFavourite={isFavourite} style={{ width: "2rem" }} />
      <span
        style={{
          color: globalStyles.col.blue,
          fontFamily: "Canto Roman",
          fontSize: "1rem",
          lineHeight: "1.1rem",
          letterSpacing: "0.02rem",
        }}
      >
        {isFavourite ? "Remove from favourites" : "Add to favourites"}
      </span>
    </button>
  )
}
