import { globalStyles } from "@data/globalStyles"
import CloseSVG from "./CloseSVG"
import { residencesData } from "@data/residencesData"
import { useStore } from "@state/store"
import { motion, AnimatePresence } from "framer-motion"

const aspects = {
  N: "North",
  E: "East",
  S: "South",
  W: "West",
}

const pageVariantsTablet = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

export default function FavouritesCard({ favourite }) {
  //
  const syncState = useStore((state) => state.syncState)
  const favourites = useStore((state) => state.favourites)
  const { unitType, bathrooms, aspect, plotSqm, livingSqm } =
    residencesData.find((v) => v.plot === favourite)
  //
  if (!favourite) return null
  return (
    <motion.div
      variants={pageVariantsTablet}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.8 }}
      style={{
        cursor: "pointer",
        width: "27rem",
        height: "100%",
        borderRadius: "2rem",
        backgroundColor: "white",
        color: globalStyles.col.blue,
        display: "grid",
        placeItems: "center",
        letterSpacing: "0.04rem",
        padding: "1.4rem",
        overflow: "hidden",
        marginRight: "2rem",
      }}
      onClick={(e) => {
        syncState("activeID", Number(favourite))
        syncState("residencePopup", true)
      }}
    >
      <CloseSVG
        onClick={(e) => {
          e.stopPropagation()
          syncState(
            "favourites",
            favourites.filter((f) => f !== favourite)
          )
        }}
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          placeSelf: "start",
          fontSize: "2.6rem",
          lineHeight: "2.6rem",
          width: "2.8rem",
        }}
      />
      <p
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          placeSelf: "start end",
          fontSize: "6.6rem",
          lineHeight: "0rem",
          paddingRight: "2rem",
          transform: "translateY(-2vh)",
        }}
      >
        <span
          style={{
            fontSize: "2.4rem",
            opacity: 0.5,
            lineHeight: "1.6rem",
            marginRight: "1rem",
          }}
        >
          Plot
        </span>
        {favourite}
      </p>
      <div
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          display: "grid",
          placeItems: "center",
          paddingTop: "3rem",
        }}
      >
        <img
          alt=""
          src={`/floor-plans/${unitType}-GROUND.webp`}
          style={{
            height: "80%",
            width: "80%",
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: "contain",
            overflow: "hidden",
          }}
        />
      </div>
      <p
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          placeSelf: "end end",
          fontSize: "1rem",
          lineHeight: "2rem",
          paddingRight: "2rem",
          paddingBottom: "0.4rem",
          textAlign: "right",
          color: "#4e769b7e",
        }}
      >
        <span style={{ marginRight: "2rem" }}>
          Beds:
          <span
            style={{
              color: globalStyles.col.blue,
              fontSize: "1.6rem",
              marginLeft: "0.6rem",
            }}
          >
            {Number(unitType.split(" ")[0])?.toLocaleString("en-US")}
          </span>
        </span>
        <span style={{}}>
          Orientation:
          <span
            style={{
              color: globalStyles.col.blue,
              fontSize: "1.6rem",
              marginLeft: "0.6rem",
            }}
          >
            {aspect?.map((a) => aspects[a]).join(" / ")}
          </span>
        </span>
        <br />
        <span style={{ marginRight: "2rem" }}>
          Plot:
          <span
            style={{
              color: globalStyles.col.blue,
              fontSize: "1.6rem",
              marginLeft: "0.6rem",
              marginRight: "0.3rem",
            }}
          >
            {plotSqm?.toLocaleString("en-US")}
          </span>
          sqm
        </span>
        <span style={{}}>
          Living:
          <span
            style={{
              color: globalStyles.col.blue,
              fontSize: "1.6rem",
              marginLeft: "0.6rem",
              marginRight: "0.3rem",
            }}
          >
            {livingSqm?.toLocaleString("en-US")}
          </span>
          sqm
        </span>
      </p>
    </motion.div>
  )
}
