import { useStore } from "@state/store"

export default function ActivitiesSVG() {
  const activeMapIndex = useStore((state) => state.activeMapIndex)
  const pos = [
    ["1315", "500"],
    ["940", "310"],
    ["615", "565"],
    ["450", "685"],
    ["100", "820"],
  ]

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1515 1080"
      height="100%"
      width="100%"
    >
      <g id="b">
        <g
          id="30"
          className={Number(30) === Number(activeMapIndex) ? "bouncing" : ""}
          data-group="target"
        >
          <g id="d">
            <ellipse
              id="e"
              cx="1016.28"
              cy="475.36"
              rx="9.73"
              ry="9.88"
              fill="#c99f42"
            />
            <g>
              <path
                d="M1016.07,471.11l-2.75,3.01v.06c.18-.02,.31-.03,.4-.03,.71,0,1.27,.18,1.69,.53,.42,.35,.63,.83,.63,1.44,0,.47-.16,.96-.47,1.49-.31,.52-.81,.97-1.5,1.33-.69,.37-1.57,.55-2.63,.55-.37,0-.76-.03-1.18-.08v-2.29h.07c.6,.48,1.18,.85,1.75,1.09s1.06,.36,1.48,.36,.78-.08,1.09-.26c.3-.17,.46-.47,.46-.9,0-.48-.23-.87-.7-1.17-.46-.31-1.24-.46-2.33-.46h-.38l-.05-.09,2.59-2.97h-2.5c-.33,0-.6,.1-.81,.29-.21,.19-.37,.51-.48,.95h-.07v-2.86l.06-.08h5.64v.06Z"
                fill="#0d294a"
              />
              <path
                d="M1018.26,479.1c-.51-.38-.91-.91-1.18-1.57-.28-.67-.41-1.39-.41-2.19,0-1.12,.22-2.01,.65-2.68,.43-.66,.91-1.13,1.42-1.39,.52-.26,.92-.4,1.21-.4,.68,0,1.28,.19,1.79,.58s.9,.92,1.18,1.58,.41,1.4,.41,2.21c0,1.1-.22,1.98-.65,2.64s-.91,1.12-1.42,1.38c-.52,.26-.92,.4-1.21,.4-.67,0-1.27-.19-1.78-.58Zm3.29-.63c.12-.27,.17-.63,.17-1.07,0-.71-.14-1.55-.42-2.52-.31-1.03-.67-1.83-1.07-2.41-.4-.58-.81-.87-1.22-.87-.07,0-.13,0-.16,.01-.19,.06-.34,.22-.46,.48-.12,.26-.17,.61-.17,1.07,0,.64,.12,1.39,.35,2.26,.3,1.08,.67,1.94,1.1,2.59s.86,.98,1.28,.98c.07,0,.12,0,.15-.01,.19-.06,.34-.22,.46-.5Z"
                fill="#0d294a"
              />
            </g>
          </g>
        </g>

        <g
          id="31"
          className={Number(31) === Number(activeMapIndex) ? "bouncing" : ""}
          data-group="target"
        >
          <g id="g">
            <ellipse
              id="h"
              cx="1345.45"
              cy="449.63"
              rx="9.73"
              ry="9.88"
              fill="#c99f42"
            />
            <g>
              <path
                d="M1346.44,445.42l-2.75,3.01v.06c.18-.02,.31-.03,.4-.03,.71,0,1.27,.18,1.69,.53,.42,.35,.63,.83,.63,1.44,0,.47-.16,.96-.47,1.49-.31,.52-.81,.97-1.5,1.33-.69,.37-1.57,.55-2.63,.55-.37,0-.76-.03-1.18-.08v-2.29h.07c.6,.48,1.18,.85,1.75,1.09s1.06,.36,1.48,.36,.78-.08,1.09-.26c.3-.17,.46-.47,.46-.9,0-.48-.23-.87-.7-1.17-.46-.31-1.24-.46-2.33-.46h-.38l-.05-.09,2.59-2.97h-2.5c-.33,0-.6,.1-.81,.29-.21,.19-.37,.51-.48,.95h-.07v-2.86l.06-.08h5.64v.06Z"
                fill="#0d294a"
              />
              <path
                d="M1349.83,445.63v7.49l.71,.63v.08h-3.49v-.08l.73-.63v-6.66l-.94-.14v-.08l2.91-1.03h.07v.4Z"
                fill="#0d294a"
              />
            </g>
          </g>
        </g>

        <g
          id="32"
          className={Number(32) === Number(activeMapIndex) ? "bouncing" : ""}
          data-group="target"
        >
          <g id="j">
            <ellipse
              id="k"
              cx="1069.63"
              cy="347.21"
              rx="9.73"
              ry="9.88"
              fill="#c99f42"
            />
            <g>
              <path
                d="M1069.73,343.14l-2.75,3.01v.06c.18-.02,.31-.03,.39-.03,.71,0,1.27,.18,1.69,.53,.42,.35,.63,.83,.63,1.44,0,.47-.16,.96-.47,1.49-.31,.52-.81,.97-1.5,1.33-.69,.37-1.57,.55-2.63,.55-.37,0-.76-.03-1.18-.08v-2.29h.07c.6,.48,1.18,.85,1.75,1.09s1.06,.36,1.48,.36,.78-.08,1.08-.26c.3-.17,.46-.47,.46-.9,0-.48-.23-.87-.7-1.17-.46-.31-1.24-.46-2.33-.46h-.38l-.05-.09,2.59-2.97h-2.5c-.33,0-.6,.1-.81,.29s-.37,.51-.47,.95h-.07v-2.86l.06-.08h5.64v.06Z"
                fill="#0d294a"
              />
              <path
                d="M1076.09,348.56v2.9l-.07,.08h-5.81v-.08l1.95-2.51c.61-.8,1.06-1.49,1.37-2.06,.3-.57,.46-1.14,.46-1.72,0-.48-.09-.86-.27-1.14-.18-.28-.44-.43-.78-.43-.49,0-.74,.41-.74,1.22,0,.33,.04,.71,.12,1.15l-2.16,.63-.04-.05c.63-1.32,1.28-2.26,1.93-2.82,.65-.56,1.26-.84,1.83-.84,.63,0,1.15,.17,1.56,.5s.61,.81,.61,1.42c0,.73-.27,1.42-.8,2.08-.53,.66-1.28,1.28-2.24,1.86l-1.2,1.03v.06h2.84c.36,0,.65-.1,.86-.29,.21-.2,.38-.52,.51-.98h.07Z"
                fill="#0d294a"
              />
            </g>
          </g>
        </g>

        <g
          id="33"
          className={Number(33) === Number(activeMapIndex) ? "bouncing" : ""}
          data-group="target"
        >
          <g id="m">
            <ellipse
              id="n"
              cx="220.43"
              cy="773.63"
              rx="9.73"
              ry="9.88"
              fill="#c99f42"
            />
            <g>
              <path
                d="M220.53,769.88l-2.75,3.01v.06c.18-.02,.31-.03,.4-.03,.71,0,1.27,.18,1.69,.53s.63,.83,.63,1.44c0,.47-.16,.96-.47,1.49-.31,.52-.81,.97-1.5,1.33-.69,.37-1.57,.55-2.63,.55-.37,0-.76-.03-1.18-.08v-2.29h.07c.6,.48,1.18,.85,1.75,1.09s1.06,.36,1.48,.36,.78-.08,1.09-.26c.3-.17,.46-.47,.46-.9,0-.48-.23-.87-.7-1.17-.46-.31-1.24-.46-2.33-.46h-.38l-.05-.09,2.59-2.97h-2.5c-.33,0-.6,.1-.81,.29-.21,.19-.37,.51-.48,.95h-.07v-2.86l.06-.08h5.64v.06Z"
                fill="#0d294a"
              />
              <path
                d="M227.11,769.88l-2.75,3.01v.06c.18-.02,.31-.03,.4-.03,.71,0,1.27,.18,1.69,.53s.63,.83,.63,1.44c0,.47-.16,.96-.47,1.49-.31,.52-.81,.97-1.5,1.33-.69,.37-1.57,.55-2.63,.55-.37,0-.76-.03-1.18-.08v-2.29h.07c.6,.48,1.18,.85,1.75,1.09s1.06,.36,1.48,.36,.78-.08,1.09-.26c.3-.17,.46-.47,.46-.9,0-.48-.23-.87-.7-1.17-.46-.31-1.24-.46-2.33-.46h-.38l-.05-.09,2.59-2.97h-2.5c-.33,0-.6,.1-.81,.29-.21,.19-.37,.51-.48,.95h-.07v-2.86l.06-.08h5.64v.06Z"
                fill="#0d294a"
              />
            </g>
          </g>
        </g>

        <g
          id="34"
          className={Number(34) === Number(activeMapIndex) ? "bouncing" : ""}
          data-group="target"
        >
          <g id="p">
            <ellipse
              id="q"
              cx="808.88"
              cy="630.07"
              rx="9.73"
              ry="9.88"
              fill="#c99f42"
            />
            <g>
              <path
                d="M808.66,625.82l-2.75,3.01v.06c.18-.02,.31-.03,.4-.03,.71,0,1.27,.18,1.69,.53s.63,.83,.63,1.44c0,.47-.16,.96-.47,1.49-.31,.52-.81,.97-1.5,1.33-.69,.37-1.57,.55-2.63,.55-.37,0-.76-.03-1.18-.08v-2.29h.07c.6,.48,1.18,.85,1.75,1.09s1.06,.36,1.48,.36,.78-.08,1.09-.26c.3-.17,.46-.47,.46-.9,0-.48-.23-.87-.7-1.17-.46-.31-1.24-.46-2.33-.46h-.38l-.05-.09,2.59-2.97h-2.5c-.33,0-.6,.1-.81,.29-.21,.19-.37,.51-.48,.95h-.07v-2.86l.06-.08h5.64v.06Z"
                fill="#0d294a"
              />
              <path
                d="M813.89,632.09h1.59v.23h-1.59v1.2l.73,.63v.08h-3.49v-.08l.73-.63v-1.2h-2.91v-.16l2.87-6.39h2.5v.08l-5.02,6.25h2.55v-2.07l1.92-1.84h.1v3.91Z"
                fill="#0d294a"
              />
            </g>
          </g>
        </g>

        <g
          id="35"
          className={Number(35) === Number(activeMapIndex) ? "bouncing" : ""}
          data-group="target"
        >
          <g id="s">
            <ellipse
              id="t"
              cx="783.2"
              cy="535.52"
              rx="9.73"
              ry="9.88"
              fill="#c99f42"
            />
            <g>
              <path
                d="M783,531.77l-2.75,3.01v.06c.18-.02,.31-.03,.4-.03,.71,0,1.27,.18,1.69,.53s.63,.83,.63,1.44c0,.47-.16,.96-.47,1.49-.31,.52-.81,.97-1.5,1.33-.69,.37-1.57,.55-2.63,.55-.37,0-.76-.03-1.18-.08v-2.29h.07c.6,.48,1.18,.85,1.75,1.09s1.06,.36,1.48,.36,.78-.08,1.09-.26c.3-.17,.46-.47,.46-.9,0-.48-.23-.87-.7-1.17-.46-.31-1.24-.46-2.33-.46h-.38l-.05-.09,2.59-2.97h-2.5c-.33,0-.6,.1-.81,.29-.21,.19-.37,.51-.48,.95h-.07v-2.86l.06-.08h5.64v.06Z"
                fill="#0d294a"
              />
              <path
                d="M786.45,534.84c.73,.16,1.31,.33,1.72,.52,.42,.19,.72,.42,.91,.71,.19,.28,.28,.65,.28,1.09,0,.42-.12,.85-.37,1.31s-.73,.86-1.46,1.21c-.73,.35-1.76,.53-3.09,.53-.16,0-.43,0-.83-.03v-2.31h.06c.2,.19,.5,.4,.92,.63,.42,.23,.86,.42,1.32,.58,.46,.15,.87,.23,1.22,.23,.44,0,.79-.09,1.03-.28s.36-.44,.36-.78-.15-.63-.46-.86c-.3-.23-.84-.45-1.6-.66l-2.7-.66,.99-4.37h2.75c.46,0,.81-.05,1.06-.15s.43-.28,.54-.54h.1v2.36l-.07,.06h-4.5l-.22,.99,2.05,.41Z"
                fill="#0d294a"
              />
            </g>
          </g>
        </g>

        <g
          id="36"
          className={Number(36) === Number(activeMapIndex) ? "bouncing" : ""}
          data-group="target"
        >
          <g id="v">
            <ellipse
              id="w"
              cx="799.15"
              cy="669.94"
              rx="9.73"
              ry="9.88"
              fill="#c99f42"
            />
            <g>
              <path
                d="M799.24,665.83l-2.75,3.01v.06c.18-.02,.31-.03,.4-.03,.71,0,1.27,.18,1.69,.53s.63,.83,.63,1.44c0,.47-.16,.96-.47,1.49-.31,.52-.81,.97-1.5,1.33-.69,.37-1.57,.55-2.63,.55-.37,0-.76-.03-1.18-.08v-2.29h.07c.6,.48,1.18,.85,1.75,1.09s1.06,.36,1.48,.36,.78-.08,1.09-.26c.3-.17,.46-.47,.46-.9,0-.48-.23-.87-.7-1.17-.46-.31-1.24-.46-2.33-.46h-.38l-.05-.09,2.59-2.97h-2.5c-.33,0-.6,.1-.81,.29-.21,.19-.37,.51-.48,.95h-.07v-2.86l.06-.08h5.64v.06Z"
                fill="#0d294a"
              />
              <path
                d="M801.35,673.94c-.48-.28-.86-.68-1.16-1.2s-.44-1.15-.44-1.87c0-.9,.22-1.72,.67-2.46,.44-.74,1.04-1.36,1.79-1.85s1.58-.84,2.47-1.04l.04,.09c-.77,.28-1.41,.75-1.92,1.44s-.85,1.4-1.02,2.16l.05,.04c.25-.28,.55-.51,.92-.68,.37-.17,.65-.25,.86-.25,.4,0,.78,.11,1.14,.34,.36,.23,.65,.55,.87,.98,.22,.43,.33,.93,.33,1.5,0,.63-.14,1.18-.42,1.67-.28,.49-.66,.87-1.13,1.14-.47,.27-.99,.41-1.55,.41-.53,0-1.03-.14-1.5-.41Zm2.87-1.15c0-.29-.05-.69-.14-1.19-.16-.79-.38-1.4-.68-1.84-.3-.44-.62-.64-.97-.6-.44,.06-.65,.43-.65,1.13,0,.35,.06,.79,.17,1.33,.16,.78,.37,1.37,.63,1.77s.56,.6,.89,.6c.5,0,.75-.4,.75-1.2Z"
                fill="#0d294a"
              />
            </g>
          </g>
        </g>

        <g
          id="37"
          className={Number(37) === Number(activeMapIndex) ? "bouncing" : ""}
          data-group="target"
        >
          <g id="y">
            <ellipse
              id="a`"
              cx="601.43"
              cy="677.63"
              rx="9.73"
              ry="9.88"
              fill="#c99f42"
            />
            <g>
              <path
                d="M601.52,673.59l-2.75,3.01v.06c.18-.02,.31-.03,.4-.03,.71,0,1.27,.18,1.69,.53s.63,.83,.63,1.44c0,.47-.16,.96-.47,1.49-.31,.52-.81,.97-1.5,1.33-.69,.37-1.57,.55-2.63,.55-.37,0-.76-.03-1.18-.08v-2.29h.07c.6,.48,1.18,.85,1.75,1.09s1.06,.36,1.48,.36,.78-.08,1.09-.26c.3-.17,.46-.47,.46-.9,0-.48-.23-.87-.7-1.17-.46-.31-1.24-.46-2.33-.46h-.38l-.05-.09,2.59-2.97h-2.5c-.33,0-.6,.1-.81,.29-.21,.19-.37,.51-.48,.95h-.07v-2.86l.06-.08h5.64v.06Z"
                fill="#0d294a"
              />
              <path
                d="M602.09,673.53h5.86v.06l-.83,1.68-1.8,6.72h-2.48v-.08l4.05-6.69h-3.5c-.33,0-.6,.1-.81,.29-.21,.19-.37,.51-.48,.95h-.07v-2.86l.06-.08Z"
                fill="#0d294a"
              />
            </g>
          </g>
        </g>
        <image
          className="draw"
          style={{ display: "inline", overflow: "visible" }}
          width="361"
          x="1200"
          y="230"
          xlinkHref={`/images/maps/le-loire/illustrations/boat.webp`}
        />

        <image
          className="draw"
          style={{ display: "inline", overflow: "visible" }}
          width="200"
          x="920"
          y="460"
          xlinkHref={`/images/maps/le-loire/illustrations/balloon.webp`}
        />

        <image
          className="draw"
          style={{ display: "inline", overflow: "visible" }}
          width="261"
          x="615"
          y="670"
          xlinkHref={`/images/maps/le-loire/illustrations/vineyard.webp`}
        />

        <image
          className="draw"
          style={{ display: "inline", overflow: "visible" }}
          width="450"
          x="-80"
          y="750"
          xlinkHref={`/images/maps/le-loire/illustrations/bike.webp`}
        />
      </g>
    </svg>
  )
}
