import { useStore } from "@state/store"
import ShareSVG from "./ShareSVG"
import FavouritesCards from "./FavouritesCards"
import ResidencePopup from "@components/Residences/ResidencePopup"
import { useGSAP } from "@gsap/react"
import { gsap } from "gsap"
import ShareCard from "./ShareCard"
import { useEffect } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import ResetSVG from "./ResetSVG"

export default function Favourites() {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const favParam = searchParams.get("favs")
  const share = useStore((state) => state.share)
  const favourites = useStore((state) => state.favourites)
  const residencePopup = useStore((state) => state.residencePopup)
  const syncState = useStore((state) => state.syncState)

  useEffect(() => {
    if (
      favourites.length === 0 &&
      favParam &&
      location.pathname === "/favourites"
    ) {
      const favs = []
      favParam.split(",").map((i) => {
        if (Number(i) > 0 && Number(i) < 53) {
          favs.push(Number(i))
        }
      })
      syncState("favourites", favs)
    }
  }, [])

  //
  useGSAP(
    () => {
      gsap.to("#residencePopup", {
        autoAlpha: residencePopup ? 1 : 0,
        duration: 0.5,
        ease: "power2.inOut",
      })
    },
    { dependencies: [residencePopup] }
  )

  useGSAP(
    () => {
      if (favourites?.length === 0) return
      gsap.to("#resetFavs", {
        autoAlpha: favourites.length > 0 ? 1 : 0,
        duration: 0.5,
        ease: "power2.inOut",
      })
    },
    { dependencies: [favourites] }
  )

  useGSAP(
    () => {
      gsap.to("#sharePopup", {
        autoAlpha: share ? 1 : 0,
        duration: 0.5,
        ease: "power2.inOut",
      })
      gsap.to("#sharePopupHide", {
        autoAlpha: share ? 0 : 1,
        duration: 0.5,
        ease: "power2.inOut",
      })
    },
    { dependencies: [share] }
  )
  return (
    <div
      style={{
        backgroundColor: "black",
        height: "100%",
        width: "100%",
        position: "fixed",
        inset: 0,
        display: "grid",
        placeItems: "center",
        pointerEvents: "auto",
      }}
    >
      <img
        alt="Six Senses"
        src="/renders/SSLB_4B_cam12.webp"
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          objectFit: "cover",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          opacity: 0.8,
          filter: "blur(6px)",
          transform: "scale(1.1)",
          zIndex: 0,
        }}
      />
      {favourites?.length === 0 && (
        <p
          style={{
            gridColumn: "1 / -1",
            gridRow: "1 / -1",
            color: "white",
            zIndex: 10,
            fontSize: "2rem",
            textAlign: "center",
          }}
        >
          You have no favourites to view
          <br />
          <br />
          Add your favourites using the Residence Finder
        </p>
      )}
      {!(favParam && location.pathname === "/favourites") &&
        favourites?.length > 0 && (
          <ShareSVG
            onClick={() => {
              syncState("share", !share)
            }}
            style={{
              pointerEvents: "auto",
              cursor: "pointer",
              gridColumn: "1 / -1",
              gridRow: "1 / -1",
              width: "4rem",
              height: "4rem",
              placeSelf: "start end",
              margin: "4rem",
              zIndex: 2,
            }}
          />
        )}
      {!(favParam && location.pathname === "/favourites") &&
        favourites?.length > 0 && (
          <ResetSVG
            id="resetFavs"
            onClick={() => {
              syncState("favourites", [])
            }}
            style={{
              pointerEvents: "auto",
              cursor: "pointer",
              gridColumn: "1 / -1",
              gridRow: "1 / -1",
              width: "4rem",
              height: "4rem",
              placeSelf: "start end",
              margin: "4rem 4rem",
              transform: "translateX(-5rem)",
              zIndex: 2,
            }}
          />
        )}
      <FavouritesCards />
      <ResidencePopup favourites />
      <ShareCard />
    </div>
  )
}
