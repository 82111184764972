import React, { Suspense } from "react"
import styled from "styled-components"
import { a, useTransition } from "react-spring"
import { useSpring, animated } from "react-spring"
import { useStore } from "@state/store"
// import { anm } from "../transition/transition"
import { Canvas } from "@react-three/fiber"
import Sphere from "./components/Sphere"
import Camera from "./components/Camera"
import FlightLines from "./components/FlightLine"
import location from "../../../data/location"
import Destinations from "./components/Destinations"
import SideBar from "./components/SideBar"

function Globe({ camTarget, canClick }) {
  const anm = {
    from: { 
        opacity: 0,
        filter: "blur(0px)",
    },
    enter: { 
        opacity: 1,
        filter: "blur(0px)",
    },
    leave: { 
        opacity: 0,
        filter: "blur(0px)",
     }
}
  const mapFilter = useStore(s => s.mapFilter)
  const transition = useTransition(mapFilter === "Destination", anm)
  const backgroundColor = "transparent"
  const stylesSidebar = useSpring({
    opacity: !camTarget.flightLines ? 1 : 1
  })
  return transition(
    (styles, loaded) =>
      loaded && (
        <Wrapper style={{ ...styles, background: backgroundColor }}>
            <SideBar details={camTarget} />
          <Canvas camera={{ fov: 70 }}>
            <Camera
              rotate={0}
              enablePan={true}
              enableZoom={true}
              backforth={false}
              camTarget={camTarget}
              canClick={canClick}
            />
            <Suspense fallback={null}>
              <Sphere toolMesh={false} />
              <Destinations
                destinations={location.destination.see}
                camTargetName={camTarget.name}
              />
              <FlightLines camTarget={camTarget} />
            </Suspense>
          </Canvas>
        </Wrapper>
      )
  )
}

export default Globe

const Wrapper = styled(a.div)`
  width: 100%;
  height: 100%;
  display: flex;
`
