import Buttons from "./Buttons"
import FloorPlan from "./FloorPlan"
import PopupButtons from "./PopupButtons"
import Render from "./Render"

export default function ResidencePopup({ favourites = false }) {
  return (
    <div
      id="residencePopup"
      style={{
        opacity: 0,
        zIndex: 4,
        // ...(favourites && { position: "fixed", inset: 0, zIndex: 10 }),
        visibility: "hidden",
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        height: "100%",
        width: "100%",
        display: "grid",
        placeItems: "center",
        backgroundColor: "#EFEFEF",
        overflow: "hidden",
      }}
    >
      <FloorPlan />
      <Render />

      <PopupButtons />
    </div>
  )
}
