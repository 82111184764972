import React, { useRef } from "react"
import * as THREE from "three"
import { useTexture } from "@react-three/drei"
import map_url from "../../../../../public/images/textures/globe.jpg"
import ToolMesh from "./ToolMesh"
function Sphere({ toolMesh }) {
  const mesh = useRef()

  const map = useTexture(map_url)
  map.encoding = THREE.sRGBEncoding
  map.minFilter = THREE.LinearFilter

  return (
    <>
      <ToolMesh display={toolMesh} scale={1.31} label="drive" />
      <ToolMesh display={toolMesh} scale={1.45} label="flight" />
      <mesh
        rotation={[0, 340 * (Math.PI / 180), 0]}
        position-x={0}
        position-y={0}
        position-z={0}
        ref={mesh}
        scale={1.3}
        castShadow 
        receiveShadow 
        >
        <sphereGeometry args={[2, 100, 200]} />
        <meshBasicMaterial wireframe={false} toneMapped={false} map={map} />
      </mesh>
      <mesh
        rotation={[0, 340 * (Math.PI / 180), 0]}
        position-x={0}
        position-y={0}
        position-z={0}
        scale={1.3} 
      >
        <sphereGeometry args={[2.05, 100, 200]} /> 
        <meshBasicMaterial color={0x456B90} transparent={true} opacity={0.02} wireframe={true} />
      </mesh>
    </>
  )
}

export default Sphere
