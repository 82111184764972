import { useEffect } from "react"
import GalleryBackground from "./GalleryBackground"
import GalleryColumns from "./GalleryColumns"
import { GalleryPopup } from "./GalleryPopup"
import { useStore } from "@state/store"

export default function Gallery() {
  const syncState = useStore((state) => state.syncState)

  useEffect(() => {
    return () => {
      syncState("galleryPopup", false)
    }
  }, [])
  return (
    <>
      <div
        style={{
          position: "fixed",
          inset: 0,
          height: "100%",
          width: "100%",
          display: "grid",
          placeItems: "center",
        }}
      >
        <GalleryBackground />
        <GalleryColumns />
        <GalleryPopup />
      </div>
    </>
  )
}
