export default function CloseSVG({ fill = "#4E769B", ...props }) {
  return (
    <svg
      width="37"
      height="35"
      viewBox="0 0 37 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.74909 0.884972L0.484862 3.87704L18.7424 20.6123L36.9999 3.87703L33.7357 0.884971L18.7424 14.6282L3.74909 0.884972Z"
        fill={fill}
      />
      <path
        d="M33.7358 34.4441L37 31.4521L18.7425 14.7168L0.48495 31.4521L3.74917 34.4441L18.7425 20.7009L33.7358 34.4441Z"
        fill={fill}
      />
    </svg>
  )
}
