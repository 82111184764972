import Video from "@components/Video/Video"
import styled from "styled-components"

function VideoComponent() {
  return (
    <Wrapper>
      <Video />
    </Wrapper>
  )
}

export default VideoComponent

const Wrapper = styled.div`
  overflow: hidden;
  display: grid;
`
