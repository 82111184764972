export default function DownSVG({ fill = "#F1EFE7", ...props }) {
  return (
    <>
      <svg
        width="20"
        height="11"
        viewBox="0 0 20 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M2.36591 0.83248L0.720703 2.29995L9.92272 10.5078L19.1247 2.29995L17.4795 0.832479L9.92272 7.57288L2.36591 0.83248Z"
          fill={fill}
        />
      </svg>
    </>
  )
}
