import LogoSVG from "@components/Nav/LogoSVG"

export default function HomeScreen3() {
  return (
    <div
      style={{
        gridColumn: "1 / -1",
        gridRow: "1 / -1",
        height: "100%",
        width: "100%",
        display: "grid",
        placeItems: "center",
        overflow: "hidden",
        backgroundColor: "black",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        columnGap: "2rem",
        gridTemplateRows: "repeat(2, minmax(0, 1fr))",
      }}
    >
      <video
        id="home-3-video"
        autoPlay
        playsInline
        muted
        loop
        style={{
          display: "none",
          visibility: "hidden",
          opacity: 0,
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          overflow: "hidden",
        }}
      >
        <source src="/videos/nature.mp4" type="video/mp4" />
      </video>
      <p
        id="home-3-text1"
        style={{
          visibility: "hidden",
          opacity: 0,
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          fontSize: "4rem",
          color: "white",
          zIndex: 2,
          // overflow: "hidden",
        }}
      >
        Where the Forest Comes Alive
      </p>
      <p
        id="home-3-text2"
        style={{
          visibility: "hidden",
          opacity: 0,
          width: "50%",
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          fontSize: "2.2rem",
          color: "white",
          zIndex: 2,
          // overflow: "hidden",
          textAlign: "center",
        }}
      >
        The lush grounds of Les Bordes Estate offer endless diversions to
        capture the fancy of every member of the family, with pursuits ranging
        from the communal to the active to the blissfully serene.
      </p>
      <img
        id="home-3-img1"
        alt="Six Senses"
        src="/images/landing/lake_small.webp"
        style={{
          // visibility: "hidden",
          opacity: 0,
          height: "auto",
          width: "30rem",
          overflow: "hidden",
          objectFit: "cover",
          gridColumn: 2,
          gridRow: 1,
          placeSelf: "end start",
          zIndex: 1,
        }}
      />
      <img
        id="home-3-img2"
        alt="Six Senses"
        src="/images/landing/tennis_small.webp"
        style={{
          // visibility: "hidden",
          opacity: 0,
          height: "auto",
          width: "30rem",
          overflow: "hidden",
          objectFit: "cover",
          gridColumn: 1,
          gridRow: 2,
          placeSelf: "start end",
          zIndex: 1,
        }}
      />
    </div>
  )
}
