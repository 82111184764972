import Title from "./Title"
import Transform from "./Transform"
import LesBordesSVG from "./LesBordesSVG"
import LoireSVG from "./LoireSVG"
import Destination from "./Destination"
import MapsButtons from "./MapsButtons"
import { useStore } from "@state/store"

export default function Map() {
  const mapFilter = useStore((state) => state.mapFilter)

  return (
    <div
      style={{
        height: "100%",
        width: mapFilter === "Destination" ? "100vw" : "100%",
        display: "grid",
        overflow: "hidden",
      }}
    >
      {mapFilter != "Destination" && <Title />}
      {mapFilter != "Destination" && (
      <Transform>
        {mapFilter === "Les Bordes Estate" && <LesBordesSVG />}
        {mapFilter === "Loire Valley" && <LoireSVG />}
      </Transform>
      )}
        {mapFilter === "Destination" && (<Destination/>)}
      <MapsButtons />
    </div>
  )
}