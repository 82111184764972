/* eslint-disable no-unused-vars */
import { Vector3 } from "three"
export const pathData = {
  bordeaux: [
    [1.73, 1.86, 0.61],
    [1.7331, 1.8681, 0.6078],
    [1.7361, 1.8763, 0.6053],
    [1.7387, 1.8844, 0.6024],
    [1.7409, 1.8925, 0.5992],
    [1.7428, 1.9006, 0.5957],
    [1.7442, 1.9085, 0.5917],
    [1.7451, 1.9162, 0.5872],
    [1.7454, 1.9237, 0.5822],
    [1.745, 1.9309, 0.5767],
    [1.7438, 1.9375, 0.5707],
    [1.7418, 1.9435, 0.5643],
    [1.739, 1.9487, 0.5575],
    [1.7354, 1.953, 0.5505],
    [1.7312, 1.9565, 0.5434],
    [1.7263, 1.9589, 0.5362],
    [1.721, 1.9605, 0.5291],
    [1.7152, 1.9613, 0.5223],
    [1.7092, 1.9613, 0.5156],
    [1.703, 1.9606, 0.5091],
    [1.6966, 1.9594, 0.5029],
    [1.6901, 1.9577, 0.4969],
    [1.6835, 1.9555, 0.4911],
    [1.6769, 1.953, 0.4856],
    [1.6703, 1.9501, 0.4802]
  ],

  paris: [
    [1.67, 1.95, 0.48],
    [1.6715, 1.9588, 0.4788],
    [1.6723, 1.9676, 0.4771],
    [1.6719, 1.9763, 0.4749],
    [1.6702, 1.9846, 0.4719],
    [1.6668, 1.9921, 0.4682],
    [1.6617, 1.9981, 0.4639],
    [1.6551, 2.0022, 0.4593],
    [1.6477, 2.0042, 0.4546],
    [1.6399, 2.0045, 0.4501],
    [1.632, 2.0035, 0.4459],
    [1.6242, 2.0014, 0.442]
  ],

  sancerre: [
    [1.71, 1.93, 0.45],
    [1.7107, 1.9378, 0.4545],
    [1.7096, 1.9451, 0.4596],
    [1.7061, 1.9514, 0.465],
    [1.6999, 1.9553, 0.4702],
    [1.6919, 1.9563, 0.4743],
    [1.6835, 1.955, 0.4772],
    [1.6752, 1.9523, 0.4791]
  ],

  normandy: [
    [1.67, 1.95, 0.48],
    [1.6714, 1.9578, 0.4844],
    [1.6723, 1.9655, 0.4889],
    [1.6727, 1.9731, 0.4936],
    [1.6726, 1.9807, 0.4985],
    [1.6717, 1.988, 0.5036],
    [1.67, 1.9951, 0.5089],
    [1.6673, 2.0017, 0.5144],
    [1.6636, 2.0077, 0.52],
    [1.6588, 2.0129, 0.5255],
    [1.6529, 2.0171, 0.5309],
    [1.6462, 2.0202, 0.536],
    [1.6387, 2.0221, 0.5407],
    [1.6308, 2.0228, 0.5448],
    [1.6225, 2.0225, 0.5484],
    [1.6142, 2.0214, 0.5515],
    [1.6058, 2.0194, 0.5542],
    [1.5974, 2.0169, 0.5564],
    [1.5892, 2.0139, 0.5583],
    [1.581, 2.0104, 0.5598]
  ],

  champagne: [
    [1.67, 1.95, 0.48],
    [1.673, 1.9579, 0.477],
    [1.6757, 1.9658, 0.4736],
    [1.6781, 1.9736, 0.4697],
    [1.68, 1.9813, 0.4654],
    [1.6814, 1.9887, 0.4606],
    [1.6822, 1.9959, 0.4552],
    [1.6822, 2.0026, 0.4493],
    [1.6814, 2.0088, 0.4427],
    [1.6797, 2.0141, 0.4357],
    [1.677, 2.0185, 0.4283],
    [1.6735, 2.0218, 0.4206],
    [1.6692, 2.0239, 0.413],
    [1.6643, 2.025, 0.4056],
    [1.659, 2.0251, 0.3983],
    [1.6532, 2.0243, 0.3914],
    [1.6473, 2.0227, 0.3849],
    [1.6411, 2.0206, 0.3787],
    [1.6349, 2.0179, 0.3728],
    [1.6286, 2.0148, 0.3672],
    [1.6222, 2.0113, 0.3618]
  ],

  newyork: [
    [-0.12, 1.7, 1.98],
    [-0.1138, 1.7064, 1.9809],
    [-0.1075, 1.7129, 1.9817],
    [-0.1013, 1.7193, 1.9826],
    [-0.0951, 1.7257, 1.9835],
    [-0.0888, 1.7322, 1.9843],
    [-0.0826, 1.7386, 1.9852],
    [-0.0764, 1.745, 1.9861],
    [-0.0701, 1.7515, 1.9869],
    [-0.0639, 1.7579, 1.9878],
    [-0.0576, 1.7643, 1.9886],
    [-0.0513, 1.7707, 1.9893],
    [-0.045, 1.777, 1.99],
    [-0.0386, 1.7834, 1.9906],
    [-0.0323, 1.7897, 1.9913],
    [-0.0259, 1.7961, 1.9919],
    [-0.0196, 1.8024, 1.9926],
    [-0.0132, 1.8088, 1.9932],
    [-0.0069, 1.8151, 1.9939],
    [-0.0005, 1.8214, 1.9946],
    [0.0059, 1.8277, 1.9951],
    [0.0124, 1.834, 1.9955],
    [0.0188, 1.8402, 1.9959],
    [0.0253, 1.8465, 1.9964],
    [0.0318, 1.8527, 1.9968],
    [0.0382, 1.859, 1.9973],
    [0.0447, 1.8652, 1.9977],
    [0.0511, 1.8715, 1.9981],
    [0.0576, 1.8777, 1.9986],
    [0.0641, 1.8839, 1.9988],
    [0.0707, 1.89, 1.9991],
    [0.0773, 1.8962, 1.9993],
    [0.0839, 1.9023, 1.9995],
    [0.0904, 1.9085, 1.9997],
    [0.097, 1.9146, 1.9999],
    [0.1036, 1.9207, 2.0001],
    [0.1102, 1.9269, 2.0003],
    [0.1167, 1.933, 2.0005],
    [0.1234, 1.9391, 2.0005],
    [0.1301, 1.9451, 2.0005],
    [0.1368, 1.9511, 2.0004],
    [0.1435, 1.9571, 2.0004],
    [0.1502, 1.9632, 2.0004],
    [0.1568, 1.9692, 2.0004],
    [0.1635, 1.9752, 2.0003],
    [0.1702, 1.9812, 2.0003],
    [0.177, 1.9872, 2.0001],
    [0.1838, 1.9931, 1.9998],
    [0.1906, 1.999, 1.9996],
    [0.1974, 2.0049, 1.9993],
    [0.2042, 2.0108, 1.999],
    [0.2109, 2.0167, 1.9987],
    [0.2177, 2.0226, 1.9985],
    [0.2245, 2.0285, 1.9982],
    [0.2314, 2.0343, 1.9977],
    [0.2383, 2.04, 1.9972],
    [0.2452, 2.0458, 1.9967],
    [0.2521, 2.0515, 1.9961],
    [0.259, 2.0573, 1.9956],
    [0.2659, 2.063, 1.9951],
    [0.2728, 2.0688, 1.9946],
    [0.2797, 2.0746, 1.994],
    [0.2867, 2.0802, 1.9932],
    [0.2937, 2.0858, 1.9924],
    [0.3007, 2.0914, 1.9916],
    [0.3077, 2.097, 1.9908],
    [0.3147, 2.1026, 1.99],
    [0.3216, 2.1082, 1.9893],
    [0.3286, 2.1138, 1.9885],
    [0.3357, 2.1193, 1.9875],
    [0.3428, 2.1248, 1.9864],
    [0.3499, 2.1302, 1.9854],
    [0.357, 2.1356, 1.9843],
    [0.3641, 2.1411, 1.9832],
    [0.3711, 2.1465, 1.9822],
    [0.3782, 2.152, 1.9811],
    [0.3854, 2.1573, 1.9799],
    [0.3925, 2.1626, 1.9786],
    [0.3997, 2.1679, 1.9772],
    [0.4069, 2.1731, 1.9759],
    [0.4141, 2.1784, 1.9745],
    [0.4212, 2.1836, 1.9732],
    [0.4284, 2.1889, 1.9718],
    [0.4356, 2.1941, 1.9703],
    [0.4429, 2.1991, 1.9687],
    [0.4501, 2.2042, 1.9671],
    [0.4574, 2.2093, 1.9654],
    [0.4646, 2.2144, 1.9638],
    [0.4719, 2.2194, 1.9621],
    [0.4791, 2.2245, 1.9605],
    [0.4865, 2.2294, 1.9587],
    [0.4938, 2.2343, 1.9567],
    [0.5011, 2.2391, 1.9548],
    [0.5084, 2.244, 1.9529],
    [0.5157, 2.2489, 1.9509],
    [0.5231, 2.2537, 1.949],
    [0.5304, 2.2585, 1.947],
    [0.5378, 2.2632, 1.9448],
    [0.5452, 2.2678, 1.9425],
    [0.5525, 2.2725, 1.9403],
    [0.5599, 2.2771, 1.9381],
    [0.5673, 2.2817, 1.9358],
    [0.5747, 2.2864, 1.9336],
    [0.5821, 2.2908, 1.9311],
    [0.5895, 2.2952, 1.9286],
    [0.597, 2.2996, 1.926],
    [0.6044, 2.304, 1.9235],
    [0.6118, 2.3084, 1.9209],
    [0.6192, 2.3128, 1.9184],
    [0.6267, 2.3171, 1.9157],
    [0.6342, 2.3212, 1.9128],
    [0.6416, 2.3254, 1.91],
    [0.6491, 2.3295, 1.9071],
    [0.6565, 2.3337, 1.9043],
    [0.664, 2.3378, 1.9014],
    [0.6715, 2.3418, 1.8984],
    [0.679, 2.3457, 1.8952],
    [0.6864, 2.3496, 1.892],
    [0.6939, 2.3535, 1.8889],
    [0.7014, 2.3573, 1.8857],
    [0.7089, 2.3612, 1.8826],
    [0.7164, 2.3649, 1.8792],
    [0.7238, 2.3685, 1.8757],
    [0.7313, 2.3721, 1.8722],
    [0.7388, 2.3757, 1.8688],
    [0.7463, 2.3793, 1.8653],
    [0.7538, 2.3829, 1.8618],
    [0.7612, 2.3862, 1.8581],
    [0.7687, 2.3896, 1.8543],
    [0.7762, 2.3929, 1.8505],
    [0.7836, 2.3962, 1.8467],
    [0.7911, 2.3995, 1.843],
    [0.7986, 2.4027, 1.8391],
    [0.806, 2.4057, 1.835],
    [0.8134, 2.4087, 1.8309],
    [0.8209, 2.4117, 1.8268],
    [0.8283, 2.4147, 1.8228],
    [0.8358, 2.4177, 1.8187],
    [0.8432, 2.4205, 1.8144],
    [0.8505, 2.4232, 1.81],
    [0.8579, 2.4259, 1.8056],
    [0.8653, 2.4285, 1.8012],
    [0.8727, 2.4312, 1.7968],
    [0.8801, 2.4338, 1.7924],
    [0.8874, 2.4362, 1.7877],
    [0.8947, 2.4385, 1.7831],
    [0.902, 2.4409, 1.7784],
    [0.9094, 2.4433, 1.7737],
    [0.9167, 2.4456, 1.769],
    [0.9239, 2.4477, 1.7641],
    [0.9312, 2.4497, 1.7592],
    [0.9384, 2.4517, 1.7542],
    [0.9456, 2.4537, 1.7492],
    [0.9529, 2.4558, 1.7443],
    [0.9601, 2.4577, 1.7392],
    [0.9672, 2.4593, 1.734],
    [0.9743, 2.461, 1.7287],
    [0.9815, 2.4627, 1.7235],
    [0.9886, 2.4643, 1.7183],
    [0.9957, 2.466, 1.713],
    [1.0027, 2.4673, 1.7075],
    [1.0097, 2.4686, 1.702],
    [1.0167, 2.4699, 1.6965],
    [1.0237, 2.4712, 1.691],
    [1.0307, 2.4726, 1.6855],
    [1.0376, 2.4736, 1.6799],
    [1.0445, 2.4746, 1.6741],
    [1.0513, 2.4756, 1.6684],
    [1.0582, 2.4765, 1.6626],
    [1.0651, 2.4775, 1.6569],
    [1.0719, 2.4783, 1.651],
    [1.0786, 2.4789, 1.6451],
    [1.0853, 2.4795, 1.6391],
    [1.092, 2.4801, 1.6331],
    [1.0987, 2.4807, 1.6271],
    [1.1054, 2.4813, 1.6211],
    [1.1119, 2.4815, 1.6149],
    [1.1184, 2.4818, 1.6087],
    [1.1249, 2.482, 1.6025],
    [1.1314, 2.4822, 1.5963],
    [1.138, 2.4825, 1.5901],
    [1.1444, 2.4825, 1.5838],
    [1.1507, 2.4824, 1.5774],
    [1.157, 2.4823, 1.571],
    [1.1633, 2.4821, 1.5646],
    [1.1697, 2.482, 1.5582],
    [1.1759, 2.4818, 1.5518],
    [1.1821, 2.4813, 1.5452],
    [1.1882, 2.4808, 1.5386],
    [1.1943, 2.4804, 1.532],
    [1.2004, 2.4799, 1.5254],
    [1.2065, 2.4794, 1.5188],
    [1.2125, 2.4787, 1.5121],
    [1.2184, 2.4778, 1.5054],
    [1.2243, 2.477, 1.4986],
    [1.2302, 2.4762, 1.4919],
    [1.2361, 2.4753, 1.4851],
    [1.2419, 2.4744, 1.4783],
    [1.2475, 2.4732, 1.4714],
    [1.2532, 2.4721, 1.4645],
    [1.2589, 2.4709, 1.4576],
    [1.2645, 2.4697, 1.4507],
    [1.2702, 2.4686, 1.4439],
    [1.2757, 2.4671, 1.4369],
    [1.2811, 2.4656, 1.4298],
    [1.2865, 2.4641, 1.4228],
    [1.2919, 2.4626, 1.4158],
    [1.2974, 2.4611, 1.4088],
    [1.3028, 2.4596, 1.4017],
    [1.3079, 2.4577, 1.3946],
    [1.3131, 2.4559, 1.3875],
    [1.3183, 2.4541, 1.3803],
    [1.3235, 2.4522, 1.3732],
    [1.3286, 2.4504, 1.3661],
    [1.3337, 2.4485, 1.3589],
    [1.3386, 2.4463, 1.3517],
    [1.3436, 2.4442, 1.3445],
    [1.3485, 2.442, 1.3372],
    [1.3534, 2.4399, 1.33],
    [1.3583, 2.4377, 1.3228],
    [1.3631, 2.4354, 1.3155],
    [1.3678, 2.433, 1.3082],
    [1.3724, 2.4305, 1.3009],
    [1.3771, 2.4281, 1.2936],
    [1.3817, 2.4256, 1.2863],
    [1.3864, 2.4231, 1.279],
    [1.3909, 2.4206, 1.2717],
    [1.3953, 2.4178, 1.2643],
    [1.3997, 2.4151, 1.257],
    [1.4041, 2.4123, 1.2496],
    [1.4085, 2.4096, 1.2422],
    [1.4129, 2.4068, 1.2349],
    [1.4172, 2.404, 1.2275],
    [1.4213, 2.401, 1.2201],
    [1.4255, 2.398, 1.2127],
    [1.4296, 2.3949, 1.2053],
    [1.4337, 2.3919, 1.1979],
    [1.4378, 2.3889, 1.1905],
    [1.442, 2.3858, 1.1831],
    [1.4459, 2.3826, 1.1757],
    [1.4497, 2.3793, 1.1682],
    [1.4536, 2.376, 1.1608],
    [1.4574, 2.3727, 1.1534],
    [1.4613, 2.3694, 1.1459],
    [1.4651, 2.3661, 1.1385],
    [1.4689, 2.3627, 1.1311],
    [1.4725, 2.3592, 1.1236],
    [1.4761, 2.3556, 1.1162],
    [1.4797, 2.352, 1.1087],
    [1.4833, 2.3485, 1.1013],
    [1.4869, 2.3449, 1.0938],
    [1.4905, 2.3414, 1.0864],
    [1.4939, 2.3377, 1.0789],
    [1.4972, 2.3339, 1.0715],
    [1.5006, 2.3301, 1.064],
    [1.5039, 2.3263, 1.0566],
    [1.5072, 2.3225, 1.0491],
    [1.5106, 2.3187, 1.0417],
    [1.5139, 2.3149, 1.0342],
    [1.5171, 2.311, 1.0268],
    [1.5201, 2.307, 1.0193],
    [1.5232, 2.303, 1.0119],
    [1.5263, 2.2989, 1.0044],
    [1.5293, 2.2949, 0.997],
    [1.5324, 2.2909, 0.9895],
    [1.5355, 2.2869, 0.9821],
    [1.5384, 2.2828, 0.9747],
    [1.5412, 2.2785, 0.9672],
    [1.5441, 2.2743, 0.9598],
    [1.5469, 2.2701, 0.9524],
    [1.5497, 2.2658, 0.945],
    [1.5525, 2.2616, 0.9375],
    [1.5553, 2.2574, 0.9301],
    [1.5581, 2.2531, 0.9227],
    [1.5607, 2.2487, 0.9153],
    [1.5633, 2.2443, 0.9079],
    [1.5658, 2.2398, 0.9005],
    [1.5684, 2.2354, 0.8931],
    [1.571, 2.2309, 0.8857],
    [1.5735, 2.2265, 0.8783],
    [1.5761, 2.2221, 0.8709],
    [1.5786, 2.2176, 0.8635],
    [1.5809, 2.213, 0.8561],
    [1.5833, 2.2083, 0.8488],
    [1.5856, 2.2037, 0.8414],
    [1.5879, 2.1991, 0.834],
    [1.5902, 2.1945, 0.8267],
    [1.5925, 2.1898, 0.8193],
    [1.5949, 2.1852, 0.812],
    [1.5971, 2.1805, 0.8046],
    [1.5992, 2.1757, 0.7973],
    [1.6013, 2.1709, 0.79],
    [1.6034, 2.1661, 0.7826],
    [1.6055, 2.1613, 0.7753],
    [1.6076, 2.1565, 0.768],
    [1.6096, 2.1517, 0.7607],
    [1.6117, 2.1469, 0.7534],
    [1.6138, 2.1421, 0.746],
    [1.6157, 2.1372, 0.7388],
    [1.6175, 2.1322, 0.7315],
    [1.6194, 2.1273, 0.7242],
    [1.6212, 2.1223, 0.7169],
    [1.6231, 2.1173, 0.7097],
    [1.625, 2.1124, 0.7024],
    [1.6268, 2.1074, 0.6951],
    [1.6287, 2.1024, 0.6878],
    [1.6305, 2.0974, 0.6806],
    [1.6321, 2.0923, 0.6734],
    [1.6337, 2.0872, 0.6661],
    [1.6354, 2.0821, 0.6589],
    [1.637, 2.077, 0.6517],
    [1.6386, 2.0718, 0.6445],
    [1.6403, 2.0667, 0.6373],
    [1.6419, 2.0616, 0.63],
    [1.6435, 2.0565, 0.6228],
    [1.6451, 2.0513, 0.6156],
    [1.6465, 2.0461, 0.6084],
    [1.6479, 2.0408, 0.6013],
    [1.6494, 2.0355, 0.5941],
    [1.6508, 2.0303, 0.5869],
    [1.6522, 2.025, 0.5798],
    [1.6536, 2.0198, 0.5726],
    [1.655, 2.0145, 0.5655],
    [1.6564, 2.0092, 0.5583],
    [1.6579, 2.004, 0.5511],
    [1.6591, 1.9986, 0.544],
    [1.6603, 1.9932, 0.5369],
    [1.6615, 1.9878, 0.5298],
    [1.6627, 1.9824, 0.5227],
    [1.6639, 1.977, 0.5156],
    [1.6651, 1.9716, 0.5085],
    [1.6664, 1.9663, 0.5014],
    [1.6676, 1.9609, 0.4943],
    [1.6688, 1.9555, 0.4872],
    [1.67, 1.9501, 0.4801]
  ],

  hongkong: [
    [-0.2, 1.02, -2.4],
    [-0.1945, 1.0271, -2.3998],
    [-0.1891, 1.0343, -2.3997],
    [-0.1836, 1.0414, -2.3995],
    [-0.1781, 1.0486, -2.3993],
    [-0.1726, 1.0557, -2.3992],
    [-0.1672, 1.0629, -2.399],
    [-0.1617, 1.07, -2.3989],
    [-0.1562, 1.0771, -2.3987],
    [-0.1508, 1.0843, -2.3985],
    [-0.1453, 1.0914, -2.3984],
    [-0.1398, 1.0986, -2.3982],
    [-0.1343, 1.1057, -2.398],
    [-0.1289, 1.1129, -2.3979],
    [-0.1234, 1.12, -2.3977],
    [-0.1179, 1.1271, -2.3975],
    [-0.1124, 1.1342, -2.3971],
    [-0.1069, 1.1413, -2.3967],
    [-0.1013, 1.1484, -2.3963],
    [-0.0958, 1.1555, -2.3959],
    [-0.0903, 1.1626, -2.3955],
    [-0.0847, 1.1697, -2.3951],
    [-0.0792, 1.1768, -2.3947],
    [-0.0737, 1.1839, -2.3943],
    [-0.0682, 1.191, -2.3939],
    [-0.0626, 1.198, -2.3935],
    [-0.0571, 1.2051, -2.3931],
    [-0.0516, 1.2122, -2.3927],
    [-0.046, 1.2193, -2.3922],
    [-0.0405, 1.2264, -2.3918],
    [-0.0349, 1.2334, -2.3912],
    [-0.0293, 1.2405, -2.3906],
    [-0.0238, 1.2475, -2.3899],
    [-0.0182, 1.2545, -2.3892],
    [-0.0126, 1.2615, -2.3886],
    [-0.007, 1.2686, -2.3879],
    [-0.0014, 1.2756, -2.3873],
    [0.0042, 1.2826, -2.3866],
    [0.0097, 1.2897, -2.386],
    [0.0153, 1.2967, -2.3853],
    [0.0209, 1.3037, -2.3846],
    [0.0265, 1.3107, -2.384],
    [0.0321, 1.3178, -2.3833],
    [0.0377, 1.3248, -2.3826],
    [0.0433, 1.3317, -2.3817],
    [0.049, 1.3387, -2.3808],
    [0.0546, 1.3457, -2.3799],
    [0.0602, 1.3526, -2.3789],
    [0.0659, 1.3596, -2.378],
    [0.0715, 1.3665, -2.3771],
    [0.0771, 1.3735, -2.3762],
    [0.0828, 1.3804, -2.3753],
    [0.0884, 1.3874, -2.3743],
    [0.094, 1.3943, -2.3734],
    [0.0997, 1.4013, -2.3725],
    [0.1053, 1.4083, -2.3716],
    [0.111, 1.4152, -2.3706],
    [0.1166, 1.4221, -2.3695],
    [0.1223, 1.429, -2.3683],
    [0.128, 1.4358, -2.3671],
    [0.1337, 1.4427, -2.3659],
    [0.1394, 1.4496, -2.3647],
    [0.1451, 1.4565, -2.3635],
    [0.1507, 1.4633, -2.3623],
    [0.1564, 1.4702, -2.3611],
    [0.1621, 1.4771, -2.3599],
    [0.1678, 1.484, -2.3587],
    [0.1735, 1.4908, -2.3576],
    [0.1792, 1.4977, -2.3564],
    [0.1849, 1.5045, -2.355],
    [0.1906, 1.5113, -2.3536],
    [0.1963, 1.5181, -2.3521],
    [0.2021, 1.5249, -2.3506],
    [0.2078, 1.5317, -2.3492],
    [0.2135, 1.5385, -2.3477],
    [0.2192, 1.5452, -2.3462],
    [0.225, 1.552, -2.3447],
    [0.2307, 1.5588, -2.3433],
    [0.2364, 1.5656, -2.3418],
    [0.2422, 1.5724, -2.3403],
    [0.2479, 1.5792, -2.3389],
    [0.2536, 1.5859, -2.3373],
    [0.2594, 1.5926, -2.3355],
    [0.2652, 1.5993, -2.3338],
    [0.2709, 1.6059, -2.332],
    [0.2767, 1.6126, -2.3302],
    [0.2825, 1.6193, -2.3285],
    [0.2882, 1.626, -2.3267],
    [0.294, 1.6327, -2.325],
    [0.2998, 1.6393, -2.3232],
    [0.3056, 1.646, -2.3214],
    [0.3113, 1.6527, -2.3197],
    [0.3171, 1.6594, -2.3179],
    [0.3229, 1.666, -2.3159],
    [0.3287, 1.6726, -2.3139],
    [0.3345, 1.6791, -2.3118],
    [0.3403, 1.6857, -2.3098],
    [0.3461, 1.6923, -2.3077],
    [0.3519, 1.6988, -2.3057],
    [0.3577, 1.7054, -2.3036],
    [0.3635, 1.7119, -2.3015],
    [0.3693, 1.7185, -2.2995],
    [0.3751, 1.7251, -2.2974],
    [0.3809, 1.7316, -2.2954],
    [0.3867, 1.7382, -2.2932],
    [0.3925, 1.7446, -2.2908],
    [0.3984, 1.751, -2.2884],
    [0.4042, 1.7575, -2.2861],
    [0.41, 1.7639, -2.2837],
    [0.4159, 1.7703, -2.2813],
    [0.4217, 1.7768, -2.279],
    [0.4275, 1.7832, -2.2766],
    [0.4333, 1.7896, -2.2742],
    [0.4392, 1.7961, -2.2719],
    [0.445, 1.8025, -2.2695],
    [0.4508, 1.8089, -2.267],
    [0.4567, 1.8152, -2.2643],
    [0.4625, 1.8215, -2.2616],
    [0.4684, 1.8278, -2.2589],
    [0.4742, 1.8341, -2.2563],
    [0.4801, 1.8404, -2.2536],
    [0.4859, 1.8466, -2.2509],
    [0.4918, 1.8529, -2.2482],
    [0.4976, 1.8592, -2.2455],
    [0.5035, 1.8655, -2.2429],
    [0.5093, 1.8718, -2.2402],
    [0.5152, 1.878, -2.2372],
    [0.521, 1.8841, -2.2342],
    [0.5269, 1.8902, -2.2312],
    [0.5328, 1.8964, -2.2282],
    [0.5386, 1.9025, -2.2252],
    [0.5445, 1.9086, -2.2222],
    [0.5503, 1.9148, -2.2192],
    [0.5562, 1.9209, -2.2162],
    [0.5621, 1.9271, -2.2132],
    [0.5679, 1.9332, -2.2102],
    [0.5738, 1.9392, -2.2069],
    [0.5796, 1.9452, -2.2036],
    [0.5855, 1.9511, -2.2003],
    [0.5914, 1.9571, -2.1969],
    [0.5972, 1.9631, -2.1936],
    [0.6031, 1.969, -2.1903],
    [0.6089, 1.975, -2.187],
    [0.6148, 1.981, -2.1836],
    [0.6207, 1.9869, -2.1803],
    [0.6265, 1.9929, -2.177],
    [0.6324, 1.9987, -2.1734],
    [0.6382, 2.0045, -2.1697],
    [0.6441, 2.0102, -2.166],
    [0.6499, 2.016, -2.1624],
    [0.6558, 2.0218, -2.1587],
    [0.6616, 2.0276, -2.1551],
    [0.6675, 2.0334, -2.1514],
    [0.6733, 2.0391, -2.1478],
    [0.6792, 2.0449, -2.1441],
    [0.685, 2.0506, -2.1404],
    [0.6909, 2.0562, -2.1364],
    [0.6967, 2.0618, -2.1324],
    [0.7025, 2.0674, -2.1284],
    [0.7084, 2.0729, -2.1244],
    [0.7142, 2.0785, -2.1204],
    [0.72, 2.0841, -2.1164],
    [0.7259, 2.0897, -2.1125],
    [0.7317, 2.0952, -2.1085],
    [0.7375, 2.1008, -2.1045],
    [0.7433, 2.1062, -2.1002],
    [0.7492, 2.1115, -2.0959],
    [0.755, 2.1169, -2.0915],
    [0.7608, 2.1222, -2.0872],
    [0.7666, 2.1276, -2.0829],
    [0.7724, 2.1329, -2.0786],
    [0.7782, 2.1383, -2.0743],
    [0.784, 2.1437, -2.0699],
    [0.7898, 2.149, -2.0656],
    [0.7955, 2.1542, -2.061],
    [0.8013, 2.1593, -2.0564],
    [0.807, 2.1644, -2.0517],
    [0.8128, 2.1695, -2.0471],
    [0.8186, 2.1747, -2.0424],
    [0.8243, 2.1798, -2.0378],
    [0.8301, 2.1849, -2.0331],
    [0.8358, 2.19, -2.0285],
    [0.8416, 2.1951, -2.0238],
    [0.8473, 2.2, -2.0189],
    [0.853, 2.2049, -2.0139],
    [0.8587, 2.2098, -2.0089],
    [0.8644, 2.2146, -2.0039],
    [0.8701, 2.2195, -1.999],
    [0.8758, 2.2244, -1.994],
    [0.8815, 2.2292, -1.989],
    [0.8872, 2.2341, -1.984],
    [0.8929, 2.2389, -1.979],
    [0.8985, 2.2435, -1.9737],
    [0.9042, 2.2481, -1.9684],
    [0.9098, 2.2527, -1.9631],
    [0.9154, 2.2573, -1.9578],
    [0.921, 2.2619, -1.9525],
    [0.9267, 2.2665, -1.9472],
    [0.9323, 2.2711, -1.9419],
    [0.9379, 2.2757, -1.9366],
    [0.9435, 2.2802, -1.9311],
    [0.9491, 2.2845, -1.9254],
    [0.9546, 2.2888, -1.9198],
    [0.9601, 2.2931, -1.9142],
    [0.9657, 2.2975, -1.9086],
    [0.9712, 2.3018, -1.9029],
    [0.9768, 2.3061, -1.8973],
    [0.9823, 2.3104, -1.8917],
    [0.9879, 2.3147, -1.886],
    [0.9933, 2.3187, -1.8801],
    [0.9987, 2.3227, -1.8742],
    [1.0042, 2.3268, -1.8682],
    [1.0096, 2.3308, -1.8623],
    [1.0151, 2.3348, -1.8564],
    [1.0205, 2.3388, -1.8504],
    [1.026, 2.3428, -1.8445],
    [1.0314, 2.3469, -1.8386],
    [1.0368, 2.3506, -1.8324],
    [1.0421, 2.3543, -1.8262],
    [1.0474, 2.358, -1.8199],
    [1.0528, 2.3618, -1.8137],
    [1.0581, 2.3655, -1.8075],
    [1.0634, 2.3692, -1.8012],
    [1.0688, 2.3729, -1.795],
    [1.0741, 2.3766, -1.7888],
    [1.0793, 2.3801, -1.7824],
    [1.0845, 2.3835, -1.7759],
    [1.0897, 2.3869, -1.7694],
    [1.0949, 2.3903, -1.7628],
    [1.1001, 2.3937, -1.7563],
    [1.1053, 2.397, -1.7498],
    [1.1105, 2.4004, -1.7433],
    [1.1157, 2.4038, -1.7368],
    [1.1209, 2.4071, -1.7301],
    [1.126, 2.4101, -1.7233],
    [1.131, 2.4132, -1.7166],
    [1.1361, 2.4162, -1.7098],
    [1.1411, 2.4193, -1.703],
    [1.1462, 2.4223, -1.6962],
    [1.1512, 2.4254, -1.6894],
    [1.1563, 2.4284, -1.6826],
    [1.1613, 2.4314, -1.6757],
    [1.1662, 2.4341, -1.6687],
    [1.1711, 2.4368, -1.6616],
    [1.176, 2.4395, -1.6546],
    [1.1809, 2.4422, -1.6476],
    [1.1858, 2.4449, -1.6405],
    [1.1907, 2.4476, -1.6335],
    [1.1956, 2.4503, -1.6264],
    [1.2005, 2.4529, -1.6193],
    [1.2052, 2.4553, -1.612],
    [1.21, 2.4577, -1.6047],
    [1.2147, 2.46, -1.5975],
    [1.2194, 2.4624, -1.5902],
    [1.2242, 2.4647, -1.5829],
    [1.2289, 2.4671, -1.5756],
    [1.2336, 2.4694, -1.5683],
    [1.2383, 2.4717, -1.561],
    [1.2429, 2.4737, -1.5535],
    [1.2474, 2.4757, -1.546],
    [1.252, 2.4777, -1.5385],
    [1.2565, 2.4797, -1.531],
    [1.2611, 2.4817, -1.5235],
    [1.2656, 2.4837, -1.516],
    [1.2702, 2.4857, -1.5085],
    [1.2747, 2.4877, -1.501],
    [1.2791, 2.4893, -1.4932],
    [1.2834, 2.4909, -1.4855],
    [1.2878, 2.4926, -1.4778],
    [1.2921, 2.4942, -1.4701],
    [1.2965, 2.4958, -1.4624],
    [1.3008, 2.4975, -1.4547],
    [1.3052, 2.4991, -1.447],
    [1.3096, 2.5008, -1.4393],
    [1.3137, 2.502, -1.4314],
    [1.3179, 2.5033, -1.4236],
    [1.322, 2.5046, -1.4157],
    [1.3262, 2.5058, -1.4078],
    [1.3303, 2.5071, -1.3999],
    [1.3345, 2.5084, -1.392],
    [1.3386, 2.5097, -1.3841],
    [1.3428, 2.5109, -1.3763],
    [1.3468, 2.5119, -1.3683],
    [1.3507, 2.5128, -1.3602],
    [1.3547, 2.5137, -1.3522],
    [1.3586, 2.5146, -1.3441],
    [1.3625, 2.5155, -1.3361],
    [1.3665, 2.5164, -1.328],
    [1.3704, 2.5173, -1.32],
    [1.3744, 2.5182, -1.312],
    [1.3782, 2.5189, -1.3038],
    [1.3819, 2.5195, -1.2957],
    [1.3856, 2.52, -1.2875],
    [1.3893, 2.5206, -1.2793],
    [1.3931, 2.5211, -1.2711],
    [1.3968, 2.5216, -1.263],
    [1.4005, 2.5222, -1.2548],
    [1.4042, 2.5227, -1.2466],
    [1.4079, 2.5232, -1.2384],
    [1.4114, 2.5234, -1.2301],
    [1.4149, 2.5235, -1.2218],
    [1.4184, 2.5237, -1.2135],
    [1.4219, 2.5239, -1.2052],
    [1.4254, 2.5241, -1.1969],
    [1.4289, 2.5243, -1.1887],
    [1.4323, 2.5244, -1.1804],
    [1.4358, 2.5246, -1.1721],
    [1.4392, 2.5245, -1.1637],
    [1.4424, 2.5244, -1.1553],
    [1.4457, 2.5242, -1.1469],
    [1.449, 2.524, -1.1385],
    [1.4522, 2.5238, -1.1302],
    [1.4555, 2.5237, -1.1218],
    [1.4587, 2.5235, -1.1134],
    [1.462, 2.5233, -1.105],
    [1.4652, 2.5231, -1.0966],
    [1.4683, 2.5225, -1.0881],
    [1.4713, 2.522, -1.0797],
    [1.4743, 2.5215, -1.0712],
    [1.4773, 2.521, -1.0628],
    [1.4804, 2.5205, -1.0543],
    [1.4834, 2.5199, -1.0458],
    [1.4864, 2.5194, -1.0374],
    [1.4895, 2.5189, -1.0289],
    [1.4924, 2.5182, -1.0204],
    [1.4952, 2.5173, -1.0119],
    [1.498, 2.5165, -1.0034],
    [1.5008, 2.5156, -0.9949],
    [1.5036, 2.5148, -0.9864],
    [1.5064, 2.5139, -0.9779],
    [1.5091, 2.513, -0.9694],
    [1.5119, 2.5122, -0.9609],
    [1.5147, 2.5113, -0.9524],
    [1.5174, 2.5103, -0.9438],
    [1.52, 2.5091, -0.9353],
    [1.5225, 2.5079, -0.9267],
    [1.5251, 2.5067, -0.9182],
    [1.5276, 2.5055, -0.9096],
    [1.5302, 2.5043, -0.9011],
    [1.5327, 2.5031, -0.8925],
    [1.5353, 2.5019, -0.884],
    [1.5379, 2.5007, -0.8755],
    [1.5403, 2.4994, -0.8669],
    [1.5426, 2.4979, -0.8583],
    [1.545, 2.4964, -0.8498],
    [1.5473, 2.4948, -0.8412],
    [1.5496, 2.4933, -0.8326],
    [1.5519, 2.4918, -0.8241],
    [1.5542, 2.4903, -0.8155],
    [1.5566, 2.4888, -0.807],
    [1.5589, 2.4873, -0.7984],
    [1.5612, 2.4857, -0.7898],
    [1.5633, 2.4839, -0.7813],
    [1.5654, 2.4821, -0.7727],
    [1.5675, 2.4803, -0.7641],
    [1.5695, 2.4784, -0.7556],
    [1.5716, 2.4766, -0.747],
    [1.5737, 2.4748, -0.7385],
    [1.5758, 2.473, -0.7299],
    [1.5779, 2.4711, -0.7213],
    [1.58, 2.4693, -0.7128],
    [1.582, 2.4674, -0.7042],
    [1.5838, 2.4652, -0.6957],
    [1.5857, 2.4631, -0.6871],
    [1.5875, 2.461, -0.6786],
    [1.5894, 2.4589, -0.67],
    [1.5912, 2.4567, -0.6615],
    [1.5931, 2.4546, -0.6529],
    [1.5949, 2.4525, -0.6444],
    [1.5968, 2.4504, -0.6358],
    [1.5986, 2.4482, -0.6273],
    [1.6004, 2.446, -0.6188],
    [1.602, 2.4436, -0.6102],
    [1.6036, 2.4411, -0.6017],
    [1.6053, 2.4387, -0.5932],
    [1.6069, 2.4363, -0.5847],
    [1.6085, 2.4339, -0.5762],
    [1.6101, 2.4315, -0.5677],
    [1.6118, 2.4291, -0.5591],
    [1.6134, 2.4267, -0.5506],
    [1.615, 2.4243, -0.5421],
    [1.6166, 2.4218, -0.5336],
    [1.618, 2.4191, -0.5251],
    [1.6194, 2.4164, -0.5166],
    [1.6208, 2.4137, -0.5082],
    [1.6222, 2.411, -0.4997],
    [1.6236, 2.4084, -0.4912],
    [1.625, 2.4057, -0.4827],
    [1.6264, 2.403, -0.4743],
    [1.6278, 2.4003, -0.4658],
    [1.6292, 2.3976, -0.4573],
    [1.6306, 2.3949, -0.4488],
    [1.6318, 2.3921, -0.4404],
    [1.633, 2.3891, -0.432],
    [1.6342, 2.3862, -0.4236],
    [1.6353, 2.3832, -0.4151],
    [1.6365, 2.3803, -0.4067],
    [1.6377, 2.3773, -0.3983],
    [1.6389, 2.3744, -0.3899],
    [1.6401, 2.3714, -0.3814],
    [1.6413, 2.3685, -0.373],
    [1.6424, 2.3655, -0.3646],
    [1.6436, 2.3626, -0.3562],
    [1.6446, 2.3594, -0.3478],
    [1.6456, 2.3562, -0.3395],
    [1.6466, 2.3531, -0.3311],
    [1.6475, 2.3499, -0.3227],
    [1.6485, 2.3467, -0.3144],
    [1.6495, 2.3435, -0.306],
    [1.6504, 2.3403, -0.2977],
    [1.6514, 2.3371, -0.2893],
    [1.6524, 2.3339, -0.2809],
    [1.6533, 2.3307, -0.2726],
    [1.6543, 2.3275, -0.2642],
    [1.6552, 2.3242, -0.2559],
    [1.6559, 2.3208, -0.2476],
    [1.6567, 2.3173, -0.2393],
    [1.6574, 2.3139, -0.231],
    [1.6582, 2.3105, -0.2228],
    [1.659, 2.307, -0.2145],
    [1.6597, 2.3036, -0.2062],
    [1.6605, 2.3002, -0.1979],
    [1.6612, 2.2968, -0.1896],
    [1.662, 2.2933, -0.1813],
    [1.6628, 2.2899, -0.173],
    [1.6635, 2.2865, -0.1648],
    [1.6641, 2.2829, -0.1565],
    [1.6647, 2.2792, -0.1483],
    [1.6652, 2.2755, -0.1401],
    [1.6658, 2.2719, -0.1319],
    [1.6664, 2.2682, -0.1237],
    [1.6669, 2.2646, -0.1155],
    [1.6675, 2.2609, -0.1073],
    [1.668, 2.2573, -0.0991],
    [1.6686, 2.2536, -0.0909],
    [1.6692, 2.25, -0.0827],
    [1.6697, 2.2463, -0.0745],
    [1.6703, 2.2427, -0.0663],
    [1.6707, 2.2389, -0.0581],
    [1.6711, 2.235, -0.05],
    [1.6714, 2.2312, -0.0419],
    [1.6718, 2.2273, -0.0337],
    [1.6722, 2.2234, -0.0256],
    [1.6725, 2.2196, -0.0175],
    [1.6729, 2.2157, -0.0094],
    [1.6733, 2.2119, -0.0013],
    [1.6736, 2.208, 0.0069],
    [1.674, 2.2041, 0.015],
    [1.6744, 2.2003, 0.0231],
    [1.6747, 2.1964, 0.0312],
    [1.6751, 2.1925, 0.0393],
    [1.6753, 2.1885, 0.0474],
    [1.6755, 2.1844, 0.0554],
    [1.6756, 2.1804, 0.0634],
    [1.6758, 2.1763, 0.0715],
    [1.676, 2.1722, 0.0795],
    [1.6762, 2.1682, 0.0875],
    [1.6764, 2.1641, 0.0956],
    [1.6765, 2.1601, 0.1036],
    [1.6767, 2.156, 0.1116],
    [1.6769, 2.1519, 0.1196],
    [1.6771, 2.1479, 0.1277],
    [1.6773, 2.1438, 0.1357],
    [1.6774, 2.1398, 0.1437],
    [1.6775, 2.1355, 0.1517],
    [1.6775, 2.1313, 0.1596],
    [1.6775, 2.127, 0.1675],
    [1.6775, 2.1228, 0.1755],
    [1.6775, 2.1186, 0.1834],
    [1.6775, 2.1143, 0.1914],
    [1.6775, 2.1101, 0.1993],
    [1.6775, 2.1058, 0.2072],
    [1.6775, 2.1016, 0.2152],
    [1.6775, 2.0973, 0.2231],
    [1.6775, 2.0931, 0.231],
    [1.6775, 2.0888, 0.239],
    [1.6775, 2.0846, 0.2469],
    [1.6774, 2.0803, 0.2548],
    [1.6772, 2.0759, 0.2627],
    [1.6771, 2.0715, 0.2705],
    [1.6769, 2.067, 0.2783],
    [1.6767, 2.0626, 0.2862],
    [1.6766, 2.0582, 0.294],
    [1.6764, 2.0538, 0.3018],
    [1.6762, 2.0494, 0.3097],
    [1.676, 2.0449, 0.3175],
    [1.6759, 2.0405, 0.3254],
    [1.6757, 2.0361, 0.3332],
    [1.6755, 2.0317, 0.341],
    [1.6754, 2.0273, 0.3489],
    [1.6752, 2.0228, 0.3567],
    [1.675, 2.0184, 0.3645],
    [1.6747, 2.0138, 0.3723],
    [1.6743, 2.0092, 0.38],
    [1.674, 2.0047, 0.3878],
    [1.6737, 2.0001, 0.3955],
    [1.6733, 1.9955, 0.4032],
    [1.673, 1.9909, 0.411],
    [1.6727, 1.9863, 0.4187],
    [1.6723, 1.9817, 0.4264],
    [1.672, 1.9772, 0.4342],
    [1.6717, 1.9726, 0.4419],
    [1.6713, 1.968, 0.4497],
    [1.671, 1.9634, 0.4574],
    [1.6706, 1.9588, 0.4651],
    [1.6703, 1.9542, 0.4729]
  ],

  miami: [
    [-0.38, 1.12, 2.33],
    [-0.3743, 1.1267, 2.3317],
    [-0.3686, 1.1335, 2.3335],
    [-0.3629, 1.1402, 2.3352],
    [-0.3572, 1.1469, 2.337],
    [-0.3514, 1.1537, 2.3387],
    [-0.3457, 1.1604, 2.3405],
    [-0.34, 1.1671, 2.3422],
    [-0.3343, 1.1739, 2.3439],
    [-0.3286, 1.1806, 2.3457],
    [-0.3229, 1.1873, 2.3474],
    [-0.3172, 1.1941, 2.3492],
    [-0.3115, 1.2008, 2.3509],
    [-0.3057, 1.2075, 2.3525],
    [-0.2998, 1.2142, 2.354],
    [-0.294, 1.2209, 2.3555],
    [-0.2882, 1.2276, 2.357],
    [-0.2824, 1.2343, 2.3585],
    [-0.2766, 1.241, 2.36],
    [-0.2707, 1.2477, 2.3616],
    [-0.2649, 1.2544, 2.3631],
    [-0.2591, 1.2611, 2.3646],
    [-0.2533, 1.2678, 2.3661],
    [-0.2475, 1.2745, 2.3676],
    [-0.2416, 1.2811, 2.369],
    [-0.2357, 1.2878, 2.3703],
    [-0.2297, 1.2944, 2.3716],
    [-0.2238, 1.3011, 2.3729],
    [-0.2179, 1.3077, 2.3742],
    [-0.212, 1.3144, 2.3754],
    [-0.206, 1.321, 2.3767],
    [-0.2001, 1.3277, 2.378],
    [-0.1942, 1.3343, 2.3793],
    [-0.1882, 1.341, 2.3806],
    [-0.1823, 1.3476, 2.3818],
    [-0.1764, 1.3543, 2.383],
    [-0.1703, 1.3609, 2.3841],
    [-0.1643, 1.3675, 2.3851],
    [-0.1583, 1.3741, 2.3861],
    [-0.1522, 1.3807, 2.3872],
    [-0.1462, 1.3873, 2.3882],
    [-0.1401, 1.3939, 2.3892],
    [-0.1341, 1.4004, 2.3903],
    [-0.1281, 1.407, 2.3913],
    [-0.122, 1.4136, 2.3923],
    [-0.116, 1.4202, 2.3934],
    [-0.1099, 1.4268, 2.3942],
    [-0.1038, 1.4333, 2.395],
    [-0.0976, 1.4399, 2.3958],
    [-0.0915, 1.4464, 2.3965],
    [-0.0853, 1.4529, 2.3973],
    [-0.0792, 1.4595, 2.3981],
    [-0.0731, 1.466, 2.3989],
    [-0.0669, 1.4725, 2.3996],
    [-0.0608, 1.4791, 2.4004],
    [-0.0546, 1.4856, 2.4012],
    [-0.0484, 1.4921, 2.4018],
    [-0.0422, 1.4986, 2.4023],
    [-0.036, 1.505, 2.4028],
    [-0.0297, 1.5115, 2.4033],
    [-0.0235, 1.5179, 2.4038],
    [-0.0172, 1.5244, 2.4043],
    [-0.011, 1.5309, 2.4048],
    [-0.0047, 1.5373, 2.4053],
    [0.0015, 1.5438, 2.4058],
    [0.0078, 1.5503, 2.4063],
    [0.0141, 1.5567, 2.4067],
    [0.0204, 1.563, 2.4069],
    [0.0268, 1.5694, 2.4071],
    [0.0331, 1.5758, 2.4073],
    [0.0394, 1.5822, 2.4075],
    [0.0458, 1.5886, 2.4078],
    [0.0521, 1.5949, 2.408],
    [0.0585, 1.6013, 2.4082],
    [0.0648, 1.6077, 2.4084],
    [0.0712, 1.6141, 2.4086],
    [0.0776, 1.6204, 2.4085],
    [0.0841, 1.6266, 2.4084],
    [0.0905, 1.6329, 2.4084],
    [0.0969, 1.6392, 2.4083],
    [0.1034, 1.6455, 2.4082],
    [0.1098, 1.6518, 2.4082],
    [0.1163, 1.6581, 2.4081],
    [0.1227, 1.6644, 2.408],
    [0.1291, 1.6707, 2.4079],
    [0.1357, 1.6768, 2.4076],
    [0.1422, 1.683, 2.4072],
    [0.1487, 1.6892, 2.4068],
    [0.1553, 1.6954, 2.4064],
    [0.1618, 1.7016, 2.406],
    [0.1683, 1.7077, 2.4057],
    [0.1749, 1.7139, 2.4053],
    [0.1814, 1.7201, 2.4049],
    [0.1879, 1.7263, 2.4045],
    [0.1945, 1.7323, 2.4038],
    [0.2012, 1.7384, 2.4031],
    [0.2078, 1.7445, 2.4024],
    [0.2144, 1.7505, 2.4017],
    [0.221, 1.7566, 2.401],
    [0.2276, 1.7626, 2.4004],
    [0.2342, 1.7687, 2.3997],
    [0.2409, 1.7748, 2.399],
    [0.2475, 1.7807, 2.3981],
    [0.2542, 1.7867, 2.3971],
    [0.2609, 1.7926, 2.3961],
    [0.2676, 1.7985, 2.395],
    [0.2743, 1.8045, 2.394],
    [0.281, 1.8104, 2.393],
    [0.2877, 1.8163, 2.392],
    [0.2944, 1.8223, 2.391],
    [0.3011, 1.8282, 2.3899],
    [0.3078, 1.8339, 2.3885],
    [0.3146, 1.8397, 2.3872],
    [0.3214, 1.8455, 2.3858],
    [0.3281, 1.8513, 2.3845],
    [0.3349, 1.8571, 2.3831],
    [0.3416, 1.8629, 2.3818],
    [0.3484, 1.8687, 2.3805],
    [0.3552, 1.8744, 2.379],
    [0.362, 1.88, 2.3773],
    [0.3688, 1.8857, 2.3756],
    [0.3756, 1.8913, 2.3739],
    [0.3825, 1.8969, 2.3723],
    [0.3893, 1.9025, 2.3706],
    [0.3961, 1.9082, 2.3689],
    [0.4029, 1.9138, 2.3672],
    [0.4098, 1.9193, 2.3653],
    [0.4166, 1.9248, 2.3633],
    [0.4235, 1.9302, 2.3613],
    [0.4304, 1.9357, 2.3593],
    [0.4372, 1.9411, 2.3572],
    [0.4441, 1.9466, 2.3552],
    [0.451, 1.952, 2.3532],
    [0.4578, 1.9575, 2.3512],
    [0.4647, 1.9628, 2.3488],
    [0.4716, 1.968, 2.3464],
    [0.4785, 1.9733, 2.344],
    [0.4854, 1.9786, 2.3417],
    [0.4923, 1.9838, 2.3393],
    [0.4993, 1.9891, 2.3369],
    [0.5062, 1.9944, 2.3345],
    [0.5131, 1.9995, 2.332],
    [0.52, 2.0046, 2.3292],
    [0.5269, 2.0096, 2.3265],
    [0.5338, 2.0147, 2.3238],
    [0.5408, 2.0197, 2.321],
    [0.5477, 2.0248, 2.3183],
    [0.5546, 2.0299, 2.3156],
    [0.5615, 2.0348, 2.3127],
    [0.5685, 2.0397, 2.3096],
    [0.5754, 2.0445, 2.3066],
    [0.5823, 2.0494, 2.3035],
    [0.5893, 2.0542, 2.3004],
    [0.5962, 2.059, 2.2973],
    [0.6031, 2.0639, 2.2942],
    [0.6101, 2.0687, 2.291],
    [0.617, 2.0733, 2.2876],
    [0.6239, 2.0779, 2.2841],
    [0.6308, 2.0825, 2.2807],
    [0.6378, 2.0871, 2.2773],
    [0.6447, 2.0917, 2.2738],
    [0.6516, 2.0963, 2.2704],
    [0.6585, 2.1008, 2.2668],
    [0.6654, 2.1052, 2.263],
    [0.6723, 2.1095, 2.2592],
    [0.6792, 2.1139, 2.2555],
    [0.6862, 2.1182, 2.2517],
    [0.6931, 2.1226, 2.2479],
    [0.7, 2.1269, 2.2441],
    [0.7068, 2.1312, 2.2401],
    [0.7137, 2.1353, 2.236],
    [0.7206, 2.1394, 2.2318],
    [0.7274, 2.1434, 2.2277],
    [0.7343, 2.1475, 2.2235],
    [0.7411, 2.1516, 2.2194],
    [0.748, 2.1557, 2.2152],
    [0.7548, 2.1596, 2.2108],
    [0.7616, 2.1634, 2.2064],
    [0.7684, 2.1672, 2.2019],
    [0.7752, 2.1711, 2.1974],
    [0.7821, 2.1749, 2.1929],
    [0.7889, 2.1787, 2.1884],
    [0.7957, 2.1825, 2.1839],
    [0.8024, 2.186, 2.1791],
    [0.8091, 2.1895, 2.1742],
    [0.8158, 2.1931, 2.1694],
    [0.8226, 2.1966, 2.1646],
    [0.8293, 2.2001, 2.1598],
    [0.836, 2.2036, 2.1549],
    [0.8427, 2.207, 2.15],
    [0.8494, 2.2102, 2.1448],
    [0.856, 2.2135, 2.1397],
    [0.8626, 2.2167, 2.1345],
    [0.8693, 2.2199, 2.1293],
    [0.8759, 2.2231, 2.1242],
    [0.8825, 2.2263, 2.119],
    [0.8891, 2.2293, 2.1136],
    [0.8956, 2.2322, 2.1081],
    [0.9021, 2.2351, 2.1027],
    [0.9087, 2.238, 2.0972],
    [0.9152, 2.241, 2.0917],
    [0.9217, 2.2439, 2.0863],
    [0.9282, 2.2467, 2.0807],
    [0.9346, 2.2493, 2.0749],
    [0.941, 2.2519, 2.0691],
    [0.9474, 2.2545, 2.0634],
    [0.9538, 2.257, 2.0576],
    [0.9602, 2.2596, 2.0518],
    [0.9666, 2.2622, 2.046],
    [0.9729, 2.2646, 2.0401],
    [0.9792, 2.2668, 2.034],
    [0.9854, 2.2691, 2.0279],
    [0.9917, 2.2714, 2.0219],
    [0.9979, 2.2736, 2.0158],
    [1.0042, 2.2759, 2.0098],
    [1.0104, 2.2781, 2.0036],
    [1.0165, 2.28, 1.9973],
    [1.0226, 2.282, 1.991],
    [1.0287, 2.2839, 1.9846],
    [1.0348, 2.2858, 1.9783],
    [1.0409, 2.2878, 1.972],
    [1.047, 2.2897, 1.9656],
    [1.053, 2.2914, 1.9591],
    [1.0589, 2.293, 1.9526],
    [1.0648, 2.2946, 1.946],
    [1.0707, 2.2962, 1.9394],
    [1.0766, 2.2978, 1.9328],
    [1.0826, 2.2994, 1.9262],
    [1.0885, 2.301, 1.9196],
    [1.0942, 2.3023, 1.9128],
    [1.1, 2.3036, 1.906],
    [1.1057, 2.3048, 1.8992],
    [1.1114, 2.3061, 1.8923],
    [1.1172, 2.3074, 1.8855],
    [1.1229, 2.3086, 1.8787],
    [1.1286, 2.3098, 1.8718],
    [1.1341, 2.3107, 1.8648],
    [1.1396, 2.3116, 1.8577],
    [1.1451, 2.3126, 1.8507],
    [1.1507, 2.3135, 1.8436],
    [1.1562, 2.3144, 1.8366],
    [1.1617, 2.3154, 1.8296],
    [1.1672, 2.3161, 1.8224],
    [1.1725, 2.3167, 1.8152],
    [1.1778, 2.3173, 1.8079],
    [1.1831, 2.3179, 1.8007],
    [1.1884, 2.3185, 1.7935],
    [1.1938, 2.3191, 1.7862],
    [1.1991, 2.3197, 1.779],
    [1.2042, 2.32, 1.7716],
    [1.2093, 2.3203, 1.7642],
    [1.2145, 2.3205, 1.7568],
    [1.2196, 2.3208, 1.7494],
    [1.2247, 2.3211, 1.742],
    [1.2298, 2.3213, 1.7346],
    [1.2349, 2.3216, 1.7272],
    [1.2398, 2.3216, 1.7197],
    [1.2447, 2.3216, 1.7121],
    [1.2495, 2.3215, 1.7045],
    [1.2544, 2.3215, 1.697],
    [1.2593, 2.3214, 1.6894],
    [1.2641, 2.3213, 1.6818],
    [1.269, 2.3213, 1.6743],
    [1.2737, 2.321, 1.6666],
    [1.2784, 2.3206, 1.6589],
    [1.283, 2.3202, 1.6512],
    [1.2876, 2.3199, 1.6435],
    [1.2923, 2.3195, 1.6358],
    [1.2969, 2.3191, 1.6281],
    [1.3016, 2.3187, 1.6204],
    [1.3061, 2.3182, 1.6126],
    [1.3105, 2.3175, 1.6048],
    [1.3149, 2.3169, 1.597],
    [1.3193, 2.3162, 1.5892],
    [1.3237, 2.3155, 1.5813],
    [1.3281, 2.3148, 1.5735],
    [1.3325, 2.3141, 1.5657],
    [1.3369, 2.3134, 1.5579],
    [1.341, 2.3124, 1.5499],
    [1.3452, 2.3114, 1.542],
    [1.3494, 2.3104, 1.5341],
    [1.3535, 2.3094, 1.5262],
    [1.3577, 2.3085, 1.5183],
    [1.3618, 2.3075, 1.5103],
    [1.366, 2.3065, 1.5024],
    [1.37, 2.3054, 1.4945],
    [1.374, 2.3041, 1.4865],
    [1.3779, 2.3028, 1.4785],
    [1.3818, 2.3015, 1.4705],
    [1.3857, 2.3002, 1.4625],
    [1.3896, 2.299, 1.4545],
    [1.3935, 2.2977, 1.4465],
    [1.3974, 2.2964, 1.4384],
    [1.4012, 2.2949, 1.4304],
    [1.4049, 2.2934, 1.4223],
    [1.4085, 2.2918, 1.4143],
    [1.4122, 2.2902, 1.4062],
    [1.4159, 2.2887, 1.3981],
    [1.4195, 2.2871, 1.3901],
    [1.4232, 2.2856, 1.382],
    [1.4269, 2.284, 1.3739],
    [1.4304, 2.2823, 1.3658],
    [1.4338, 2.2804, 1.3577],
    [1.4373, 2.2786, 1.3496],
    [1.4407, 2.2767, 1.3415],
    [1.4441, 2.2749, 1.3334],
    [1.4475, 2.2731, 1.3252],
    [1.451, 2.2712, 1.3171],
    [1.4544, 2.2694, 1.309],
    [1.4577, 2.2675, 1.3009],
    [1.4609, 2.2654, 1.2927],
    [1.4641, 2.2633, 1.2846],
    [1.4673, 2.2612, 1.2764],
    [1.4705, 2.2591, 1.2683],
    [1.4737, 2.257, 1.2601],
    [1.4768, 2.2549, 1.252],
    [1.48, 2.2528, 1.2438],
    [1.4832, 2.2507, 1.2356],
    [1.4862, 2.2484, 1.2275],
    [1.4891, 2.2461, 1.2193],
    [1.4921, 2.2437, 1.2111],
    [1.495, 2.2414, 1.203],
    [1.498, 2.2391, 1.1948],
    [1.5009, 2.2367, 1.1866],
    [1.5039, 2.2344, 1.1784],
    [1.5068, 2.232, 1.1703],
    [1.5098, 2.2297, 1.1621],
    [1.5125, 2.2271, 1.1539],
    [1.5152, 2.2245, 1.1457],
    [1.5179, 2.222, 1.1375],
    [1.5206, 2.2194, 1.1293],
    [1.5233, 2.2168, 1.1212],
    [1.5261, 2.2142, 1.113],
    [1.5288, 2.2116, 1.1048],
    [1.5315, 2.2091, 1.0966],
    [1.5342, 2.2065, 1.0884],
    [1.5367, 2.2037, 1.0802],
    [1.5392, 2.2009, 1.072],
    [1.5417, 2.1981, 1.0639],
    [1.5442, 2.1953, 1.0557],
    [1.5466, 2.1925, 1.0475],
    [1.5491, 2.1897, 1.0393],
    [1.5516, 2.1869, 1.0311],
    [1.5541, 2.1841, 1.0229],
    [1.5566, 2.1813, 1.0148],
    [1.559, 2.1784, 1.0066],
    [1.5612, 2.1754, 0.9984],
    [1.5635, 2.1724, 0.9902],
    [1.5658, 2.1693, 0.9821],
    [1.568, 2.1663, 0.9739],
    [1.5703, 2.1633, 0.9657],
    [1.5725, 2.1603, 0.9575],
    [1.5748, 2.1573, 0.9494],
    [1.5771, 2.1543, 0.9412],
    [1.5793, 2.1512, 0.933],
    [1.5815, 2.1481, 0.9249],
    [1.5835, 2.1449, 0.9167],
    [1.5855, 2.1417, 0.9086],
    [1.5876, 2.1385, 0.9004],
    [1.5896, 2.1352, 0.8923],
    [1.5917, 2.132, 0.8841],
    [1.5937, 2.1288, 0.876],
    [1.5957, 2.1256, 0.8678],
    [1.5978, 2.1224, 0.8596],
    [1.5998, 2.1191, 0.8515],
    [1.6018, 2.1159, 0.8433],
    [1.6036, 2.1124, 0.8352],
    [1.6055, 2.109, 0.8271],
    [1.6073, 2.1056, 0.819],
    [1.6091, 2.1022, 0.8108],
    [1.6109, 2.0988, 0.8027],
    [1.6128, 2.0954, 0.7946],
    [1.6146, 2.092, 0.7865],
    [1.6164, 2.0886, 0.7783],
    [1.6182, 2.0852, 0.7702],
    [1.6201, 2.0817, 0.7621],
    [1.6218, 2.0782, 0.754],
    [1.6234, 2.0746, 0.7459],
    [1.625, 2.071, 0.7378],
    [1.6266, 2.0674, 0.7297],
    [1.6283, 2.0639, 0.7216],
    [1.6299, 2.0603, 0.7135],
    [1.6315, 2.0567, 0.7054],
    [1.6331, 2.0531, 0.6974],
    [1.6347, 2.0495, 0.6893],
    [1.6364, 2.0459, 0.6812],
    [1.638, 2.0423, 0.6731],
    [1.6395, 2.0386, 0.665],
    [1.6409, 2.0349, 0.657],
    [1.6424, 2.0311, 0.6489],
    [1.6438, 2.0273, 0.6409],
    [1.6452, 2.0236, 0.6328],
    [1.6466, 2.0198, 0.6248],
    [1.648, 2.016, 0.6167],
    [1.6495, 2.0123, 0.6086],
    [1.6509, 2.0085, 0.6006],
    [1.6523, 2.0048, 0.5925],
    [1.6537, 2.001, 0.5845],
    [1.6552, 1.9972, 0.5764],
    [1.6564, 1.9933, 0.5684],
    [1.6576, 1.9894, 0.5604],
    [1.6589, 1.9855, 0.5524],
    [1.6601, 1.9815, 0.5444],
    [1.6613, 1.9776, 0.5364],
    [1.6626, 1.9737, 0.5284],
    [1.6638, 1.9698, 0.5204],
    [1.665, 1.9659, 0.5124],
    [1.6662, 1.9619, 0.5044],
    [1.6675, 1.958, 0.4964],
    [1.6687, 1.9541, 0.4884],
    [1.6699, 1.9502, 0.4804]
  ],

  sanfrancisco: [
    [-1.61, 1.54, 1.35],
    [-1.6052, 1.5473, 1.3523],
    [-1.6004, 1.5545, 1.3545],
    [-1.5956, 1.5618, 1.3568],
    [-1.5908, 1.5691, 1.3591],
    [-1.586, 1.5764, 1.3613],
    [-1.5813, 1.5836, 1.3636],
    [-1.5765, 1.5909, 1.3659],
    [-1.5717, 1.5982, 1.3681],
    [-1.5669, 1.6055, 1.3704],
    [-1.5621, 1.6127, 1.3727],
    [-1.5573, 1.62, 1.3749],
    [-1.5525, 1.6273, 1.3772],
    [-1.5477, 1.6346, 1.3795],
    [-1.5429, 1.6418, 1.3817],
    [-1.538, 1.649, 1.3839],
    [-1.533, 1.6562, 1.3861],
    [-1.528, 1.6634, 1.3882],
    [-1.523, 1.6706, 1.3904],
    [-1.518, 1.6777, 1.3925],
    [-1.513, 1.6849, 1.3947],
    [-1.508, 1.6921, 1.3968],
    [-1.503, 1.6993, 1.399],
    [-1.4981, 1.7064, 1.4011],
    [-1.4931, 1.7136, 1.4033],
    [-1.4881, 1.7208, 1.4054],
    [-1.4831, 1.7279, 1.4075],
    [-1.4781, 1.7351, 1.4097],
    [-1.4731, 1.7423, 1.4118],
    [-1.4679, 1.7494, 1.4139],
    [-1.4627, 1.7564, 1.4159],
    [-1.4575, 1.7635, 1.4179],
    [-1.4523, 1.7706, 1.4199],
    [-1.4471, 1.7776, 1.422],
    [-1.4419, 1.7847, 1.424],
    [-1.4367, 1.7917, 1.426],
    [-1.4315, 1.7988, 1.428],
    [-1.4263, 1.8059, 1.43],
    [-1.4211, 1.8129, 1.4321],
    [-1.4159, 1.82, 1.4341],
    [-1.4107, 1.8271, 1.4361],
    [-1.4055, 1.8341, 1.4381],
    [-1.4002, 1.8411, 1.44],
    [-1.3948, 1.848, 1.4419],
    [-1.3894, 1.855, 1.4438],
    [-1.384, 1.8619, 1.4457],
    [-1.3786, 1.8689, 1.4476],
    [-1.3731, 1.8758, 1.4495],
    [-1.3677, 1.8828, 1.4514],
    [-1.3623, 1.8897, 1.4533],
    [-1.3569, 1.8967, 1.4552],
    [-1.3515, 1.9036, 1.457],
    [-1.3461, 1.9105, 1.4589],
    [-1.3407, 1.9175, 1.4608],
    [-1.3353, 1.9244, 1.4627],
    [-1.3297, 1.9312, 1.4644],
    [-1.324, 1.938, 1.4662],
    [-1.3184, 1.9448, 1.4679],
    [-1.3128, 1.9516, 1.4697],
    [-1.3072, 1.9585, 1.4714],
    [-1.3016, 1.9653, 1.4732],
    [-1.296, 1.9721, 1.4749],
    [-1.2903, 1.9789, 1.4767],
    [-1.2847, 1.9857, 1.4784],
    [-1.2791, 1.9925, 1.4802],
    [-1.2735, 1.9993, 1.4819],
    [-1.2679, 2.0061, 1.4837],
    [-1.262, 2.0128, 1.4853],
    [-1.2562, 2.0195, 1.4869],
    [-1.2504, 2.0261, 1.4885],
    [-1.2445, 2.0328, 1.4901],
    [-1.2387, 2.0395, 1.4917],
    [-1.2329, 2.0461, 1.4933],
    [-1.227, 2.0528, 1.4949],
    [-1.2212, 2.0595, 1.4965],
    [-1.2154, 2.0661, 1.4981],
    [-1.2095, 2.0728, 1.4997],
    [-1.2037, 2.0795, 1.5013],
    [-1.1978, 2.0861, 1.5028],
    [-1.1918, 2.0926, 1.5043],
    [-1.1857, 2.0991, 1.5057],
    [-1.1797, 2.1056, 1.5071],
    [-1.1736, 2.1121, 1.5086],
    [-1.1676, 2.1186, 1.51],
    [-1.1615, 2.1251, 1.5115],
    [-1.1555, 2.1316, 1.5129],
    [-1.1494, 2.1381, 1.5144],
    [-1.1434, 2.1446, 1.5158],
    [-1.1373, 2.1511, 1.5173],
    [-1.1313, 2.1576, 1.5187],
    [-1.125, 2.164, 1.52],
    [-1.1187, 2.1703, 1.5212],
    [-1.1125, 2.1766, 1.5225],
    [-1.1062, 2.183, 1.5238],
    [-1.1, 2.1893, 1.5251],
    [-1.0937, 2.1956, 1.5264],
    [-1.0874, 2.202, 1.5277],
    [-1.0812, 2.2083, 1.5289],
    [-1.0749, 2.2147, 1.5302],
    [-1.0686, 2.221, 1.5315],
    [-1.0623, 2.2273, 1.5327],
    [-1.0558, 2.2334, 1.5339],
    [-1.0494, 2.2396, 1.535],
    [-1.0429, 2.2457, 1.5361],
    [-1.0364, 2.2519, 1.5372],
    [-1.0299, 2.258, 1.5383],
    [-1.0234, 2.2642, 1.5394],
    [-1.017, 2.2703, 1.5405],
    [-1.0105, 2.2765, 1.5416],
    [-1.004, 2.2826, 1.5427],
    [-0.9975, 2.2888, 1.5438],
    [-0.9909, 2.2947, 1.5448],
    [-0.9842, 2.3007, 1.5458],
    [-0.9775, 2.3066, 1.5467],
    [-0.9708, 2.3126, 1.5476],
    [-0.9641, 2.3185, 1.5486],
    [-0.9574, 2.3245, 1.5495],
    [-0.9507, 2.3304, 1.5504],
    [-0.944, 2.3364, 1.5513],
    [-0.9373, 2.3423, 1.5523],
    [-0.9307, 2.3482, 1.5532],
    [-0.9238, 2.3541, 1.554],
    [-0.9169, 2.3598, 1.5548],
    [-0.91, 2.3655, 1.5555],
    [-0.9031, 2.3712, 1.5563],
    [-0.8962, 2.377, 1.557],
    [-0.8893, 2.3827, 1.5578],
    [-0.8824, 2.3884, 1.5585],
    [-0.8755, 2.3941, 1.5593],
    [-0.8686, 2.3999, 1.56],
    [-0.8617, 2.4056, 1.5607],
    [-0.8546, 2.4111, 1.5613],
    [-0.8475, 2.4166, 1.5619],
    [-0.8404, 2.4221, 1.5624],
    [-0.8333, 2.4276, 1.563],
    [-0.8262, 2.4331, 1.5636],
    [-0.8191, 2.4386, 1.5641],
    [-0.812, 2.4441, 1.5647],
    [-0.8049, 2.4496, 1.5652],
    [-0.7978, 2.4551, 1.5658],
    [-0.7906, 2.4605, 1.5663],
    [-0.7833, 2.4657, 1.5666],
    [-0.776, 2.4709, 1.567],
    [-0.7687, 2.4762, 1.5673],
    [-0.7613, 2.4814, 1.5677],
    [-0.754, 2.4867, 1.568],
    [-0.7467, 2.4919, 1.5684],
    [-0.7394, 2.4971, 1.5687],
    [-0.7321, 2.5024, 1.5691],
    [-0.7248, 2.5076, 1.5694],
    [-0.7173, 2.5126, 1.5696],
    [-0.7098, 2.5176, 1.5697],
    [-0.7023, 2.5225, 1.5699],
    [-0.6948, 2.5275, 1.57],
    [-0.6873, 2.5325, 1.5702],
    [-0.6798, 2.5374, 1.5703],
    [-0.6723, 2.5424, 1.5705],
    [-0.6648, 2.5474, 1.5706],
    [-0.6573, 2.5523, 1.5708],
    [-0.6496, 2.557, 1.5707],
    [-0.6419, 2.5617, 1.5706],
    [-0.6342, 2.5664, 1.5706],
    [-0.6265, 2.5711, 1.5705],
    [-0.6189, 2.5758, 1.5705],
    [-0.6112, 2.5805, 1.5704],
    [-0.6035, 2.5851, 1.5703],
    [-0.5958, 2.5898, 1.5703],
    [-0.5881, 2.5944, 1.5701],
    [-0.5802, 2.5988, 1.5699],
    [-0.5724, 2.6032, 1.5696],
    [-0.5645, 2.6076, 1.5693],
    [-0.5567, 2.612, 1.569],
    [-0.5488, 2.6164, 1.5687],
    [-0.541, 2.6207, 1.5685],
    [-0.5331, 2.6251, 1.5682],
    [-0.5252, 2.6295, 1.5679],
    [-0.5173, 2.6337, 1.5675],
    [-0.5093, 2.6378, 1.567],
    [-0.5013, 2.6418, 1.5665],
    [-0.4932, 2.6459, 1.566],
    [-0.4852, 2.65, 1.5655],
    [-0.4772, 2.654, 1.565],
    [-0.4692, 2.6581, 1.5645],
    [-0.4612, 2.6622, 1.564],
    [-0.4531, 2.6661, 1.5634],
    [-0.445, 2.6699, 1.5627],
    [-0.4368, 2.6736, 1.562],
    [-0.4287, 2.6773, 1.5612],
    [-0.4205, 2.6811, 1.5605],
    [-0.4124, 2.6848, 1.5598],
    [-0.4042, 2.6885, 1.5591],
    [-0.396, 2.6923, 1.5583],
    [-0.3879, 2.696, 1.5576],
    [-0.3796, 2.6994, 1.5567],
    [-0.3713, 2.7028, 1.5557],
    [-0.363, 2.7062, 1.5548],
    [-0.3547, 2.7096, 1.5538],
    [-0.3465, 2.7129, 1.5529],
    [-0.3382, 2.7163, 1.5519],
    [-0.3299, 2.7197, 1.551],
    [-0.3216, 2.7231, 1.55],
    [-0.3132, 2.7262, 1.5489],
    [-0.3048, 2.7292, 1.5477],
    [-0.2964, 2.7322, 1.5465],
    [-0.288, 2.7353, 1.5453],
    [-0.2797, 2.7383, 1.5441],
    [-0.2713, 2.7413, 1.543],
    [-0.2629, 2.7443, 1.5418],
    [-0.2545, 2.7474, 1.5406],
    [-0.246, 2.7501, 1.5392],
    [-0.2375, 2.7527, 1.5378],
    [-0.229, 2.7554, 1.5364],
    [-0.2205, 2.758, 1.535],
    [-0.2121, 2.7607, 1.5336],
    [-0.2036, 2.7633, 1.5322],
    [-0.1951, 2.766, 1.5308],
    [-0.1866, 2.7686, 1.5294],
    [-0.1781, 2.7709, 1.5278],
    [-0.1695, 2.7732, 1.5261],
    [-0.1609, 2.7755, 1.5245],
    [-0.1524, 2.7777, 1.5229],
    [-0.1438, 2.78, 1.5212],
    [-0.1353, 2.7823, 1.5196],
    [-0.1267, 2.7845, 1.518],
    [-0.1182, 2.7868, 1.5163],
    [-0.1096, 2.7886, 1.5145],
    [-0.101, 2.7905, 1.5126],
    [-0.0924, 2.7924, 1.5107],
    [-0.0838, 2.7943, 1.5089],
    [-0.0751, 2.7962, 1.507],
    [-0.0665, 2.798, 1.5052],
    [-0.0579, 2.7999, 1.5033],
    [-0.0493, 2.8017, 1.5014],
    [-0.0407, 2.8031, 1.4993],
    [-0.0321, 2.8046, 1.4972],
    [-0.0234, 2.8061, 1.4952],
    [-0.0148, 2.8076, 1.4931],
    [-0.0062, 2.8091, 1.491],
    [0.0025, 2.8105, 1.4889],
    [0.0111, 2.812, 1.4868],
    [0.0197, 2.8133, 1.4846],
    [0.0284, 2.8143, 1.4824],
    [0.037, 2.8154, 1.4801],
    [0.0456, 2.8165, 1.4778],
    [0.0543, 2.8176, 1.4755],
    [0.0629, 2.8186, 1.4732],
    [0.0715, 2.8197, 1.4709],
    [0.0802, 2.8208, 1.4686],
    [0.0888, 2.8215, 1.4661],
    [0.0974, 2.8222, 1.4636],
    [0.106, 2.8229, 1.4611],
    [0.1147, 2.8235, 1.4586],
    [0.1233, 2.8242, 1.4561],
    [0.1319, 2.8249, 1.4536],
    [0.1405, 2.8255, 1.4511],
    [0.1491, 2.8262, 1.4486],
    [0.1577, 2.8265, 1.4459],
    [0.1663, 2.8267, 1.4432],
    [0.1749, 2.827, 1.4405],
    [0.1835, 2.8272, 1.4378],
    [0.192, 2.8275, 1.4351],
    [0.2006, 2.8278, 1.4324],
    [0.2092, 2.828, 1.4297],
    [0.2178, 2.8282, 1.4269],
    [0.2263, 2.828, 1.424],
    [0.2348, 2.8279, 1.4211],
    [0.2433, 2.8278, 1.4182],
    [0.2518, 2.8276, 1.4153],
    [0.2604, 2.8275, 1.4124],
    [0.2689, 2.8273, 1.4095],
    [0.2774, 2.8272, 1.4066],
    [0.2859, 2.8269, 1.4036],
    [0.2943, 2.8263, 1.4005],
    [0.3028, 2.8258, 1.3974],
    [0.3112, 2.8252, 1.3944],
    [0.3196, 2.8247, 1.3913],
    [0.3281, 2.8241, 1.3882],
    [0.3365, 2.8236, 1.3851],
    [0.3449, 2.823, 1.382],
    [0.3533, 2.8223, 1.3788],
    [0.3617, 2.8213, 1.3756],
    [0.37, 2.8204, 1.3723],
    [0.3783, 2.8194, 1.369],
    [0.3867, 2.8185, 1.3658],
    [0.395, 2.8176, 1.3625],
    [0.4033, 2.8166, 1.3593],
    [0.4117, 2.8157, 1.356],
    [0.4199, 2.8145, 1.3526],
    [0.4282, 2.8132, 1.3492],
    [0.4364, 2.8119, 1.3458],
    [0.4446, 2.8105, 1.3424],
    [0.4528, 2.8092, 1.3389],
    [0.461, 2.8079, 1.3355],
    [0.4692, 2.8065, 1.3321],
    [0.4774, 2.8052, 1.3286],
    [0.4856, 2.8037, 1.3251],
    [0.4937, 2.802, 1.3216],
    [0.5017, 2.8003, 1.318],
    [0.5098, 2.7986, 1.3144],
    [0.5179, 2.7968, 1.3108],
    [0.526, 2.7951, 1.3072],
    [0.534, 2.7934, 1.3036],
    [0.5421, 2.7917, 1.3],
    [0.5502, 2.7899, 1.2964],
    [0.5581, 2.7878, 1.2927],
    [0.566, 2.7858, 1.289],
    [0.5739, 2.7837, 1.2852],
    [0.5818, 2.7816, 1.2815],
    [0.5898, 2.7795, 1.2778],
    [0.5977, 2.7774, 1.274],
    [0.6056, 2.7753, 1.2703],
    [0.6135, 2.7732, 1.2666],
    [0.6213, 2.7709, 1.2627],
    [0.6291, 2.7684, 1.2589],
    [0.6368, 2.766, 1.255],
    [0.6446, 2.7635, 1.2512],
    [0.6523, 2.7611, 1.2473],
    [0.6601, 2.7587, 1.2434],
    [0.6678, 2.7562, 1.2396],
    [0.6756, 2.7538, 1.2357],
    [0.6833, 2.7513, 1.2318],
    [0.6909, 2.7485, 1.2278],
    [0.6984, 2.7457, 1.2238],
    [0.706, 2.7429, 1.2198],
    [0.7136, 2.7401, 1.2158],
    [0.7211, 2.7373, 1.2119],
    [0.7287, 2.7345, 1.2079],
    [0.7363, 2.7317, 1.2039],
    [0.7439, 2.7289, 1.1999],
    [0.7513, 2.726, 1.1959],
    [0.7587, 2.7229, 1.1918],
    [0.7661, 2.7198, 1.1877],
    [0.7735, 2.7166, 1.1836],
    [0.7809, 2.7135, 1.1795],
    [0.7882, 2.7104, 1.1754],
    [0.7956, 2.7073, 1.1713],
    [0.803, 2.7041, 1.1672],
    [0.8104, 2.701, 1.1631],
    [0.8177, 2.6978, 1.1589],
    [0.8248, 2.6943, 1.1547],
    [0.832, 2.6909, 1.1505],
    [0.8392, 2.6874, 1.1463],
    [0.8464, 2.684, 1.1421],
    [0.8535, 2.6806, 1.1379],
    [0.8607, 2.6771, 1.1337],
    [0.8679, 2.6737, 1.1295],
    [0.8751, 2.6702, 1.1253],
    [0.8822, 2.6667, 1.1211],
    [0.8892, 2.663, 1.1168],
    [0.8961, 2.6592, 1.1125],
    [0.9031, 2.6555, 1.1082],
    [0.9101, 2.6518, 1.1039],
    [0.917, 2.648, 1.0996],
    [0.924, 2.6443, 1.0953],
    [0.931, 2.6405, 1.091],
    [0.9379, 2.6368, 1.0867],
    [0.9449, 2.633, 1.0824],
    [0.9517, 2.6291, 1.0781],
    [0.9585, 2.6251, 1.0737],
    [0.9652, 2.621, 1.0693],
    [0.972, 2.617, 1.0649],
    [0.9787, 2.613, 1.0606],
    [0.9855, 2.6089, 1.0562],
    [0.9923, 2.6049, 1.0518],
    [0.999, 2.6009, 1.0474],
    [1.0058, 2.5968, 1.0431],
    [1.0125, 2.5928, 1.0387],
    [1.0191, 2.5885, 1.0342],
    [1.0256, 2.5842, 1.0298],
    [1.0321, 2.5799, 1.0253],
    [1.0387, 2.5756, 1.0209],
    [1.0452, 2.5713, 1.0165],
    [1.0517, 2.567, 1.012],
    [1.0583, 2.5627, 1.0076],
    [1.0648, 2.5584, 1.0031],
    [1.0713, 2.5541, 0.9987],
    [1.0779, 2.5498, 0.9942],
    [1.0842, 2.5453, 0.9897],
    [1.0905, 2.5407, 0.9852],
    [1.0969, 2.5362, 0.9807],
    [1.1032, 2.5316, 0.9762],
    [1.1095, 2.527, 0.9717],
    [1.1158, 2.5225, 0.9672],
    [1.1221, 2.5179, 0.9627],
    [1.1284, 2.5134, 0.9581],
    [1.1347, 2.5088, 0.9536],
    [1.141, 2.5042, 0.9491],
    [1.1473, 2.4996, 0.9446],
    [1.1534, 2.4948, 0.94],
    [1.1595, 2.49, 0.9355],
    [1.1655, 2.4852, 0.9309],
    [1.1716, 2.4804, 0.9263],
    [1.1777, 2.4756, 0.9218],
    [1.1838, 2.4708, 0.9172],
    [1.1899, 2.466, 0.9126],
    [1.196, 2.4612, 0.9081],
    [1.2021, 2.4563, 0.9035],
    [1.2082, 2.4515, 0.8989],
    [1.2141, 2.4466, 0.8944],
    [1.22, 2.4416, 0.8897],
    [1.2259, 2.4366, 0.8851],
    [1.2317, 2.4315, 0.8805],
    [1.2376, 2.4265, 0.8759],
    [1.2435, 2.4215, 0.8713],
    [1.2493, 2.4164, 0.8667],
    [1.2552, 2.4114, 0.8621],
    [1.2611, 2.4064, 0.8575],
    [1.2669, 2.4013, 0.8528],
    [1.2728, 2.3963, 0.8482],
    [1.2786, 2.3912, 0.8436],
    [1.2843, 2.386, 0.839],
    [1.2899, 2.3807, 0.8343],
    [1.2955, 2.3755, 0.8297],
    [1.3012, 2.3702, 0.825],
    [1.3068, 2.365, 0.8204],
    [1.3125, 2.3597, 0.8157],
    [1.3181, 2.3545, 0.8111],
    [1.3238, 2.3492, 0.8064],
    [1.3294, 2.344, 0.8017],
    [1.335, 2.3388, 0.7971],
    [1.3407, 2.3335, 0.7924],
    [1.3462, 2.3281, 0.7878],
    [1.3516, 2.3227, 0.7831],
    [1.357, 2.3172, 0.7784],
    [1.3625, 2.3118, 0.7737],
    [1.3679, 2.3064, 0.769],
    [1.3733, 2.3009, 0.7644],
    [1.3787, 2.2955, 0.7597],
    [1.3842, 2.29, 0.755],
    [1.3896, 2.2846, 0.7503],
    [1.395, 2.2791, 0.7456],
    [1.4004, 2.2737, 0.7409],
    [1.4059, 2.2682, 0.7363],
    [1.4112, 2.2627, 0.7316],
    [1.4164, 2.2571, 0.7268],
    [1.4216, 2.2514, 0.7221],
    [1.4268, 2.2458, 0.7174],
    [1.432, 2.2402, 0.7127],
    [1.4372, 2.2345, 0.708],
    [1.4424, 2.2289, 0.7033],
    [1.4476, 2.2233, 0.6986],
    [1.4528, 2.2176, 0.6939],
    [1.458, 2.212, 0.6892],
    [1.4632, 2.2064, 0.6844],
    [1.4684, 2.2008, 0.6797],
    [1.4736, 2.1951, 0.675],
    [1.4786, 2.1893, 0.6703],
    [1.4836, 2.1835, 0.6656],
    [1.4886, 2.1777, 0.6608],
    [1.4936, 2.1719, 0.6561],
    [1.4986, 2.1661, 0.6514],
    [1.5036, 2.1603, 0.6466],
    [1.5086, 2.1545, 0.6419],
    [1.5136, 2.1487, 0.6372],
    [1.5186, 2.1429, 0.6324],
    [1.5236, 2.1371, 0.6277],
    [1.5286, 2.1313, 0.623],
    [1.5336, 2.1255, 0.6182],
    [1.5385, 2.1197, 0.6135],
    [1.5434, 2.1137, 0.6088],
    [1.5481, 2.1078, 0.604],
    [1.5529, 2.1018, 0.5993],
    [1.5577, 2.0958, 0.5945],
    [1.5625, 2.0899, 0.5898],
    [1.5673, 2.0839, 0.585],
    [1.5721, 2.0779, 0.5803],
    [1.5768, 2.072, 0.5755],
    [1.5816, 2.066, 0.5708],
    [1.5864, 2.06, 0.566],
    [1.5912, 2.0541, 0.5613],
    [1.596, 2.0481, 0.5565],
    [1.6008, 2.0422, 0.5518],
    [1.6055, 2.0362, 0.5471],
    [1.6101, 2.03, 0.5423],
    [1.6147, 2.0239, 0.5375],
    [1.6192, 2.0178, 0.5328],
    [1.6238, 2.0117, 0.528],
    [1.6284, 2.0056, 0.5233],
    [1.633, 1.9995, 0.5185],
    [1.6375, 1.9934, 0.5137],
    [1.6421, 1.9872, 0.509],
    [1.6467, 1.9811, 0.5042],
    [1.6513, 1.975, 0.4995],
    [1.6559, 1.9689, 0.4947],
    [1.6604, 1.9628, 0.4899],
    [1.665, 1.9567, 0.4852],
    [1.6696, 1.9505, 0.4804]
  ],

  zürich: [
    [1.67, 1.95, 0.48],
    [1.6753, 1.9563, 0.4763],
    [1.6805, 1.9625, 0.4724],
    [1.6855, 1.9687, 0.4682],
    [1.6905, 1.9747, 0.4637],
    [1.6953, 1.9805, 0.4588],
    [1.7, 1.9861, 0.4536],
    [1.7044, 1.9915, 0.4479],
    [1.7086, 1.9967, 0.4419],
    [1.7125, 2.0015, 0.4354],
    [1.7161, 2.0059, 0.4284],
    [1.7192, 2.0099, 0.4209],
    [1.7219, 2.0133, 0.413],
    [1.7241, 2.0161, 0.4047],
    [1.7256, 2.0182, 0.3961],
    [1.7265, 2.0195, 0.3873],
    [1.7268, 2.0202, 0.3783],
    [1.7265, 2.0201, 0.3693],
    [1.7256, 2.0193, 0.3604],
    [1.7241, 2.0178, 0.3517],
    [1.722, 2.0157, 0.3431],
    [1.7196, 2.013, 0.3349],
    [1.7167, 2.01, 0.3269],
    [1.7135, 2.0064, 0.3193],
    [1.7101, 2.0026, 0.3119],
    [1.7063, 1.9984, 0.3049],
    [1.7024, 1.994, 0.2981],
    [1.6982, 1.9893, 0.2916],
    [1.6939, 1.9845, 0.2854]
  ],

  singapore: [
    [0.29, -0.01, -2.6],
    [0.296, -0.0033, -2.6008],
    [0.302, 0.0033, -2.6015],
    [0.308, 0.01, -2.6023],
    [0.314, 0.0167, -2.603],
    [0.32, 0.0234, -2.6038],
    [0.3259, 0.03, -2.6045],
    [0.3319, 0.0367, -2.6053],
    [0.3379, 0.0434, -2.606],
    [0.3439, 0.0501, -2.6068],
    [0.3499, 0.0567, -2.6075],
    [0.3559, 0.0634, -2.6083],
    [0.3619, 0.0701, -2.609],
    [0.3679, 0.0768, -2.6098],
    [0.3739, 0.0834, -2.6106],
    [0.3799, 0.0901, -2.6113],
    [0.3859, 0.0968, -2.6121],
    [0.3918, 0.1035, -2.6126],
    [0.3978, 0.1102, -2.6131],
    [0.4038, 0.1169, -2.6136],
    [0.4098, 0.1236, -2.6141],
    [0.4158, 0.1303, -2.6146],
    [0.4218, 0.137, -2.6151],
    [0.4278, 0.1437, -2.6156],
    [0.4337, 0.1504, -2.6162],
    [0.4397, 0.1571, -2.6167],
    [0.4457, 0.1638, -2.6172],
    [0.4517, 0.1705, -2.6177],
    [0.4577, 0.1772, -2.6182],
    [0.4637, 0.1839, -2.6187],
    [0.4697, 0.1906, -2.6192],
    [0.4757, 0.1973, -2.6197],
    [0.4816, 0.204, -2.6201],
    [0.4876, 0.2107, -2.6204],
    [0.4936, 0.2174, -2.6206],
    [0.4996, 0.2242, -2.6209],
    [0.5055, 0.2309, -2.6211],
    [0.5115, 0.2376, -2.6213],
    [0.5175, 0.2443, -2.6216],
    [0.5235, 0.2511, -2.6218],
    [0.5295, 0.2578, -2.622],
    [0.5354, 0.2645, -2.6223],
    [0.5414, 0.2712, -2.6225],
    [0.5474, 0.278, -2.6228],
    [0.5534, 0.2847, -2.623],
    [0.5593, 0.2914, -2.6232],
    [0.5653, 0.2981, -2.6235],
    [0.5713, 0.3049, -2.6237],
    [0.5773, 0.3116, -2.6236],
    [0.5832, 0.3184, -2.6236],
    [0.5892, 0.3251, -2.6236],
    [0.5951, 0.3318, -2.6235],
    [0.6011, 0.3386, -2.6235],
    [0.6071, 0.3453, -2.6235],
    [0.613, 0.3521, -2.6234],
    [0.619, 0.3588, -2.6234],
    [0.6249, 0.3656, -2.6233],
    [0.6309, 0.3723, -2.6233],
    [0.6369, 0.379, -2.6233],
    [0.6428, 0.3858, -2.6232],
    [0.6488, 0.3925, -2.6232],
    [0.6547, 0.3993, -2.6232],
    [0.6607, 0.406, -2.6229],
    [0.6666, 0.4128, -2.6226],
    [0.6726, 0.4195, -2.6223],
    [0.6785, 0.4263, -2.6219],
    [0.6844, 0.433, -2.6216],
    [0.6904, 0.4398, -2.6213],
    [0.6963, 0.4466, -2.621],
    [0.7023, 0.4533, -2.6207],
    [0.7082, 0.4601, -2.6203],
    [0.7141, 0.4668, -2.62],
    [0.7201, 0.4736, -2.6197],
    [0.726, 0.4803, -2.6194],
    [0.732, 0.4871, -2.619],
    [0.7379, 0.4938, -2.6187],
    [0.7438, 0.5006, -2.6181],
    [0.7497, 0.5074, -2.6175],
    [0.7556, 0.5141, -2.6169],
    [0.7615, 0.5209, -2.6163],
    [0.7674, 0.5276, -2.6156],
    [0.7734, 0.5344, -2.615],
    [0.7793, 0.5412, -2.6144],
    [0.7852, 0.5479, -2.6138],
    [0.7911, 0.5547, -2.6132],
    [0.797, 0.5614, -2.6125],
    [0.8029, 0.5682, -2.6119],
    [0.8088, 0.575, -2.6113],
    [0.8147, 0.5817, -2.6107],
    [0.8206, 0.5885, -2.6099],
    [0.8265, 0.5952, -2.609],
    [0.8323, 0.602, -2.608],
    [0.8382, 0.6087, -2.6071],
    [0.8441, 0.6155, -2.6062],
    [0.85, 0.6223, -2.6052],
    [0.8558, 0.629, -2.6043],
    [0.8617, 0.6358, -2.6034],
    [0.8676, 0.6425, -2.6025],
    [0.8735, 0.6493, -2.6015],
    [0.8793, 0.656, -2.6006],
    [0.8852, 0.6628, -2.5997],
    [0.8911, 0.6696, -2.5987],
    [0.8969, 0.6763, -2.5976],
    [0.9027, 0.6831, -2.5963],
    [0.9086, 0.6898, -2.5951],
    [0.9144, 0.6966, -2.5938],
    [0.9202, 0.7033, -2.5926],
    [0.926, 0.71, -2.5913],
    [0.9319, 0.7168, -2.5901],
    [0.9377, 0.7235, -2.5888],
    [0.9435, 0.7303, -2.5876],
    [0.9493, 0.737, -2.5863],
    [0.9552, 0.7438, -2.5851],
    [0.961, 0.7505, -2.5838],
    [0.9668, 0.7573, -2.5825],
    [0.9726, 0.764, -2.5809],
    [0.9783, 0.7707, -2.5793],
    [0.9841, 0.7774, -2.5777],
    [0.9899, 0.7842, -2.5762],
    [0.9956, 0.7909, -2.5746],
    [1.0014, 0.7976, -2.573],
    [1.0072, 0.8043, -2.5714],
    [1.0129, 0.8111, -2.5699],
    [1.0187, 0.8178, -2.5683],
    [1.0245, 0.8245, -2.5667],
    [1.0302, 0.8312, -2.5651],
    [1.036, 0.838, -2.5634],
    [1.0417, 0.8446, -2.5615],
    [1.0474, 0.8513, -2.5596],
    [1.0531, 0.858, -2.5577],
    [1.0588, 0.8647, -2.5558],
    [1.0645, 0.8714, -2.5539],
    [1.0702, 0.8781, -2.5519],
    [1.0759, 0.8848, -2.55],
    [1.0816, 0.8915, -2.5481],
    [1.0873, 0.8982, -2.5462],
    [1.093, 0.9049, -2.5443],
    [1.0987, 0.9116, -2.5424],
    [1.1043, 0.9183, -2.5401],
    [1.11, 0.9249, -2.5379],
    [1.1156, 0.9316, -2.5356],
    [1.1212, 0.9382, -2.5334],
    [1.1269, 0.9449, -2.5311],
    [1.1325, 0.9515, -2.5289],
    [1.1381, 0.9582, -2.5266],
    [1.1437, 0.9648, -2.5243],
    [1.1494, 0.9715, -2.5221],
    [1.155, 0.9781, -2.5198],
    [1.1606, 0.9848, -2.5176],
    [1.1662, 0.9914, -2.515],
    [1.1717, 0.998, -2.5124],
    [1.1772, 1.0046, -2.5098],
    [1.1828, 1.0112, -2.5072],
    [1.1883, 1.0178, -2.5046],
    [1.1939, 1.0244, -2.502],
    [1.1994, 1.031, -2.4994],
    [1.2049, 1.0376, -2.4968],
    [1.2105, 1.0442, -2.4942],
    [1.216, 1.0508, -2.4915],
    [1.2215, 1.0574, -2.4889],
    [1.227, 1.0639, -2.486],
    [1.2324, 1.0704, -2.483],
    [1.2379, 1.077, -2.4801],
    [1.2433, 1.0835, -2.4771],
    [1.2487, 1.09, -2.4741],
    [1.2542, 1.0965, -2.4712],
    [1.2596, 1.1031, -2.4682],
    [1.265, 1.1096, -2.4652],
    [1.2705, 1.1161, -2.4622],
    [1.2759, 1.1227, -2.4593],
    [1.2813, 1.1292, -2.4562],
    [1.2866, 1.1356, -2.4529],
    [1.2919, 1.1421, -2.4495],
    [1.2973, 1.1485, -2.4462],
    [1.3026, 1.155, -2.4429],
    [1.3079, 1.1614, -2.4395],
    [1.3132, 1.1679, -2.4362],
    [1.3185, 1.1743, -2.4329],
    [1.3239, 1.1808, -2.4295],
    [1.3292, 1.1872, -2.4262],
    [1.3345, 1.1936, -2.4228],
    [1.3397, 1.2, -2.4191],
    [1.3449, 1.2063, -2.4154],
    [1.3501, 1.2127, -2.4117],
    [1.3553, 1.219, -2.408],
    [1.3605, 1.2254, -2.4043],
    [1.3657, 1.2317, -2.4006],
    [1.3709, 1.2381, -2.3969],
    [1.3761, 1.2445, -2.3932],
    [1.3812, 1.2508, -2.3895],
    [1.3864, 1.2572, -2.3858],
    [1.3915, 1.2634, -2.3817],
    [1.3965, 1.2696, -2.3777],
    [1.4016, 1.2759, -2.3736],
    [1.4066, 1.2821, -2.3695],
    [1.4117, 1.2883, -2.3655],
    [1.4167, 1.2946, -2.3614],
    [1.4218, 1.3008, -2.3573],
    [1.4269, 1.3071, -2.3533],
    [1.4319, 1.3133, -2.3492],
    [1.4369, 1.3195, -2.345],
    [1.4418, 1.3256, -2.3406],
    [1.4467, 1.3317, -2.3362],
    [1.4516, 1.3378, -2.3317],
    [1.4565, 1.344, -2.3273],
    [1.4614, 1.3501, -2.3229],
    [1.4663, 1.3562, -2.3184],
    [1.4712, 1.3623, -2.314],
    [1.4761, 1.3684, -2.3096],
    [1.481, 1.3745, -2.3051],
    [1.4858, 1.3805, -2.3005],
    [1.4905, 1.3865, -2.2957],
    [1.4952, 1.3925, -2.2909],
    [1.5, 1.3984, -2.2861],
    [1.5047, 1.4044, -2.2813],
    [1.5094, 1.4104, -2.2765],
    [1.5141, 1.4163, -2.2717],
    [1.5189, 1.4223, -2.2669],
    [1.5236, 1.4283, -2.2621],
    [1.5283, 1.4342, -2.2572],
    [1.5328, 1.44, -2.252],
    [1.5374, 1.4458, -2.2468],
    [1.5419, 1.4516, -2.2417],
    [1.5465, 1.4574, -2.2365],
    [1.551, 1.4632, -2.2314],
    [1.5556, 1.469, -2.2262],
    [1.5601, 1.4749, -2.2211],
    [1.5646, 1.4807, -2.2159],
    [1.5692, 1.4865, -2.2107],
    [1.5735, 1.4921, -2.2052],
    [1.5779, 1.4977, -2.1997],
    [1.5822, 1.5034, -2.1942],
    [1.5866, 1.509, -2.1887],
    [1.5909, 1.5146, -2.1832],
    [1.5953, 1.5203, -2.1777],
    [1.5996, 1.5259, -2.1722],
    [1.604, 1.5315, -2.1666],
    [1.6083, 1.5372, -2.1611],
    [1.6125, 1.5426, -2.1553],
    [1.6166, 1.5481, -2.1495],
    [1.6208, 1.5535, -2.1436],
    [1.6249, 1.5589, -2.1378],
    [1.6291, 1.5644, -2.1319],
    [1.6332, 1.5698, -2.126],
    [1.6373, 1.5753, -2.1202],
    [1.6415, 1.5807, -2.1143],
    [1.6456, 1.5861, -2.1085],
    [1.6496, 1.5914, -2.1024],
    [1.6535, 1.5966, -2.0962],
    [1.6574, 1.6019, -2.09],
    [1.6613, 1.6071, -2.0838],
    [1.6652, 1.6123, -2.0776],
    [1.6691, 1.6176, -2.0714],
    [1.6731, 1.6228, -2.0652],
    [1.677, 1.628, -2.059],
    [1.6809, 1.6332, -2.0528],
    [1.6846, 1.6383, -2.0464],
    [1.6883, 1.6433, -2.0399],
    [1.692, 1.6483, -2.0334],
    [1.6957, 1.6533, -2.0269],
    [1.6993, 1.6583, -2.0203],
    [1.703, 1.6633, -2.0138],
    [1.7067, 1.6683, -2.0073],
    [1.7104, 1.6733, -2.0008],
    [1.7141, 1.6784, -1.9943],
    [1.7175, 1.6831, -1.9875],
    [1.721, 1.6879, -1.9807],
    [1.7244, 1.6927, -1.9739],
    [1.7278, 1.6974, -1.9671],
    [1.7313, 1.7022, -1.9603],
    [1.7347, 1.707, -1.9534],
    [1.7381, 1.7118, -1.9466],
    [1.7416, 1.7165, -1.9398],
    [1.745, 1.7213, -1.933],
    [1.7482, 1.7258, -1.9259],
    [1.7514, 1.7303, -1.9188],
    [1.7545, 1.7348, -1.9117],
    [1.7577, 1.7394, -1.9046],
    [1.7609, 1.7439, -1.8975],
    [1.7641, 1.7484, -1.8904],
    [1.7673, 1.7529, -1.8833],
    [1.7705, 1.7574, -1.8762],
    [1.7736, 1.7619, -1.869],
    [1.7765, 1.7662, -1.8616],
    [1.7794, 1.7704, -1.8543],
    [1.7824, 1.7747, -1.8469],
    [1.7853, 1.7789, -1.8395],
    [1.7882, 1.7832, -1.8321],
    [1.7911, 1.7875, -1.8248],
    [1.794, 1.7917, -1.8174],
    [1.797, 1.796, -1.81],
    [1.7998, 1.8001, -1.8026],
    [1.8024, 1.8041, -1.7949],
    [1.8051, 1.8081, -1.7873],
    [1.8077, 1.8121, -1.7797],
    [1.8104, 1.8161, -1.7721],
    [1.813, 1.8201, -1.7645],
    [1.8157, 1.8241, -1.7569],
    [1.8183, 1.8281, -1.7492],
    [1.821, 1.832, -1.7416],
    [1.8235, 1.8359, -1.7339],
    [1.8259, 1.8396, -1.726],
    [1.8283, 1.8433, -1.7182],
    [1.8307, 1.847, -1.7103],
    [1.833, 1.8507, -1.7025],
    [1.8354, 1.8544, -1.6946],
    [1.8378, 1.8581, -1.6868],
    [1.8402, 1.8619, -1.6789],
    [1.8425, 1.8656, -1.6711],
    [1.8448, 1.8691, -1.6631],
    [1.8469, 1.8725, -1.6551],
    [1.849, 1.876, -1.647],
    [1.8511, 1.8794, -1.639],
    [1.8532, 1.8828, -1.6309],
    [1.8553, 1.8862, -1.6229],
    [1.8574, 1.8896, -1.6148],
    [1.8595, 1.8931, -1.6067],
    [1.8616, 1.8965, -1.5987],
    [1.8635, 1.8997, -1.5905],
    [1.8654, 1.9029, -1.5823],
    [1.8672, 1.906, -1.5741],
    [1.869, 1.9091, -1.5658],
    [1.8708, 1.9123, -1.5576],
    [1.8726, 1.9154, -1.5493],
    [1.8745, 1.9185, -1.5411],
    [1.8763, 1.9216, -1.5328],
    [1.8781, 1.9248, -1.5246],
    [1.8798, 1.9278, -1.5163],
    [1.8813, 1.9306, -1.5079],
    [1.8829, 1.9334, -1.4995],
    [1.8844, 1.9363, -1.4911],
    [1.886, 1.9391, -1.4827],
    [1.8875, 1.9419, -1.4743],
    [1.889, 1.9448, -1.4659],
    [1.8906, 1.9476, -1.4575],
    [1.8921, 1.9504, -1.4491],
    [1.8936, 1.9532, -1.4407],
    [1.8949, 1.9558, -1.4321],
    [1.8961, 1.9583, -1.4236],
    [1.8974, 1.9608, -1.415],
    [1.8987, 1.9634, -1.4065],
    [1.8999, 1.9659, -1.3979],
    [1.9012, 1.9684, -1.3894],
    [1.9024, 1.971, -1.3808],
    [1.9037, 1.9735, -1.3723],
    [1.9049, 1.976, -1.3638],
    [1.906, 1.9783, -1.3551],
    [1.907, 1.9806, -1.3465],
    [1.908, 1.9828, -1.3378],
    [1.9089, 1.985, -1.3291],
    [1.9099, 1.9873, -1.3205],
    [1.9109, 1.9895, -1.3118],
    [1.9119, 1.9917, -1.3031],
    [1.9129, 1.994, -1.2945],
    [1.9138, 1.9962, -1.2858],
    [1.9148, 1.9984, -1.2771],
    [1.9155, 2.0003, -1.2684],
    [1.9162, 2.0023, -1.2596],
    [1.9169, 2.0042, -1.2509],
    [1.9176, 2.0061, -1.2421],
    [1.9183, 2.0081, -1.2333],
    [1.919, 2.01, -1.2246],
    [1.9197, 2.0119, -1.2158],
    [1.9204, 2.0139, -1.207],
    [1.9211, 2.0158, -1.1983],
    [1.9217, 2.0176, -1.1895],
    [1.9222, 2.0193, -1.1807],
    [1.9226, 2.0209, -1.1718],
    [1.923, 2.0226, -1.163],
    [1.9235, 2.0242, -1.1541],
    [1.9239, 2.0258, -1.1453],
    [1.9243, 2.0275, -1.1365],
    [1.9248, 2.0291, -1.1276],
    [1.9252, 2.0307, -1.1188],
    [1.9256, 2.0324, -1.1099],
    [1.926, 2.0339, -1.1011],
    [1.9262, 2.0353, -1.0922],
    [1.9263, 2.0366, -1.0833],
    [1.9265, 2.038, -1.0744],
    [1.9267, 2.0393, -1.0655],
    [1.9268, 2.0407, -1.0566],
    [1.927, 2.042, -1.0477],
    [1.9272, 2.0433, -1.0388],
    [1.9273, 2.0447, -1.0299],
    [1.9275, 2.046, -1.021],
    [1.9277, 2.0474, -1.0121],
    [1.9276, 2.0484, -1.0032],
    [1.9275, 2.0495, -0.9942],
    [1.9274, 2.0506, -0.9853],
    [1.9273, 2.0516, -0.9764],
    [1.9272, 2.0527, -0.9674],
    [1.9271, 2.0537, -0.9585],
    [1.927, 2.0548, -0.9496],
    [1.9269, 2.0558, -0.9406],
    [1.9268, 2.0569, -0.9317],
    [1.9267, 2.058, -0.9227],
    [1.9265, 2.0589, -0.9138],
    [1.9262, 2.0597, -0.9048],
    [1.9259, 2.0604, -0.8959],
    [1.9255, 2.0612, -0.8869],
    [1.9252, 2.062, -0.878],
    [1.9248, 2.0628, -0.869],
    [1.9245, 2.0635, -0.86],
    [1.9241, 2.0643, -0.8511],
    [1.9238, 2.0651, -0.8421],
    [1.9234, 2.0659, -0.8332],
    [1.9231, 2.0666, -0.8242],
    [1.9226, 2.0673, -0.8152],
    [1.922, 2.0678, -0.8063],
    [1.9214, 2.0683, -0.7973],
    [1.9209, 2.0688, -0.7883],
    [1.9203, 2.0693, -0.7794],
    [1.9197, 2.0698, -0.7704],
    [1.9191, 2.0703, -0.7614],
    [1.9185, 2.0708, -0.7525],
    [1.9179, 2.0713, -0.7435],
    [1.9173, 2.0718, -0.7345],
    [1.9167, 2.0723, -0.7256],
    [1.9161, 2.0727, -0.7166],
    [1.9152, 2.0729, -0.7076],
    [1.9144, 2.0731, -0.6987],
    [1.9136, 2.0734, -0.6897],
    [1.9128, 2.0736, -0.6808],
    [1.912, 2.0738, -0.6718],
    [1.9111, 2.0741, -0.6628],
    [1.9103, 2.0743, -0.6539],
    [1.9095, 2.0745, -0.6449],
    [1.9087, 2.0748, -0.636],
    [1.9078, 2.075, -0.627],
    [1.907, 2.0752, -0.618],
    [1.906, 2.0753, -0.6091],
    [1.905, 2.0752, -0.6002],
    [1.9039, 2.0752, -0.5912],
    [1.9029, 2.0752, -0.5823],
    [1.9018, 2.0752, -0.5733],
    [1.9008, 2.0751, -0.5644],
    [1.8997, 2.0751, -0.5555],
    [1.8987, 2.0751, -0.5465],
    [1.8976, 2.0751, -0.5376],
    [1.8966, 2.075, -0.5287],
    [1.8955, 2.075, -0.5197],
    [1.8945, 2.075, -0.5108],
    [1.8932, 2.0748, -0.5019],
    [1.892, 2.0745, -0.493],
    [1.8907, 2.0742, -0.4841],
    [1.8894, 2.0739, -0.4751],
    [1.8882, 2.0736, -0.4662],
    [1.8869, 2.0734, -0.4573],
    [1.8856, 2.0731, -0.4484],
    [1.8843, 2.0728, -0.4395],
    [1.8831, 2.0725, -0.4306],
    [1.8818, 2.0723, -0.4217],
    [1.8805, 2.072, -0.4128],
    [1.8793, 2.0717, -0.4039],
    [1.8779, 2.0713, -0.395],
    [1.8764, 2.0708, -0.3861],
    [1.875, 2.0703, -0.3773],
    [1.8735, 2.0698, -0.3684],
    [1.872, 2.0693, -0.3596],
    [1.8705, 2.0687, -0.3507],
    [1.8691, 2.0682, -0.3418],
    [1.8676, 2.0677, -0.333],
    [1.8661, 2.0672, -0.3241],
    [1.8646, 2.0667, -0.3152],
    [1.8632, 2.0662, -0.3064],
    [1.8617, 2.0656, -0.2975],
    [1.8602, 2.0651, -0.2887],
    [1.8586, 2.0645, -0.2798],
    [1.8569, 2.0637, -0.271],
    [1.8553, 2.063, -0.2622],
    [1.8536, 2.0622, -0.2534],
    [1.8519, 2.0615, -0.2446],
    [1.8502, 2.0607, -0.2358],
    [1.8486, 2.06, -0.227],
    [1.8469, 2.0592, -0.2181],
    [1.8452, 2.0585, -0.2093],
    [1.8435, 2.0577, -0.2005],
    [1.8419, 2.057, -0.1917],
    [1.8402, 2.0562, -0.1829],
    [1.8385, 2.0555, -0.1741],
    [1.8368, 2.0547, -0.1653],
    [1.8349, 2.0537, -0.1565],
    [1.8331, 2.0527, -0.1478],
    [1.8312, 2.0518, -0.139],
    [1.8293, 2.0508, -0.1303],
    [1.8275, 2.0498, -0.1215],
    [1.8256, 2.0489, -0.1128],
    [1.8237, 2.0479, -0.104],
    [1.8219, 2.0469, -0.0953],
    [1.82, 2.0459, -0.0865],
    [1.8181, 2.045, -0.0778],
    [1.8163, 2.044, -0.069],
    [1.8144, 2.043, -0.0603],
    [1.8125, 2.0421, -0.0515],
    [1.8106, 2.041, -0.0428],
    [1.8086, 2.0398, -0.0341],
    [1.8065, 2.0386, -0.0254],
    [1.8045, 2.0375, -0.0168],
    [1.8024, 2.0363, -0.0081],
    [1.8004, 2.0351, 0.0006],
    [1.7983, 2.0339, 0.0093],
    [1.7963, 2.0327, 0.018],
    [1.7942, 2.0315, 0.0267],
    [1.7922, 2.0304, 0.0354],
    [1.7901, 2.0292, 0.044],
    [1.7881, 2.028, 0.0527],
    [1.7861, 2.0268, 0.0614],
    [1.784, 2.0256, 0.0701],
    [1.782, 2.0244, 0.0788],
    [1.7798, 2.0231, 0.0874],
    [1.7775, 2.0217, 0.096],
    [1.7753, 2.0203, 0.1046],
    [1.7731, 2.0189, 0.1132],
    [1.7709, 2.0175, 0.1218],
    [1.7687, 2.0161, 0.1304],
    [1.7665, 2.0147, 0.1391],
    [1.7642, 2.0133, 0.1477],
    [1.762, 2.012, 0.1563],
    [1.7598, 2.0106, 0.1649],
    [1.7576, 2.0092, 0.1735],
    [1.7554, 2.0078, 0.1821],
    [1.7532, 2.0064, 0.1907],
    [1.7509, 2.005, 0.1993],
    [1.7487, 2.0036, 0.208],
    [1.7463, 2.0021, 0.2165],
    [1.744, 2.0005, 0.225],
    [1.7416, 1.9989, 0.2336],
    [1.7392, 1.9973, 0.2421],
    [1.7368, 1.9957, 0.2506],
    [1.7344, 1.9942, 0.2592],
    [1.7321, 1.9926, 0.2677],
    [1.7297, 1.991, 0.2762],
    [1.7273, 1.9894, 0.2848],
    [1.7249, 1.9878, 0.2933],
    [1.7226, 1.9863, 0.3018],
    [1.7202, 1.9847, 0.3104],
    [1.7178, 1.9831, 0.3189],
    [1.7154, 1.9815, 0.3274],
    [1.713, 1.9799, 0.336],
    [1.7106, 1.9783, 0.3445],
    [1.7081, 1.9765, 0.3529],
    [1.7055, 1.9748, 0.3614],
    [1.703, 1.973, 0.3698],
    [1.7005, 1.9712, 0.3783],
    [1.6979, 1.9695, 0.3868],
    [1.6954, 1.9677, 0.3952],
    [1.6929, 1.9659, 0.4037],
    [1.6903, 1.9642, 0.4121],
    [1.6878, 1.9624, 0.4206],
    [1.6853, 1.9606, 0.429],
    [1.6827, 1.9589, 0.4375],
    [1.6802, 1.9571, 0.4459],
    [1.6777, 1.9553, 0.4544],
    [1.6751, 1.9536, 0.4628],
    [1.6726, 1.9518, 0.4713],
    [1.6701, 1.9501, 0.4797]
  ],

  berlin: [
    [1.67, 1.95, 0.48],
    [1.6737, 1.9573, 0.4763],
    [1.6774, 1.9646, 0.4725],
    [1.6809, 1.9719, 0.4686],
    [1.6844, 1.9791, 0.4646],
    [1.6878, 1.9864, 0.4604],
    [1.6911, 1.9935, 0.4561],
    [1.6944, 2.0007, 0.4517],
    [1.6975, 2.0078, 0.4471],
    [1.7005, 2.0148, 0.4423],
    [1.7034, 2.0218, 0.4374],
    [1.7062, 2.0287, 0.4324],
    [1.7088, 2.0355, 0.4271],
    [1.7113, 2.0422, 0.4217],
    [1.7136, 2.0489, 0.4161],
    [1.7158, 2.0555, 0.4104],
    [1.7177, 2.0619, 0.4044],
    [1.7196, 2.0682, 0.3982],
    [1.7212, 2.0744, 0.3919],
    [1.7226, 2.0804, 0.3853],
    [1.7238, 2.0862, 0.3786],
    [1.7248, 2.0919, 0.3717],
    [1.7255, 2.0973, 0.3645],
    [1.7259, 2.1025, 0.3572],
    [1.7261, 2.1075, 0.3497],
    [1.726, 2.1122, 0.342],
    [1.7256, 2.1166, 0.3342],
    [1.725, 2.1207, 0.3262],
    [1.724, 2.1245, 0.3181],
    [1.7228, 2.128, 0.3099],
    [1.7212, 2.1311, 0.3016],
    [1.7193, 2.1339, 0.2932],
    [1.7172, 2.1363, 0.2848],
    [1.7148, 2.1383, 0.2764],
    [1.7121, 2.14, 0.268],
    [1.7091, 2.1413, 0.2596],
    [1.7059, 2.1422, 0.2512],
    [1.7025, 2.1427, 0.2429],
    [1.6988, 2.1429, 0.2347],
    [1.6949, 2.1428, 0.2266],
    [1.6908, 2.1423, 0.2186],
    [1.6865, 2.1415, 0.2107],
    [1.6821, 2.1404, 0.2029],
    [1.6776, 2.1391, 0.1953],
    [1.6729, 2.1374, 0.1878],
    [1.668, 2.1356, 0.1804],
    [1.6631, 2.1335, 0.1732],
    [1.658, 2.1311, 0.1662],
    [1.6529, 2.1285, 0.1592],
    [1.6477, 2.1257, 0.1524],
    [1.6424, 2.1228, 0.1458],
    [1.637, 2.1196, 0.1393],
    [1.6316, 2.1163, 0.1329],
    [1.6261, 2.1129, 0.1266],
    [1.6206, 2.1093, 0.1205],
    [1.615, 2.1056, 0.1145],
    [1.6094, 2.1017, 0.1086],
    [1.6038, 2.0977, 0.1029],
    [1.5981, 2.0936, 0.0972],
    [1.5924, 2.0894, 0.0917],
    [1.5867, 2.0851, 0.0862],
    [1.5809, 2.0807, 0.0809]
  ],

  london: [
    [1.62, 1.97, 0.46],
    [1.6207, 1.9784, 0.4632],
    [1.6211, 1.9868, 0.4665],
    [1.6211, 1.9951, 0.4698],
    [1.6207, 2.0034, 0.4732],
    [1.6199, 2.0117, 0.4766],
    [1.6186, 2.0199, 0.4802],
    [1.6168, 2.0279, 0.4838],
    [1.6143, 2.0358, 0.4874],
    [1.6112, 2.0434, 0.491],
    [1.6073, 2.0507, 0.4947],
    [1.6027, 2.0575, 0.4984],
    [1.5974, 2.0638, 0.5019],
    [1.5913, 2.0694, 0.5054],
    [1.5845, 2.0743, 0.5087],
    [1.5771, 2.0784, 0.5118],
    [1.5692, 2.0815, 0.5147],
    [1.5609, 2.0839, 0.5173],
    [1.5523, 2.0854, 0.5196],
    [1.5436, 2.0861, 0.5216],
    [1.5347, 2.0861, 0.5234],
    [1.5259, 2.0855, 0.5249],
    [1.5171, 2.0843, 0.5262],
    [1.5083, 2.0825, 0.5273],
    [1.4996, 2.0804, 0.5282],
    [1.491, 2.0778, 0.5289],
    [1.4825, 2.0749, 0.5294],
    [1.4741, 2.0717, 0.5298]
  ],

  madrid: [
    [1.82, 1.7, 0.79],
    [1.8233, 1.7082, 0.7883],
    [1.8267, 1.7164, 0.7865],
    [1.8298, 1.7246, 0.7846],
    [1.8329, 1.7328, 0.7826],
    [1.8359, 1.741, 0.7805],
    [1.8388, 1.7492, 0.7782],
    [1.8415, 1.7575, 0.7759],
    [1.8441, 1.7657, 0.7734],
    [1.8466, 1.7739, 0.7707],
    [1.8489, 1.7822, 0.7679],
    [1.8511, 1.7904, 0.765],
    [1.853, 1.7986, 0.7618],
    [1.8548, 1.8068, 0.7585],
    [1.8564, 1.8149, 0.755],
    [1.8579, 1.823, 0.7514],
    [1.8591, 1.8311, 0.7476],
    [1.86, 1.8391, 0.7435],
    [1.8608, 1.847, 0.7393],
    [1.8612, 1.8548, 0.7349],
    [1.8614, 1.8625, 0.7303],
    [1.8614, 1.8701, 0.7254],
    [1.861, 1.8776, 0.7204],
    [1.8604, 1.8849, 0.7152],
    [1.8594, 1.892, 0.7097],
    [1.8581, 1.8989, 0.7041],
    [1.8565, 1.9055, 0.6982],
    [1.8546, 1.9119, 0.6922],
    [1.8523, 1.9181, 0.686],
    [1.8497, 1.9239, 0.6797],
    [1.8467, 1.9294, 0.6733],
    [1.8435, 1.9346, 0.6667],
    [1.8399, 1.9395, 0.66],
    [1.836, 1.944, 0.6532],
    [1.8319, 1.9481, 0.6464],
    [1.8275, 1.9519, 0.6395],
    [1.8228, 1.9553, 0.6326],
    [1.8179, 1.9583, 0.6257],
    [1.8128, 1.961, 0.6188],
    [1.8075, 1.9633, 0.6119],
    [1.802, 1.9653, 0.6051],
    [1.7963, 1.967, 0.5983],
    [1.7905, 1.9683, 0.5915],
    [1.7846, 1.9693, 0.5848],
    [1.7785, 1.97, 0.5782],
    [1.7724, 1.9704, 0.5716],
    [1.7661, 1.9706, 0.5652],
    [1.7598, 1.9705, 0.5588],
    [1.7534, 1.9702, 0.5524],
    [1.7469, 1.9696, 0.5462],
    [1.7404, 1.9688, 0.5401],
    [1.7339, 1.9678, 0.534],
    [1.7273, 1.9666, 0.528],
    [1.7206, 1.9653, 0.5221],
    [1.7139, 1.9637, 0.5162],
    [1.7072, 1.962, 0.5105],
    [1.7005, 1.9601, 0.5048],
    [1.6938, 1.9581, 0.4992],
    [1.687, 1.9559, 0.4936],
    [1.6803, 1.9537, 0.4882],
    [1.6735, 1.9512, 0.4828]
  ],

  rome: [
    [1.91, 1.76, 0.27],
    [1.912, 1.7679, 0.2738],
    [1.914, 1.7758, 0.2777],
    [1.9158, 1.7836, 0.2817],
    [1.9174, 1.7915, 0.2857],
    [1.9188, 1.7994, 0.2898],
    [1.92, 1.8073, 0.294],
    [1.9211, 1.8152, 0.2982],
    [1.9219, 1.823, 0.3026],
    [1.9225, 1.8308, 0.307],
    [1.9228, 1.8386, 0.3115],
    [1.9229, 1.8464, 0.316],
    [1.9228, 1.8541, 0.3207],
    [1.9223, 1.8617, 0.3254],
    [1.9215, 1.8693, 0.3302],
    [1.9204, 1.8768, 0.3351],
    [1.9189, 1.8842, 0.34],
    [1.9171, 1.8914, 0.3451],
    [1.9149, 1.8985, 0.3502],
    [1.9124, 1.9054, 0.3553],
    [1.9094, 1.9121, 0.3605],
    [1.906, 1.9186, 0.3657],
    [1.9021, 1.9248, 0.371],
    [1.8979, 1.9308, 0.3762],
    [1.8932, 1.9364, 0.3815],
    [1.8882, 1.9417, 0.3867],
    [1.8827, 1.9467, 0.3918],
    [1.8768, 1.9513, 0.3969],
    [1.8706, 1.9554, 0.4019],
    [1.8641, 1.9592, 0.4068],
    [1.8572, 1.9625, 0.4116],
    [1.8501, 1.9655, 0.4162],
    [1.8427, 1.968, 0.4207],
    [1.8351, 1.9701, 0.425],
    [1.8273, 1.9718, 0.4292],
    [1.8194, 1.9731, 0.4332],
    [1.8113, 1.9741, 0.437],
    [1.8031, 1.9747, 0.4407],
    [1.7948, 1.9749, 0.4442],
    [1.7864, 1.9749, 0.4476],
    [1.778, 1.9745, 0.4507],
    [1.7696, 1.9739, 0.4538],
    [1.7611, 1.973, 0.4567],
    [1.7526, 1.9718, 0.4594],
    [1.7441, 1.9704, 0.462],
    [1.7356, 1.9687, 0.4645],
    [1.7271, 1.9669, 0.4669],
    [1.7187, 1.9649, 0.4691],
    [1.7102, 1.9627, 0.4712],
    [1.7018, 1.9603, 0.4732],
    [1.6933, 1.9578, 0.4752],
    [1.6849, 1.9551, 0.477],
    [1.6766, 1.9523, 0.4787]
  ],

  milan: [
    [1.78, 1.89, 0.31],
    [1.7824, 1.897, 0.3151],
    [1.7846, 1.9039, 0.3204],
    [1.7865, 1.9108, 0.3259],
    [1.7882, 1.9176, 0.3316],
    [1.7895, 1.9242, 0.3375],
    [1.7904, 1.9308, 0.3436],
    [1.7909, 1.9371, 0.35],
    [1.7909, 1.9433, 0.3565],
    [1.7904, 1.9492, 0.3633],
    [1.7893, 1.9547, 0.3703],
    [1.7876, 1.9599, 0.3775],
    [1.7852, 1.9646, 0.3848],
    [1.7822, 1.9688, 0.3922],
    [1.7785, 1.9723, 0.3996],
    [1.7741, 1.9752, 0.4069],
    [1.769, 1.9774, 0.414],
    [1.7635, 1.9788, 0.4209],
    [1.7574, 1.9795, 0.4275],
    [1.7509, 1.9796, 0.4338],
    [1.7442, 1.979, 0.4397],
    [1.7372, 1.9779, 0.4452],
    [1.73, 1.9762, 0.4504],
    [1.7226, 1.9741, 0.4551],
    [1.7152, 1.9715, 0.4595],
    [1.7077, 1.9686, 0.4636],
    [1.7002, 1.9654, 0.4674],
    [1.6927, 1.9619, 0.4709],
    [1.6852, 1.9582, 0.4741],
    [1.6777, 1.9542, 0.4772],
    [1.6702, 1.9501, 0.4799]
  ],

  edinburgh: [
    [1.67, 1.95, 0.48],
    [1.6703, 1.9587, 0.4824],
    [1.6706, 1.9673, 0.4848],
    [1.6707, 1.976, 0.4872],
    [1.6707, 1.9847, 0.4896],
    [1.6705, 1.9933, 0.492],
    [1.6701, 2.002, 0.4944],
    [1.6697, 2.0107, 0.4969],
    [1.6689, 2.0193, 0.4993],
    [1.668, 2.0279, 0.5017],
    [1.667, 2.0365, 0.5042],
    [1.6656, 2.0451, 0.5066],
    [1.6641, 2.0536, 0.509],
    [1.6624, 2.0621, 0.5114],
    [1.6604, 2.0705, 0.5138],
    [1.6581, 2.0789, 0.5163],
    [1.6556, 2.0872, 0.5187],
    [1.6529, 2.0955, 0.521],
    [1.6499, 2.1036, 0.5234],
    [1.6465, 2.1116, 0.5258],
    [1.6428, 2.1195, 0.5281],
    [1.6389, 2.1272, 0.5304],
    [1.6346, 2.1348, 0.5327],
    [1.63, 2.1422, 0.5349],
    [1.625, 2.1494, 0.5371],
    [1.6198, 2.1564, 0.5392],
    [1.6142, 2.1631, 0.5413],
    [1.6083, 2.1696, 0.5433],
    [1.602, 2.1758, 0.5452],
    [1.5955, 2.1817, 0.5471],
    [1.5886, 2.1872, 0.5489],
    [1.5814, 2.1924, 0.5506],
    [1.574, 2.1972, 0.5522],
    [1.5664, 2.2017, 0.5538],
    [1.5585, 2.2058, 0.5552],
    [1.5504, 2.2095, 0.5565],
    [1.5421, 2.2128, 0.5577],
    [1.5337, 2.2157, 0.5589],
    [1.5251, 2.2182, 0.5599],
    [1.5164, 2.2204, 0.5608],
    [1.5076, 2.2221, 0.5616],
    [1.4987, 2.2235, 0.5623],
    [1.4898, 2.2246, 0.563],
    [1.4809, 2.2253, 0.5635],
    [1.4719, 2.2257, 0.5639],
    [1.4629, 2.2257, 0.5643],
    [1.4539, 2.2255, 0.5645],
    [1.4449, 2.225, 0.5647],
    [1.4359, 2.2242, 0.5649],
    [1.427, 2.2232, 0.5649],
    [1.4181, 2.2219, 0.5649],
    [1.4092, 2.2204, 0.5648],
    [1.4004, 2.2187, 0.5646],
    [1.3916, 2.2168, 0.5644],
    [1.3828, 2.2147, 0.5642],
    [1.3742, 2.2124, 0.5638],
    [1.3655, 2.2099, 0.5635],
    [1.3569, 2.2073, 0.5631],
    [1.3484, 2.2044, 0.5626],
    [1.3399, 2.2016, 0.5621],
    [1.3314, 2.1984, 0.5615],
    [1.323, 2.1952, 0.561],
    [1.3147, 2.1919, 0.5604]
  ],

  boston: [
    [0, 1.77, 1.92],
    [0.0064, 1.7763, 1.9207],
    [0.0127, 1.7827, 1.9213],
    [0.0191, 1.789, 1.922],
    [0.0254, 1.7954, 1.9226],
    [0.0318, 1.8017, 1.9233],
    [0.0381, 1.808, 1.9239],
    [0.0445, 1.8144, 1.9246],
    [0.0508, 1.8207, 1.9253],
    [0.0572, 1.8271, 1.9259],
    [0.0636, 1.8333, 1.9264],
    [0.0701, 1.8396, 1.9269],
    [0.0765, 1.8458, 1.9273],
    [0.083, 1.8521, 1.9278],
    [0.0895, 1.8583, 1.9282],
    [0.0959, 1.8646, 1.9287],
    [0.1024, 1.8708, 1.9291],
    [0.1088, 1.8771, 1.9296],
    [0.1153, 1.8833, 1.93],
    [0.1219, 1.8895, 1.9303],
    [0.1284, 1.8956, 1.9305],
    [0.135, 1.9018, 1.9307],
    [0.1416, 1.9079, 1.931],
    [0.1482, 1.9141, 1.9312],
    [0.1547, 1.9202, 1.9314],
    [0.1613, 1.9263, 1.9317],
    [0.1679, 1.9325, 1.9319],
    [0.1745, 1.9386, 1.932],
    [0.1812, 1.9446, 1.932],
    [0.1878, 1.9507, 1.932],
    [0.1945, 1.9567, 1.932],
    [0.2012, 1.9627, 1.932],
    [0.2079, 1.9688, 1.932],
    [0.2146, 1.9748, 1.932],
    [0.2212, 1.9808, 1.932],
    [0.2279, 1.9868, 1.932],
    [0.2347, 1.9927, 1.9318],
    [0.2415, 1.9986, 1.9315],
    [0.2483, 2.0046, 1.9313],
    [0.2551, 2.0105, 1.9311],
    [0.2619, 2.0164, 1.9308],
    [0.2687, 2.0223, 1.9306],
    [0.2754, 2.0282, 1.9304],
    [0.2823, 2.0341, 1.93],
    [0.2891, 2.0398, 1.9296],
    [0.296, 2.0456, 1.9291],
    [0.3029, 2.0514, 1.9286],
    [0.3098, 2.0571, 1.9281],
    [0.3167, 2.0629, 1.9276],
    [0.3236, 2.0687, 1.9272],
    [0.3305, 2.0745, 1.9267],
    [0.3374, 2.0801, 1.926],
    [0.3444, 2.0858, 1.9253],
    [0.3514, 2.0914, 1.9245],
    [0.3584, 2.097, 1.9238],
    [0.3654, 2.1026, 1.9231],
    [0.3724, 2.1083, 1.9223],
    [0.3793, 2.1139, 1.9216],
    [0.3864, 2.1194, 1.9207],
    [0.3935, 2.1249, 1.9197],
    [0.4005, 2.1304, 1.9187],
    [0.4076, 2.1358, 1.9177],
    [0.4147, 2.1413, 1.9167],
    [0.4218, 2.1468, 1.9157],
    [0.4288, 2.1523, 1.9147],
    [0.436, 2.1576, 1.9136],
    [0.4431, 2.1629, 1.9123],
    [0.4503, 2.1682, 1.911],
    [0.4575, 2.1735, 1.9098],
    [0.4646, 2.1788, 1.9085],
    [0.4718, 2.1841, 1.9072],
    [0.479, 2.1894, 1.906],
    [0.4862, 2.1946, 1.9045],
    [0.4934, 2.1997, 1.9029],
    [0.5007, 2.2048, 1.9014],
    [0.5079, 2.2099, 1.8998],
    [0.5152, 2.215, 1.8983],
    [0.5224, 2.2201, 1.8967],
    [0.5297, 2.2252, 1.8951],
    [0.537, 2.2301, 1.8933],
    [0.5443, 2.235, 1.8915],
    [0.5516, 2.2399, 1.8896],
    [0.5589, 2.2448, 1.8878],
    [0.5662, 2.2497, 1.8859],
    [0.5736, 2.2547, 1.8841],
    [0.5809, 2.2594, 1.882],
    [0.5883, 2.2641, 1.8799],
    [0.5957, 2.2688, 1.8778],
    [0.603, 2.2735, 1.8756],
    [0.6104, 2.2782, 1.8735],
    [0.6178, 2.2829, 1.8714],
    [0.6252, 2.2874, 1.8691],
    [0.6326, 2.2919, 1.8666],
    [0.6401, 2.2963, 1.8642],
    [0.6475, 2.3008, 1.8618],
    [0.6549, 2.3053, 1.8593],
    [0.6623, 2.3097, 1.8569],
    [0.6698, 2.314, 1.8543],
    [0.6773, 2.3183, 1.8516],
    [0.6847, 2.3225, 1.8488],
    [0.6922, 2.3267, 1.8461],
    [0.6997, 2.3309, 1.8434],
    [0.7071, 2.3352, 1.8406],
    [0.7146, 2.3392, 1.8376],
    [0.7221, 2.3431, 1.8346],
    [0.7296, 2.3471, 1.8316],
    [0.7371, 2.3511, 1.8286],
    [0.7446, 2.355, 1.8255],
    [0.7521, 2.3589, 1.8224],
    [0.7596, 2.3626, 1.8191],
    [0.7671, 2.3663, 1.8158],
    [0.7746, 2.37, 1.8124],
    [0.7821, 2.3737, 1.8091],
    [0.7896, 2.3774, 1.8057],
    [0.7971, 2.3809, 1.8022],
    [0.8045, 2.3843, 1.7986],
    [0.812, 2.3877, 1.7949],
    [0.8195, 2.3911, 1.7913],
    [0.827, 2.3945, 1.7876],
    [0.8345, 2.3979, 1.7839],
    [0.842, 2.401, 1.78],
    [0.8494, 2.4041, 1.7761],
    [0.8569, 2.4072, 1.7721],
    [0.8644, 2.4103, 1.7682],
    [0.8719, 2.4134, 1.7642],
    [0.8793, 2.4162, 1.76],
    [0.8867, 2.419, 1.7558],
    [0.8941, 2.4218, 1.7515],
    [0.9016, 2.4246, 1.7473],
    [0.909, 2.4274, 1.743],
    [0.9164, 2.43, 1.7386],
    [0.9238, 2.4325, 1.7341],
    [0.9311, 2.435, 1.7296],
    [0.9385, 2.4374, 1.725],
    [0.9459, 2.4399, 1.7205],
    [0.9532, 2.4422, 1.7158],
    [0.9605, 2.4444, 1.711],
    [0.9678, 2.4465, 1.7062],
    [0.9751, 2.4486, 1.7014],
    [0.9824, 2.4508, 1.6965],
    [0.9897, 2.4528, 1.6916],
    [0.9969, 2.4546, 1.6865],
    [1.004, 2.4564, 1.6814],
    [1.0112, 2.4582, 1.6763],
    [1.0184, 2.46, 1.6712],
    [1.0256, 2.4617, 1.6661],
    [1.0327, 2.4632, 1.6607],
    [1.0398, 2.4646, 1.6553],
    [1.0468, 2.466, 1.65],
    [1.0539, 2.4675, 1.6446],
    [1.061, 2.4689, 1.6392],
    [1.0679, 2.47, 1.6336],
    [1.0749, 2.471, 1.6279],
    [1.0818, 2.4721, 1.6223],
    [1.0888, 2.4732, 1.6167],
    [1.0957, 2.4743, 1.611],
    [1.1025, 2.475, 1.6052],
    [1.1093, 2.4757, 1.5993],
    [1.1161, 2.4765, 1.5935],
    [1.1229, 2.4772, 1.5876],
    [1.1296, 2.4779, 1.5817],
    [1.1363, 2.4782, 1.5756],
    [1.1429, 2.4786, 1.5696],
    [1.1495, 2.479, 1.5635],
    [1.1561, 2.4793, 1.5574],
    [1.1627, 2.4797, 1.5513],
    [1.1692, 2.4797, 1.545],
    [1.1756, 2.4797, 1.5387],
    [1.182, 2.4797, 1.5324],
    [1.1885, 2.4797, 1.5261],
    [1.1949, 2.4797, 1.5198],
    [1.2011, 2.4793, 1.5133],
    [1.2073, 2.479, 1.5068],
    [1.2136, 2.4786, 1.5004],
    [1.2198, 2.4783, 1.4939],
    [1.226, 2.4779, 1.4874],
    [1.2321, 2.4772, 1.4807],
    [1.2381, 2.4765, 1.4741],
    [1.2441, 2.4758, 1.4674],
    [1.2501, 2.4751, 1.4608],
    [1.2561, 2.4744, 1.4541],
    [1.2619, 2.4734, 1.4473],
    [1.2677, 2.4723, 1.4405],
    [1.2735, 2.4713, 1.4337],
    [1.2793, 2.4702, 1.4269],
    [1.2851, 2.4692, 1.4201],
    [1.2907, 2.4679, 1.4132],
    [1.2963, 2.4665, 1.4062],
    [1.3018, 2.4651, 1.3993],
    [1.3074, 2.4637, 1.3923],
    [1.3129, 2.4623, 1.3854],
    [1.3183, 2.4607, 1.3784],
    [1.3236, 2.459, 1.3713],
    [1.3289, 2.4573, 1.3642],
    [1.3342, 2.4555, 1.3572],
    [1.3395, 2.4538, 1.3501],
    [1.3448, 2.452, 1.343],
    [1.3498, 2.45, 1.3359],
    [1.3549, 2.4479, 1.3287],
    [1.3599, 2.4459, 1.3215],
    [1.365, 2.4438, 1.3144],
    [1.37, 2.4417, 1.3072],
    [1.3749, 2.4395, 1.3],
    [1.3796, 2.4371, 1.2927],
    [1.3844, 2.4347, 1.2855],
    [1.3892, 2.4324, 1.2782],
    [1.394, 2.43, 1.271],
    [1.3987, 2.4276, 1.2637],
    [1.4032, 2.4249, 1.2564],
    [1.4077, 2.4222, 1.2491],
    [1.4122, 2.4196, 1.2418],
    [1.4167, 2.4169, 1.2344],
    [1.4212, 2.4142, 1.2271],
    [1.4256, 2.4114, 1.2198],
    [1.4299, 2.4084, 1.2124],
    [1.4341, 2.4055, 1.2051],
    [1.4384, 2.4025, 1.1977],
    [1.4426, 2.3995, 1.1903],
    [1.4468, 2.3966, 1.183],
    [1.4509, 2.3934, 1.1756],
    [1.4549, 2.3902, 1.1682],
    [1.4588, 2.387, 1.1608],
    [1.4628, 2.3837, 1.1534],
    [1.4668, 2.3805, 1.146],
    [1.4707, 2.3772, 1.1386],
    [1.4745, 2.3738, 1.1312],
    [1.4782, 2.3703, 1.1237],
    [1.4819, 2.3668, 1.1163],
    [1.4856, 2.3633, 1.1089],
    [1.4893, 2.3598, 1.1015],
    [1.493, 2.3563, 1.0941],
    [1.4966, 2.3527, 1.0866],
    [1.5, 2.3489, 1.0792],
    [1.5034, 2.3452, 1.0718],
    [1.5069, 2.3414, 1.0643],
    [1.5103, 2.3377, 1.0569],
    [1.5137, 2.3339, 1.0495],
    [1.5171, 2.3301, 1.0421],
    [1.5203, 2.3261, 1.0346],
    [1.5234, 2.3221, 1.0272],
    [1.5266, 2.3182, 1.0198],
    [1.5297, 2.3142, 1.0124],
    [1.5329, 2.3102, 1.005],
    [1.536, 2.3062, 0.9975],
    [1.5391, 2.3021, 0.9901],
    [1.5419, 2.2978, 0.9827],
    [1.5448, 2.2936, 0.9753],
    [1.5477, 2.2894, 0.9679],
    [1.5506, 2.2852, 0.9605],
    [1.5535, 2.281, 0.9531],
    [1.5564, 2.2768, 0.9457],
    [1.5591, 2.2724, 0.9383],
    [1.5618, 2.268, 0.9309],
    [1.5644, 2.2635, 0.9235],
    [1.567, 2.2591, 0.9161],
    [1.5697, 2.2547, 0.9088],
    [1.5723, 2.2503, 0.9014],
    [1.5749, 2.2459, 0.894],
    [1.5774, 2.2413, 0.8866],
    [1.5798, 2.2367, 0.8793],
    [1.5822, 2.232, 0.872],
    [1.5845, 2.2274, 0.8646],
    [1.5869, 2.2228, 0.8573],
    [1.5893, 2.2182, 0.8499],
    [1.5917, 2.2136, 0.8426],
    [1.5939, 2.2088, 0.8352],
    [1.5961, 2.204, 0.8279],
    [1.5982, 2.1992, 0.8206],
    [1.6004, 2.1944, 0.8133],
    [1.6025, 2.1896, 0.806],
    [1.6046, 2.1848, 0.7987],
    [1.6068, 2.18, 0.7914],
    [1.6089, 2.1752, 0.7841],
    [1.6108, 2.1702, 0.7769],
    [1.6127, 2.1653, 0.7696],
    [1.6146, 2.1603, 0.7623],
    [1.6165, 2.1553, 0.7551],
    [1.6184, 2.1504, 0.7478],
    [1.6203, 2.1454, 0.7406],
    [1.6222, 2.1404, 0.7333],
    [1.6241, 2.1354, 0.7261],
    [1.6258, 2.1303, 0.7189],
    [1.6274, 2.1251, 0.7117],
    [1.6291, 2.12, 0.7045],
    [1.6308, 2.1149, 0.6973],
    [1.6324, 2.1097, 0.6901],
    [1.6341, 2.1046, 0.6829],
    [1.6358, 2.0995, 0.6757],
    [1.6374, 2.0943, 0.6685],
    [1.6389, 2.0891, 0.6613],
    [1.6403, 2.0838, 0.6542],
    [1.6418, 2.0785, 0.647],
    [1.6432, 2.0732, 0.6399],
    [1.6447, 2.0679, 0.6327],
    [1.6461, 2.0626, 0.6256],
    [1.6475, 2.0574, 0.6185],
    [1.649, 2.0521, 0.6113],
    [1.6503, 2.0467, 0.6042],
    [1.6515, 2.0413, 0.5971],
    [1.6528, 2.0359, 0.59],
    [1.654, 2.0305, 0.583],
    [1.6552, 2.0251, 0.5759],
    [1.6565, 2.0196, 0.5688],
    [1.6577, 2.0142, 0.5617],
    [1.6589, 2.0088, 0.5546],
    [1.6601, 2.0034, 0.5476],
    [1.6612, 1.9979, 0.5405],
    [1.6622, 1.9923, 0.5335],
    [1.6633, 1.9868, 0.5265],
    [1.6643, 1.9812, 0.5195],
    [1.6653, 1.9757, 0.5125],
    [1.6663, 1.9701, 0.5055],
    [1.6673, 1.9646, 0.4984],
    [1.6683, 1.959, 0.4914],
    [1.6694, 1.9535, 0.4844]
  ],

  geneva: [
    [1.75, 1.9, 0.4],
    [1.7517, 1.9076, 0.4045],
    [1.7528, 1.9151, 0.4093],
    [1.7534, 1.9226, 0.4143],
    [1.7533, 1.9298, 0.4196],
    [1.7522, 1.9368, 0.4252],
    [1.7502, 1.9434, 0.431],
    [1.747, 1.9493, 0.437],
    [1.7426, 1.9543, 0.4431],
    [1.737, 1.9582, 0.449],
    [1.7305, 1.961, 0.4545],
    [1.7232, 1.9624, 0.4596],
    [1.7154, 1.9627, 0.4641],
    [1.7074, 1.962, 0.468],
    [1.6992, 1.9604, 0.4714],
    [1.6909, 1.9581, 0.4743],
    [1.6828, 1.9553, 0.4768],
    [1.6747, 1.9521, 0.4789]
  ],

  seoul: [
    [-0.59, 1.57, -2],
    [-0.5847, 1.5772, -1.9993],
    [-0.5794, 1.5845, -1.9986],
    [-0.5741, 1.5917, -1.9979],
    [-0.5687, 1.5989, -1.9972],
    [-0.5634, 1.6061, -1.9965],
    [-0.5581, 1.6134, -1.9958],
    [-0.5528, 1.6206, -1.9951],
    [-0.5475, 1.6278, -1.9944],
    [-0.5422, 1.6351, -1.9937],
    [-0.5368, 1.6423, -1.993],
    [-0.5315, 1.6495, -1.9923],
    [-0.5262, 1.6567, -1.9916],
    [-0.5209, 1.664, -1.9909],
    [-0.5156, 1.6712, -1.9902],
    [-0.5102, 1.6783, -1.9893],
    [-0.5048, 1.6855, -1.9884],
    [-0.4993, 1.6926, -1.9875],
    [-0.4939, 1.6997, -1.9866],
    [-0.4885, 1.7069, -1.9857],
    [-0.4831, 1.714, -1.9848],
    [-0.4777, 1.7211, -1.9839],
    [-0.4722, 1.7282, -1.983],
    [-0.4668, 1.7354, -1.9821],
    [-0.4614, 1.7425, -1.9811],
    [-0.456, 1.7496, -1.9802],
    [-0.4506, 1.7568, -1.9793],
    [-0.4452, 1.7639, -1.9784],
    [-0.4397, 1.771, -1.9774],
    [-0.4342, 1.778, -1.9763],
    [-0.4287, 1.785, -1.9752],
    [-0.4231, 1.792, -1.9741],
    [-0.4176, 1.799, -1.9729],
    [-0.4121, 1.8061, -1.9718],
    [-0.4066, 1.8131, -1.9707],
    [-0.401, 1.8201, -1.9696],
    [-0.3955, 1.8271, -1.9684],
    [-0.39, 1.8341, -1.9673],
    [-0.3845, 1.8411, -1.9662],
    [-0.379, 1.8482, -1.9651],
    [-0.3734, 1.8552, -1.9639],
    [-0.3679, 1.8622, -1.9627],
    [-0.3623, 1.8691, -1.9614],
    [-0.3566, 1.8759, -1.96],
    [-0.351, 1.8828, -1.9587],
    [-0.3454, 1.8897, -1.9573],
    [-0.3398, 1.8966, -1.956],
    [-0.3341, 1.9035, -1.9546],
    [-0.3285, 1.9104, -1.9533],
    [-0.3229, 1.9173, -1.9519],
    [-0.3173, 1.9242, -1.9506],
    [-0.3116, 1.9311, -1.9492],
    [-0.306, 1.938, -1.9479],
    [-0.3004, 1.9449, -1.9465],
    [-0.2947, 1.9517, -1.945],
    [-0.2889, 1.9584, -1.9434],
    [-0.2832, 1.9652, -1.9418],
    [-0.2775, 1.972, -1.9402],
    [-0.2718, 1.9787, -1.9386],
    [-0.266, 1.9855, -1.937],
    [-0.2603, 1.9922, -1.9355],
    [-0.2546, 1.999, -1.9339],
    [-0.2489, 2.0058, -1.9323],
    [-0.2431, 2.0125, -1.9307],
    [-0.2374, 2.0193, -1.9291],
    [-0.2317, 2.0261, -1.9275],
    [-0.2259, 2.0327, -1.9257],
    [-0.2201, 2.0393, -1.9239],
    [-0.2142, 2.0459, -1.9221],
    [-0.2084, 2.0525, -1.9203],
    [-0.2026, 2.0591, -1.9185],
    [-0.1968, 2.0658, -1.9166],
    [-0.1909, 2.0724, -1.9148],
    [-0.1851, 2.079, -1.913],
    [-0.1793, 2.0856, -1.9112],
    [-0.1735, 2.0922, -1.9093],
    [-0.1676, 2.0988, -1.9075],
    [-0.1618, 2.1054, -1.9056],
    [-0.1559, 2.1118, -1.9035],
    [-0.1499, 2.1183, -1.9014],
    [-0.144, 2.1248, -1.8994],
    [-0.1381, 2.1312, -1.8973],
    [-0.1322, 2.1377, -1.8952],
    [-0.1263, 2.1441, -1.8932],
    [-0.1204, 2.1506, -1.8911],
    [-0.1144, 2.157, -1.889],
    [-0.1085, 2.1635, -1.8869],
    [-0.1026, 2.1699, -1.8849],
    [-0.0966, 2.1764, -1.8827],
    [-0.0906, 2.1826, -1.8804],
    [-0.0846, 2.1889, -1.8781],
    [-0.0786, 2.1952, -1.8758],
    [-0.0726, 2.2015, -1.8734],
    [-0.0666, 2.2078, -1.8711],
    [-0.0606, 2.214, -1.8688],
    [-0.0546, 2.2203, -1.8665],
    [-0.0486, 2.2266, -1.8641],
    [-0.0425, 2.2329, -1.8618],
    [-0.0365, 2.2392, -1.8595],
    [-0.0305, 2.2453, -1.857],
    [-0.0244, 2.2514, -1.8544],
    [-0.0183, 2.2575, -1.8518],
    [-0.0122, 2.2636, -1.8492],
    [-0.0061, 2.2697, -1.8467],
    [0, 2.2758, -1.8441],
    [0.0061, 2.2819, -1.8415],
    [0.0122, 2.288, -1.8389],
    [0.0183, 2.2941, -1.8363],
    [0.0244, 2.3002, -1.8337],
    [0.0305, 2.3062, -1.8311],
    [0.0367, 2.3121, -1.8282],
    [0.0429, 2.318, -1.8254],
    [0.0491, 2.3239, -1.8225],
    [0.0553, 2.3298, -1.8197],
    [0.0614, 2.3357, -1.8168],
    [0.0676, 2.3415, -1.814],
    [0.0738, 2.3474, -1.8111],
    [0.08, 2.3533, -1.8083],
    [0.0862, 2.3592, -1.8054],
    [0.0923, 2.3651, -1.8025],
    [0.0986, 2.3707, -1.7994],
    [0.1049, 2.3764, -1.7963],
    [0.1111, 2.3821, -1.7931],
    [0.1174, 2.3877, -1.79],
    [0.1236, 2.3934, -1.7869],
    [0.1299, 2.3991, -1.7838],
    [0.1361, 2.4047, -1.7806],
    [0.1424, 2.4104, -1.7775],
    [0.1486, 2.4161, -1.7744],
    [0.1549, 2.4216, -1.7711],
    [0.1613, 2.427, -1.7677],
    [0.1676, 2.4325, -1.7643],
    [0.1739, 2.4379, -1.7609],
    [0.1802, 2.4433, -1.7575],
    [0.1865, 2.4488, -1.7541],
    [0.1929, 2.4542, -1.7508],
    [0.1992, 2.4596, -1.7474],
    [0.2055, 2.4651, -1.744],
    [0.2118, 2.4705, -1.7405],
    [0.2182, 2.4757, -1.7369],
    [0.2246, 2.4808, -1.7332],
    [0.231, 2.486, -1.7295],
    [0.2373, 2.4912, -1.7258],
    [0.2437, 2.4964, -1.7222],
    [0.2501, 2.5015, -1.7185],
    [0.2565, 2.5067, -1.7148],
    [0.2629, 2.5119, -1.7111],
    [0.2692, 2.5171, -1.7075],
    [0.2757, 2.522, -1.7035],
    [0.2821, 2.5269, -1.6996],
    [0.2885, 2.5318, -1.6956],
    [0.2949, 2.5367, -1.6917],
    [0.3014, 2.5416, -1.6877],
    [0.3078, 2.5465, -1.6838],
    [0.3142, 2.5515, -1.6798],
    [0.3207, 2.5564, -1.6759],
    [0.3271, 2.5612, -1.6719],
    [0.3335, 2.5659, -1.6677],
    [0.34, 2.5705, -1.6635],
    [0.3465, 2.5751, -1.6592],
    [0.3529, 2.5797, -1.655],
    [0.3594, 2.5844, -1.6508],
    [0.3659, 2.589, -1.6466],
    [0.3723, 2.5936, -1.6423],
    [0.3788, 2.5982, -1.6381],
    [0.3853, 2.6027, -1.6338],
    [0.3917, 2.607, -1.6293],
    [0.3982, 2.6114, -1.6248],
    [0.4047, 2.6157, -1.6203],
    [0.4112, 2.62, -1.6158],
    [0.4177, 2.6243, -1.6113],
    [0.4242, 2.6287, -1.6068],
    [0.4307, 2.633, -1.6023],
    [0.4371, 2.6373, -1.5978],
    [0.4436, 2.6413, -1.593],
    [0.4501, 2.6453, -1.5882],
    [0.4566, 2.6493, -1.5835],
    [0.4631, 2.6533, -1.5787],
    [0.4696, 2.6573, -1.5739],
    [0.4761, 2.6613, -1.5691],
    [0.4826, 2.6653, -1.5644],
    [0.4891, 2.6693, -1.5596],
    [0.4956, 2.6731, -1.5547],
    [0.5021, 2.6768, -1.5496],
    [0.5086, 2.6805, -1.5446],
    [0.5151, 2.6841, -1.5395],
    [0.5216, 2.6878, -1.5345],
    [0.5281, 2.6915, -1.5295],
    [0.5346, 2.6951, -1.5244],
    [0.5411, 2.6988, -1.5194],
    [0.5476, 2.7023, -1.5142],
    [0.554, 2.7056, -1.5089],
    [0.5605, 2.7089, -1.5036],
    [0.567, 2.7123, -1.4983],
    [0.5735, 2.7156, -1.493],
    [0.5799, 2.7189, -1.4877],
    [0.5864, 2.7222, -1.4825],
    [0.5929, 2.7256, -1.4772],
    [0.5993, 2.7287, -1.4717],
    [0.6058, 2.7317, -1.4662],
    [0.6122, 2.7346, -1.4606],
    [0.6187, 2.7376, -1.4551],
    [0.6251, 2.7405, -1.4496],
    [0.6315, 2.7435, -1.444],
    [0.638, 2.7465, -1.4385],
    [0.6444, 2.7494, -1.4329],
    [0.6508, 2.7522, -1.4272],
    [0.6572, 2.7548, -1.4214],
    [0.6636, 2.7573, -1.4157],
    [0.67, 2.7599, -1.4099],
    [0.6764, 2.7625, -1.4041],
    [0.6828, 2.7651, -1.3983],
    [0.6892, 2.7677, -1.3925],
    [0.6956, 2.7703, -1.3867],
    [0.7019, 2.7726, -1.3808],
    [0.7082, 2.7748, -1.3748],
    [0.7146, 2.777, -1.3687],
    [0.7209, 2.7792, -1.3627],
    [0.7272, 2.7814, -1.3567],
    [0.7335, 2.7836, -1.3507],
    [0.7398, 2.7858, -1.3447],
    [0.7462, 2.788, -1.3387],
    [0.7524, 2.7898, -1.3324],
    [0.7586, 2.7916, -1.3262],
    [0.7649, 2.7934, -1.32],
    [0.7711, 2.7953, -1.3138],
    [0.7774, 2.7971, -1.3075],
    [0.7836, 2.7989, -1.3013],
    [0.7898, 2.8007, -1.2951],
    [0.7961, 2.8024, -1.2888],
    [0.8022, 2.8038, -1.2824],
    [0.8083, 2.8052, -1.2759],
    [0.8145, 2.8066, -1.2695],
    [0.8206, 2.808, -1.2631],
    [0.8268, 2.8095, -1.2567],
    [0.8329, 2.8109, -1.2502],
    [0.839, 2.8123, -1.2438],
    [0.8451, 2.8134, -1.2372],
    [0.8511, 2.8145, -1.2306],
    [0.8571, 2.8155, -1.224],
    [0.8632, 2.8165, -1.2174],
    [0.8692, 2.8175, -1.2108],
    [0.8752, 2.8185, -1.2042],
    [0.8812, 2.8195, -1.1976],
    [0.8873, 2.8205, -1.191],
    [0.8931, 2.8211, -1.1842],
    [0.899, 2.8217, -1.1774],
    [0.9049, 2.8223, -1.1706],
    [0.9108, 2.8229, -1.1639],
    [0.9167, 2.8236, -1.1571],
    [0.9226, 2.8242, -1.1503],
    [0.9285, 2.8248, -1.1435],
    [0.9343, 2.8252, -1.1367],
    [0.9401, 2.8254, -1.1298],
    [0.9458, 2.8256, -1.1229],
    [0.9516, 2.8258, -1.1159],
    [0.9573, 2.826, -1.109],
    [0.9631, 2.8262, -1.1021],
    [0.9688, 2.8264, -1.0952],
    [0.9746, 2.8266, -1.0882],
    [0.9802, 2.8266, -1.0812],
    [0.9858, 2.8264, -1.0742],
    [0.9914, 2.8262, -1.0671],
    [0.997, 2.826, -1.0601],
    [1.0025, 2.8258, -1.053],
    [1.0081, 2.8255, -1.0459],
    [1.0137, 2.8253, -1.0389],
    [1.0193, 2.8251, -1.0318],
    [1.0247, 2.8246, -1.0247],
    [1.0301, 2.824, -1.0175],
    [1.0355, 2.8234, -1.0104],
    [1.041, 2.8228, -1.0032],
    [1.0464, 2.8222, -0.996],
    [1.0518, 2.8215, -0.9889],
    [1.0572, 2.8209, -0.9817],
    [1.0626, 2.8203, -0.9745],
    [1.0678, 2.8193, -0.9673],
    [1.073, 2.8183, -0.96],
    [1.0783, 2.8173, -0.9527],
    [1.0835, 2.8163, -0.9455],
    [1.0887, 2.8153, -0.9382],
    [1.0939, 2.8143, -0.931],
    [1.0992, 2.8133, -0.9237],
    [1.1044, 2.8122, -0.9164],
    [1.1094, 2.8108, -0.9091],
    [1.1144, 2.8094, -0.9018],
    [1.1195, 2.8081, -0.8944],
    [1.1245, 2.8067, -0.8871],
    [1.1295, 2.8053, -0.8798],
    [1.1345, 2.8039, -0.8724],
    [1.1396, 2.8025, -0.8651],
    [1.1446, 2.801, -0.8578],
    [1.1494, 2.7993, -0.8504],
    [1.1542, 2.7975, -0.843],
    [1.1591, 2.7957, -0.8356],
    [1.1639, 2.794, -0.8282],
    [1.1687, 2.7922, -0.8208],
    [1.1735, 2.7904, -0.8134],
    [1.1784, 2.7887, -0.8061],
    [1.1832, 2.7869, -0.7987],
    [1.1878, 2.7848, -0.7912],
    [1.1924, 2.7826, -0.7838],
    [1.197, 2.7805, -0.7764],
    [1.2017, 2.7783, -0.769],
    [1.2063, 2.7762, -0.7615],
    [1.2109, 2.7741, -0.7541],
    [1.2155, 2.7719, -0.7467],
    [1.2201, 2.7698, -0.7393],
    [1.2246, 2.7674, -0.7318],
    [1.229, 2.7649, -0.7244],
    [1.2334, 2.7624, -0.7169],
    [1.2378, 2.7599, -0.7095],
    [1.2422, 2.7574, -0.702],
    [1.2466, 2.7549, -0.6946],
    [1.251, 2.7524, -0.6871],
    [1.2554, 2.7499, -0.6797],
    [1.2597, 2.7474, -0.6723],
    [1.2639, 2.7445, -0.6648],
    [1.2681, 2.7417, -0.6574],
    [1.2722, 2.7389, -0.6499],
    [1.2764, 2.736, -0.6425],
    [1.2806, 2.7332, -0.635],
    [1.2848, 2.7303, -0.6276],
    [1.2889, 2.7275, -0.6201],
    [1.2931, 2.7247, -0.6127],
    [1.2972, 2.7216, -0.6052],
    [1.3011, 2.7185, -0.5978],
    [1.3051, 2.7153, -0.5903],
    [1.309, 2.7121, -0.5829],
    [1.313, 2.709, -0.5754],
    [1.3169, 2.7058, -0.568],
    [1.3209, 2.7026, -0.5606],
    [1.3248, 2.6995, -0.5531],
    [1.3288, 2.6963, -0.5457],
    [1.3326, 2.6929, -0.5383],
    [1.3363, 2.6894, -0.5308],
    [1.34, 2.6859, -0.5234],
    [1.3437, 2.6825, -0.516],
    [1.3475, 2.679, -0.5086],
    [1.3512, 2.6755, -0.5012],
    [1.3549, 2.672, -0.4938],
    [1.3586, 2.6685, -0.4863],
    [1.3623, 2.665, -0.4789],
    [1.3659, 2.6614, -0.4715],
    [1.3694, 2.6576, -0.4642],
    [1.3729, 2.6538, -0.4568],
    [1.3764, 2.65, -0.4494],
    [1.3799, 2.6463, -0.442],
    [1.3834, 2.6425, -0.4346],
    [1.3869, 2.6387, -0.4273],
    [1.3904, 2.6349, -0.4199],
    [1.3939, 2.6311, -0.4125],
    [1.3974, 2.6273, -0.4051],
    [1.4006, 2.6232, -0.3978],
    [1.4039, 2.6191, -0.3905],
    [1.4072, 2.6151, -0.3831],
    [1.4104, 2.611, -0.3758],
    [1.4137, 2.6069, -0.3685],
    [1.417, 2.6029, -0.3611],
    [1.4203, 2.5988, -0.3538],
    [1.4235, 2.5947, -0.3465],
    [1.4268, 2.5907, -0.3391],
    [1.43, 2.5865, -0.3318],
    [1.433, 2.5821, -0.3246],
    [1.436, 2.5778, -0.3173],
    [1.4391, 2.5734, -0.31],
    [1.4421, 2.5691, -0.3027],
    [1.4452, 2.5648, -0.2955],
    [1.4482, 2.5604, -0.2882],
    [1.4513, 2.5561, -0.2809],
    [1.4543, 2.5517, -0.2737],
    [1.4574, 2.5474, -0.2664],
    [1.4603, 2.5429, -0.2591],
    [1.4631, 2.5383, -0.2519],
    [1.4659, 2.5337, -0.2447],
    [1.4688, 2.5291, -0.2375],
    [1.4716, 2.5245, -0.2303],
    [1.4744, 2.52, -0.2231],
    [1.4772, 2.5154, -0.2159],
    [1.4801, 2.5108, -0.2087],
    [1.4829, 2.5062, -0.2015],
    [1.4857, 2.5016, -0.1943],
    [1.4885, 2.4969, -0.1871],
    [1.4911, 2.4921, -0.18],
    [1.4937, 2.4873, -0.1728],
    [1.4963, 2.4824, -0.1657],
    [1.4989, 2.4776, -0.1586],
    [1.5015, 2.4728, -0.1515],
    [1.5041, 2.4679, -0.1443],
    [1.5067, 2.4631, -0.1372],
    [1.5093, 2.4583, -0.1301],
    [1.5119, 2.4534, -0.1229],
    [1.5145, 2.4486, -0.1158],
    [1.517, 2.4436, -0.1087],
    [1.5194, 2.4386, -0.1017],
    [1.5218, 2.4335, -0.0946],
    [1.5242, 2.4285, -0.0876],
    [1.5266, 2.4234, -0.0805],
    [1.529, 2.4183, -0.0735],
    [1.5314, 2.4133, -0.0664],
    [1.5338, 2.4082, -0.0594],
    [1.5361, 2.4032, -0.0523],
    [1.5385, 2.3981, -0.0453],
    [1.5409, 2.393, -0.0382],
    [1.5432, 2.3879, -0.0312],
    [1.5454, 2.3826, -0.0242],
    [1.5476, 2.3773, -0.0173],
    [1.5498, 2.3721, -0.0103],
    [1.5519, 2.3668, -0.0034],
    [1.5541, 2.3615, 0.0036],
    [1.5563, 2.3563, 0.0106],
    [1.5585, 2.351, 0.0175],
    [1.5607, 2.3457, 0.0245],
    [1.5628, 2.3404, 0.0314],
    [1.565, 2.3352, 0.0384],
    [1.5672, 2.3299, 0.0454],
    [1.5692, 2.3245, 0.0522],
    [1.5712, 2.319, 0.0591],
    [1.5732, 2.3135, 0.066],
    [1.5751, 2.308, 0.0729],
    [1.5771, 2.3026, 0.0797],
    [1.5791, 2.2971, 0.0866],
    [1.5811, 2.2916, 0.0935],
    [1.583, 2.2862, 0.1003],
    [1.585, 2.2807, 0.1072],
    [1.587, 2.2752, 0.1141],
    [1.589, 2.2698, 0.1209],
    [1.5909, 2.2643, 0.1278],
    [1.5927, 2.2587, 0.1346],
    [1.5945, 2.253, 0.1414],
    [1.5963, 2.2473, 0.1481],
    [1.5981, 2.2417, 0.1549],
    [1.5998, 2.236, 0.1617],
    [1.6016, 2.2304, 0.1685],
    [1.6034, 2.2247, 0.1752],
    [1.6052, 2.2191, 0.182],
    [1.607, 2.2134, 0.1888],
    [1.6087, 2.2078, 0.1955],
    [1.6105, 2.2021, 0.2023],
    [1.6123, 2.1965, 0.2091],
    [1.614, 2.1907, 0.2158],
    [1.6156, 2.1849, 0.2225],
    [1.6172, 2.1791, 0.2292],
    [1.6187, 2.1733, 0.2359],
    [1.6203, 2.1674, 0.2425],
    [1.6219, 2.1616, 0.2492],
    [1.6235, 2.1558, 0.2559],
    [1.6251, 2.15, 0.2625],
    [1.6267, 2.1441, 0.2692],
    [1.6282, 2.1383, 0.2759],
    [1.6298, 2.1325, 0.2826],
    [1.6314, 2.1267, 0.2892],
    [1.633, 2.1208, 0.2959],
    [1.6345, 2.1149, 0.3026],
    [1.6359, 2.109, 0.3091],
    [1.6373, 2.103, 0.3157],
    [1.6387, 2.097, 0.3223],
    [1.6401, 2.091, 0.3289],
    [1.6415, 2.085, 0.3354],
    [1.6429, 2.079, 0.342],
    [1.6443, 2.073, 0.3486],
    [1.6457, 2.0671, 0.3552],
    [1.6471, 2.0611, 0.3617],
    [1.6485, 2.0551, 0.3683],
    [1.6499, 2.0491, 0.3749],
    [1.6513, 2.0431, 0.3814],
    [1.6527, 2.0371, 0.388],
    [1.6539, 2.031, 0.3945],
    [1.6551, 2.0249, 0.401],
    [1.6563, 2.0187, 0.4075],
    [1.6576, 2.0126, 0.4139],
    [1.6588, 2.0065, 0.4204],
    [1.66, 2.0003, 0.4269],
    [1.6612, 1.9942, 0.4333],
    [1.6624, 1.9881, 0.4398],
    [1.6637, 1.9819, 0.4463],
    [1.6649, 1.9758, 0.4528],
    [1.6661, 1.9697, 0.4592],
    [1.6673, 1.9635, 0.4657],
    [1.6685, 1.9574, 0.4722],
    [1.6697, 1.9513, 0.4787]
  ]
}
