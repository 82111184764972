import React, { CSSProperties, useEffect, useRef, useState } from "react"
import useEmblaCarousel from "embla-carousel-react"
import { useStore } from "@state/store"
import { gsap } from "gsap"
import { useGSAP } from "@gsap/react"
import { globalStyles } from "@data/globalStyles"
import { useLocation } from "react-router-dom"
import DownSVG from "@components/SVGs/Down"
import CloseSVG from "@components/SVGs/Close"

const images = {
  1: {
    Exteriors: [
      "/renders/SSLB_4B_cam05.webp",
      "/renders/SSLB_4B_cam12.webp",
      "/renders/SSLB_4B_cam16.webp",
      "/renders/3 Bed.webp",
      "renders/4 Bed.webp",
      "/renders/4 Bed XL.webp",
      "/renders/5 Bed.webp",
      "renders/5 Bed XL.webp",
      "/renders/6 Bed.webp",
      "/renders/6 Bed XL.webp",
      "/renders/7 Bed.webp",
    ],
    Interiors: ["/renders/Bathroom_HR_V2.webp", "/renders/Bedroom_HR_V3.webp"],
    Amenities: [
      "/images/amenities/1.webp",
      "/images/amenities/2.webp",
      "/images/amenities/3.webp",
      "/images/amenities/4.webp",
      "/images/amenities/5.webp",
      "/images/amenities/6.webp",
      "/images/amenities/7.webp",
      "/images/amenities/8.webp",
      "/images/amenities/9.webp",
      "/images/amenities/10.webp",
      "/images/amenities/11.webp",
      "/images/amenities/12.webp",
      "/images/amenities/13.webp",
      "/images/amenities/14.webp",
      "/images/amenities/15.webp",
      "/images/amenities/16.webp",
      "/images/amenities/17.webp",
    ],
  },
  2: {
    Experiences: [
      "/images/experiences/1.webp",
      "/images/experiences/2.webp",
      "/images/experiences/3.webp",
      "/images/experiences/4.webp",
      "/images/experiences/5.webp",
      "/images/experiences/6.webp",
      "/images/experiences/7.webp",
      "/images/experiences/8.webp",
      "/images/experiences/9.webp",
      "/images/experiences/10.webp",
      "/images/experiences/11.webp",
      "/images/experiences/12.webp",
    ],
    Sustainability: [
      "/images/sustainability/1.webp",
      "/images/sustainability/2.webp",
      "/images/sustainability/3.webp",
      "/images/sustainability/4.webp",
      "/images/sustainability/5.webp",
      "/images/sustainability/6.webp",
      "/images/sustainability/7.webp",
    ],
    Wellness: [
      "/images/wellness/1.webp",
      "/images/wellness/2.webp",
      "/images/wellness/3.webp",
      "/images/wellness/4.webp",
      "/images/wellness/5.webp",
      "/images/wellness/6.webp",
      "/images/wellness/7.webp",
      "/images/wellness/8.webp",
      "/images/wellness/9.webp",
      "/images/wellness/10.webp",
      "/images/wellness/11.webp",
      "/images/wellness/12.webp",
      "/images/wellness/13.webp",
    ],
  },
}

export function GalleryPopup({
  gridAutoColumns = "100%",
  columnGap = "0rem",
  ...props
}) {
  const galleryPopup = useStore((state) => state.galleryPopup)
  const galleryCategory = useStore((state) => state.galleryCategory)
  const syncState = useStore((state) => state.syncState)

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: "start",
  })
  const containerRef = useRef(null)

  const [currentImages, setCurrentImages] = useState([])
  const [category, setCategory] = useState("EXTERIORS")

  useEffect(() => {
    // This effect updates the category when galleryCategory changes
    const availableCategories = Object.keys(images[galleryCategory] || {})
    if (!availableCategories.includes(category)) {
      // If current category does not exist in the new galleryCategory, update it to the first available category
      setCategory(availableCategories[0])
    }
    // Update images based on the new galleryCategory and potentially updated category
    setCurrentImages(
      images[galleryCategory][category] ||
        images[galleryCategory][availableCategories[0]]
    )
  }, [galleryCategory, category])

  useGSAP(
    () => {
      gsap.to("#galleryPopup", {
        autoAlpha: galleryPopup ? 1 : 0,
        duration: 0.4,
      })
    },
    { dependencies: [galleryPopup] }
  )

  return (
    <>
      <div
        ref={containerRef}
        id="galleryPopup"
        style={{
          gridColumn: "1 / -1",
          gridRow: "1 / -1",
          overflow: "hidden",
          height: "100%",
          width: "100%",
          pointerEvents: "auto",
          zIndex: 10,
          gridAutoFlow: "row",
          gridTemplateRows: "minmax(0, 16fr) minmax(0, 1fr)",
          backgroundColor: "black",
          ...styles.wrapper,
        }}
      >
        <div
          style={{
            placeSelf: "end",
            // height: "100%",
            // width: "33%",
            gridColumn: "1 / -1",
            gridRow: 2,
            display: "grid",
            gridAutoFlow: "column",
            columnGap: "1rem",
            placeItems: "center",
            padding: "0rem 8rem 2rem 0rem",
            zIndex: 10,
          }}
        >
          {Object.keys(images[galleryCategory]).map((cat, i) => {
            const active = category === cat
            return (
              <button
                onClick={() => setCategory(cat)}
                style={{
                  cursor: "pointer",

                  // height: "100%",
                  // width: "100%",
                  // border: `1px solid ${globalStyles.col.blue}`,
                  border: `1px solid white`,
                  padding: "0.4rem 0.8rem",
                  boxShadow: "none",
                  backgroundColor: "#ffffff25",
                  // backgroundColor: active
                  //   ? globalStyles.col.blue
                  //   : globalStyles.col.white,
                  color: active ? globalStyles.col.white : "#737171",
                  fontFamily: "Canto Roman",
                  fontSize: "1rem",
                  lineHeight: "1.2rem",
                  letterSpacing: "0.1rem",
                  pointerEvents: "auto",
                  transition: "background-color 0.8s, color 0.8s",
                }}
                key={i}
              >
                {cat}
              </button>
            )
          })}
        </div>
        <div
          style={{
            zIndex: 10,
            gridColumn: "1 / -1",
            gridRow: 2,
            display: "grid",
            placeSelf: "end start",
            padding: "0rem 0rem 2rem 8rem",
            columnGap: "1rem",
            placeItems: "center",
            gridAutoFlow: "column",
          }}
        >
          <div
            onClick={() => {
              syncState("galleryPopup", false)
            }}
            style={{
              cursor: "pointer",
              height: "2rem",
              aspectRatio: 1,
              display: "grid",
              placeItems: "center",
              backgroundColor: "#ffffff25",
              border: `1px solid white`,
              zIndex: 10,
            }}
          >
            <CloseSVG
              fill="white"
              style={{
                transform: "rotate(-90deg)",
                height: "40%",
                width: "40%",
              }}
            />
          </div>
          <div
            onClick={() => {
              emblaApi && emblaApi.scrollPrev()
            }}
            style={{
              height: "2rem",
              aspectRatio: 1,
              display: "grid",
              placeItems: "center",
              backgroundColor: "#ffffff25",
              border: `1px solid white`,
              zIndex: 10,
              cursor: "pointer",
            }}
          >
            <DownSVG
              fill="white"
              style={{
                transform: "rotate(-270deg)",
                height: "40%",
                width: "40%",
              }}
            />
          </div>
          <div
            onClick={() => {
              emblaApi && emblaApi.scrollNext()
            }}
            style={{
              height: "2rem",
              aspectRatio: 1,
              display: "grid",
              placeItems: "center",
              backgroundColor: "#ffffff25",
              border: `1px solid white`,
              zIndex: 10,
              cursor: "pointer",
            }}
          >
            <DownSVG
              fill="white"
              style={{
                transform: "rotate(-90deg)",
                height: "40%",
                width: "40%",
              }}
            />
          </div>
        </div>
        {/* Embla */}
        <div
          className="embla"
          style={{
            zIndex: 9,
            gridColumn: "1 / -1",
            gridRow: 1,
            overflow: "hidden",
            ...styles.embla,
          }}
          ref={emblaRef}
          {...props}
        >
          <div
            className="embla__container"
            style={{
              columnGap: columnGap,
              ...styles.emblaContainer,
            }}
          >
            {currentImages &&
              currentImages?.map((src, i) => {
                return (
                  <div
                    className={`embla__slide-${i}`}
                    key={i}
                    style={{
                      touchAction: "auto",
                      marginLeft: columnGap,
                      ...styles.emblaSlide,
                    }}
                  >
                    <img
                      src={src}
                      alt=""
                      style={{
                        objectFit: "cover",
                        objectPosition: "center",
                        overflow: "hidden",
                        width: "100%",
                        height: "100%",
                        ...styles.emblaSlideImage,
                      }}
                    />
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}

const styles: Record<string, CSSProperties> = {
  wrapper: {
    gridColumn: "1 / -1",
    gridRow: "1 / -1",
    overflow: "hidden",
    position: "fixed",
    display: "grid",
    inset: 0,
    height: "100%",
    width: "100%",
    zIndex: 9,
    opacity: 0,
  },
  embla: {
    overflow: "hidden",
    gridColumn: "1 / -1",
    gridRow: "1 / -1",
    height: "100%",
    width: "100%",
    display: "grid",
    placeItems: "center",
  },
  emblaContainer: {
    height: "100vh",
    // height: "100%",
    // width: "100%",
    // gridColumn: "1 / -1",
    // gridRow: "1 / -1",
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "100%",
  },
  emblaSlide: {
    display: "grid",
    placeItems: "center",
    overflow: "hidden",
    // padding: "8rem",
    height: "100%",
    width: "100%",
  },
  emblaSlideImage: {
    height: "100%",
    width: "100%",
    overflow: "hidden",
    objectPosition: "center",
    objectFit: "cover",
  },
  bottomBar: {
    maxWidth: "95%",
    zIndex: 10,
    padding: "4rem",
    marginBottom: "1%",
    // overflow: "hidden",
    gridColumn: "1 / -1",
    gridRow: "1 / -1",
    placeSelf: "end center",
    display: "grid",
    gridAutoFlow: "column",
    placeItems: "center",
    columnGap: "2rem",
  },
  arrowButton: {
    background:
      "linear-gradient(266deg, rgba(251, 251, 248, 0.95) 26.03%, rgba(251, 251, 248, 0.7) 81.52%)",
    border: "none",
    padding: "1.1rem 4rem",
    borderRadius: "10rem",
    fontSize: "2rem",
    lineHeight: "2rem",
    letterSpacing: "0.4rem",
    color: globalStyles.col.blue,
    height: "4rem",
    textDecoration: "none",
    textAlign: "center",
    display: "grid",
    placeItems: "center",
    gridAutoFlow: "column",
    columnGap: "2rem",
  },
}
