//@ts-nocheck
function EmailTemplate({ link }) {
  const pStyle = {
    fontFamily: `sans-serif`,
    fontSize: "15px",
    textAlign: "center",
    width: "60%",
    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "20%",
  }
  const h3Style = {
    fontFamily: `sans-serif`,
    fontSize: "20px",
    lineHeight: "25px",
    textAlign: "center",
    width: "60%",
    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "20%",
  }
  const thStyle = {
    position: "relative",
    width: "100%",
  }

  const resetStyle = {
    margin: 0,
    padding: 0,
    border: 0,
    verticalAlign: "baseline",
  }

  return (
    <html
      style={{
        width: "1269px",
        maxWidth: "100vw",
        backgroundColor: "white",
      }}
    >
      <head></head>
      <body
        style={{
          width: "1269px",
          maxWidth: "100vw",
          backgroundColor: "white",
        }}
      >
        <table
          cellSpacing={0}
          style={{
            width: "1269px",
            maxWidth: "100vw",
          }}
        >
          <tbody>
            <tr>
              <th
                style={{
                  ...resetStyle,
                  ...thStyle,
                  height: "714px",
                }}
              >
                <a
                  href={link}
                  target="_blank"
                  style={{
                    ...resetStyle,
                    display: "block",
                    position: "relative",
                    width: "100%",
                    height: "550px",
                  }}
                >
                  <img
                    src="https://ss.lesbordesestate.app/images/email/emailHeader.png"
                    alt=""
                    style={{
                      ...resetStyle,
                      display: "block",
                      position: "relative",
                      width: "100%",
                      height: "714px",
                      objectFit: "contain",
                    }}
                  />
                </a>
              </th>
            </tr>
            <tr>
              <th
                style={{
                  ...resetStyle,
                  ...thStyle,
                  height: "232px",
                }}
              >
                <a
                  href={"https://lesbordesestate.com/six-senses-residences/"}
                  target="_blank"
                  style={{
                    ...resetStyle,
                    display: "block",
                    position: "relative",
                    width: "100%",
                    height: "232px",
                  }}
                >
                  <img
                    src="https://ss.lesbordesestate.app/images/email/emailFooter.png"
                    alt=""
                    style={{
                      ...resetStyle,
                      display: "block",
                      position: "relative",
                      width: "100%",
                      height: "232px",
                      objectFit: "contain",
                    }}
                  />
                </a>
              </th>
            </tr>
          </tbody>
        </table>
      </body>
    </html>
  )
}

export default EmailTemplate
