export default function ArrowSVG({ ...props }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 602 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M601.621 7.62149C601.965 7.27825 601.965 6.72175 601.621 6.37851L596.028 0.785104C595.685 0.441865 595.128 0.441865 594.785 0.785104C594.442 1.12834 594.442 1.68484 594.785 2.02808L599.757 7L594.785 11.9719C594.442 12.3152 594.442 12.8717 594.785 13.2149C595.128 13.5581 595.685 13.5581 596.028 13.2149L601.621 7.62149ZM601 6.12108L0 6.12108V7.87892L601 7.87892V6.12108Z"
        fill="#F1EFE7"
      />
    </svg>
  )
}
